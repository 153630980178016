import React, { useEffect, useContext, useState } from 'react'
import { Form, Formik } from 'formik'

import { DashboardContext } from '../../context/dashboard-state'
import { UIContext } from '../../context/ui-state'

import Env from '../../config/env'
import { NOT_EMPTY_REGEX } from '../../config/validators'

import CustomField from '../../components/custom-field/custom-field'
import CheckboxField from '../../components/checkbox-field/checkbox-field'
import { InfoTip, ErrorTip } from '../../components/tip/tip'

import './cancel-and-refund-form.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const CancelAndRefundForm = props => {

  const dashboardContext = useContext( DashboardContext )
  const UI = useContext( UIContext )
  const { pledge, cancelAndRefundPledge } = dashboardContext
  const { className = '', hasUnrefundableCharges = false } = props
  const [ isCanceling, setCanceling ] = useState( false )
  const [ isConfirmed, setConfirmed ] = useState( false )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleValidation = values => {
    const errors = {}

    if ( !NOT_EMPTY_REGEX.test( values.reason ) ) errors.reason = 'Invalid reason'

    return errors
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSubmit = async payload => {
    if ( !window.confirm( `Are you sure you want to cancel${ payload.refund ? ' and refund with fees' : '' }?\n\n${ hasUnrefundableCharges ? `REMEMBER: This pledge contains Paypal transactions that are ${ Env.paypalTransactionRefundMaxAge } days or older. If you cancel it, these transactions will have to be refunded manually.\n\n` : '' }This action can't be undone. ` ) ) return

    setCanceling( true )

    try {
      await cancelAndRefundPledge( pledge.details.id, payload )
      UI.showSuccessDisclaimer( `The canceling ${ payload.refund ? 'and refunding' : '' } was performed successfully!` )

    } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }

    setCanceling( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <div className={ `pd-cancel-and-refund-form pd-white-box ${ className }` }>
      <h3 className="pd-title-3">Cancel pledge</h3>
      <Formik
        initialValues={ { reason: '', refund: false, waiveFee: false } }
        onSubmit={ handleSubmit }
        validate={ handleValidation }
        validateOnChange={ false }
        validateOnBlur={ false }>
        { fProps => (
          <Form className="pd-cancel-and-refund-form__form">
            <CustomField label="Reason" name="reason" className="pd-cancel-and-refund-form__reason-field" />
            <CheckboxField name="refund" className="pd-cancel-and-refund-form__refund-field">Refund</CheckboxField>

            { fProps.values.refund ? (

              <CheckboxField name="waiveFee" className="pd-cancel-and-refund-form__waive-field">Waive Fee</CheckboxField>

            ) : null }

            { fProps.values.refund && hasUnrefundableCharges ? (

              <ErrorTip className="pd-cancel-and-refund-form__disclaimer">
                <div className="pd-cancel-and-refund-form__unrefundable-disclaimer">
                  <h3 className="pd-title-4">Important notice</h3>

                  <p>
                    This pledge contains <strong>Paypal</strong> transactions that are
                    <strong>{ Env.paypalTransactionRefundMaxAge } days or older</strong>.
                    <strong>Refund the transactions manually</strong> when canceling this pledge.
                  </p>

                  <label className="pd-cancel-and-refund-form__confirmation" htmlFor="refund-confirmation">
                    <input id="refund-confirmation" type="checkbox" onChange={ evt => setConfirmed( evt.target.checked ) } />
                    <strong>I understood and wish to procceed to cancel.</strong>
                  </label>

                  <button
                    className="pd-button pd-button--danger"
                    disabled={ fProps.isSubmitting || isCanceling || ( !isConfirmed ) }>
                    { fProps.isSubmitting || isCanceling ? 'Processing...' : ( fProps.values.refund ? 'Cancel and refund' : 'Cancel' ) }
                  </button>
                </div>
              </ErrorTip>

            ) : (

              <button
                className="pd-button pd-button--danger pd-cancel-and-refund-form__submit"
                disabled={ fProps.isSubmitting || isCanceling }>
                { fProps.isSubmitting || isCanceling ? 'Processing...' : ( fProps.values.refund ? 'Cancel and refund' : 'Cancel' ) }
              </button>

            ) }

            { fProps.values.refund ? (

              <InfoTip className="pd-cancel-and-refund-form__disclaimer">
                Processing fees will be charged if Paypal or Stripe refunds are made after 15
                days of purchase. <strong>Kickstarter refunds are NOT processed by the system at all</strong>,
                those must be done separately <strong>after</strong> you cancel an order on this system.
              </InfoTip>

            ) : null }

          </Form>
        ) }
      </Formik>
    </div>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default CancelAndRefundForm