import React, { useEffect, useContext, useState, useRef } from 'react'
import { find, findIndex, keys, startCase } from 'lodash'
import { PortalWithState } from 'react-portal'
import { Formik, Form, Field } from 'formik'
import { useParams } from 'react-router'

import { GlobalContext } from '../../context/global-state'
import { DashboardContext } from '../../context/dashboard-state'
import { UIContext } from '../../context/ui-state'

import { ROLE } from '../../config/constants'
import { sFetch } from '../../config/fetch'

import Lightbox from '../../components/lightbox/lightbox'
import Table from '../../components/table/table'

import './collaborators.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const Collaborators = props => {

  const context = useContext( GlobalContext )
  const dashboardContext = useContext( DashboardContext )
  const UI = useContext( UIContext )
  const { loggedUser } = context
  const { user } = dashboardContext
  const [ loggedUserCompanies, setLoggedUserCompanies ] = useState( [] )
  const [ isProccessing, setProccessing ] = useState( false )
  const { userId } = useParams()
  const lightboxRef = useRef()
  const { className = '' } = props

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => {
    parseTableContent()
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const parseTableContent = () => {
    const loggedUserCompanies = loggedUser.data.companies.filter( c => c.roles.indexOf( ROLE.SUPER_ADMIN ) >= 0 )
    const currentUserCompanies = user.details.companies

    const dataset = loggedUserCompanies.map( loggedUserCompany => {
      const currentUserCompany = find( currentUserCompanies, { id: loggedUserCompany.id } )

      return {
        id: loggedUserCompany.id,
        name: loggedUserCompany.name,
        roles: currentUserCompany?.roles,
        isMember: !!currentUserCompany
      }
    } )

    setLoggedUserCompanies( dataset )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSave = async payload => {
    setProccessing( true )

    try {

      const collaboratorsByCompany = await sFetch( `/companies/${ payload.companyId }/collaborators` )
      const collaborator = find( collaboratorsByCompany, c => ( c.user.id === parseInt( userId ) ) )
      let savedCollaborator

      if ( collaborator ) {

        savedCollaborator = await sFetch( `/companies/${ payload.companyId }/collaborators/${ collaborator.id }`, {
          method: 'put',
          body: JSON.stringify( {
            collaborator: {
              email: user.details.email,
              roles: payload.roles
            }
          } )
        } )

      } else {

        savedCollaborator = await sFetch( `/companies/${ payload.companyId }/collaborators/`, {
          method: 'post',
          body: JSON.stringify( {
            collaborator: {
              email: user.details.email,
              roles: payload.roles
            }
          } )
        } )

      }

      const index = findIndex( loggedUserCompanies, { id: savedCollaborator.company.id } )
      if ( index >= 0 ) {
        loggedUserCompanies[ index ].roles = savedCollaborator.roles
        loggedUserCompanies[ index ].isMember = true
        setLoggedUserCompanies( loggedUserCompanies )
      }

      UI.showSuccessDisclaimer( 'You have updated collaborator access privileges successfully!' )

    } catch ( exception ) {

      UI.showErrorDisclaimer( exception.message )

    }

    setProccessing( false )
    closeLightbox()
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleDelete = async ( companyId ) => {
    if ( !window.confirm( 'Are you sure you want to remove all collaborator access privileges of this user?' ) ) return

    setProccessing( true )

    try {

      const collaboratorsByCompany = await sFetch( `/companies/${ companyId }/collaborators` )
      const collaborator = find( collaboratorsByCompany, c => ( c.user.id === parseInt( userId ) ) )

      if ( collaborator ) {
        await sFetch( `/companies/${ companyId }/collaborators/${ collaborator.id }`, {
          method: 'delete',
          body: JSON.stringify( {
            collaborator: { email: user.details.email }
          } )
        } )

        const index = findIndex( loggedUserCompanies, { id: companyId } )
        if ( index >= 0 ) {
          loggedUserCompanies[ index ].roles = null
          loggedUserCompanies[ index ].isMember = false
          setLoggedUserCompanies( loggedUserCompanies )
        }

        UI.showSuccessDisclaimer( 'You have removed collaborator access privileges successfully!' )

      }

    } catch ( exception ) {

      UI.showErrorDisclaimer( exception.message )

    }

    setProccessing( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleCancel = event => {
    event.preventDefault()
    closeLightbox()
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const closeLightbox = () => {
    lightboxRef.current.close()
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const renderForm = ( companyId, initialRoles = [] ) => {

    return (
      <Formik
        initialValues={ { userId: userId, companyId: companyId, roles: initialRoles } }
        enableReinitialize={ true }
        onSubmit={ handleSave }
        validateOnChange={ false }
        validateOnBlur={ false }>
        { fProps => (
          <Form className="pd-collaborators__form">
            <Field name="userId" type="hidden" />
            <Field name="companyId" type="hidden" />

            <ul className="pd-collaborators__form-list">
              { keys( ROLE ).map( ( k, i ) => (

                <li className="pd-collaborators__form-list-item" key={ k }>
                  <Field name="roles" value={ ROLE[ k ] } id={ k } type="checkbox" />
                  <label className="pd-text" htmlFor={ k }>{ startCase( ROLE[ k ] ) }</label>
                </li>

              ) ) }
            </ul>

            <nav className="pd-collaborators__form-nav">
              <button className="pd-button pd-button--navy" type="submit" disabled={ isProccessing || !fProps.values.roles.length }>{ isProccessing ? 'Saving...' : 'Save' }</button>
              <button className="pd-button pd-button--cyan" onClick={ handleCancel } disabled={ isProccessing }>Cancel</button>
            </nav>
          </Form>
        ) }
      </Formik>
    )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return loggedUserCompanies.length ? (

    <div className={ `pd-collaborators ${ className }` }>
      <h4 className="pd-title-4">Collaborator access privileges</h4>

      <Table
        className="pd-collaborators__table"
        cols={ [ 'Company', 'Member', 'Roles', 'Options' ] }
        rows={ loggedUserCompanies.map( item => ( [
          item.name,
          item.isMember ? 'Yes' : 'No',
          item.roles && item.roles.length ? item.roles.map( r => startCase( r ) ).join( ', ' ) : '-',
          <div className="pd-collaborators__actions">
            <PortalWithState>
              { portalBag => (
                <>
                  { item.isMember ? (

                    <>
                      <button onClick={ portalBag.openPortal } className="pd-button pd-button--cyan pd-button--mini" disabled={ isProccessing }>Change</button>
                      <button className="pd-button pd-button--danger pd-button--mini" onClick={ handleDelete.bind( this, item.id ) } disabled={ isProccessing }>Remove</button>
                    </>

                  ) : (

                    <button onClick={ portalBag.openPortal } className="pd-button pd-button--cyan pd-button--mini" disabled={ isProccessing }>Include</button>

                  ) }

                  { portalBag.portal(
                    <Lightbox portalBag={ portalBag } ref={ lightboxRef } isLocked={ true }>
                      { renderForm( item.id, item.isMember ? item.roles : [] ) }
                    </Lightbox>
                  ) }
                </>
              ) }
            </PortalWithState>
          </div>
        ] ) ) }
      />
    </div>

  ) : null

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default Collaborators