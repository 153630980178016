import React, { useContext } from 'react'

import { GlobalContext } from '../../context/global-state'
import { CheckoutContext } from '../../context/checkout-state'

import CheckoutShippingInfo from '../../modules/checkout-shipping-info/checkout-shipping-info'
import CheckoutShippingTrackingNumberInfo from '../../modules/checkout-tracking-number-info/checkout-tracking-number-info'
import TabPanel, { TabPanelItem } from '../../components/tab-panel/tab-panel'
import { InfoTip } from '../../components/tip/tip'

import './checkout-shipped-disclaimer.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const CheckoutShippedDisclaimer = props => {

  const { className = '' } = props
  const context = useContext( GlobalContext )
  const { isRetailer } = context
  const checkoutContext = useContext( CheckoutContext )
  const { pledge, shipping, project } = checkoutContext
  const { supportUrl, supportEmail, waves, isLateConfirms, isLatePledge, hasMultipleWaves, retailer } = project
  const { isMultiwave } = pledge

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <div className={ `pd-checkout-shipped-disclaimer pd-checkout-generic-disclaimer ${ className }` }>

      { pledge.isReadyToShip && (
        <>
          <h3 className="pd-title-3">Your pledge is Ready to Ship.</h3>
          <p className="pd-text">Your order is now ready to be shipped, its contents are locked-in and awaiting pickup for delivery. Once its tracking number is available you will be notified and its status will change to Shipped.</p>
          {/* <InlineShare fbUrl="#" twUrl="#" /> */ }
        </>
      ) }

      { pledge.isShipped && (
        <>
          <h3 className="pd-title-3">Your pledge has Shipped!.</h3>
          <p className="pd-text">Your order has been shipped! You may find its tracking information below:</p>
          {/* <InlineShare fbUrl="#" twUrl="#" /> */ }
        </>
      ) }

      { hasMultipleWaves && !isLateConfirms && !isLatePledge && !retailer && (
        <InfoTip className="pd-checkout-generic-disclaimer__shipping-strategy" micro>
          <strong>{ isMultiwave ? `${ waves }-Wave Expedited Shipping` : 'Single-Wave Shipping' }</strong>
        </InfoTip>
      ) }

      <p className="pd-text"> If you have any questions, please contact this project's
        <a
          href={ supportUrl || `mailto:${ supportEmail }` }
          className="pd-button pd-button--navy pd-button--text"
          target="_blank"
          rel="noopener noreferrer">
          { isRetailer ? 'retailer support' : 'customer support' }
        </a>.
      </p>

      <TabPanel className="pd-tab-panel--cyan">
        <TabPanelItem label="Shipping address">
          <CheckoutShippingInfo className="pd-white-box" shippingInfo={ shipping } />
        </TabPanelItem>

        {/* { pledge.addressWaves.map( addressWave => (
          <TabPanelItem key={ addressWave.wave } label={ `Wave ${ addressWave.wave }` }>

            <CheckoutShippingInfo className="pd-white-box" shippingInfo={ {
              form: addressWave.address,
              countries: shipping.countries,
              provinces: shipping.provinces
            } } />

          </TabPanelItem>
        ) ) } */}

        { pledge.trackingNumbers.length ? (

          <TabPanelItem className="pd-white-box" label="Tracking information">
            <CheckoutShippingTrackingNumberInfo trackingNumbers={ pledge.trackingNumbers } />
          </TabPanelItem>

        ) : null }
      </TabPanel>
    </div>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default CheckoutShippedDisclaimer