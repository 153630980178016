import React, { useEffect } from 'react'
import { fill } from 'lodash'

import './pagination.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const Pagination = props => {

  const {
    className = '',
    itemClassName = 'pd-button pd-button--light',
    pages = 0,
    current = 1,
    onChange = () => null
  } = props

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return pages > 1 ? (

    <nav className={ `pd-pagination ${ className }` }>
      { fill( Array( pages ) ).map( ( item, index ) => (

        <button
          className={ `pd-pagination__button ${ current === ( index + 1 ) ? 'pd-pagination__button--active' : '' } ${ itemClassName }` }
          key={ index }
          onClick={ onChange.bind( this, ( index + 1 ) ) }>
          { index + 1 }
        </button>

      ) ) }
    </nav>

  ) : null

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default Pagination