import React, { useEffect } from 'react'

import Env from '../../config/env'
import { getPadNumber } from '../../config/adapters'

import { ReactComponent as IconAdd } from '../../assets/icons/add.svg'
import { ReactComponent as IconRemove } from '../../assets/icons/remove.svg'

import './amount-selector.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const AmountSelector = props => {

  const { className = '', mini = false, onChange, initialValue, max = Env.arbritaryLimit, min = 0, isDisabled } = props

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleClick = ( value, event ) => {
    event.preventDefault()
    onChange( value )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <div className={ `pd-amount-selector ${ mini ? 'pd-amount-selector--mini' : '' }  ${ className }` }>
      <button
        className={ `pd-button pd-button--cyan pd-button--icon pd-button--icon-only ${ mini ? 'pd-button--mini' : '' }` }
        disabled={ isDisabled || initialValue <= min }
        onClick={ handleClick.bind( this, initialValue - 1 ) }>
        <IconRemove className="pd-icon" />
      </button>
      <span className="pd-text">{ getPadNumber( initialValue ) }</span>
      <button
        className={ `pd-button pd-button--cyan pd-button--icon pd-button--icon-only ${ mini ? 'pd-button--mini' : '' }` }
        disabled={ isDisabled || initialValue >= max }
        onClick={ handleClick.bind( this, initialValue + 1 ) }>
        <IconAdd className="pd-icon" />
      </button>
    </div>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default AmountSelector