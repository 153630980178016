import React, { useEffect, useState, useContext } from 'react'
import pluralize from 'pluralize'
import { sortBy, find, has, groupBy, keys } from 'lodash'

import { DashboardContext } from '../../context/dashboard-state'

import { formatCurrency } from '../../config/adapters'

import Table from '../../components/table/table'
import Accordion from '../../components/accordion/accordion'

import { ReactComponent as IconRight } from '../../assets/icons/right.svg'
import { ReactComponent as IconLock } from '../../assets/icons/lock.svg'

import './pledge-order-contents.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const PledgeOrderContents = props => {

  const dashboardContext = useContext( DashboardContext )
  const { project, pledge } = dashboardContext
  const { className = '' } = props
  const [ contentsList, setContentsList ] = useState( [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => {
    parsePledgeData()
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const getBalanceLabel = balance => {
    if ( balance === 0 ) return 'Balance'
    if ( balance > 0 ) return 'Balance Due'
    return 'Credit remaining'
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const parsePledgeData = () => {
    const { shippingCost, total, credit = 0, cartVat, shippingVat } = pledge.details

    const _parse = ( baseList, fullList ) => baseList.map( item => {
      const fullItem = find( fullList, { id: item.id } )
      const products = fullItem.bundleProducts || fullItem.addOnProducts || []
      const waves = keys( groupBy( products, p => p.product.wave ) )

      return [ (
        <Accordion
          indicator90={ <IconRight className={ `pd-icon pd-pledge-order-contents__expand-indicator` } /> }
          heading={ (
            <>
              { item.name } { item.base ? '(Base)' : ( has( item, 'base' ) ? '(Extra)' : '' ) } ({ pluralize( 'wave', waves.length ) } { waves.join( ', ' ) })
              { ' ' }{ item.lockedToOrder && ( <IconLock className="pd-icon pd-checkout-summary__icon-lock" /> ) }
            </>
          ) }
          content={ (
            <ul className="pd-pledge-order-contents__products pd-text-sm">
              { products.map( ( content, index ) => (

                <li key={ index }>
                  { content.quantity }x { content.product.name } (wave { content.product.wave })
                </li>

              ) ) }
            </ul>
          ) }
        />
      ),
      { value: formatCurrency( item.unitPrice ), sortValue: item.unitPrice },
      item.amount,
      formatCurrency( item.unitPrice * item.amount )
      ]
    } )
    const bundles = _parse( sortBy( pledge.details.bundles, 'base' ), project.details.bundles )
    const addOns = _parse( pledge.details.addOns, project.details.addOns )

    const balance = total - credit
    const balanceRow = [
      [ <span className="pd-title-4">Products total</span>, '', '', formatCurrency( total - ( shippingCost + parseFloat( cartVat ) + parseFloat( shippingVat ) ) ) ],
      [ 'VAT', '', '', formatCurrency( cartVat ) ],
      [ 'Shipping + Handling fees', '', '', formatCurrency( shippingCost ) ],
      [ 'VAT (Shipping + Handling fees)', '', '', formatCurrency( shippingVat ) ],
      [ <span className="pd-title-4">Order total</span>, '', '', formatCurrency( total ) ],
      [ <span className="pd-title-4">Credit</span>, '', '', formatCurrency( credit ) ],
      [ <span className="pd-title-4">{ getBalanceLabel( balance ) }</span>, '', '', formatCurrency( Math.abs( balance ) ) ],
    ]

    setContentsList( [].concat( bundles, addOns, balanceRow ) )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return contentsList.length > 0 ? (

    <div className={ `pd-pledge-order-contents ${ className }` }>
      <h3 className="pd-title-3">Order contents</h3>
      <Table
        className="pd-pledge-order-contents__table"
        cols={ [ 'Product', { label: 'Price', sortable: true }, 'Units', 'Total' ] }
        rows={ contentsList }
      />
    </div>

  ) : null

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default PledgeOrderContents