import React, { useEffect } from 'react'

import './checkout-missed-deadline-disclaimer.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const CheckoutMissedDeadlineDisclaimer = props => {

  const { className = '' } = props

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <div className={ `pd-checkout-missed-deadline-disclaimer ${ className }` }>
      <h3 className="pd-title-3">This pledge missed the project's deadline.</h3>
      <p className="pd-text">
        Your pledge was canceled as your order was not confirmed by the deadline.
        If you have any questions, please contact your sales representative at <a className="pd-button--text-inline" href="mailto:retailerpledge@cmon.com">retailerpledge@cmon.com</a>.
      </p>
    </div>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default CheckoutMissedDeadlineDisclaimer