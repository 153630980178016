import React, { useContext, useReducer, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { isNil } from 'lodash'

import Env from '../config/env'
import { rFetch, sFetch } from '../config/fetch'
import { toSnakeCase } from '../config/adapters'
import { PLEDGE_MANAGER_STATE, PLEDGE_STATE } from '../config/constants'

import { GlobalContext } from './global-state'
import { UIContext } from './ui-state'

import { initialState, checkoutReducer, MAIN, PROJECT, PLEDGE, SUMMARY, SHOWCASE, SHIPPING, STEP } from './checkout-reducer'

export const CheckoutContext = React.createContext()

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -


export const CheckoutState = props => {

  const context = useContext( GlobalContext )
  const UI = useContext( UIContext )
  const { loggedUser, fetchProvinces } = context
  const { projectSlug } = useParams()
  const [ checkout, dispatchCheckout ] = useReducer( checkoutReducer, initialState )

  // PUBLIC METHODS = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

  const fetchData = async ( isRetailer = false ) => {
    try {

      const projectEndpoint = `/projects/detail/${ projectSlug }?retailer=${ isRetailer }`
      const projectPayload = loggedUser.isLogged ? await sFetch( projectEndpoint ) : await rFetch( projectEndpoint )
      dispatchCheckout( { type: PROJECT.FETCHED, payload: projectPayload } )

      if ( projectPayload.retailer !== isRetailer ) {
        dispatchCheckout( { type: PROJECT.NOT_FOUND } )
        return
      }

      if ( isRetailer && ( loggedUser.isLogged && !loggedUser.isRetailer && !loggedUser.isAdmin ) ) {
        dispatchCheckout( { type: PROJECT.NOT_FOUND } )
        return
      }

      try {

        const countries = await sFetch( `/countries/${ projectPayload.id }` )
        dispatchCheckout( { type: SHIPPING.SET_COUNTRIES, payload: countries } )

      } catch ( exception ) { }


      if ( loggedUser.isLogged ) {

        try {

          const pledgePayload = await sFetch( `/projects/${ projectSlug }/geeks` )
          dispatchCheckout( { type: PLEDGE.FETCHED, payload: pledgePayload } )

          if ( projectPayload.waves > 1 && isNil( pledgePayload.multiwave ) ) {

            if ( projectPayload.allowMultiwaveSelection && projectPayload.pledgeManagerState !== PLEDGE_MANAGER_STATE.LATE_PLEDGE ) {

              dispatchCheckout( { type: MAIN.RESOLVE_MULTIWAVE, payload: false } )
              const isMultiwave = await requestShippingWaveStrategy()
              dispatchCheckout( { type: PLEDGE.SET_MULTIWAVE, payload: isMultiwave } )
              dispatchCheckout( { type: PLEDGE.SET_LOCAL_CHANGES, payload: true } )
              dispatchCheckout( { type: MAIN.RESOLVE_MULTIWAVE, payload: true } )

            } else {

              dispatchCheckout( { type: PLEDGE.SET_MULTIWAVE, payload: false } )
              dispatchCheckout( { type: MAIN.RESOLVE_MULTIWAVE, payload: true } )

            }

          }

          dispatchCheckout( { type: PROJECT.PARSE_AVAILABLE_BUNDLES_AND_ADD_ONS } )

          dispatchCheckout( { type: PLEDGE.LOCK_PLEDGE_LOCALLY, payload: pledgePayload.state === PLEDGE_STATE.FINISHED } )

          if ( pledgePayload.address && pledgePayload.address.countryId ) {

            try {

              await updateProvinces( pledgePayload.address.countryId )

            } catch ( exception ) { }

          }

        } catch ( exception ) {

          dispatchCheckout( { type: PLEDGE.EMPTY } )

          if ( projectPayload.waves > 1 ) {

            if ( projectPayload.allowMultiwaveSelection && projectPayload.pledgeManagerState !== PLEDGE_MANAGER_STATE.LATE_PLEDGE ) {

              dispatchCheckout( { type: MAIN.RESOLVE_MULTIWAVE, payload: false } )
              const isMultiwave = await requestShippingWaveStrategy()
              dispatchCheckout( { type: PLEDGE.SET_MULTIWAVE, payload: isMultiwave } )
              dispatchCheckout( { type: PLEDGE.SET_LOCAL_CHANGES, payload: true } )

              dispatchCheckout( { type: MAIN.RESOLVE_MULTIWAVE, payload: true } )

            } else {

              dispatchCheckout( { type: PLEDGE.SET_MULTIWAVE, payload: projectPayload.pledgeManagerState === PLEDGE_MANAGER_STATE.LATE_PLEDGE ? null : false } )
              dispatchCheckout( { type: MAIN.RESOLVE_MULTIWAVE, payload: true } )

            }

          }

          dispatchCheckout( { type: PROJECT.PARSE_AVAILABLE_BUNDLES_AND_ADD_ONS } )

        }

        dispatchCheckout( { type: SHIPPING.PARSE_SHIPPING_INFO } )
        dispatchCheckout( { type: SUMMARY.INITIAL_PARSE } )
        if ( projectPayload.waves > 1 && projectPayload.currentWave === 1 ) dispatchCheckout( { type: SUMMARY.REPLACE_SUMMARY_ITEMS_BY_WAVE } )
        dispatchCheckout( { type: SUMMARY.UPDATE_ORDER_TOTAL } )

      } else {

        dispatchCheckout( { type: PLEDGE.EMPTY } )
        dispatchCheckout( { type: PROJECT.PARSE_AVAILABLE_BUNDLES_AND_ADD_ONS } )

      }

      dispatchCheckout( { type: SHOWCASE.PARSE_BASE_PLEDGE_OPTIONS } )
      dispatchCheckout( { type: SHOWCASE.PARSE_OPTIONAL_BUYS } )
      dispatchCheckout( { type: SHOWCASE.CHECK_REQUIREMENTS } )
      dispatchCheckout( { type: SHOWCASE.UPDATE_AMOUNTS } )
      dispatchCheckout( { type: SHOWCASE.CHECK_STEP } )
      dispatchCheckout( { type: SHOWCASE.CHECK_NAVIGATION, payload: { loggedUser: loggedUser } } )

      if ( loggedUser.isLogged ) {
        dispatchCheckout( { type: SHOWCASE.SET_INITIAL_AMOUNTS } )
        dispatchCheckout( { type: SHOWCASE.SET_INITIAL_LOCKED_ITEMS } )
        dispatchCheckout( { type: SHOWCASE.CHECK_LIMITED_STOCK_ITEMS_PRESENCE } )
        dispatchCheckout( { type: SHOWCASE.UPDATE_MAX_ALLOWED } )
        dispatchCheckout( { type: SHOWCASE.REPLACE_LOCKED_BASE_PLEDGE } )
        dispatchCheckout( { type: SHOWCASE.APPEND_LOCKED_OPTIONAL_BUYS } )
      }

      dispatchCheckout( { type: MAIN.RELEASE_RENDER } )

    } catch ( exception ) {

      if ( exception.request && exception.request.status === 403 ) {
        dispatchCheckout( { type: PROJECT.HIDDEN, payload: exception.message } )
        return
      }

      if ( exception.request && exception.request.status === 404 ) {
        dispatchCheckout( { type: PROJECT.NOT_FOUND } )
        return
      }

      throw exception
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const changeShippingWaveStrategy = async currentMultiwaveStrategy => {
    dispatchCheckout( { type: MAIN.RESOLVE_MULTIWAVE, payload: false } )
    const newMultiwaveStrategy = await requestShippingWaveStrategy()

    if ( newMultiwaveStrategy == currentMultiwaveStrategy ) {

      dispatchCheckout( { type: MAIN.RESOLVE_MULTIWAVE, payload: true } )
      return

    }

    dispatchCheckout( { type: MAIN.RELEASE_RENDER, payload: false } )

    dispatchCheckout( { type: PLEDGE.SET_MULTIWAVE, payload: newMultiwaveStrategy } )
    dispatchCheckout( { type: PLEDGE.SET_LOCAL_CHANGES, payload: true } )

    dispatchCheckout( { type: MAIN.RESOLVE_MULTIWAVE, payload: true } )

    dispatchCheckout( { type: PROJECT.PARSE_AVAILABLE_BUNDLES_AND_ADD_ONS } )

    dispatchCheckout( { type: SUMMARY.REPLACE_SUMMARY_ITEMS_BY_WAVE } )
    dispatchCheckout( { type: SUMMARY.UPDATE_ORDER_TOTAL } )

    dispatchCheckout( { type: SHOWCASE.PARSE_BASE_PLEDGE_OPTIONS } )
    dispatchCheckout( { type: SHOWCASE.PARSE_OPTIONAL_BUYS } )
    dispatchCheckout( { type: SHOWCASE.CHECK_REQUIREMENTS } )
    dispatchCheckout( { type: SHOWCASE.UPDATE_AMOUNTS } )
    dispatchCheckout( { type: SHOWCASE.CHECK_STEP } )
    dispatchCheckout( { type: SHOWCASE.CHECK_NAVIGATION, payload: { loggedUser: loggedUser } } )
    dispatchCheckout( { type: SHOWCASE.SET_INITIAL_AMOUNTS } )
    dispatchCheckout( { type: SHOWCASE.SET_INITIAL_LOCKED_ITEMS } )
    dispatchCheckout( { type: SHOWCASE.CHECK_LIMITED_STOCK_ITEMS_PRESENCE } )
    dispatchCheckout( { type: SHOWCASE.UPDATE_MAX_ALLOWED } )

    dispatchCheckout( { type: MAIN.RELEASE_RENDER } )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const selectBasePledge = basePledgeId => {
    dispatchCheckout( { type: PLEDGE.SET_LOCAL_CHANGES, payload: true } )

    dispatchCheckout( { type: SUMMARY.UPDATE_BASE_PLEDGE_SELECTED, payload: basePledgeId } )
    dispatchCheckout( { type: SUMMARY.UPDATE_SHIPPING_COST, payload: null } )
    dispatchCheckout( { type: SUMMARY.UPDATE_ORDER_TOTAL } )

    dispatchCheckout( { type: SHOWCASE.UPDATE_BASE_PLEDGE_SELECTED } )
    dispatchCheckout( { type: SHOWCASE.UPDATE_AMOUNTS } )
    dispatchCheckout( { type: SHOWCASE.CHECK_REQUIREMENTS } )
    dispatchCheckout( { type: SHOWCASE.CHECK_LIMITED_STOCK_ITEMS_PRESENCE } )
    dispatchCheckout( { type: SHOWCASE.UPDATE_MAX_ALLOWED } )
    dispatchCheckout( { type: SHOWCASE.CHECK_NAVIGATION, payload: { loggedUser: loggedUser } } )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const updateBasePledgeAmount = ( basePledgeId, value ) => {
    dispatchCheckout( { type: PLEDGE.SET_LOCAL_CHANGES, payload: true } )

    dispatchCheckout( { type: SUMMARY.UPDATE_BASE_PLEDGE_AMOUNT, payload: { uid: basePledgeId, value: value } } )
    dispatchCheckout( { type: SUMMARY.UPDATE_SHIPPING_COST, payload: null } )
    dispatchCheckout( { type: SUMMARY.UPDATE_ORDER_TOTAL } )

    dispatchCheckout( { type: SHOWCASE.UPDATE_AMOUNTS } )
    dispatchCheckout( { type: SHOWCASE.CHECK_REQUIREMENTS } )
    dispatchCheckout( { type: SHOWCASE.CHECK_LIMITED_STOCK_ITEMS_PRESENCE } )
    dispatchCheckout( { type: SHOWCASE.UPDATE_MAX_ALLOWED } )
    dispatchCheckout( { type: SHOWCASE.CHECK_NAVIGATION, payload: { loggedUser: loggedUser } } )
    dispatchCheckout( { type: SHOWCASE.PREVENT_EMPTY_PLEDGE } )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const addOptionalBuy = optionalBuyId => {
    dispatchCheckout( { type: PLEDGE.SET_LOCAL_CHANGES, payload: true } )

    dispatchCheckout( { type: SUMMARY.INCLUDE_OPTIONAL_BUY, payload: optionalBuyId } )
    dispatchCheckout( { type: SUMMARY.UPDATE_SHIPPING_COST, payload: null } )
    dispatchCheckout( { type: SUMMARY.UPDATE_ORDER_TOTAL } )

    dispatchCheckout( { type: SHOWCASE.UPDATE_AMOUNTS } )
    dispatchCheckout( { type: SHOWCASE.CHECK_REQUIREMENTS } )
    dispatchCheckout( { type: SHOWCASE.CHECK_LIMITED_STOCK_ITEMS_PRESENCE } )
    dispatchCheckout( { type: SHOWCASE.UPDATE_MAX_ALLOWED } )
    dispatchCheckout( { type: SHOWCASE.CHECK_NAVIGATION, payload: { loggedUser: loggedUser } } )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const updateOptionalBuyAmount = ( optionalBuyId, value, warning = false ) => {

    dispatchCheckout( { type: PLEDGE.SET_LOCAL_CHANGES, payload: true } )

    dispatchCheckout( { type: SUMMARY.UPDATE_OPTIONAL_BUY_AMOUNT, payload: { uid: optionalBuyId, value: value } } )
    dispatchCheckout( { type: SUMMARY.UPDATE_SHIPPING_COST, payload: null } )
    dispatchCheckout( { type: SUMMARY.UPDATE_ORDER_TOTAL } )

    dispatchCheckout( { type: SHOWCASE.UPDATE_AMOUNTS } )
    dispatchCheckout( { type: SHOWCASE.CHECK_REQUIREMENTS } )
    dispatchCheckout( { type: SHOWCASE.CHECK_LIMITED_STOCK_ITEMS_PRESENCE } )
    dispatchCheckout( { type: SHOWCASE.UPDATE_MAX_ALLOWED } )
    dispatchCheckout( { type: SHOWCASE.CHECK_NAVIGATION, payload: { loggedUser: loggedUser } } )
    dispatchCheckout( { type: SHOWCASE.PREVENT_EMPTY_PLEDGE } )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const stepTo = step => {
    dispatchCheckout( { type: SHOWCASE.SET_STEP, payload: step } )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const updateProvinces = async countryId => {
    dispatchCheckout( { type: SHIPPING.SET_PROVINCES, payload: [] } )

    try {

      if ( !countryId ) return
      const provinces = await fetchProvinces( countryId )
      dispatchCheckout( { type: SHIPPING.SET_PROVINCES, payload: provinces } )

    } catch ( exception ) { throw exception }

  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const updateShippingInfo = async payload => {
    dispatchCheckout( { type: SHIPPING.UPDATE_SHIPPING_INFO, payload: payload } )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const calculateShippingCost = async () => {
    try {

      dispatchCheckout( { type: SUMMARY.WILL_CALCULATE_SHIPPING_COST } )

      const { project } = checkout
      const payload = _getShippingCostPayload()
      const data = await rFetch( `/projects/${ project.id }/shipping_cost`, { method: 'post', body: JSON.stringify( toSnakeCase( payload ) ) } )

      dispatchCheckout( { type: SUMMARY.UPDATE_SHIPPING_COST, payload: data } )
      dispatchCheckout( { type: SUMMARY.UPDATE_ORDER_TOTAL } )

    } catch ( exception ) {

      dispatchCheckout( { type: SUMMARY.HANDLE_SHIPPING_COST_CALCULATION_FAILURE } )
      throw exception

    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const savePledgeData = async () => {
    try {
      dispatchCheckout( { type: PLEDGE.SAVING, payload: true } )
      dispatchCheckout( { type: SHOWCASE.LOCK_SHOWCASE, payload: true } )
      dispatchCheckout( { type: SUMMARY.LOCK_SUMMARY, payload: true } )

      const { shipping, project, pledge } = checkout
      const shippingCostPayload = _getShippingCostPayload()

      const payload = {
        geek: {
          bundles: shippingCostPayload.bundles,
          addOns: shippingCostPayload.addOns,
          multiwave: pledge.isMultiwave
        },
        address: shipping.form
      }

      const savedPledge = await sFetch( `/projects/${ project.id }/geeks`, { method: 'post', body: JSON.stringify( toSnakeCase( payload ) ) } )
      await handleSavePledgeSuccess( savedPledge )

    } catch ( exception ) {

      dispatchCheckout( { type: PLEDGE.SAVING, payload: false } )
      dispatchCheckout( { type: SUMMARY.LOCK_SUMMARY, payload: false } )
      dispatchCheckout( { type: SHOWCASE.LOCK_SHOWCASE, payload: false } )
      throw exception

    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const savePledgePayment = async paymentInfo => {
    try {
      dispatchCheckout( { type: PLEDGE.SAVING, payload: true } )
      dispatchCheckout( { type: SUMMARY.LOCK_SUMMARY, payload: true } )
      dispatchCheckout( { type: SHOWCASE.LOCK_SHOWCASE, payload: true } )

      const { summary, shipping, project, pledge } = checkout
      const shippingCostPayload = _getShippingCostPayload()

      const payload = project.isLatePledge ?
        {
          geek: {
            bundles: [ {
              id: summary.basePledge.id,
              amount: 1,
              base: true
            } ],
            payment: paymentInfo
          }
        } : {
          geek: {
            bundles: shippingCostPayload.bundles,
            addOns: shippingCostPayload.addOns,
            payment: paymentInfo,
            multiwave: pledge.isMultiwave
          },
          address: shipping.form
        }

      const savedPledge = await sFetch( `/projects/${ project.id }/geeks`, { method: 'post', body: JSON.stringify( toSnakeCase( payload ) ) } )
      await handleSavePledgeSuccess( savedPledge )

    } catch ( exception ) {

      dispatchCheckout( { type: PLEDGE.SAVING, payload: false } )
      throw exception

    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSavePledgeSuccess = async ( savedPledge ) => (
    new Promise( async ( resolve, reject ) => {
      try {
        const project = await sFetch( `/projects/detail/${ projectSlug }` )
        dispatchCheckout( { type: PROJECT.FETCHED, payload: project } )

        dispatchCheckout( { type: PLEDGE.FETCHED, payload: savedPledge } )

        dispatchCheckout( { type: PROJECT.PARSE_AVAILABLE_BUNDLES_AND_ADD_ONS } )

        dispatchCheckout( { type: PLEDGE.LOCK_PLEDGE_LOCALLY, payload: savedPledge.state === PLEDGE_STATE.FINISHED } )
        dispatchCheckout( { type: PLEDGE.SET_LOCAL_CHANGES, payload: false } )

        dispatchCheckout( { type: SUMMARY.INITIAL_PARSE } )
        dispatchCheckout( { type: SUMMARY.UPDATE_ORDER_TOTAL } )

        dispatchCheckout( { type: SHOWCASE.PARSE_BASE_PLEDGE_OPTIONS } )
        dispatchCheckout( { type: SHOWCASE.PARSE_OPTIONAL_BUYS } )
        dispatchCheckout( { type: SHOWCASE.CHECK_REQUIREMENTS } )
        dispatchCheckout( { type: SHOWCASE.UPDATE_AMOUNTS } )
        dispatchCheckout( { type: SHOWCASE.CHECK_STEP } )
        dispatchCheckout( { type: SHOWCASE.CHECK_NAVIGATION, payload: { loggedUser: loggedUser } } )
        dispatchCheckout( { type: SHOWCASE.SET_INITIAL_AMOUNTS } )
        dispatchCheckout( { type: SHOWCASE.SET_INITIAL_LOCKED_ITEMS } )
        dispatchCheckout( { type: SHOWCASE.CHECK_LIMITED_STOCK_ITEMS_PRESENCE } )
        dispatchCheckout( { type: SHOWCASE.UPDATE_MAX_ALLOWED } )
        dispatchCheckout( { type: SHOWCASE.REPLACE_LOCKED_BASE_PLEDGE } )
        dispatchCheckout( { type: SHOWCASE.APPEND_LOCKED_OPTIONAL_BUYS } )

        dispatchCheckout( { type: SHIPPING.PARSE_SHIPPING_INFO } )

        dispatchCheckout( { type: SHOWCASE.LOCK_SHOWCASE, payload: false } )
        dispatchCheckout( { type: PLEDGE.SAVING, payload: false } )

        resolve()

      } catch ( exception ) { reject( exception ) }
    } )
  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const unlockPledge = async () => {
    dispatchCheckout( { type: PLEDGE.LOCK_PLEDGE_LOCALLY, payload: false } )
    dispatchCheckout( { type: PLEDGE.SET_LOCAL_CHANGES, payload: true } )
    dispatchCheckout( { type: SUMMARY.UPDATE_PAYMENT_PAYLOAD, payload: null } )
    dispatchCheckout( { type: SUMMARY.LOCK_SUMMARY, payload: false } )
    dispatchCheckout( { type: SHOWCASE.CHECK_NAVIGATION, payload: { loggedUser: loggedUser } } )
    dispatchCheckout( { type: SHOWCASE.SET_INITIAL_STEP } )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const lockSummary = lock => {
    dispatchCheckout( { type: SUMMARY.LOCK_SUMMARY, payload: lock } )

  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const lockShowcase = lock => {
    dispatchCheckout( { type: SHOWCASE.LOCK_SHOWCASE, payload: lock } )

  }










  // HELPERS = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

  const _getShippingCostPayload = () => {
    const { summary, shipping } = checkout
    const { basePledge, optionalBuys, hasBasePledgeSelected } = summary
    const { form } = shipping

    const bundles = optionalBuys
      .filter( item => item.bundle )
      .map( item => ( { id: item.id, amount: item.amount } ) )

    const addOns = optionalBuys
      .filter( item => item.addOn )
      .map( item => ( { id: item.id, amount: item.amount } ) )

    const baseBundle = hasBasePledgeSelected ? [ { id: basePledge.id, amount: basePledge.amount, base: true } ] : []

    return {
      bundles: [].concat( baseBundle, bundles ),
      addOns: addOns,
      address: form
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const requestShippingWaveStrategy = async () => (
    new Promise( async resolve => {
      UI.setConfirmationCallback( resolve )
    } )
  )









  // EFFECTS = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

  useEffect( () => {
    if ( Env.nodeEnv !== 'development' ) return

    console.clear()
    console.log( 'loggedUser:         ', loggedUser )
    console.log( 'project:            ', checkout.project )
    console.log( 'pledge:             ', checkout.pledge )
    console.log( 'showcase:           ', checkout.showcase )
    console.log( 'summary:            ', checkout.summary )
    console.log( 'shipping:           ', checkout.shipping )
    console.log( 'isReady:            ', checkout.isReady )
    console.log( 'isMultiwaveResolved:', checkout.isMultiwaveResolved )

  }, [ loggedUser, checkout ] )










  // = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

  return (
    <CheckoutContext.Provider value={ {
      isReady: checkout.isReady,
      isMultiwaveResolved: checkout.isMultiwaveResolved,

      shipping: checkout.shipping,
      summary: checkout.summary,
      showcase: checkout.showcase,
      project: checkout.project,
      pledge: checkout.pledge,

      fetchData: fetchData,
      selectBasePledge: selectBasePledge,
      updateBasePledgeAmount: updateBasePledgeAmount,
      addOptionalBuy: addOptionalBuy,
      updateOptionalBuyAmount: updateOptionalBuyAmount,
      stepTo: stepTo,
      updateProvinces: updateProvinces,
      updateShippingInfo: updateShippingInfo,
      calculateShippingCost: calculateShippingCost,
      savePledgeData: savePledgeData,
      savePledgePayment: savePledgePayment,
      unlockPledge: unlockPledge,
      lockSummary: lockSummary,
      lockShowcase: lockShowcase,
      changeShippingWaveStrategy: changeShippingWaveStrategy,
      STEP: STEP
    } }>
      { props.children }
    </CheckoutContext.Provider>
  )
}