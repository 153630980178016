import React, { useEffect } from 'react'
import moment from 'moment-timezone'
import { toPairs } from 'lodash'

import { formatCurrency, getReadbleOrderState } from '../../config/adapters'

import Table from '../../components/table/table'

import './activity-table.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const ActivityTable = props => {

  const { rows = [] } = props

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const getNormalizedAudits = () => {
    let normalized = []

    for ( let index in rows ) {
      const audit = rows[ index ]
      const { auditedChanges, changes, action, createdAt, comment } = audit

      const date = moment( createdAt ).format( 'MMM DD, YYYY, HH:mm' )

      if ( action === 'update' ) {
        const rawChanges = toPairs( auditedChanges || changes )

        for ( let _index in rawChanges ) {
          const item = rawChanges[ _index ]
          let changeDescripion = item[ 0 ]
          let changeFrom = item[ 1 ][ 0 ]
          let changeTo = item[ 1 ][ 1 ]
          let reason = ''

          if ( changeDescripion === 'credit' ) {
            changeFrom = formatCurrency( changeFrom )
            changeTo = formatCurrency( changeTo )
          }

          if ( changeDescripion === 'address' ) {
            changeFrom = changeFrom ? changeFrom.formattedAddress : 'empty'
            changeTo = changeTo.formattedAddress
          }

          if ( changeDescripion === 'state' ) {
            changeFrom = getReadbleOrderState( changeFrom ).toLowerCase()
            changeTo = getReadbleOrderState( changeTo ).toLowerCase()
            reason = comment ? `\nReason: ${ comment }.` : ''

            changeDescripion = ''
          }

          if ( changeDescripion === 'name' || changeDescripion === 'address' ) changeDescripion = `their ${ changeDescripion }`
          if ( changeDescripion === 'addressComplement' ) changeDescripion = 'their address complement'

          normalized.push( [ date, `${ renderUser( audit.user ) } changed ${ changeDescripion } from ${ changeFrom } to ${ changeTo }. ${ reason }` ] )
        }
      }

      if ( action === 'discount' ) {
        const discount = parseFloat( auditedChanges.credit[ 1 ] ) - parseFloat( auditedChanges.credit[ 0 ] )
        normalized.push( [ date, `${ renderUser( audit.user ) } added a ${ formatCurrency( discount ) } discount to this pledge. ${ audit.comment || 'No reason specified.' }` ] )
      }

      if ( action === 'cancel_order' ) {
        normalized.push( [ date, `${ renderUser( audit.user ) } canceled this pledge. ${ audit.comment || 'No reason specified.' }` ] )
      }

      if ( action === 'create' ) {
        const total = auditedChanges ? auditedChanges.total : changes.total
        const credit = auditedChanges ? auditedChanges.credit : changes.credit

        normalized.push( [ date, `${ renderUser( audit.user ) } created this pledge: Order total: ${ formatCurrency( total ) }; New credit: ${ formatCurrency( credit ) }` ] )
      }

      if ( action === 'destroy' ) {
        const total = auditedChanges ? auditedChanges.total : changes.total

        normalized.push( [ date, `${ renderUser( audit.user ) } deleted a ${ formatCurrency( total ) } pledge` ] )
      }

      if ( action === 'import_backer' ) {
        normalized.push( [ date, `${ renderUser( audit.user ) } imported this pledge. Their pledge state is ${ getReadbleOrderState( auditedChanges.state[ 1 ] ).toLowerCase() } and has ${ formatCurrency( auditedChanges.credit ) } in credit.` ] )
      }
    }

    return normalized
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const renderUser = user => user ? `${ user.name } (${ user.email })` : 'System'

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <Table
      className={ `pd-activity-table` }
      cols={ [ 'Date', 'Action' ] }
      rows={ getNormalizedAudits() }
    />

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default ActivityTable