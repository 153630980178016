import React, { useEffect, useContext } from 'react'
import { NavLink } from 'react-router-dom'

import { renderRoute } from '../../config/routes'
import { GlobalContext } from '../../context/global-state'
import { DashboardContext, DashboardState } from '../../context/dashboard-state'

import NotFound from '../../pages/not-found/not-found'
import LoadingMessage from '../../components/loading-message/loading-message'

import './dashboard-user.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const DashboardUser = props => {

  const context = useContext( GlobalContext )
  const dashboardContext = useContext( DashboardContext )
  const { secureRender } = context
  const { fetchUser, user } = dashboardContext
  const { match, routes, location } = props
  const { userId } = match.params
  const sideNav = [
    { label: 'Overview', url: '' }
  ]

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const onRouteChange = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )
  useEffect( () => { fetchUser() }, [ userId ] )
  useEffect( onRouteChange, [ location ] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  if ( user.error && user.error.status === 404 ) return <NotFound />

  return secureRender(

    <section className="pd-dashboard-user pd-tpl-dashboard pd-page">
      { user.isFetched ? (
        <>
          <header className="pd-tpl-dashboard__header">
            <h3 className="pd-title-2">{ user.isFetched ? user.details.name : 'Loading...' }</h3>
          </header>

          <nav className="pd-tpl-dashboard__nav">
            <ul className="pd-tpl-dashboard__nav-list">
              { sideNav.map( ( item, index ) => (

                <li key={ index } className="pd-tpl-dashboard__nav-item">
                  <NavLink
                    to={ `/dashboard/user/${ userId }/${ item.url }` }
                    className="pd-button pd-button--glass-dark"
                    activeClassName=""
                    exact={ true }>{ item.label }</NavLink>
                </li>

              ) ) }
            </ul>
          </nav>

          <div className="pd-tpl-dashboard__container">
            { routes.map( ( route, index ) => secureRender( renderRoute( route, index ) ) ) }
          </div>
        </>
      ) : <LoadingMessage /> }
    </section>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

const DashboardUserWrapper = props => {
  const { params } = props.match

  return (
    <DashboardState params={ params }>
      <DashboardUser { ...props } />
    </DashboardState>
  )
}

export default DashboardUserWrapper