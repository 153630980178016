import React, { useEffect, useContext, useState } from 'react'
import pluralize from 'pluralize'

import { UIContext } from '../../context/ui-state'

import { sFetch } from '../../config/fetch'

import SimpleNavList from '../simple-nav-list/simple-nav-list'

import './user-search.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const UserSearch = props => {

  const UI = useContext( UIContext )
  const [ param, setParam ] = useState( '' )
  const [ results, setResults ] = useState( null )
  const [ notFound, setNotFound ] = useState( false )
  const [ isSearching, setSearching ] = useState( false )
  const [ isFetched, setFetched ] = useState( false )
  const { className = '' } = props

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSearchClick = async event => fetchSearch()

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleKeyPress = async event => {
    if ( event.charCode === 13 ) fetchSearch()
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const fetchSearch = async () => {
    setSearching( true )
    setFetched( false )
    setResults( null )
    setNotFound( false )

    try {

      const { users } = await sFetch( `/users/report?page=${ 1 }&per_page=50&by_text=${ param }` )
      setResults( users )
      setNotFound( users.length === 0 )
      setFetched( true )


    } catch ( exception ) {

      setResults( null )
      setFetched( false )

      UI.showErrorDisclaimer( exception.message )
    }

    setSearching( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <div className={ `pd-user-search ${ className }` }>
      <div className="pd-user-search__form">
        <h3 className="pd-title-4">User search</h3>
        <div className="pd-pledge-search__field-group">
          <input
            className="pd-textfield"
            type="text"
            value={ param }
            onChange={ event => setParam( event.target.value ) }
            onKeyPress={ handleKeyPress }
            placeholder="Name or email"
          />
          <button className="pd-button pd-button--navy" onClick={ handleSearchClick } disabled={ isSearching }>
            { isSearching ? 'Processing...' : 'Search' }
          </button>
        </div>
        { isSearching ? ( <span className="pd-text-sm">Searching, please wait...</span> ) : null }
        { notFound ? ( <span className="pd-text-sm pd-text-error">User not found. Try different search params.</span> ) : null }
      </div>

      { !notFound && isFetched ? (

        <div className="pd-user-search__results">
          <strong className="pd-text">{ `${ results.length } ${ pluralize( 'result', results.length ) } found:` }</strong>
          <SimpleNavList source={ results.map( item => ( { label: `${ item.name } (${ item.email })`, url: `/dashboard/user/${ item.id }` } ) ) } />
        </div>

      ) : null }
    </div>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default UserSearch