import React, { useEffect, useContext, useState } from 'react'
import { Form, Formik } from 'formik'

import { GlobalContext } from '../../context/global-state'
import { UIContext } from '../../context/ui-state'
import { EMAIL_REGEX, NOT_EMPTY_REGEX } from '../../config/validators'

import CustomField from '../../components/custom-field/custom-field'
import { ReactComponent as IconUser } from '../../assets/icons/user.svg'

import './account-information.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const AccountInformation = props => {

  const context = useContext( GlobalContext )
  const UI = useContext( UIContext )
  const { loggedUser, updateUser } = context
  const { className = '' } = props
  const [ isUpdating, setUpdating ] = useState( false )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSave = async ( payload, actions ) => {
    try {

      setUpdating( true )

      await updateUser( {
        name: payload.name,
        email: payload.email,
      } )

      setUpdating( false )
      actions.setSubmitting( false )
      UI.showSuccessDisclaimer( 'You have updated your profile successfully!' )

    } catch ( exception ) {

      UI.showErrorDisclaimer( exception.message )

    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleValidation = values => {
    let errors = {}

    if ( !NOT_EMPTY_REGEX.test( values.name ) ) errors.name = 'Invalid name'
    if ( !EMAIL_REGEX.test( values.email ) ) errors.email = 'Invalid e-mail'

    return errors
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <section className={ `pd-account-information pd-account__section pd-white-box pd-white-box--no-padding pd-text ${ className }` }>
      <header className="pd-account__header">
        <IconUser className="pd-icon" />
        <h3 className="pd-title-3">Account Information</h3>
      </header>

      <div className="pd-account__container">
        <Formik
          initialValues={ { name: loggedUser.data.name, email: loggedUser.data.email, phone: '' } }
          onSubmit={ handleSave }
          validate={ handleValidation }
          validateOnChange={ false }
          validateOnBlur={ false }>
          { fProps => (

            <Form className="pd-account-information__form">
              <CustomField name="name" label="Name" disabled={ isUpdating } required />

              <CustomField name="email" label="E-mail" disabled={ isUpdating || loggedUser.isAdmin } required />

              <nav className="pd-account-information__nav">
                <button
                  type="submit"
                  className="pd-button pd-button--navy"
                  disabled={ fProps.isSubmitting || isUpdating }>
                  { fProps.isSubmitting || isUpdating ? 'Updating...' : 'Update' }
                </button>

                <span className="pd-text-sm pd-account-information__req">
                  <span>*</span> required fields
                </span>
              </nav>
            </Form>

          ) }
        </Formik>
      </div>
    </section>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default AccountInformation