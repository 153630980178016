import React, { useEffect, useState, useContext, useRef } from 'react'
import moment from 'moment-timezone'
import { isFinite, isNil } from 'lodash'
import { useParams } from 'react-router-dom'

import { UIContext } from '../../context/ui-state'
import { DashboardContext } from '../../context/dashboard-state'

import Env from '../../config/env'
import { sFetch } from '../../config/fetch'
import { toSnakeCase } from '../../config/adapters'
import { PLEDGE_MANAGER_STATE } from '../../config/constants'
import useInterval from '../../hooks/use-interval'

import { InfoTip } from '../../components/tip/tip'
import TabPanel, { TabPanelItem } from '../../components/tab-panel/tab-panel'
import LoadingMessage from '../../components/loading-message/loading-message'

import './packing-slips.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const PackingSlips = props => {

  const UI = useContext( UIContext )
  const dashboardContext = useContext( DashboardContext )
  const { project } = dashboardContext
  const [ currentItem, setCurrentItem ] = useState( null )
  const [ currentLateConfirmsItem, setCurrentLateConfirmsItem ] = useState( null )
  const [ isProcessingSlips, setProcessingSlips ] = useState( false )
  const currentLateConfirmsItemRef = useRef( currentLateConfirmsItem )
  const currentItemRef = useRef( currentItem )
  const [ list, setList ] = useState( [] )
  const [ lateConfirmsList, setLateConfirmsList ] = useState( [] )
  const [ isFetched, setFetched ] = useState( false )
  const [ isProcessing, setProcessing ] = useState( false )
  const { projectId } = useParams()
  const endpoint = `/projects/${ projectId }/geeks/generate_packing_slips`

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => {
    fetchList()
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const fetchList = async () => {
    try {

      const fullList = await sFetch( endpoint )

      const list = fullList.filter( item => !item.lateConfirms )
      setList( list )

      const lateConfirmsList = fullList.filter( item => item.lateConfirms )
      setLateConfirmsList( lateConfirmsList )

      setFetched( true )

      if ( list.length > 0 ) {
        const firstItem = list[ 0 ]
        if ( !currentItemRef.current || currentItemRef.current.id === firstItem.id ) setCurrentItem( firstItem )
        setProcessingSlips( !firstItem.finishedAt )
      }

      if ( lateConfirmsList.length > 0 ) {
        const firstItem = lateConfirmsList[ 0 ]
        if ( !currentLateConfirmsItemRef.current || currentLateConfirmsItemRef.current.id === firstItem.id ) setCurrentLateConfirmsItem( firstItem )
        setProcessingSlips( !firstItem.finishedAt )
      }

    } catch ( exception ) {

      setFetched( false )

    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const startJob = async ( regenerate = false, lateConfirms = false, wave = null ) => {
    setProcessing( true )

    try {

      let payload = {
        regenerate: regenerate,
        lateConfirms: lateConfirms
      }
      if ( wave ) payload.wave = wave

      setCurrentItem( null )
      await sFetch( endpoint, { method: 'post', body: JSON.stringify( toSnakeCase( payload ) ) } )
      UI.showSuccessDisclaimer( 'Packing slip job generation started.' )
      fetchList()

    } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }

    setProcessing( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const renderListItem = ( item, index ) => {
    const current = item.lateConfirms ? currentLateConfirmsItem : currentItem
    const itemClassName = `pd-text pd-tpl-nav-content__item ${ item.createdAt === ( current && current.createdAt ) ? 'pd-tpl-nav-content__item--active' : '' }`

    return (
      <li
        key={ index }
        className={ itemClassName }
        onClick={ event => {
          if ( item.lateConfirms ) {

            setCurrentLateConfirmsItem( item )

          } else {

            setCurrentItem( item )

          }
        } }>
        { moment( item.createdAt ).format( 'MMMM Do YYYY HH:mm' ) }
      </li>
    )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleGenerateClick = ( regenerate, lateConfirms, event ) => {
    let wave

    if ( !lateConfirms ) {
      wave = prompt( 'Please inform the shipping wave (ex: 1):' )

      if ( isNil( wave ) ) return

      if ( !isFinite( parseInt( wave ) ) ) {
        UI.showErrorDisclaimer( 'Invalid shipping wave.' )
        return
      }
    }

    if ( window.confirm( 'Are you sure?' ) ) {
      startJob( regenerate, lateConfirms, wave )
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )
  useEffect( () => { currentItemRef.current = currentItem }, [ currentItem ] )
  useEffect( () => { currentLateConfirmsItemRef.current = currentLateConfirmsItem }, [ currentLateConfirmsItem ] )
  useInterval( () => {
    if ( isProcessingSlips ) fetchList()
  }, Env.packingSlipStatusCheckerInterval * 1000 )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <section className="pd-packing-slips pd-tpl-nav-contentP">
      { isFetched ? (
        <TabPanel className="pd-tab-panel--cyan" selectedIndex={ project.details.pledgeManagerState === PLEDGE_MANAGER_STATE.OPENED ? 0 : 1 }>
          <TabPanelItem label="Regular Packing Slips">
            <div className="pd-tpl-nav-content pd-packing-slips__wrapper">
              <nav className="pd-tpl-nav-content__nav">
                <button
                  className="pd-button pd-button--navy"
                  disabled={ isProcessing }
                  onClick={ event => handleGenerateClick( false, false ) }>
                  Generate packing slips
                  </button>

                <button
                  className="pd-button pd-button--warning"
                  disabled={ isProcessing }
                  onClick={ event => handleGenerateClick( true, false ) }>
                  Regenerate packing slips
                  </button>

                <ul className="pd-tpl-nav-content__list">
                  { list.map( renderListItem ) }
                </ul>
              </nav>

              <div className="pd-tpl-nav-content__main">
                { currentItem ? (

                  <div className="pd-tpl-nav-content__box">
                    <p className="pd-text">Created at { moment( currentItem.createdAt ).format( 'MMMM Do YYYY HH:mm' ) } by { currentItem.user ? currentItem.user.name : 'System' }</p>
                    <p className="pd-text">Updated at { moment( currentItem.updatedAt ).format( 'MMMM Do YYYY HH:mm' ) }</p>
                    { currentItem.finishedAt && ( <p className="pd-text">Finished at { moment( currentItem.finishedAt ).format( 'MMMM Do YYYY HH:mm' ) }</p> ) }
                    { currentItem.elapsedTime && ( <p className="pd-text">Elapsed time: { currentItem.elapsedTime } seconds</p> ) }
                    <p className="pd-text">Total Slips: { currentItem.totalSlips || 0 }</p>
                    {
                      currentItem.errors.length ? (

                        <div className="pd-packing-slips__error-log">
                          <h4 className="pd-title-4">Error log</h4>
                          { currentItem.errors.map( ( eItem, eIndex ) => (
                            <p className="pd-packing-slips__error" key={ eIndex }>{ eItem.message }</p>
                          ) ) }
                        </div>

                      ) : null

                    }
                  </div>

                ) : (

                  <InfoTip>
                    { list.length ? 'Use the history list to select an item to view.' : 'No packing slips have been generated for this project yet.' }
                  </InfoTip>

                ) }
              </div>
            </div>
          </TabPanelItem>

          <TabPanelItem label="Late Confirms Packing Slips">
            <div className="pd-tpl-nav-content pd-packing-slips__wrapper">
              <nav className="pd-tpl-nav-content__nav">
                <button
                  className="pd-button pd-button--navy"
                  disabled={ isProcessing }
                  onClick={ event => handleGenerateClick( false, true ) }>
                  Generate packing slips
                  </button>

                <button
                  className="pd-button pd-button--warning"
                  disabled={ isProcessing }
                  onClick={ event => handleGenerateClick( true, true ) }>
                  Regenerate packing slips
                  </button>

                <ul className="pd-tpl-nav-content__list">
                  { lateConfirmsList.map( renderListItem ) }
                </ul>
              </nav>

              <div className="pd-tpl-nav-content__main">
                { currentLateConfirmsItem ? (

                  <div className="pd-tpl-nav-content__box">
                    <p className="pd-text">Created at { moment( currentLateConfirmsItem.createdAt ).format( 'MMMM Do YYYY HH:mm' ) } by { currentLateConfirmsItem.user ? currentLateConfirmsItem.user.name : 'System' }</p>
                    <p className="pd-text">Updated at { moment( currentLateConfirmsItem.updatedAt ).format( 'MMMM Do YYYY HH:mm' ) }</p>
                    { currentLateConfirmsItem.finishedAt && ( <p className="pd-text">Finished at { moment( currentLateConfirmsItem.finishedAt ).format( 'MMMM Do YYYY HH:mm' ) }</p> ) }
                    { currentLateConfirmsItem.elapsedTime && ( <p className="pd-text">Elapsed time: { currentLateConfirmsItem.elapsedTime } seconds</p> ) }
                    <p className="pd-text">Total Slips: { currentLateConfirmsItem.totalSlips || 0 }</p>
                  </div>

                ) : (

                  <InfoTip>
                    { list.length ? 'Use the history list to select an item to view.' : 'No packing slips have been generated for this project yet.' }
                  </InfoTip>

                ) }
              </div>
            </div>
          </TabPanelItem>
        </TabPanel>
      ) : <LoadingMessage /> }
    </section>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default PackingSlips