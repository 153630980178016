import React, { useEffect, useContext, useState } from 'react'
import { NavLink } from 'react-router-dom'

import { GlobalContext } from '../../context/global-state'
import { UIContext } from '../../context/ui-state'

import { renderRoute } from '../../config/routes'

import LoadingMessage from '../../components/loading-message/loading-message'
import { WarningTip } from '../../components/tip/tip'

import { ReactComponent as IconCart } from '../../assets/icons/shopping-cart.svg'
import { ReactComponent as IconLock } from '../../assets/icons/lock.svg'
import { ReactComponent as IconUser } from '../../assets/icons/user.svg'

import './account.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const Account = props => {

  const context = useContext( GlobalContext )
  const UI = useContext( UIContext )
  const { secureRender, loggedUser, sendConfirmationEmail } = context
  const [ isSending, setSending ] = useState( false )
  const { className = '', routes } = props

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleResendClick = async event => {
    setSending( true )

    try {

      await sendConfirmationEmail()
      UI.showSuccessDisclaimer( 'Email successfully sent!' )

    } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }

    setSending( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return secureRender(

    <section className={ `pd-account pd-page ${ className }` }>
      { loggedUser.isFetched ?
        (

          <>
            { loggedUser.data.confirmed ? null : (

              <div className="pd-account__confirmation-disclaimer pd-white-box">
                <WarningTip>Your account is not confirmed yet.</WarningTip>
                <button className="pd-button pd-button--navy" onClick={ handleResendClick } disabled={ isSending }>{ isSending ? 'Sending...' : 'Resend confirmation email' } </button>
              </div>

            ) }

            <nav className="pd-account__nav">
              <ul className="pd-account__nav-list">
                <li className="pd-account__nav-item">
                  <NavLink to="/account/pledges" activeClassName="pd-button--active" className="pd-button pd-button--text pd-button--icon pd-button--navy">
                    <IconCart className="pd-icon" />
                    <span>Your pledges</span>
                  </NavLink>
                </li>
                <li className="pd-account__nav-item">
                  <NavLink to="/account/information" activeClassName="pd-button--active" className="pd-button pd-button--text pd-button--icon pd-button--navy">
                    <IconUser className="pd-icon" />
                    <span>Account information</span>
                  </NavLink>
                </li>
                <li className="pd-account__nav-item">
                  <NavLink to="/account/security" activeClassName="pd-button--active" className="pd-button pd-button--text pd-button--icon pd-button--navy">
                    <IconLock className="pd-icon" />
                    <span>Security</span>
                  </NavLink>
                </li>
              </ul>
            </nav>

            { routes.map( ( route, index ) => secureRender( renderRoute( route, index ) ) ) }
          </>

        ) : (

          <LoadingMessage />

        )
      }
    </section>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default Account