import React, { useEffect, useState } from 'react'
import { isArray } from 'lodash'

import './switch.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const Switch = props => {
  const { className = '', onChange = () => null, checked = false, label = null, disabled = false, confirm = null } = props
  const [ isChecked, setChecked ] = useState( checked )
  const onLabel = isArray( label ) ? label[ 0 ] : label
  const offLabel = isArray( label ) ? label[ 1 ] : label

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleClick = event => {
    if ( disabled ) return
    if ( confirm && !window.confirm( confirm ) ) return

    onChange( !isChecked )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )
  useEffect( () => setChecked( checked ), [ checked ] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <div className={ `pd-switch ${ className } ${ disabled ? 'pd-switch--disabled' : '' } ${ label ? 'pd-switch--labeled' : '' } ${ isChecked ? 'pd-switch--checked' : '' }` } onClick={ handleClick }>
      <span className="pd-switch__element"></span>
      { label ? <span className="pd-text-sm pd-switch--label">{ isChecked ? onLabel : offLabel }</span> : null }
    </div>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default Switch
