import React, { useContext, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Formik, Form } from 'formik'
import queryString from 'query-string'

import { GlobalContext } from '../../context/global-state'
import { UIContext } from '../../context/ui-state'

import { NOT_EMPTY_REGEX, EMAIL_REGEX, SOFT_PASSWORD_REGEX } from '../../config/validators'

import CustomField from '../../components/custom-field/custom-field'

import './sign-up.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const SignUp = props => {
  const context = useContext( GlobalContext )
  const UI = useContext( UIContext )

  const { signUp, isRetailer } = context

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => {
    if ( isRetailer ) document.location.href = '/'
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleValidation = values => {
    let errors = {}

    if ( !NOT_EMPTY_REGEX.test( values.name ) ) errors.name = 'Invalid name'
    if ( !EMAIL_REGEX.test( values.email ) ) errors.email = 'Invalid e-mail'
    if ( !SOFT_PASSWORD_REGEX.test( values.password ) ) errors.password = 'Password must be at least 8 characters long and include one letter and one number.'
    if ( values.password !== values.passwordConfirmation ) errors.passwordConfirmation = 'Password doesn\'t match.'

    return errors
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSignUp = async ( payload, actions ) => {
    try {

      await signUp( payload )

      UI.showSuccessDisclaimer( 'Account successfully created!' )
      UI.showInfoDisclaimer( 'Account confirmation email sent. Check your inbox to confirm your account before continuing.', 1000 )

      props.history.push( queryString.parse( document.location.search ).redirect || '' )

    } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }

    actions.setSubmitting( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return isRetailer ? null : (

    <section className="pd-sign-up pd-page pd-form-box-page">
      <Formik
        initialValues={ { name: '', email: '', password: '', passwordConfirmation: '' } }
        onSubmit={ handleSignUp }
        validate={ handleValidation }
        validateOnChange={ false }
        validateOnBlur={ false }>
        { fProps => (

          <Form className={ `pd-form-box-page__form` }>
            <header className="pd-form-box-page__header">
              <h4 className="pd-title-3">Create an account</h4>
              <p className="pd-text">Fill in the fields below to create your Pledg.it account.</p>
            </header>

            <CustomField name="name" label="Name" required />
            <CustomField name="email" type="email" label="E-mail" required />
            <div className="pd-form-box-page__field-group">
              <CustomField name="password" type="password" label="Password" required />
              <CustomField name="passwordConfirmation" type="password" label="Confirm password" required />
            </div>

            <nav className="pd-form-box-page__actions">
              <button className="pd-button pd-button--navy" type="submit" disabled={ fProps.isSubmitting }>{ fProps.isSubmitting ? 'Processing...' : 'Create account' }</button>
              <Link className="pd-button pd-button--link" to="/sign-in">or sign-in</Link>
            </nav>
          </Form>

        ) }
      </Formik>
    </section>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default withRouter( SignUp )