import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'

import { NOT_EMPTY_REGEX } from '../../config/validators'

import CustomField from '../../components/custom-field/custom-field'

import './new-shipping-table-form.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const NewShippingTableForm = props => {

  const { className = '', onSave, onChange } = props
  const { projectId } = useParams()

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleValidation = values => {
    let errors = {}

    if ( !NOT_EMPTY_REGEX.test( values.name ) ) errors.name = 'Invalid name'

    return errors
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSave = async ( payload, actions ) => {
    actions.setSubmitting( true )

    try {

      await onSave( payload )
      actions.resetForm()

    } catch ( exception ) { throw exception }

    actions.setSubmitting( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <Formik
      initialValues={ { name: '', projectId: projectId } }
      enableReinitialize={ true }
      onSubmit={ handleSave }
      validate={ handleValidation }
      validateOnChange={ false }
      validateOnBlur={ false }>
      { fProps => (

        <Form className={ `pd-new-shipping-table-form pd-tpl-lightbox-form pd-white-box ${ className }` } onChange={ onChange }>
          <Field type="hidden" name="projectId" />

          <span className="pd-text-sm pd-text--tip pd-tpl-lightbox-form__required">
            <span className="pd-text--info">*</span> Required fields
          </span>

          <CustomField name="name" label="Table name" required />

          <button className="pd-tpl-lightbox-form__submit pd-button pd-button--navy" type="submit" disabled={ fProps.isSubmitting }>
            { fProps.isSubmitting ? 'Processing...' : 'Create new table' }
          </button>
        </Form>

      ) }
    </Formik>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default NewShippingTableForm