import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'

import { NOT_EMPTY_REGEX } from '../../config/validators'

import CustomField from '../../components/custom-field/custom-field'

import './new-city-form.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const NewCityForm = props => {

  const { className = '', countries = [], selectedProvinces, onSave, onChange } = props
  const [ provinces, setProvinces ] = useState( [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleCountryChange = async event => {
    const countryId = event.target.value
    setProvinces( countryId ? selectedProvinces[ countryId ] : [] )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleValidation = values => {
    let errors = {}

    if ( !NOT_EMPTY_REGEX.test( values.name ) ) errors.name = 'Invalid name'
    if ( !NOT_EMPTY_REGEX.test( values.countryId ) ) errors.countryId = 'Select a country'
    if ( !NOT_EMPTY_REGEX.test( values.provinceId ) ) errors.provinceId = 'Select a province/state'

    return errors
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSave = async ( payload, actions ) => {
    actions.setSubmitting( true )

    try {

      await onSave( payload )
      actions.resetForm()
      setProvinces( [] )

    } catch ( exception ) { throw exception }

    actions.setSubmitting( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const hasProvinces = provinces.length > 0

  return (

    <Formik
      initialValues={ { countryId: '', provinceId: '', name: '' } }
      enableReinitialize={ true }
      onSubmit={ handleSave }
      validate={ handleValidation }
      validateOnChange={ false }
      validateOnBlur={ false }>
      { fProps => (

        <Form className={ `pd-new-city-form pd-tpl-lightbox-form pd-white-box ${ className }` } onChange={ onChange }>
          <span className="pd-text-sm pd-text--tip pd-tpl-lightbox-form__required">
            <span className="pd-text--info">*</span> Required fields
          </span>

          <CustomField name="countryId" label="Country" component="select" onChange={ handleCountryChange } required>
            <option value="">Select</option>
            { countries.map( ( country, index ) => <option key={ index } value={ country.id }>{ country.name }</option> ) }
          </CustomField>

          <CustomField name="provinceId" label="State/Province" component="select" disabled={ !hasProvinces } required>
            <option value="">Select</option>
            { provinces.map( ( province, index ) => <option key={ index } value={ province.id }>{ province.name }</option> ) }
          </CustomField>

          <CustomField name="name" label="City name" disabled={ !hasProvinces } required />

          <button className="pd-tpl-lightbox-form__submit pd-button pd-button--navy" type="submit" disabled={ fProps.isSubmitting }>
            { fProps.isSubmitting ? 'Processing...' : 'Add new city' }
          </button>
        </Form>

      ) }
    </Formik>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default NewCityForm