import React, { useEffect } from 'react'

import { getImageSrc } from '../../config/adapters'

import './checkout-product-contents.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const CheckoutProductContents = props => {

  const { className = '', product, showWaveInfo } = props
  const unavailablePlaceholder = 'https://via.placeholder.com/768x432?text=UNAVAILABLE+IMAGE'

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleImageLoadError = event => event.target.src = unavailablePlaceholder

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <div className={ `pd-checkout-product-contents ${ className } notranslate` }>
      <h3 className="pd-checkout-product-contents__product pd-title-3">{ product.name }</h3>

      <figure className="pd-checkout-product-contents__figure">
        <img
          className="pd-checkout-product-contents__image"
          src={ getImageSrc( product.image, { w: 768, h: 432, resizeTo: 'fill', quality: 60 } ) || '' }
          alt={ product.name }
          onError={ handleImageLoadError }
        />
      </figure>

      <h4 className="pd-checkout-product-contents__title pd-title-4">What's included?</h4>

      <div className="pd-checkout-product-contents__description">
        <ul className="pd-checkout-product-contents__items">
          { product.products.map( product => (
            <li key={ product.id } className="pd-checkout-product-contents__item pd-text">
              <span className="pd-checkout-product-contents__amount pd-text-sm">{ product.quantity }x</span>
              { product.name } { showWaveInfo ? `(wave ${ product.wave })` : '' }
            </li>
          ) ) }
        </ul>

        { product.whatsIncluded ? <p className="pd-checkout-product-contents__notes pd-text-sm">{ product.whatsIncluded }</p> : null }
      </div>
    </div>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default CheckoutProductContents