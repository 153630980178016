import React, { useEffect, useState, useRef } from 'react'
import InputMask from 'react-input-mask'

import { getCurrencyValue } from '../../config/adapters'

import './currency-field.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const CurrencyField = props => {

  const [ mask, setMask ] = useState( '' )
  const elementRef = useRef( null )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleMaskChange = () => {
    handleMask( props.value )
    handleCursor()
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleMask = ( value = '' ) => {
    let currentMask = '$9999999999';

    const len = getCurrencyValue( value ).length
    if ( len < 4 ) currentMask = '$ 99999999999'
    if ( len === 4 ) currentMask = '$ 9,999999999'
    if ( len > 4 && len < 6 ) currentMask = '$ 99,99999999'
    if ( len === 6 ) currentMask = '$ 999,9999999'
    if ( len === 7 ) currentMask = '$ 9.999,99999'
    if ( len === 8 ) currentMask = '$ 99.999,9999'
    if ( len === 9 ) currentMask = '$ 999.999,999'

    setMask( currentMask )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleCursor = () => {
    setTimeout( () => {
      console.log( '...' )
      const target = elementRef.current.getInputDOMNode()
      const pos = target.value.replace( /[ ]/g, '' ).length + 1

      if ( "selectionStart" in target && "selectionEnd" in target ) {
        target.selectionStart = pos
        target.selectionEnd = pos
      } else {
        var range = target.createTextRange()
        range.collapse( true )
        range.moveStart( "character", pos )
        range.moveEnd( pos )
        range.select()
      }
    }, 10 )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )
  useEffect( handleMaskChange, [ props.value ] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <InputMask ref={ elementRef } className="pd-textfield" mask={ mask } maskChar={ '' } { ...props } />

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default CurrencyField