import React, { useContext, useEffect } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { Formik, Form } from 'formik'
import queryString from 'query-string'

import { GlobalContext } from '../../context/global-state'
import { UIContext } from '../../context/ui-state'

import { EMAIL_REGEX, SOFT_PASSWORD_REGEX } from '../../config/validators'

import { InfoTip } from '../../components/tip/tip'
import CustomField from '../../components/custom-field/custom-field'

import './sign-in.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const SignIn = props => {

  const context = useContext( GlobalContext )
  const UI = useContext( UIContext )
  const location = useLocation()
  const history = useHistory()

  const { signIn, loggedUser, isRetailer, showRetailerDisclaimer } = context

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleValidation = values => {
    let errors = {}

    if ( !EMAIL_REGEX.test( values.email ) ) errors.email = 'Invalid e-mail'
    if ( !SOFT_PASSWORD_REGEX.test( values.password ) ) errors.password = 'Invalid password'

    return errors
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSignIn = async ( payload, actions ) => {
    try {

      const success = await signIn( payload )
      if ( success ) history.push( queryString.parse( location.search ).redirect || '' )

    } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }

    actions.setSubmitting( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const preventRenderWhenLoggedIn = () => {
    if ( loggedUser.isLogged && loggedUser.isFetched ) history.push( queryString.parse( location.search ).redirect || '' )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( preventRenderWhenLoggedIn, [ loggedUser ] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <section className="pd-sign-in pd-page pd-form-box-page">
      <Formik
        initialValues={ { email: '', password: '' } }
        onSubmit={ handleSignIn }
        validate={ handleValidation }
        validateOnChange={ false }
        validateOnBlur={ false }>
        { fProps => (

          <Form className="pd-form-box-page__form">
            <header className="pd-form-box-page__header">
              <h4 className="pd-title-3">Sign-in</h4>
              <p className="pd-text">Use your email and password below to sign-in.</p>
            </header>

            <CustomField name="email" type="email" label="E-mail" />
            <CustomField name="password" type="password" label="Password" />

            <nav className="pd-form-box-page__actions">
              <button className="pd-button pd-button--navy" type="submit" disabled={ fProps.isSubmitting }>{ fProps.isSubmitting ? 'Processing...' : 'Sign-in' }</button>
              { isRetailer ? null : <Link className="pd-button pd-button--link" to="/sign-up">or create an account</Link> }
            </nav>

            { showRetailerDisclaimer ? (

              <InfoTip>
                <>
                  This is not a registered retailer address. <br />
                  If you’d like to register a retailer account, please contact your sales representative
                  at <a className="pd-button--text-inline" href="mailto:retailerpledge@cmon.com">retailerpledge@cmon.com</a>.
                </>
              </InfoTip>

            ) : null }

            <nav className="pd-form-box-page__secondary-actions">
              <Link className="pd-button pd-button--link" to="/password/recovery">Forgot password?</Link>
            </nav>
          </Form>

        ) }
      </Formik>
    </section>

  )
}

export default SignIn