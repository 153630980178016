import React, { useContext, useState } from 'react'
import { sortBy } from 'lodash'

import { CheckoutContext } from '../../context/checkout-state'
import { UIContext } from '../../context/ui-state'

import CheckoutProduct from '../checkout-product/checkout-product'
import CheckoutCartWarningLightbox from '../../modules/checkout-cart-warning-lightbox/checkout-cart-warning-lightbox'
import AmountSelector from '../../components/amount-selector/amount-selector'
import { InfoTip, WarningTip } from '../../components/tip/tip'

import './checkout-base-pledge-selector.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const CheckoutBasePledgeSelector = props => {
  const checkoutContext = useContext( CheckoutContext )
  const UI = useContext( UIContext )
  const { project, showcase, pledge, selectBasePledge, updateBasePledgeAmount, stepTo, STEP } = checkoutContext
  const { isBasePledgeSelectedLocked } = showcase
  const { waves } = project
  const [ cartWarning, setCartWarning ] = useState( null )
  const { className = '' } = props

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleContinueClick = async event => {
    stepTo( project.isLatePledge ? STEP.PAYMENT : ( showcase.hasOptionalBuys ? STEP.OPTIONALS : STEP.SHIPPING ) )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const showCartWarning = async () => (
    new Promise( async resolve => {
      UI.setConfirmationCallback( resolve )
    } )
  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSelectClick = async item => {
    const { cartWarning, id } = item

    selectBasePledge( id )

    if ( cartWarning ) {
      setCartWarning( cartWarning )
      await showCartWarning()
      setCartWarning( null )
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <div className={ `pd-checkout-base-pledge-selector pd-checkout-module ${ className }` }>
      <header className="pd-checkout-module__header">
        <h3 className="pd-title-3">01. Base pledge</h3>
        <p className="pd-text">Please choose a base pledge for your order below{ showcase.hasOptionalBuys ? '. You will be able to select add-ons in the next step' : '' }.</p>

        { isBasePledgeSelectedLocked ? (

          <WarningTip>
            The deadline to edit { waves }-Wave Expedited Shipping base pledges has passed.
          </WarningTip>

        ) : null }
      </header>

      <ul className="pd-checkout-module__list">
        { sortBy( showcase.basePledgeOptions, 'position' ).map( item => {

          return (

            <li key={ item.id } className="pd-checkout-module__list-item">
              <CheckoutProduct item={ item } showWaveInfo={ pledge.isMultiwave && project.hasMultipleWaves }>
                { project.retailer && item.isSelected ? (

                  <>
                    { item.minimumPerUser ? <InfoTip micro={ true }>Minimum units: <strong>{ item.minimumPerUser }</strong></InfoTip> : null }
                    <AmountSelector
                      max={ item.maxAllowed }
                      initialValue={ item.amount }
                      isDisabled={ item.isDisabled || item.isLockedToOrder || showcase.isLocked }
                      onChange={ value => updateBasePledgeAmount( item.uid, value ) }
                    />
                  </>

                ) : (

                  <button
                    className={ `pd-button ${ !pledge.requiresBasePledge && item.isSelected ? 'pd-button--danger' : 'pd-button--cyan' }` }
                    disabled={ ( pledge.requiresBasePledge && item.isSelected ) || item.isDisabled || isBasePledgeSelectedLocked || showcase.isLocked }
                    onClick={ handleSelectClick.bind( this, item ) }>
                    { item.isSelected ? ( pledge.requiresBasePledge ? 'Selected' : 'Remove' ) : 'Select' }
                  </button>

                ) }
              </CheckoutProduct>
            </li>

          )
        } ) }
      </ul>

      <nav className="pd-checkout-module__nav">
        <button
          className="pd-button pd-button--navy"
          onClick={ handleContinueClick }
          disabled={ ( showcase.hasOptionalBuys ? showcase.optionalBuysLocked : ( project.isLatePledge ? showcase.paymentLocked : showcase.shippingLocked ) ) || showcase.isLocked }>
          Continue
        </button>
      </nav>

      { cartWarning && (
        <CheckoutCartWarningLightbox cartWarning={ cartWarning } />
      ) }
    </div>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default CheckoutBasePledgeSelector