import React, { useEffect, useContext } from 'react'

import { DashboardContext } from '../../context/dashboard-state'

import { formatCurrency } from '../../config/adapters'

import Table from '../../components/table/table'

import './rewards-summary.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const RewardsSummary = props => {

  const dashboardContext = useContext( DashboardContext )
  const { project } = dashboardContext
  const { bundles, addOns } = project.details
  const { className = '' } = props

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const renderItem = ( item, index ) => {
    const data = ( item.bundleProducts || item.addOnProducts ).map( p => {
      return [
        p.product.sku,
        p.product.wave,
        p.product.name,
        p.quantity,
        `${ p.product.weight }kg`,
        `${ p.product.width }x${ p.product.height }x${ p.product.length }cm`,
        formatCurrency( p.product.slipCost ),
        formatCurrency( p.product.productionCost )
      ]
    } )

    return (
      <div key={ index } className="pd-rewards-summary__item">
        <h3 className="pd-title-3">{ item.name }</h3>

        <p className="pd-text">
          Price: { formatCurrency( item.price ) } <br />
          Stock: { ( item.amountLimit ? `${ item.amountLimit } (${ item.amountLimit - item.amountTaken } left)` : `Unlimited. ${ item.amountTaken } taken` ) } <br />
          Kickstarter ID: { item.kickstarterId || <em className="pd-text-sm">Empty</em> }
        </p>

        <h4 className="pd-title-4">Products included</h4>

        <Table
          className="pd-rewards-summary__table pd-table--x-scroll"
          cols={ [ 'SKU', 'Wave', 'Name', 'Qty', 'Weight', 'Dimensions', 'Slip cost', 'Prod cost' ] }
          rows={ data }
        />
      </div>
    )

  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <section className={ `pd-rewards-summary ${ className }` }>
      { bundles.map( renderItem ) }
      { addOns.map( renderItem ) }
    </section>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default RewardsSummary