import React, { useEffect, useContext, useState } from 'react'

import { UIContext } from '../../context/ui-state'

import { sFetch } from '../../config/fetch'
import { toSnakeCase } from '../../config/adapters'

import { InfoTip } from '../../components/tip/tip'

import './pledge-totals-report-generator.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const PledgeTotalsReportGenerator = props => {

  const UI = useContext( UIContext )
  const [ isProcessing, setProcessing ] = useState( false )
  const { className = '', projectId } = props

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleRequest = async () => {
    if ( !window.confirm( 'Are you sure?' ) ) return

    setProcessing( true )

    try {

      await sFetch( '/geeks/report/pledge_totals_report', { method: 'POST', body: JSON.stringify( toSnakeCase( { projectId: projectId } ) ) } )
      UI.showSuccessDisclaimer( 'Your report is being generated. You\'ll be notified by email when it is ready.' )

    } catch ( exception ) {

      UI.showErrorDisclaimer( exception.message )

    }

    setProcessing( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <InfoTip className={ `pd-pledge-totals-report-generator ${ className }` }>
      <div className="pd-pledge-totals-report-generator__wrapper">
        <h3 className="pd-title-4">Generate Pledge Totals Report</h3>
        <p className="pd-text">Generate a csv report of this project’s pledges and email yourself a link when finished.</p>
        <button className="pd-button pd-button--info" onClick={ handleRequest } disabled={ isProcessing }>{ isProcessing ? 'Processing...' : 'Generate' }</button>
      </div>
    </InfoTip>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default PledgeTotalsReportGenerator