import React, { useEffect, useState } from 'react'

import './accordion.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const Accordion = props => {

  const { className = '', heading, content, indicator90, indicator180, onExpand = null, onCollapse = null } = props
  const [ isExpanded, setExpanded ] = useState( false )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleClick = event => {
    if ( onExpand && !isExpanded ) onExpand()
    if ( onCollapse && isExpanded ) onCollapse()
    setExpanded( !isExpanded )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <div className={ `pd-accordion ${ className } ${ isExpanded ? 'pd-accordion--expanded' : '' }` }>
      <div className="pd-accordion__heading" onClick={ handleClick }>
        { indicator90 && ( <span className="pd-accordion__indicator90">{ indicator90 }</span> ) }
        { indicator180 && ( <span className="pd-accordion__indicator180">{ indicator180 }</span> ) }
        <span>{ heading }</span>
      </div>
      <div className="pd-accordion__content">{ content }</div>
    </div>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default Accordion