import React, { useContext, useEffect, useState, Fragment, createRef } from 'react'
import { PortalWithState } from 'react-portal'
import { Link, useParams } from 'react-router-dom'
import { sortBy, forIn, sumBy } from 'lodash'

import { DashboardState, DashboardContext } from '../../context/dashboard-state'
import { ShippingTableState, ShippingTableContext } from '../../context/shipping-table-state'
import { UIContext } from '../../context/ui-state'
import { pluralize } from '../../config/adapters'

import NewHubImportForm, { IMPORT_STRATEGY, IMPORT_TYPE } from '../../modules/new-hub-import-form/new-hub-import-form'
import NewHubForm, { emptyHub } from '../../modules/new-hub-form/new-hub-form'
import NewCountryForm from '../../modules/new-country-form/new-country-form'
import NewProviceForm from '../../modules/new-province-form/new-province-form'
import NewCityForm from '../../modules/new-city-form/new-city-form'
import NewZipcodeRangeForm from '../../modules/new-zipcode-range-form/new-zipcode-range-form'
import LoadingMessage from '../../components/loading-message/loading-message'
import Lightbox from '../../components/lightbox/lightbox'
import { InfoTip } from '../../components/tip/tip'

import { ReactComponent as IconCopy } from '../../assets/icons/copy.svg'
import { ReactComponent as IconPaste } from '../../assets/icons/paste.svg'
import { ReactComponent as IconCancel } from '../../assets/icons/close.svg'
import { ReactComponent as IconAdd } from '../../assets/icons/add.svg'

import './shipping-table-editor.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

let lightboxRef

const ShippingTableEditor = props => {

  const dashboardContext = useContext( DashboardContext )
  const shippingTableContext = useContext( ShippingTableContext )
  const UI = useContext( UIContext )
  const { project, fetchProject } = dashboardContext
  const {
    currentTable,
    fetchCurrentTable,
    currentHub,
    fetchCurrentHub,
    saveCurrentHub,
    importHub,
    setCurrentHub,
    createNewCountry,
    countries,
    fetchCountries,
    notSelectedCountries,
    selectedCountries,
    createNewProvince,
    selectedProvinces,
    notSelectedProvinces,
    createNewCity,
    createNewZipcodeRange,
    createNewVolWeightRage,
    updateWeightRange,
    updateCost,
    isSavingHub,
    isFetchingHub
  } = shippingTableContext
  const [ copiedRow, setCopiedRow ] = useState( null )
  const [ hubDetails, setHubDetails ] = useState( { rows: 0, cols: 0 } )

  const params = useParams()
  const { projectId, tableId } = params

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => {
    lightboxRef = createRef()
    fetchCountries()
    fetchCurrentTable( tableId )
    fetchProject( projectId )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleHubImport = async payload => {
    try {
      lightboxRef.current.lock()

      if ( payload.importType === IMPORT_TYPE.COUNTRIES ) delete payload.countryId
      if ( payload.strategy === IMPORT_STRATEGY.NEW_HUB ) delete payload.id
      if ( payload.strategy === IMPORT_STRATEGY.OVERWRITE ) forIn( emptyHub, ( value, key ) => delete payload[ key ] )
      delete payload.strategy

      await importHub( payload )

      UI.showSuccessDisclaimer( 'Hub successfully imported!' )
      lightboxRef.current.close()

    } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleHubSave = async payload => {
    try {

      lightboxRef.current.lock()

      await saveCurrentHub( payload )

      UI.showSuccessDisclaimer( 'Hub successfully saved!' )
      lightboxRef.current.close()

    } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleCountrySave = async payload => {
    try {

      lightboxRef.current.lock()

      await createNewCountry( payload )

      UI.showSuccessDisclaimer( 'Country successfully added!' )
      lightboxRef.current.close()

    } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleProvinceSave = async payload => {
    try {

      lightboxRef.current.lock()

      await createNewProvince( payload )

      UI.showSuccessDisclaimer( 'State successfully added!' )
      lightboxRef.current.close()

    } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleCitySave = async payload => {
    try {

      lightboxRef.current.lock()

      await createNewCity( payload )

      UI.showSuccessDisclaimer( 'City successfully added!' )
      lightboxRef.current.close()

    } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleZipcodeRangeSave = async payload => {
    try {

      lightboxRef.current.lock()

      await createNewZipcodeRange( payload )

      UI.showSuccessDisclaimer( 'Zipcode range successfully added!' )
      lightboxRef.current.close()

    } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleHubChange = event => {
    const hubId = event.target.value

    if ( hubId ) {

      fetchCurrentHub( hubId )

    } else {

      setCurrentHub( null )

    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleAddRangeClick = async event => {

    try {

      await createNewVolWeightRage()
      UI.showSuccessDisclaimer( 'Your shipping table was saved successfully!' )

    } catch ( exception ) { throw exception }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleWeightChange = event => {
    const { name, value } = event.target
    updateWeightRange( name, value )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleCostChange = event => {
    const { name, value, dataset } = event.target
    updateCost( dataset.childId, name, value ) //  childId, rangeId, cost
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSaveClick = async event => {
    await saveCurrentHub()
    UI.showSuccessDisclaimer( 'Your shipping table was saved successfully!' )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleCopyRowClick = row => {

    const recursivePaste = ( collection, pasteId ) => collection.map( child => {

      if ( child.id === pasteId ) {

        child.rangeCosts = child.rangeCosts.map( ( range, index ) => {
          range.cost = copiedRow.rangeCosts[ index ].cost
          return range
        } )

      } else child.children = recursivePaste( child.children, pasteId )

      return child
    } )

    if ( copiedRow ) {

      if ( row.id !== copiedRow.id ) {

        let currentHubCopy = Object.assign( {}, currentHub )
        currentHubCopy.children = recursivePaste( currentHub.rootShippingZones, row.id )
        setCurrentHub( currentHubCopy )

      }

      setCopiedRow( null )

    } else setCopiedRow( row )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleCurrentHubChange = () => {
    if ( !currentHub ) {
      setHubDetails( { rows: 0, cols: 0 } )
      return
    }

    const getLength = list => list.length + sumBy( list, item => ( item.children ? getLength( item.children ) : 0 ) )
    setHubDetails( { rows: getLength( currentHub.rootShippingZones ), cols: currentHub.volumetricWeightRanges.length } )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const renderRowTitle = ( row ) => {
    if ( row.zipFrom ) return `${ row.zipFrom } to ${ row.zipTo }`
    if ( row.city ) return row.city
    if ( row.state ) return row.state.name
    if ( row.country ) return row.country.name
    return null
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const renderRows = ( collection = [], level = 0 ) => collection.map( ( row, rowIndex ) => (
    <Fragment key={ rowIndex }>
      <tr className={ `pd-shipping-table-editor__tr pd-shipping-table-editor__tr--lv${ level }` }>
        <td className="pd-shipping-table-editor__td pd-shipping-table-editor__td-title pd-text-sm">
          {/* <input type="checkbox" name={ row.id } onChange={ handleRowSelection } /> */ }
          { renderRowTitle( row ) }
        </td>

        { row.rangeCosts.map( ( cost, rangeIndex ) => (

          <td key={ rangeIndex } className="pd-shipping-table-editor__td pd-shipping-table-editor__td-fields">
            <input
              onChange={ handleCostChange }
              data-child-id={ row.id }
              className="pd-textfield"
              type="text"
              name={ cost.id }
              value={ cost.cost }
            />
          </td>

        ) ) }

        <td className="pd-shipping-table-editor__td pd-shipping-table-editor__td-action">
          <button title={ copiedRow ? ( copiedRow.id === row.id ? 'Cancel copy' : 'Paste row' ) : 'Copy row' } className="pd-button pd-button--icon pd-button--icon-only pd-button--cyan" onClick={ handleCopyRowClick.bind( this, row ) }>
            { copiedRow ? ( copiedRow.id === row.id ? <IconCancel className="pd-icon" /> : <IconPaste className="pd-icon" /> ) : <IconCopy className="pd-icon" /> }
          </button>
        </td>
      </tr>

      { renderRows( row.children, level + 1 ) }
    </Fragment>
  ) )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const preventEditLoss = () => {
    lightboxRef.current.askBeforeClose( true )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )
  useEffect( handleCurrentHubChange, [ currentHub ] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <section className={ `pd-shipping-table-editor pd-page ${ isSavingHub ? 'pd-shipping-table-editor--saving' : '' }` }>
      { project.isFetched && currentTable ?
        (
          <>
            <header className="pd-shipping-table-editor__header">
              <Link className="pd-title-2" to={ `/dashboard/project/${ projectId }` }>{ `${ project.details.name }` } » </Link>
              <Link className="pd-title-3" to={ `/dashboard/project/${ projectId }/shipping-tables` }>Shipping tables » </Link>
              <span className="pd-title-3">{ currentTable.name }</span>
            </header>

            <nav className="pd-shipping-table-editor__nav">
              { currentHub ? (

                <PortalWithState>
                  { portalBag => (
                    <>
                      <button className="pd-button pd-button--navy" disabled={ isFetchingHub } onClick={ portalBag.openPortal }>
                        Edit current hub
                      </button>

                      { portalBag.portal(
                        <Lightbox portalBag={ portalBag } ref={ lightboxRef }>
                          <NewHubForm
                            hub={ currentHub }
                            onChange={ preventEditLoss }
                            onSave={ handleHubSave }
                          />
                        </Lightbox>
                      ) }
                    </>
                  ) }
                </PortalWithState>

              ) : null }

              <PortalWithState>
                { portalBag => (
                  <>
                    <button className="pd-button pd-button--navy" disabled={ isFetchingHub } onClick={ portalBag.openPortal }>
                      Import hub
                    </button>

                    { portalBag.portal(
                      <Lightbox portalBag={ portalBag } ref={ lightboxRef }>
                        <NewHubImportForm
                          hubs={ sortBy( currentTable.shippingHubs, 'name' ) }
                          countries={ countries }
                          onChange={ preventEditLoss }
                          onSave={ handleHubImport }
                        />
                      </Lightbox>
                    ) }
                  </>
                ) }
              </PortalWithState>


              <div className="pd-shipping-table-editor__new-entities">
                <h4 className="pd-label">Add new:</h4>

                <PortalWithState>
                  { portalBag => (
                    <>
                      <button className="pd-button pd-button--cyan" disabled={ isFetchingHub } onClick={ portalBag.openPortal }>
                        Hub
                      </button>

                      { portalBag.portal(
                        <Lightbox portalBag={ portalBag } ref={ lightboxRef }>
                          <NewHubForm
                            onChange={ preventEditLoss }
                            onSave={ handleHubSave }
                          />
                        </Lightbox>
                      ) }
                    </>
                  ) }
                </PortalWithState>

                { currentHub ? (

                  <PortalWithState>
                    { portalBag => (
                      <>
                        <button className="pd-button pd-button--cyan" disabled={ isFetchingHub } onClick={ portalBag.openPortal }>
                          Country
                        </button>

                        { portalBag.portal(
                          <Lightbox portalBag={ portalBag } ref={ lightboxRef }>
                            <NewCountryForm
                              countries={ notSelectedCountries }
                              onChange={ preventEditLoss }
                              onSave={ handleCountrySave }
                            />
                          </Lightbox>
                        ) }
                      </>
                    ) }
                  </PortalWithState>

                ) : null }

                { selectedCountries.length > 0 ? (
                  <>
                    <PortalWithState>
                      { portalBag => (
                        <>
                          <button className="pd-button pd-button--cyan" disabled={ isFetchingHub } onClick={ portalBag.openPortal }>
                            State/province
                          </button>

                          { portalBag.portal(
                            <Lightbox portalBag={ portalBag } ref={ lightboxRef }>
                              <NewProviceForm
                                countries={ selectedCountries }
                                notSelectedProvinces={ notSelectedProvinces }
                                onChange={ preventEditLoss }
                                onSave={ handleProvinceSave }
                              />
                            </Lightbox>
                          ) }
                        </>
                      ) }
                    </PortalWithState>

                    <PortalWithState>
                      { portalBag => (
                        <>
                          <button className="pd-button pd-button--cyan" disabled={ isFetchingHub } onClick={ portalBag.openPortal }>
                            City
                          </button>

                          { portalBag.portal(
                            <Lightbox portalBag={ portalBag } ref={ lightboxRef }>
                              <NewCityForm
                                countries={ selectedCountries }
                                selectedProvinces={ selectedProvinces }
                                onChange={ preventEditLoss }
                                onSave={ handleCitySave }
                              />
                            </Lightbox>
                          ) }
                        </>
                      ) }
                    </PortalWithState>

                    <PortalWithState>
                      { portalBag => (
                        <>
                          <button className="pd-button pd-button--cyan" disabled={ isFetchingHub } onClick={ portalBag.openPortal }>
                            Zipcode range
                          </button>

                          { portalBag.portal(
                            <Lightbox portalBag={ portalBag } ref={ lightboxRef }>
                              <NewZipcodeRangeForm
                                countries={ selectedCountries }
                                selectedProvinces={ selectedProvinces }
                                onChange={ preventEditLoss }
                                onSave={ handleZipcodeRangeSave }
                              />
                            </Lightbox>
                          ) }
                        </>
                      ) }
                    </PortalWithState>
                  </>
                ) : null }
              </div>

            </nav>

            {
              isFetchingHub ? ( <LoadingMessage>Fetching hub</LoadingMessage> ) : (
                <>
                  { currentHub ? (
                    <InfoTip className="pd-shipping-table-editor__info">
                      This hub contains { pluralize( hubDetails.cols, 'column' ) } and { pluralize( hubDetails.rows, 'row' ) }.
                    </InfoTip>
                  ) : null }

                  <div className="pd-shipping-table-editor__scrollpanel">
                    <table className="pd-shipping-table-editor__table">
                      <thead className="pd-shipping-table-editor__thead">
                        <tr className="pd-shipping-table-editor__tr">
                          <th className="pd-shipping-table-editor__th pd-shipping-table-editor__th-selector">
                            <select className="pd-select" name="hubSelector" onChange={ handleHubChange } value={ currentHub ? currentHub.id : '' }>
                              <option value="">Please select</option>
                              { sortBy( currentTable.shippingHubs, 'name' ).map( ( hub, index ) => <option key={ index } value={ hub.id }>{ hub.name }</option> ) }
                            </select>
                          </th>

                          { currentHub ? ( <>
                            { sortBy( currentHub.volumetricWeightRanges, 'id' ).map( ( item, index, arr ) => (

                              <th key={ index } className="pd-shipping-table-editor__th pd-shipping-table-editor__th-fields">
                                <span className="pd-shipping-table-editor__th-range">
                                  <span className="pd-text">{ arr[ index - 1 ] ? arr[ index - 1 ].max : 0 }</span>
                                  <span>-</span>
                                  <input type="text" className="pd-textfield" name={ item.id } value={ item.max } onChange={ handleWeightChange } />
                                </span>
                              </th>

                            ) ) }

                            <th className="pd-shipping-table-editor__th pd-shipping-table-editor__th-action">
                              <button title="Add new column" className="pd-button pd-button--icon pd-button--icon-only pd-button--navy" onClick={ handleAddRangeClick }><IconAdd className="pd-icon" /></button>
                            </th>
                          </> ) : null }

                        </tr>
                      </thead>

                      { currentHub ? (
                        <tbody className="pd-shipping-table-editor__tbody">
                          { renderRows( currentHub.rootShippingZones ) }
                        </tbody>
                      ) : null }

                    </table>
                  </div>

                  { currentHub ? (

                    <nav className="pd-shipping-table-editor__nav">
                      <button className="pd-button pd-button--navy" disabled={ isSavingHub } onClick={ handleSaveClick }>
                        { isSavingHub ? 'Processing...' : 'Save changes' }
                      </button>
                    </nav>

                  ) : null }

                </>
              )
            }
          </>

        ) : ( <LoadingMessage>Fetching shipping table</LoadingMessage> )
      }
    </section>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

const ShippingTableEditorWrapper = props => (
  <DashboardState>
    <ShippingTableState>
      <ShippingTableEditor { ...props } />
    </ShippingTableState>
  </DashboardState>
)


export default ShippingTableEditorWrapper