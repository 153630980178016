import React, { useEffect, useContext, useState } from 'react'
import { Form, Formik } from 'formik'

import { UIContext } from '../../context/ui-state'
import { sFetch } from '../../config/fetch'
import { EMAIL_REGEX, NOT_EMPTY_REGEX, PHONE_REGEX } from '../../config/validators'

import TabPanel, { TabPanelItem } from '../../components/tab-panel/tab-panel'
import CustomField from '../../components/custom-field/custom-field'

import './user-picker.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const UserPicker = props => {

  const UI = useContext( UIContext )
  const [ usersFound, setUsersFound ] = useState( [] )
  const { className = '', onSelect, onTabChange, onSearchStart, disabled = false } = props

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSearchUserSubmit = async ( payload, fProps ) => {
    fProps.setSubmitting( true )
    if ( onSearchStart ) onSearchStart( payload )
    setUsersFound( [] )

    try {

      const { users } = await sFetch( `/users/report?by_text=${ encodeURIComponent( payload.email ) }` )
      if ( users.length > 1 ) setUsersFound( users )
      if ( users.length === 1 ) onSelect( users[ 0 ] )
      if ( users.length === 0 ) UI.showErrorDisclaimer( `No users found with thiese search params.` )

    } catch ( exception ) { }

    fProps.setSubmitting( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleCreateUserSubmit = ( payload, fProps ) => {
    onSelect( payload )
    fProps.setSubmitting( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSearchResultClick = ( payload, fProps ) => {
    onSelect( payload )
    setUsersFound( [] )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleCreateUserValidation = values => {
    let errors = {}

    if ( !NOT_EMPTY_REGEX.test( values.name ) ) errors.name = 'Invalid name'
    if ( !EMAIL_REGEX.test( values.email ) ) errors.email = 'Invalid e-mail'

    return errors
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <TabPanel className={ `pd-tab-panel--cyan ${ className }` } onTabChange={ onTabChange }>
      <TabPanelItem key="0" label="Find a user" className="pd-white-box" disabled={ disabled }>
        <div className="pd-user-picker__search">
          <Formik
            initialValues={ { email: '' } }
            onSubmit={ handleSearchUserSubmit }
            validateOnChange={ false }
            validateOnBlur={ false }>
            { fProps => (

              <Form className="pd-user-picker__search-field">
                <CustomField label="E-mail" name="email" disabled={ fProps.isSubmitting } />
                <button className="pd-button pd-button--cyan" disabled={ fProps.isSubmitting || disabled }>{ fProps.isSubmitting ? `Searching...` : `Search` }</button>
              </Form>

            ) }
          </Formik>

          { usersFound.length ? (
            <div className="pd-user-picker__search-results">
              <h4 className="pd-title-4">{ usersFound.length } results found</h4>
              <p className="pd-text">Please select the user that will receive the transfer:</p>
              <ul className="pd-user-picker__search-results-list">
                { usersFound.map( user => (

                  <li key={ user.id }>
                    <button
                      className="pd-button pd-button--text pd-button--cyan"
                      onClick={ handleSearchResultClick.bind( this, user ) }>
                      { user.name } ({ user.email })
                      </button>
                  </li>

                ) ) }
              </ul>
            </div>
          ) : null }
        </div>
      </TabPanelItem>

      <TabPanelItem key="1" label="Create a new user" disabled={ disabled }>
        <Formik
          initialValues={ { name: '', email: '' } }
          onSubmit={ handleCreateUserSubmit }
          validate={ handleCreateUserValidation }
          validateOnChange={ false }
          validateOnBlur={ false }>
          { fProps => (

            <Form className="pd-user-picker__create-form pd-white-box">
              <CustomField label="Name" name="name" disabled={ fProps.isSubmitting } />
              <CustomField label="E-mail" name="email" disabled={ fProps.isSubmitting } />
              <button className="pd-button pd-button--cyan" disabled={ fProps.isSubmitting || disabled }>{ fProps.isSubmitting ? `Creating...` : `Create` }</button>
            </Form>

          ) }
        </Formik>
      </TabPanelItem>
    </TabPanel>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default UserPicker
