import React, { useEffect, useContext, useState } from 'react'
import { startCase } from 'lodash'

import { GlobalContext } from '../../context/global-state'

import { ROLE } from '../../config/constants'
import { sFetch } from '../../config/fetch'

import Table from '../../components/table/table'

import './collaborators-list.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const CollaboratorsList = props => {

  const context = useContext( GlobalContext )
  const { loggedUser } = context
  const { companies } = loggedUser.data
  const [ collaboratorsDataset, setCollaboratorsDataset ] = useState( {
    hasData: false
  } )
  const { className = '' } = props

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => {
    parse()
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const parse = async () => {
    let data = { hasData: false }

    for ( let index in companies ) {
      const company = companies[ index ]

      if ( company.roles.indexOf( ROLE.SUPER_ADMIN ) >= 0 ) {

        const list = await sFetch( `/companies/${ company.id }/collaborators` )
        data[ company.id ] = {
          list: list.map( item => ( [
            { value: item.user.name, link: `/dashboard/user/${ item.user.id }` },
            { value: item.roles?.map( r => startCase( r ) ).join( ', ' ) || '-', link: `/dashboard/user/${ item.user.id }` }
          ] ) )
        }
        data.hasData = true

      }
    }

    setCollaboratorsDataset( data )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return collaboratorsDataset.hasData ? (

    <div className={ `pd-collaborators-list ${ className }` }>
      <h4 className="pd-title-4">Collaborator access privileges</h4>

      { loggedUser.data.companies
        .filter( c => c.roles.indexOf( ROLE.SUPER_ADMIN ) >= 0 )
        .map( company => (

          <Table
            key={ company.id }
            className="pd-collaborators-list__table"
            cols={ [ 'User', 'Roles' ] }
            rows={ collaboratorsDataset[ company.id ]?.list || [] }
          />

        ) ) }
    </div>

  ) : null

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default CollaboratorsList