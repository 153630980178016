import React, { useEffect, useState, useCallback, forwardRef } from 'react'

import { ReactComponent as IconDown } from '../../assets/icons/down.svg'

import './expansion-panel.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const ExpansionPanelContent = forwardRef( ( props, ref ) => (
  <div className="pd-expansion-panel__wrapper" ref={ ref }>{ props.children }</div>
) )

const ExpansionPanel = props => {

  const { className = '', title = '', expanded = false, children, onExpand = () => null, onCollapse = () => null, theme = 'cyan' } = props
  const [ isExpanded, setExpanded ] = useState( expanded )
  const [ initHeight, setInitHeight ] = useState( null )
  const callbackRef = useCallback( node => {
    if ( node ) {
      const { height } = node.getBoundingClientRect()
      if ( height > 0 && height !== initHeight ) setInitHeight( height )
    }
  } )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleClick = event => {
    if ( isExpanded ) {
      setExpanded( false )
      onCollapse()
    } else {
      setExpanded( true )
      onExpand()
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <div className={ `pd-expansion-panel pd-expansion-panel--${ theme } ${ isExpanded ? 'pd-expansion-panel--expanded' : '' } ${ className }` }>
      <div className="pd-expansion-panel__title">
        <button className={ `pd-button pd-button--icon pd-button--${ theme } pd-expansion-panel__button` } onClick={ handleClick }>
          <IconDown className="pd-icon" />
          { title }
        </button>
      </div>
      <div className="pd-expansion-panel__content" style={ { height: isExpanded ? initHeight : 0 } }>
        <ExpansionPanelContent ref={ callbackRef }>{ children }</ExpansionPanelContent>
      </div>
    </div>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default ExpansionPanel