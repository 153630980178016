import React, { useState, useEffect, useReducer } from 'react'
import { cloneDeep, find, first, forEach, forIn, snakeCase } from 'lodash'

import { sFetch, rFetch } from '../config/fetch'
import { toSnakeCase } from '../config/adapters'
import { ACCEPT_ONLY_FLOAT_REGEX } from '../config/validators'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const ShippingTableContext = React.createContext()

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const ShippingTableState = props => {

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const [ tables, setTables ] = useState( [] )
  const [ currentTable, setCurrentTable ] = useState( null )
  const [ currentHub, setCurrentHub ] = useState( null )
  const [ countries, setCountries ] = useState( [] )
  const [ notSelectedCountries, setNotSelectedCountries ] = useState( [] )
  const [ selectedCountries, setSelectedCountries ] = useState( [] )
  const [ isFetchingHub, setFetchingHub ] = useState( false )
  const [ isSavingHub, setSavingHub ] = useState( false )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const provincesReducer = ( state, action ) => ( { ...state, [ action.countryId ]: action.provinces } )
  const [ provinces, dispatchProvinces ] = useReducer( provincesReducer, {} )

  const selectedProvincesReducer = ( state, action ) => ( { ...state, [ action.countryId ]: action.provinces } )
  const [ selectedProvinces, dispatchSelectedProvinces ] = useReducer( selectedProvincesReducer, {} )

  const notSelectedProvincesReducer = ( state, action ) => ( { ...state, [ action.countryId ]: action.provinces } )
  const [ notSelectedProvinces, dispatchNotSelectedProvinces ] = useReducer( notSelectedProvincesReducer, {} )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  

  const fetchTables = async () => {
    try {

      const list = await sFetch( '/shipping_table' )
      setTables( list )

    } catch ( exception ) { throw exception }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const createNewTable = async payload => {
    try {

      const newTable = await sFetch( '/shipping_table', { method: 'post', body: JSON.stringify( toSnakeCase( payload ) ) } )
      delete newTable.shippingHubs
      setTables( [ newTable ].concat( tables ) )

    } catch ( exception ) { throw exception }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const fetchCurrentTable = async tableId => {
    try {

      const table = await sFetch( `/shipping_table/${ tableId }` )
      setCurrentTable( table )

    } catch ( exception ) { throw exception }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleCurrentHubChange = () => {
    if ( currentHub ) {
      handleCountryAndProvincesChanges()
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleCountryAndProvincesChanges = async () => {
    const countries = await getCountries()
    const selectedCountries = currentHub.rootShippingZones.map( item => item.country.id )
    const selectedProvinces = currentHub.rootShippingZones.map( item => ( { countryId: item.country.id, provinces: item.children.map( subItem => subItem.state ? subItem.state.id : null ) } ) )

    setNotSelectedCountries( countries.filter( country => selectedCountries.indexOf( country.id ) < 0 ? country : false ) )
    setSelectedCountries( countries.filter( country => selectedCountries.indexOf( country.id ) < 0 ? false : country ) )

    forEach( selectedProvinces, async item => {
      const provinces = await getProvincesByCountryId( item.countryId )

      dispatchSelectedProvinces( {
        countryId: item.countryId,
        provinces: provinces.filter( province => item.provinces.indexOf( province.id ) < 0 ? false : province )
      } )

      dispatchNotSelectedProvinces( {
        countryId: item.countryId,
        provinces: provinces.filter( province => item.provinces.indexOf( province.id ) < 0 ? province : false )
      } )
    } )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const fetchCurrentHub = async hubId => {
    try {

      setFetchingHub( true )
      const hub = await sFetch( `/shipping_table/${ currentTable.id }/shipping_hub/${ hubId }` )
      setCurrentHub( hub )
      setFetchingHub( false )

    } catch ( exception ) { throw exception }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const importHub = async payload => {
    try {

      setSavingHub( true )

      const formData = new FormData()
      forIn( payload, ( value, key ) => formData.append( snakeCase( key ), value ) )

      const savedHub = await sFetch( `/shipping_table/${ currentTable.id }/shipping_hub`, { method: 'post', body: formData }, true )
      let shippingHubs = cloneDeep( currentTable.shippingHubs )

      if ( find( shippingHubs, { id: savedHub.id } ) ) {

        shippingHubs = shippingHubs.map( hub => hub.id === savedHub.id ? savedHub : hub )

      } else {

        shippingHubs.push( savedHub )

      }

      setCurrentTable( {
        ...currentTable,
        shippingHubs: shippingHubs
      } )

      setCurrentHub( savedHub )
      setSavingHub( false )
      return savedHub

    } catch ( exception ) {

      setSavingHub( false )
      throw exception

    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const saveCurrentHub = async ( hub = currentHub ) => {
    try {

      setSavingHub( true )

      const savedHub = await sFetch( `/shipping_table/${ currentTable.id }/shipping_hub`, { method: 'post', body: JSON.stringify( toSnakeCase( hub ) ) } )
      let shippingHubs = cloneDeep( currentTable.shippingHubs )

      if ( find( shippingHubs, { id: savedHub.id } ) ) {

        shippingHubs = shippingHubs.map( hub => hub.id === savedHub.id ? savedHub : hub )

      } else {

        shippingHubs.push( savedHub )

      }

      setCurrentTable( {
        ...currentTable,
        shippingHubs: shippingHubs
      } )

      setCurrentHub( savedHub )
      setSavingHub( false )
      return savedHub

    } catch ( exception ) {

      setSavingHub( false )
      throw exception

    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const createNewVolWeightRage = async () => {
    try {

      let hubCopy = cloneDeep( currentHub )

      const recursiveAddition = collection => {
        return collection.map( item => {
          item.rangeCosts.push( { cost: 0, volumetricWeightRangeId: first( hubCopy.volumetricWeightRanges ).id } )
          if ( item.children ) item.children = recursiveAddition( item.children )
          return item
        } )
      }

      const firstRange = first( hubCopy.volumetricWeightRanges )
      hubCopy.volumetricWeightRanges.push( { max: firstRange ? parseFloat( firstRange.max ) + 1 : 1 } )

      hubCopy = await saveCurrentHub( hubCopy )
      hubCopy.rootShippingZones = recursiveAddition( hubCopy.rootShippingZones )
      hubCopy = await saveCurrentHub( hubCopy )

    } catch ( exception ) { throw exception }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const createNewCountry = async country => {
    try {

      let hubCopy = cloneDeep( currentHub )
      const firstRange = first( hubCopy.rootShippingZones )
      const rangeCosts = firstRange ?
        (

          firstRange.rangeCosts.map( range => ( {
            cost: range.cost,
            volumetricWeightRangeId: range.volumetricWeightRangeId
          } ) )

        ) : (

          hubCopy.volumetricWeightRanges.map( range => ( {
            cost: 0,
            volumetricWeightRangeId: range.id
          } ) )

        )

      hubCopy.rootShippingZones.push( {
        rangeCosts: rangeCosts,
        country: country,
        children: []
      } )

      await saveCurrentHub( hubCopy )

    } catch ( exception ) { throw exception }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const createNewProvince = async payload => {
    const countryId = parseInt( payload.countryId )
    const provinceId = parseInt( payload.provinceId )

    try {

      let hubCopy = cloneDeep( currentHub )

      hubCopy.rootShippingZones.map( child => {
        if ( child.country.id === countryId ) {
          child.children.push( {
            parentId: child.id,
            rangeCosts: first( hubCopy.rootShippingZones ).rangeCosts.map( range => ( {
              cost: range.cost,
              volumetricWeightRangeId: range.volumetricWeightRangeId
            } ) ),
            state: { id: provinceId },
            children: []
          } )
        }

        return child
      } )

      await saveCurrentHub( hubCopy )

    } catch ( exception ) { throw exception }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const createNewCity = async payload => {
    const countryId = parseInt( payload.countryId )
    const provinceId = parseInt( payload.provinceId )
    const cityName = payload.name

    try {

      let hubCopy = cloneDeep( currentHub )

      hubCopy.rootShippingZones.map( child => {
        if ( child.country.id === countryId ) {

          child.children.map( subChild => {

            if ( subChild.state.id === provinceId ) {

              subChild.children.push( {
                parentId: subChild.id,
                rangeCosts: first( hubCopy.rootShippingZones ).rangeCosts.map( range => ( {
                  cost: range.cost,
                  volumetricWeightRangeId: range.volumetricWeightRangeId
                } ) ),
                city: cityName,
                children: []
              } )

            }

            return subChild
          } )
        }

        return child
      } )

      await saveCurrentHub( hubCopy )

    } catch ( exception ) { throw exception }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const createNewZipcodeRange = async payload => {
    const { zipFrom, zipTo } = payload
    const countryId = parseInt( payload.countryId )
    const provinceId = payload.provinceId ? parseInt( payload.provinceId ) : null

    try {

      let hubCopy = cloneDeep( currentHub )

      hubCopy.rootShippingZones.map( child => {
        if ( child.country.id === countryId ) {

          if ( provinceId ) {

            child.children.map( subChild => {

              if ( subChild.state.id === provinceId ) {

                subChild.children.push( {
                  parentId: subChild.id,
                  rangeCosts: first( hubCopy.rootShippingZones ).rangeCosts.map( range => ( {
                    cost: range.cost,
                    volumetricWeightRangeId: range.volumetricWeightRangeId
                  } ) ),
                  zipFrom: zipFrom,
                  zipTo: zipTo,
                  children: []
                } )

              }

              return subChild
            } )

          } else {

            child.children.push( {
              parentId: child.id,
              rangeCosts: first( hubCopy.rootShippingZones ).rangeCosts.map( range => ( {
                cost: range.cost,
                volumetricWeightRangeId: range.volumetricWeightRangeId
              } ) ),
              zipFrom: zipFrom,
              zipTo: zipTo,
              children: []
            } )

          }

        }

        return child
      } )

      await saveCurrentHub( hubCopy )

    } catch ( exception ) { throw exception }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const updateWeightRange = async ( rangeId, weight ) => {
    let hubCopy = cloneDeep( currentHub )

    hubCopy.volumetricWeightRanges = hubCopy.volumetricWeightRanges.map( range => {
      if ( range.id === parseInt( rangeId ) ) range.max = normalizeFloat( weight )
      return range
    } )

    setCurrentHub( hubCopy )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const updateCost = async ( childId, rangeId, cost ) => {
    const recursiveChange = ( collection, childId, rangeId, cost ) => collection.map( child => {
      if ( child.id === parseInt( childId ) ) {

        child.rangeCosts = child.rangeCosts.map( range => {

          if ( range.id === parseInt( rangeId ) ) range.cost = normalizeFloat( cost )
          return range

        } )

      } else {

        child.children = recursiveChange( child.children, childId, rangeId, cost )

      }

      return child
    } )

    let hubCopy = cloneDeep( currentHub )
    hubCopy.rootShippingZones = recursiveChange( hubCopy.rootShippingZones, childId, rangeId, cost )

    setCurrentHub( hubCopy )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const dropRows = async collection => {
    // WIP
    try {

      let hubCopy = cloneDeep( currentHub )

      forEach( collection, item => {
        const parsed = item.split( '|' )
        const countryId = parseInt( parsed[ 0 ] )
        const level = parsed.length - 1

        if ( level === 0 ) {
          hubCopy.rootShippingZones = hubCopy.rootShippingZones.filter( item => item.id !== countryId )
        }
      } )

      debugger

      saveCurrentHub( hubCopy )

    } catch ( exception ) { throw exception }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const fetchCountries = async () => {
    const list = await rFetch( `/countries` )
    setCountries( list )
    return list
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const fetchProvinces = async countryId => {
    const newProvinces = await rFetch( `/states?country_id=${ countryId }` )
    dispatchProvinces( { countryId: countryId, provinces: newProvinces } )
    return newProvinces
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const getCountries = async () => new Promise( async resolve => {
    if ( countries.length > 0 ) {
      resolve( countries )
      return
    }

    resolve( await fetchCountries() )
  } )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const getProvincesByCountryId = async countryId => new Promise( async resolve => {
    if ( !provinces[ countryId ] ) {
      const newProvinces = await fetchProvinces( countryId )
      resolve( newProvinces )
      return
    }

    resolve( provinces[ countryId ] )
  } )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const normalizeFloat = value => value.replace( ACCEPT_ONLY_FLOAT_REGEX, '' )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( handleCurrentHubChange, [ currentHub ] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (
    <ShippingTableContext.Provider value={ {
      fetchTables: fetchTables,
      createNewTable: createNewTable,
      tables: tables,

      fetchCurrentTable: fetchCurrentTable,
      currentTable: currentTable,
      setCurrentTable: setCurrentTable,

      fetchCurrentHub: fetchCurrentHub,
      importHub: importHub,
      saveCurrentHub: saveCurrentHub,
      setCurrentHub: setCurrentHub,
      currentHub: currentHub,
      isFetchingHub: isFetchingHub,
      isSavingHub: isSavingHub,

      fetchCountries: fetchCountries,
      createNewCountry: createNewCountry,
      countries: countries,
      selectedCountries: selectedCountries,
      notSelectedCountries: notSelectedCountries,

      fetchProvinces: fetchProvinces,
      createNewProvince: createNewProvince,
      getProvincesByCountryId: getProvincesByCountryId,
      provinces: provinces,
      selectedProvinces: selectedProvinces,
      notSelectedProvinces: notSelectedProvinces,

      createNewCity: createNewCity,
      createNewZipcodeRange: createNewZipcodeRange,
      createNewVolWeightRage: createNewVolWeightRage,
      updateWeightRange: updateWeightRange,
      updateCost: updateCost,
      dropRows: dropRows

    } }>
      { props.children }
    </ShippingTableContext.Provider>
  )
}