import React from 'react'
import { Link } from 'react-router-dom'

import { getImageSrc } from '../../config/adapters'

import './company-projects-list.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const CompanyProjectsList = props => {

  const { className = '', collection = [] } = props
  const imageUnavailable = 'https://placehold.it/640x360?text=Unavailable+image'

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return collection.length > 0 ? (

    <section className={ `pd-company-projects-list ${ className }` }>
      <div className="pd-company-projects-list__container pd-container">
        <h2 className="pd-company-projects-list__title pd-title-2">Your<br />company's projects</h2>
        <ul className="pd-company-projects-list__list">
          { collection.map( ( project, index ) => (

            <li key={ index } className="pd-company-projects-list__item">
              <Link className="pd-company-projects-list__link" to={ `/checkout/${ project.slug }` }>
                <img src={ getImageSrc( project.image, { w: 640, h: 360, resizeTo: 'fill', quality: 60 } ) || imageUnavailable } alt={ project.name } />
                <div className="pd-company-projects-list__wrapper">
                  <h4 className="pd-title-3">{ project.name }</h4>
                  <p className="pd-text">{ project.headline }</p>
                </div>
              </Link>
            </li>

          ) ) }
        </ul>
      </div>
    </section>

  ) : null

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default CompanyProjectsList