import React, { useEffect, useContext } from 'react'
import { Prompt, Link } from 'react-router-dom'
import { PortalWithState } from 'react-portal'

import { GlobalContext } from '../../context/global-state'
import { UIContext } from '../../context/ui-state'
import { CheckoutState, CheckoutContext } from '../../context/checkout-state'

import { getRichTextContent } from '../../config/adapters'

import CheckoutProjectInfo from '../../modules/checkout-project-info/checkout-project-info'
import CheckoutBreadcrumb from '../../modules/checkout-breadcrumb/checkout-breadcrumb'
import CheckoutSummary from '../../modules/checkout-summary/checkout-summary'
import CheckoutPaymentOptions from '../../modules/checkout-payment-options/checkout-payment-options'
import CheckoutShippedDisclaimer from '../../modules/checkout-shipped-disclaimer/checkout-shipped-disclaimer'
import CheckoutLockedDisclaimer from '../../modules/checkout-locked-disclaimer/checkout-locked-disclaimer'
import CheckoutCanceledDisclaimer from '../../modules/checkout-canceled-disclaimer/checkout-canceled-disclaimer'
import CheckoutMissedDeadlineDisclaimer from '../../modules/checkout-missed-deadline-disclaimer/checkout-missed-deadline-disclaimer'
import CheckoutBasePledgeSelector from '../../modules/checkout-base-pledge-selector/checkout-base-pledge-selector'
import CheckoutOptionalBuysSelector from '../../modules/checkout-optional-buys-selector/checkout-optional-buys-selector'
import CheckoutShipping from '../../modules/checkout-shipping/checkout-shipping'
import CheckoutShippingStrategySelector from '../../modules/checkout-shipping-strategy-selector/checkout-shipping-strategy-selector'

import Lightbox from '../../components/lightbox/lightbox'
import LoadingMessage from '../../components/loading-message/loading-message'
import { WarningTip } from '../../components/tip/tip'

import './checkout.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const Checkout = props => {

  const context = useContext( GlobalContext )
  const UI = useContext( UIContext )
  const { loggedUser, isRetailer } = context
  const preventRender = isRetailer && !loggedUser.isLogged

  const checkoutContext = useContext( CheckoutContext )
  const {
    project,
    pledge,
    showcase,
    summary,
    shipping,
    isReady,
    isMultiwaveResolved,
    fetchData,
    lockSummary,
    calculateShippingCost,
    STEP
  } = checkoutContext

  const { step } = showcase

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => {
    if ( preventRender ) document.location.href = '/'
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const fetchInitialData = async () => {
    if ( loggedUser.isResolved ) {
      if ( loggedUser.isLogged && !loggedUser.data.confirmed ) UI.showInfoDisclaimer( <Link to="/profile">You need to confirm your account before continuing.</Link> )
      try {

        await fetchData( isRetailer )


      } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const getActiveClassName = selectedStep => ( step === selectedStep ? 'pd-checkout__step--active' : '' )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleStepChange = () => {
    lockSummary(
      showcase.step === STEP.RETAILER_MISSED_DEADLINE ||
      showcase.step === STEP.CUSTOMER_MISSED_DEADLINE ||
      showcase.step === STEP.PAYMENT ||
      showcase.step === STEP.CANCELED ||
      showcase.step === STEP.SHIPPED ||
      showcase.step === STEP.LOCKED
    )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleShippingCalculation = async () => {
    if ( showcase.step === STEP.SHIPPING && shipping.hasAddress ) {
      try {

        await calculateShippingCost()

      } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleCustomerChanges = () => {
    if ( pledge.hasLocalChanges ) {
      window.onbeforeunload = () => false
    } else {
      window.onbeforeunload = undefined
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleProjectsNotFound = () => {
    if ( project.isNotFound ) document.location.href = '/'
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const renderDisclamers = () => {
    if ( project.isLatePledge && project.latePledgeDisclaimer ) {
      return (

        <div className="pd-checkout__disclaimer">
          <h3 className="pd-title-3">Late pledge</h3>
          <p dangerouslySetInnerHTML={ getRichTextContent( project.latePledgeDisclaimer ) }></p>
        </div>

      )
    }

    if ( project.isLateConfirms ) {
      return ( ( pledge.isConfirmed || pledge.isReadyToShip || pledge.isShipped ) && !pledge.isLateConfirmed ) ? null : (

        <div className="pd-checkout__disclaimer">
          <h3 className="pd-title-3">Late confirms</h3>
          <p className="pd-text">
            As you’re attempting to confirm your pledge after the deadline, you are limited to getting the full Late Confirm pack,
            which consists of the items listed on the Pledge below. Note that there’s only a limited quantity of Late Confirm packs
            available. Late Confirm packs will be shipped from the USA hub at a later date. For non-US backers, that will likely
            incur increased shipping costs and customs fees.
          </p>
        </div>

      )
    }

    return null
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )
  useEffect( () => { fetchInitialData() }, [ loggedUser.isResolved, loggedUser.isFetched ] )
  useEffect( handleProjectsNotFound, [ project ] )
  useEffect( handleStepChange, [ showcase.step ] )
  useEffect( () => { handleShippingCalculation() }, [ showcase.step, summary.basePledge, summary.optionalBuys, shipping.form ] )
  useEffect( handleCustomerChanges, [ pledge.hasLocalChanges ] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return preventRender ? null : (

    <section className={ `pd-checkout pd-page ${ pledge.isSaving ? 'pd-checkout--saving' : '' } ` }>
      { project.isFetched ? (

        <>
          <Prompt when={ pledge.hasLocalChanges } message={ location => 'Your order has unsaved changes. Are you sure you want to navigate away?' } />

          <div className="pd-checkout__container">
            <CheckoutProjectInfo className="pd-checkout__project" />

            { isReady ?
              (
                <>

                  <CheckoutBreadcrumb className="pd-checkout__breadcrumb" />

                  { renderDisclamers() }

                  <div className="pd-checkout__steps">
                    <div className={ `pd-checkout__step ${ getActiveClassName( STEP.CUSTOMER_MISSED_DEADLINE ) }` }>
                      <div className="pd-checkout__customer-missed-deadline pd-white-box">
                        <h3 className="pd-title-3">Project closed.</h3>
                        <p className="pd-text">
                          This project was closed on { project.pledgeManagerDeadline ? project.pledgeManagerDeadline.format( 'MMM DD, YYYY' ) : null } and you didn't confirm your pledge by the deadline.
                          We will no longer take orders for it. If you have any questions, please contact our <a
                            href={ project.supportUrl || `mailto:${ project.supportEmail }` }
                            className="pd-button pd-button--navy pd-button--text"
                            target="_blank"
                            rel="noopener noreferrer">
                            { project.isRetailer ? 'retailer support' : 'customer support' }
                          </a>.
                        </p>
                      </div>
                    </div>

                    <div className={ `pd-checkout__missed pd-checkout__step ${ getActiveClassName( STEP.RETAILER_MISSED_DEADLINE ) }` }>
                      <CheckoutMissedDeadlineDisclaimer />
                    </div>

                    <div className={ `pd-checkout__canceled pd-checkout__step ${ getActiveClassName( STEP.CANCELED ) }` }>
                      <CheckoutCanceledDisclaimer />
                    </div>

                    <div className={ `pd-checkout__shipped pd-checkout__step ${ getActiveClassName( STEP.SHIPPED ) }` }>
                      <CheckoutShippedDisclaimer />
                    </div>

                    <div className={ `pd-checkout__locked pd-checkout__step ${ getActiveClassName( STEP.LOCKED ) }` }>
                      <CheckoutLockedDisclaimer />
                    </div>

                    <div className={ `pd-checkout__base-pledge pd-checkout__step ${ getActiveClassName( STEP.BASE ) }` }>
                      <CheckoutBasePledgeSelector />
                    </div>

                    <div className={ `pd-checkout__optional-buys pd-checkout__step ${ getActiveClassName( STEP.OPTIONALS ) }` }>
                      <CheckoutOptionalBuysSelector />
                    </div>

                    <div className={ `pd-checkout__shipping pd-checkout__step ${ getActiveClassName( STEP.SHIPPING ) }` }>
                      <CheckoutShipping />
                    </div>

                    <div className={ `pd-checkout__payment pd-checkout__step ${ getActiveClassName( STEP.PAYMENT ) }` }>
                      <CheckoutPaymentOptions />
                    </div>
                  </div>

                </>
              ) : (

                <LoadingMessage />

              )
            }
          </div>

          { isReady && (

            <CheckoutSummary className="pd-checkout__summary" id="pd-checkout__summary" />

          ) }
        </>

      ) : (
        project.isHidden ?
          (

            <WarningTip>{ project.hiddenDisclaimer }</WarningTip>

          ) : (

            <LoadingMessage />

          )
      )
      }

      { !isMultiwaveResolved && (

        <PortalWithState defaultOpen>
          { portalBag => portalBag.portal(

            <Lightbox portalBag={ portalBag } isLocked={ true }>
              <CheckoutShippingStrategySelector />
            </Lightbox>

          ) }
        </PortalWithState>

      ) }

    </section>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

const CheckoutWrapper = props => {
  const { params } = props.match

  return (
    <CheckoutState params={ params }>
      <Checkout { ...props } />
    </CheckoutState>
  )
}

export default CheckoutWrapper

