import React, { useEffect, useState } from 'react'
import { find } from 'lodash'

import './checkout-shipping-info.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const CheckoutShippingInfo = props => {

  const { className = '', shippingInfo = {}, showTitle = false } = props
  const [ countryAddressLine, setCountryAddressLine ] = useState( '' )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleCountryInformation = () => {
    const { countries, provinces, form } = shippingInfo

    if ( countries.length ) {

      const countryItem = find( countries, { id: parseInt( form.countryId ) } )
      const provinceItem = find( provinces, { id: parseInt( form.stateId ) } )

      setCountryAddressLine( `${ form.city ? `${ form.city }, ` : '' }${ provinceItem && provinceItem.code } ${ form.postalCode }\n${ countryItem && countryItem.name }` )

    } else { setCountryAddressLine( '' ) }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )
  useEffect( handleCountryInformation, [ shippingInfo.form, shippingInfo.countries, shippingInfo.provinces ] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <div className={ `pd-checkout-shipping-info pd-text ${ className }` }>
      { showTitle && ( <h4 className="pd-title-4">Shipping information</h4> ) }
      <p>
        { shippingInfo.form.name } <br />
        { shippingInfo.form.taxpayerId ? <>Taxpayer ID: { shippingInfo.form.taxpayerId }<br /></> : null }
        { shippingInfo.form.addressLine1 }{ shippingInfo.form.complement ? <> — { shippingInfo.form.complement }</> : null }<br />
        { shippingInfo.form.addressLine2 ? ( <>{ shippingInfo.form.addressLine2 }<br /></> ) : null }
        { countryAddressLine.split( /\n/g ).map( ( i, index ) => ( <span key={ index }>{ i }<br /></span> ) ) }
      Phone: { shippingInfo.form.phone || '-' }
      </p>
    </div>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default CheckoutShippingInfo