import moment from 'moment-timezone'
import { cloneDeep, find, forEach, findIndex, min, sum, sumBy, sortBy, every, kebabCase, round, some, intersectionWith, differenceWith, isNil, groupBy, map, filter, reject } from 'lodash'

import { PLEDGE_STATE, PLEDGE_MANAGER_STATE, STOCK, PREVENT_DELETION_MESSAGE, PREVENT_SOLD_OUT_DELETION_MESSAGE } from '../config/constants'
import Env from '../config/env'

// CONSTANTS = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

export const MAIN = {
  RELEASE_RENDER: 'RELEASE_RENDER',
  RESOLVE_MULTIWAVE: 'RESOLVE_MULTIWAVE'
}

export const PROJECT = {
  FETCHED: 'PROJECT_FETCHED',
  PARSE_AVAILABLE_BUNDLES_AND_ADD_ONS: 'PROJECT_PARSE_AVAILABLE_BUNDLES_AND_ADD_ONS',
  HIDDEN: 'PROJECT_HIDDEN',
  NOT_FOUND: 'PROJECT_NOT_FOUND'
}

export const PLEDGE = {
  EMPTY: 'PLEDGE_EMPTY',
  FETCHED: 'PLEDGE_FETCHED',
  SET_MULTIWAVE: 'PLEDGE_SET_MULTIWAVE',
  SAVING: 'PLEDGE_SAVING',
  LOCK_PLEDGE_LOCALLY: 'PLEDGE_LOCK_PLEDGE_LOCALLY',
  SET_LOCAL_CHANGES: 'PLEDGE_SET_LOCAL_CHANGES',
}

export const SUMMARY = {
  INITIAL_PARSE: 'SUMMARY_INITIAL_PARSE',
  UPDATE_BASE_PLEDGE_SELECTED: 'SUMMARY_UPDATE_BASE_PLEDGE_SELECTED',
  UPDATE_BASE_PLEDGE_AMOUNT: 'SUMMARY_UPDATE_BASE_PLEDGE_AMOUNT',
  INCLUDE_OPTIONAL_BUY: 'SUMMARY_INCLUDE_OPTIONAL_BUY',
  UPDATE_OPTIONAL_BUY_AMOUNT: 'SUMMARY_UPDATE_OPTIONAL_BUY_AMOUNT',
  UPDATE_ORDER_TOTAL: 'SUMMARY_UPDATE_ORDER_TOTAL',
  UPDATE_PAYMENT_PAYLOAD: 'SUMMARY_UPDATE_PAYMENT_PAYLOAD',
  WILL_CALCULATE_SHIPPING_COST: 'SUMMARY_WILL_CALCULATE_SHIPPING_COST',
  UPDATE_SHIPPING_COST: 'SUMMARY_UPDATE_SHIPPING_COST',
  LOCK_SUMMARY: 'SUMMARY_LOCK_SUMMARY',
  UPDATE_SAVE_PAYLOAD: 'SUMMARY_UPDATE_SAVE_PAYLOAD',
  HANDLE_SHIPPING_COST_CALCULATION_FAILURE: 'SUMMARY_HANDLE_SHIPPING_COST_CALCULATION_FAILURE',
  EMPTY_SUMMARY_ITEMS: 'SUMMARY_EMPTY_SUMMARY_ITEMS',
  REPLACE_SUMMARY_ITEMS_BY_WAVE: 'SUMMARY_REPLACE_SUMMARY_ITEMS_BY_WAVE'
}

export const SHOWCASE = {
  PARSE_BASE_PLEDGE_OPTIONS: 'SHOWCASE_PARSE_BASE_PLEDGE_OPTIONS',
  PARSE_OPTIONAL_BUYS: 'SHOWCASE_PARSE_OPTIONAL_BUYS',
  CHECK_STEP: 'SHOWCASE_CHECK_STEP',
  SET_STEP: 'SHOWCASE_SET_STEP',
  SET_INITIAL_STEP: 'SHOWCASE_SET_INITIAL_STEP',
  CHECK_NAVIGATION: 'SHOWCASE_CHECK_NAVIGATION',
  PREVENT_EMPTY_PLEDGE: 'SHOWCASE_PREVENT_EMPTY_PLEDGE',
  CHECK_LIMITED_STOCK_ITEMS_PRESENCE: 'SHOWCASE_CHECK_LIMITED_STOCK_ITEMS_PRESENCE',
  UPDATE_AMOUNTS: 'SHOWCASE_UPDATE_AMOUNTS',
  CHECK_REQUIREMENTS: 'SHOWCASE_CHECK_REQUIREMENTS',
  SET_INITIAL_AMOUNTS: 'SHOWCASE_SET_INITIAL_AMOUNTS',
  SET_INITIAL_LOCKED_ITEMS: 'SHOWCASE_SET_INITIAL_LOCKED_ITEMS',
  UPDATE_BASE_PLEDGE_SELECTED: 'SHOWCASE_UPDATE_BASE_PLEDGE_SELECTED',
  UPDATE_MAX_ALLOWED: 'SHOWCASE_UPDATE_MAX_ALLOWED',
  LOCK_SHOWCASE: 'SHOWCASE_LOCK_SHOWCASE',
  REPLACE_LOCKED_BASE_PLEDGE: 'SHOWCASE_REPLACE_LOCKED_BASE_PLEDGE',
  APPEND_LOCKED_OPTIONAL_BUYS: 'SHOWCASE_APPEND_LOCKED_OPTIONAL_BUYS',
}

export const SHIPPING = {
  PARSE_SHIPPING_INFO: 'SHIPPING_PARSE_SHIPPING_INFO',
  SET_COUNTRIES: 'SHIPPING_SET_COUNTRIES',
  SET_PROVINCES: 'SHIPPING_SET_PROVINCES',
  UPDATE_SHIPPING_INFO: 'SHIPPING_UPDATE_SHIPPING_INFO',
}

export const STEP = {
  LOCKED: 'LOCKED',
  BASE: 'BASE',
  OPTIONALS: 'OPTIONALS',
  SHIPPING: 'SHIPPING',
  PAYMENT: 'PAYMENT',
  SHIPPED: 'SHIPPED',
  CANCELED: 'CANCELED',
  RETAILER_MISSED_DEADLINE: 'RETAILER_MISSED_DEADLINE',
  CUSTOMER_MISSED_DEADLINE: 'CUSTOMER_MISSED_DEADLINE'
}











// CLASSES = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

class ShowcaseBasePledge {
  get defaultProps () {
    return { isDisabled: false, isSelected: false, amount: 0, initialAmount: 0, initiallySelected: false }
  }

  constructor ( model, baseProps ) {
    const props = { ...this.defaultProps, ...baseProps }
    this.id = model.id
    this.uid = `${ model.id }-${ kebabCase( model.name ) }`
    this.image = model.image
    this.name = model.name
    this.price = model.price
    this.description = model.description
    this.whatsIncluded = model.whatsIncluded
    this.products = model.bundleProducts.map( item => ( { ...item.product, quantity: item.quantity } ) )
    this.limitPerUser = model.limitPerUser || false
    this.minimumPerUser = model.minimumPerUser || 0
    this.position = model.position
    this.cartWarning = model.cartWarning

    this.isLimitedStock = model.stockStatus === STOCK.LIMITED
    this.isLowStock = model.stockStatus === STOCK.LOW
    this.isSoldOut = model.stockStatus === STOCK.SOLD_OUT
    this.isLockedToOrder = false

    this.amount = props.amount
    this.initialAmount = props.initialAmount
    this.isSelected = props.isSelected
    this.isDisabled = props.isDisabled
    this.initiallySelected = props.initiallySelected
    this.maxAllowed = this.isSoldOut ? this.initialAmount : ( this.limitPerUser || Env.arbritaryLimit )
    this.isBasePledge = true
  }

  get maxAllowed () { return this._maxAllowed }
  set maxAllowed ( value ) {
    this.isDisabled = value <= 0
    this._maxAllowed = value
  }

}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

class ShowcaseOptionalBuy {
  get defaultProps () {
    return { isDisabled: false, isSelected: false, isBundle: false, amount: 0, initialAmount: 0, initiallySelected: false }
  }

  constructor ( model, baseProps ) {
    const props = { ...this.defaultProps, ...baseProps }
    this.id = model.id
    this.uid = `${ model.id }-${ kebabCase( model.name ) }`
    this.image = model.image
    this.name = model.name
    this.price = model.price
    this.description = model.description
    this.cartWarning = model.cartWarning
    this.whatsIncluded = model.whatsIncluded
    this.products = ( model.bundleProducts || model.addOnProducts || [] ).map( item => ( { ...item.product, quantity: item.quantity } ) )
    this.limitPerUser = model.limitPerUser || false
    this.limitPerBundle = model.limitPerBundle || false
    this.limitedByBasePledge = model.limitedByBasePledge || false
    this.limitPerBasePledge = null
    this.minimumPerUser = model.minimumPerUser || 0
    this.position = model.position
    this.requiredBundles = model.requiredBundles.length ? model.requiredBundles : false
    this.isLimitedStock = model.stockStatus === STOCK.LIMITED
    this.isLowStock = model.stockStatus === STOCK.LOW
    this.isSoldOut = model.stockStatus === STOCK.SOLD_OUT
    this.isLockedToOrder = false

    this.amount = props.amount
    this.isSelected = props.isSelected
    this.isBundle = props.isBundle
    this.initiallySelected = props.initiallySelected
    this.initialAmount = props.initialAmount
    this.maxAllowed = this.isSoldOut ? this.initialAmount : min( [ this.limitPerBundle || Env.arbritaryLimit, this.limitPerUser || Env.arbritaryLimit ] )
    this.isDisabled = props.isDisabled

    if ( this.isBundle ) this.name = `Extra ${ this.name }`
  }

  get maxAllowed () { return this._maxAllowed }
  set maxAllowed ( value ) {
    this.isDisabled = value <= 0
    this._maxAllowed = value
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

class SummaryItem {
  get defaultProps () {
    return { isDisabled: false, amount: 0, initialAmount: 0 }
  }

  constructor ( model, baseProps ) {
    const props = { ...this.defaultProps, ...baseProps }
    this.id = model.id

    if ( model.bundle ) {
      this.bundle = model.bundle
      this.uid = `${ model.id }-${ kebabCase( this.bundle.name ) }`
      this.name = model.base ? this.bundle.name : `Extra ${ this.bundle.name }`
      this.price = parseFloat( this.bundle.price )
      this.stockStatus = this.bundle.stockStatus
      this.maxAllowed = this.bundle.limitPerUser || Env.arbritaryLimit
      this.minimumPerUser = this.bundle.minimumPerUser || 0
      this.requiredBundles = this.bundle.requiredBundles.length ? this.bundle.requiredBundles : false
      this.quantityOfLimitedAddOns = this.bundle.quantityOfLimitedAddOns
      this.isBundle = true
    }

    if ( model.addOn ) {
      this.addOn = model.addOn
      this.uid = `${ model.id }-${ kebabCase( this.addOn.name ) }`
      this.name = this.addOn.name
      this.price = parseFloat( this.addOn.price )
      this.stockStatus = this.addOn.stockStatus
      this.maxAllowed = this.addOn.limitPerUser || Env.arbritaryLimit
      this.minimumPerUser = this.addOn.minimumPerUser || 0
      this.requiredBundles = this.addOn.requiredBundles.length ? this.addOn.requiredBundles : false

      this.isBundle = false
    }

    if ( this.requiredBundles ) {
      this.requiredBundles.map( item => {
        item.uid = `${ item.id }-${ kebabCase( item.name ) }`
        return item
      } )
    }

    this.hasStockLimit = this.stockStatus === STOCK.LIMITED || this.stockStatus === STOCK.LOW
    this.isSoldOut = this.stockStatus === STOCK.SOLD_OUT
    this.isLockedToOrder = model.lockedToOrder || false

    this.initialAmount = props.initialAmount
    this.amount = props.amount
    this.isDisabled = props.isDisabled
    this.hasDependents = false
  }

  get maxAllowed () { return this._maxAllowed }
  set maxAllowed ( value ) {
    this.isDisabled = value <= 0
    this._maxAllowed = value
  }

}











// MAIN = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

const releaseRender = ( state, action ) => {
  const { payload = true } = action

  return {
    ...state,
    isReady: payload
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const resolveMultiwave = ( state, action ) => {
  return {
    ...state,
    isMultiwaveResolved: action.payload
  }
}










// PROJECT = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

// Helpers - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const _getInvalidRewards = ( collection, state ) => {
  const { project, pledge } = state
  const { currentWave } = project
  const { isMultiwave } = pledge

  const duplicatedRewards = filter( groupBy( collection, item => item.name ), items => items.length > 1 )
  return map( duplicatedRewards, duplicates => {
    return filter( duplicates, duplicate => isMultiwave && currentWave == 1 ? duplicate.cutoffWave > 1 : duplicate.cutoffWave === 1 )[ 0 ].id
  } )
}
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const parseProject = ( state, action ) => {
  const { project } = state
  const { payload } = action
  const latePledgeUntil = payload.latePledgeUntil ? moment( payload.latePledgeUntil ) : false
  const pledgeManagerDeadline = payload.pledgeManagerDeadline ? moment( payload.pledgeManagerDeadline ) : false
  const rightNow = moment( new Date() )
  const acceptLatePledge = latePledgeUntil ? ( rightNow.isSameOrBefore( latePledgeUntil ) ) : payload.acceptLatePledge

  return {
    ...state,
    project: {
      ...project,
      ...payload,
      initialAvailableBundles: payload.availableBundles,
      initialAvailableAddOns: payload.availableAddOns,
      isLatePledge: payload.pledgeManagerState === PLEDGE_MANAGER_STATE.LATE_PLEDGE,
      isLateConfirms: payload.pledgeManagerState === PLEDGE_MANAGER_STATE.LATE_CONFIRMS,
      isOpened: payload.pledgeManagerState === PLEDGE_MANAGER_STATE.OPENED,
      isClosed: payload.pledgeManagerState === PLEDGE_MANAGER_STATE.CLOSED,
      latePledgeUntil: latePledgeUntil,
      pledgeManagerDeadline: pledgeManagerDeadline,
      acceptLatePledge: acceptLatePledge,
      hasMultipleWaves: payload.waves > 1,
      isFetched: true
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const parseAvailableBundlesAndAddOns = ( state, action ) => {
  const { project } = state
  const { bundles, addOns, initialAvailableBundles, initialAvailableAddOns, hasMultipleWaves } = project

  let availableBundles = initialAvailableBundles.map( availableBundle => {
    const item = find( bundles, bundle => bundle.id === availableBundle.id )
    if ( item.requiredBundles ) {
      item.requiredBundles.map( rItem => {
        rItem.name = find( bundles, fItem => fItem.id === rItem.id ).name
        rItem.uid = `${ rItem.id }-${ kebabCase( rItem.name ) }`
        return rItem
      } )
    }
    return item
  } )

  let availableAddOns = ( initialAvailableAddOns || [] ).map( availableAddOn => {
    const item = find( addOns, addOn => addOn.id === availableAddOn.id )
    if ( item.requiredBundles ) {
      item.requiredBundles.map( rItem => {
        rItem.name = find( bundles, fItem => fItem.id === rItem.id ).name
        rItem.uid = `${ rItem.id }-${ kebabCase( rItem.name ) }`
        return rItem
      } )
    }
    return item
  } )

  if ( hasMultipleWaves ) {
    const invalidBundles = _getInvalidRewards( availableBundles, state )
    const invalidAddOns = _getInvalidRewards( availableAddOns, state )
    availableBundles = filter( availableBundles, item => invalidBundles.indexOf( item.id ) < 0 )
    availableAddOns = filter( availableAddOns, item => invalidAddOns.indexOf( item.id ) < 0 )
  }

  return {
    ...state,
    project: {
      ...project,
      availableBundles: availableBundles,
      availableAddOns: availableAddOns,
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const handleHiddenProject = ( state, action ) => {
  const { project } = state

  return {
    ...state,
    project: {
      ...project,
      isHidden: true,
      hiddenDisclaimer: action.payload
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const handleNotFoundProject = ( state, action ) => {
  const { project } = state

  return {
    ...state,
    project: {
      ...project,
      isNotFound: true
    }
  }
}












// PLEDGE = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

const parseEmptyPledge = ( state, action ) => {
  const { project } = state

  return {
    ...state,
    pledge: {
      ...state.pledge,
      requiresBasePledge: project.requiresBaseBundle,
      isFetched: true,
      hasData: false
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const parsePledge = ( state, action ) => {
  const { project, pledge } = state
  const { payload } = action
  const { bundles, addOns } = payload
  const basePledgeSelected = find( bundles, bundle => bundle.base )

  return {
    ...state,
    pledge: {
      ...pledge,

      bundles: bundles.map( item => {
        item.bundle = find( project.bundles, bundle => bundle.id === item.id )
        delete item.name
        delete item.unitPrice
        return item
      } ),

      addOns: addOns.map( item => {
        item.addOn = find( project.addOns, addOn => addOn.id === item.id )
        delete item.name
        delete item.unitPrice
        return item
      } ),

      id: payload.id,
      credit: payload.credit,
      shippingCost: payload.shippingCost,
      shippingVat: payload.shippingVat,
      cartVat: payload.cartVat,
      total: payload.total,
      basePledgeSelected: basePledgeSelected,
      trackingNumbers: payload.trackingNumbers,

      address: payload.address,
      addressWaves: payload.addressWaves,

      isFetched: true,
      isSaving: false,
      hasData: true,
      hasBasePledgeSelected: !!basePledgeSelected,
      requiresBasePledge: project.requiresBaseBundle,
      hasShippingAddress: !!payload.address,
      hasShippingCostCalculated: !isNil( payload.shippingCost ),

      isMultiwave: payload.multiwave,
      state: ( payload.state === PLEDGE_STATE.PENDING || payload.state === PLEDGE_STATE.UNLOCKED ) && project.isClosed ? PLEDGE_STATE.CUSTOMER_MISSED_DEADLINE : payload.state,
      isPending: payload.state === PLEDGE_STATE.PENDING && !project.isClosed,
      isConfirmed: payload.state === PLEDGE_STATE.FINISHED,
      isUnlocked: payload.state === PLEDGE_STATE.UNLOCKED && !project.isClosed,
      isShipped: payload.state === PLEDGE_STATE.SHIPPED,
      isReadyToShip: payload.state === PLEDGE_STATE.READY_TO_SHIP,
      isErrored: payload.state === PLEDGE_STATE.ERRORED,
      isCanceled: payload.state === PLEDGE_STATE.CANCELED,
      isRetailerMissedDeadline: payload.state === PLEDGE_STATE.RETAILER_MISSED_DEADLINE,
      isCustomerMissedDeadline: ( payload.state === PLEDGE_STATE.PENDING || payload.state === PLEDGE_STATE.UNLOCKED ) && project.isClosed,
      isLateConfirmed: payload.lateConfirmed
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const setMultiwave = ( state, action ) => {
  const { pledge } = state
  const { payload } = action

  return {
    ...state,
    pledge: {
      ...pledge,
      isMultiwave: payload
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const setSaving = ( state, action ) => {
  const { pledge } = state

  return {
    ...state,
    pledge: {
      ...pledge,
      isSaving: action.payload
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const lockPledgeLocally = ( state, action ) => {
  const { pledge } = state

  return {
    ...state,
    pledge: {
      ...pledge,
      isLocallyLocked: action.payload
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const setLocalChanges = ( state, action ) => {
  const { pledge } = state

  return {
    ...state,
    pledge: {
      ...pledge,
      hasLocalChanges: action.payload
    }
  }
}










// SUMMARY = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

// Helpers - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const _sanitizeOptionalBuys = summary => {
  // TODO: needs optimization

  let { optionalBuys, basePledge, hasBasePledgeSelected } = summary

  const recursive = list => {

    let newList = cloneDeep( list )

    newList = newList.filter( item => {
      if ( item.amount === 0 ) return false

      const { requiredBundles } = item

      if ( requiredBundles ) {
        const basePledgeMatches = hasBasePledgeSelected ? ( some( requiredBundles, { uid: basePledge.uid } ) ) : false
        const optionalsMatches = intersectionWith( newList, requiredBundles, ( nItem, rItem ) => {
          return nItem.uid === rItem.uid
        } ).length > 0

        if ( !basePledgeMatches && !optionalsMatches ) return false
      }

      return item
    } )

    newList = _updateSummaryMaxAllowed( newList, basePledge )

    newList = newList.map( item => {
      item.amount = min( [ item.amount, item.maxAllowed ] )
      return item
    } )

    newList = newList.filter( item => item.amount > 0 )

    if ( newList.length !== list.length ) return recursive( newList )

    return newList
  }

  optionalBuys = recursive( optionalBuys )

  return { ...summary, optionalBuys: optionalBuys }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const _updateSummaryMaxAllowed = ( optionalBuys, basePledge ) => {

  optionalBuys = optionalBuys.map( item => {

    if ( item.isSoldOut ) {
      item.maxAllowed = item.initialAmount
      return item
    }

    if ( item.bundle ) return item

    let totalSelectedBundles = 0

    if ( item.requiredBundles ) {

      if ( basePledge && some( item.requiredBundles, { uid: basePledge.uid } ) ) totalSelectedBundles = basePledge.amount

      totalSelectedBundles += sumBy( optionalBuys, _item => {
        if ( _item.addOn ) return 0
        if ( !some( item.requiredBundles, { uid: _item.uid } ) ) return 0
        return _item.amount
      } )
    } else {

      totalSelectedBundles += basePledge?.amount || 0
    }

    const perBundle = ( item.addOn.limitPerBundle * totalSelectedBundles ) || Env.arbritaryLimit
    const perUser = item.addOn.limitPerUser || Env.arbritaryLimit
    const perBasePledge = item.addOn.limitedByBasePledge ? ( basePledge ? ( basePledge.quantityOfLimitedAddOns * basePledge.amount ) : 0 ) : Env.arbritaryLimit
    item.maxAllowed = min( [ perBundle, perUser, perBasePledge ] )

    return item
  } )

  return optionalBuys
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const parseInitialSummary = ( state, action ) => {
  const { pledge, summary, project } = state
  const { basePledgeSelected } = pledge
  if ( !pledge.hasData ) return state

  const credit = round( ( pledge.credit || 0 ), 2 )
  const shippingCost = round( ( pledge.shippingCost || 0 ), 2 )
  const shippingVat = round( ( pledge.shippingVat || 0 ), 2 )
  const cartVat = round( ( pledge.cartVat || 0 ), 2 )

  const extraBundles = pledge.bundles
    .filter( bundle => !bundle.base )
    .map( extra => new SummaryItem( extra, { amount: extra.amount, initialAmount: extra.amount } ) )

  const addOns = pledge.addOns.map( addOn => new SummaryItem( addOn, { amount: addOn.amount, initialAmount: addOn.amount } ) )

  let newSummary = cloneDeep( summary )
  newSummary.basePledge = pledge.hasBasePledgeSelected ? new SummaryItem( basePledgeSelected, { amount: basePledgeSelected.amount, initialAmount: basePledgeSelected.amount } ) : null
  newSummary.optionalBuys = [].concat( extraBundles, addOns )
  newSummary.optionalBuys = _updateSummaryMaxAllowed( newSummary.optionalBuys, newSummary.basePledge )
  newSummary = {
    ...newSummary,
    shippingCost: shippingCost,
    shippingVat: shippingVat,
    cartVat: cartVat,
    credit: credit,
    hasBasePledgeSelected: pledge.hasBasePledgeSelected,
    hasOptionalBuysSelected: newSummary.optionalBuys.length > 0,
    hasShippingCostCalculated: pledge.hasShippingCostCalculated,
    isLocked: pledge.isLocallyLocked || project.isClosed
  }

  return { ...state, summary: newSummary }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const updateSummaryBasePledgeSelected = ( state, action ) => {
  const { project, summary } = state

  if ( summary.hasBasePledgeSelected && summary.basePledge.isSoldOut && !window.confirm( PREVENT_SOLD_OUT_DELETION_MESSAGE ) ) return state

  let newSummary = cloneDeep( summary )

  const isRemoving = summary.hasBasePledgeSelected && summary.basePledge.id === action.payload

  if ( isRemoving ) {

    newSummary = {
      ...newSummary,
      basePledge: null,
      hasBasePledgeSelected: false
    }

  } else {

    const newBasePledge = find( project.bundles, { id: action.payload } )

    newSummary = {
      ...newSummary,
      basePledge: new SummaryItem( {
        id: newBasePledge.id,
        base: true,
        amount: 1,
        bundle: newBasePledge
      }, { amount: newBasePledge.minimumPerUser || 1 } ),
      hasBasePledgeSelected: true
    }

  }

  newSummary = _sanitizeOptionalBuys( newSummary )

  const hasLessItems = newSummary.optionalBuys.length < summary.optionalBuys.length
  const hasAmountLosses = hasLessItems || differenceWith( summary.optionalBuys, newSummary.optionalBuys, ( c, n ) => c.uid === n.uid && c.amount === n.amount ).length > 0
  if ( hasAmountLosses && !window.confirm( PREVENT_DELETION_MESSAGE.replace( /PLEDGE_NAME/g, summary.basePledge.name ) ) ) return state

  return {
    ...state,
    summary: newSummary
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const updateSummaryBasePledgeAmount = ( state, action ) => {
  const { summary, project } = state
  const { payload } = action
  const { value } = payload

  let newSummary = cloneDeep( summary )
  newSummary.basePledge.amount = value
  newSummary.basePledge.amount = value < newSummary.basePledge.minimumPerUser ? 0 : value

  if ( newSummary.basePledge.amount === 0 ) newSummary = {
    ...newSummary,
    basePledge: null,
    hasBasePledgeSelected: false
  }

  if ( project.requiresBaseBundle && !newSummary.hasBasePledgeSelected ) newSummary = {
    ...newSummary,
    optionalBuys: [],
    hasOptionalBuysSelected: false
  }

  newSummary = _sanitizeOptionalBuys( newSummary )

  const hasLessItems = newSummary.optionalBuys.length < summary.optionalBuys.length
  const hasAmountLosses = hasLessItems || differenceWith( summary.optionalBuys, newSummary.optionalBuys, ( c, n ) => c.uid === n.uid && c.amount === n.amount ).length > 0
  if ( hasAmountLosses && !window.confirm( PREVENT_DELETION_MESSAGE.replace( /PLEDGE_NAME/g, summary.basePledge.name ) ) ) return state

  return {
    ...state,
    summary: newSummary
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const includeOptionalBuy = ( state, action ) => {
  const { showcase, summary, project, pledge } = state
  const optionalBuy = find( showcase.optionalBuys, { uid: action.payload } )

  let initialAmount = 0

  let model = {
    id: optionalBuy.id,
  }

  if ( optionalBuy.isBundle ) {
    const originalItemOnPledge = find( pledge.bundles, item => item.id === optionalBuy.id && !item.base )
    if ( originalItemOnPledge ) initialAmount = originalItemOnPledge.amount

    model.bundle = find( project.bundles, bundle => bundle.id === optionalBuy.id )
  } else {
    const originalItemOnPledge = find( pledge.addOns, item => item.id === optionalBuy.id )
    if ( originalItemOnPledge ) initialAmount = originalItemOnPledge.amount

    model.addOn = find( project.addOns, addOn => addOn.id === optionalBuy.id )
  }

  let optionalBuys = cloneDeep( summary.optionalBuys )
  optionalBuys.push( new SummaryItem( model, { amount: optionalBuy.minimumPerUser || 1, initialAmount: initialAmount } ) )
  optionalBuys = _updateSummaryMaxAllowed( optionalBuys, summary.basePledge )

  return {
    ...state,
    summary: {
      ...summary,
      optionalBuys: sortBy( optionalBuys, item => item.bundle ? 0 : 1 ),
      hasOptionalBuysSelected: optionalBuys.length > 0
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const updateSummaryOptionalBuyAmount = ( state, action ) => {
  const { summary } = state
  const { payload } = action
  const { uid, value } = payload

  let requestSoldOutConfirm = false

  let newSummary = cloneDeep( summary )
  newSummary.optionalBuys = newSummary.optionalBuys.map( optionalBuy => {
    if ( optionalBuy.uid === uid ) {
      if ( optionalBuy.isSoldOut && value < optionalBuy.amount ) requestSoldOutConfirm = true
      optionalBuy.amount = value < optionalBuy.minimumPerUser ? 0 : value
    }

    return optionalBuy
  } )

  if ( requestSoldOutConfirm && !window.confirm( PREVENT_SOLD_OUT_DELETION_MESSAGE ) ) return state

  newSummary = _sanitizeOptionalBuys( newSummary )

  const hasLessItems = ( newSummary.optionalBuys.length + 1 ) < summary.optionalBuys.length

  const hasAmountLosses = hasLessItems || !every( newSummary.optionalBuys, newItem => {
    if ( newItem.uid === uid ) return true
    const currentItem = find( summary.optionalBuys, item => item.uid === newItem.uid )
    return newItem.amount === currentItem.amount
  } )

  if ( hasAmountLosses ) {
    const relatedItem = find( summary.optionalBuys, item => item.uid === uid )
    if ( !window.confirm( PREVENT_DELETION_MESSAGE.replace( /PLEDGE_NAME/g, relatedItem.name ) ) ) return state
  }

  newSummary.hasOptionalBuysSelected = newSummary.optionalBuys.length > 0

  return {
    ...state,
    summary: newSummary
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const updateOrderTotal = ( state, action ) => {
  const { summary, project } = state
  const { basePledge, optionalBuys, shippingCost = 0, shippingVat = 0, cartVat = 0, credit = 0, hasBasePledgeSelected } = summary
  const subTotal =
    ( hasBasePledgeSelected ? round( basePledge.price * basePledge.amount, 2 ) : 0 ) +
    ( optionalBuys ? sum( optionalBuys.map( item => round( item.price * item.amount, 2 ) ) ) : 0 )
  const orderTotal = round( subTotal + shippingCost + shippingVat + cartVat, 2 )
  const balance = project.retailer ? round( ( project.depositFee + shippingCost ) - credit, 2 ) : round( orderTotal - credit, 2 )

  return {
    ...state,
    summary: {
      ...summary,
      subTotal: subTotal,
      orderTotal: orderTotal,
      balance: balance,
      hasRemainingCredit: balance < 0,
      hasBalanceDue: balance > 0
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const willCalculateShippingCost = ( state, action ) => {
  const { summary } = state

  return {
    ...state,
    summary: {
      ...summary,
      shippingCost: 0,
      shippingVat: 0,
      cartVat: 0,
      hasShippingCostCalculated: false,
      isCalculatingShippingCost: true
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const updateShippingCost = ( state, action ) => {
  const { summary } = state
  const { payload } = action
  let [ shippingCost, shippingVat, cartVat, hasShippingCostCalculated ] = [ null, null, null, false ]

  if ( !isNil( payload ) ) {
    shippingCost = round( payload.shippingCost, 2 )
    shippingVat = round( payload.shippingVat, 2 )
    cartVat = round( payload.cartVat, 2 )
    hasShippingCostCalculated = true
  }

  return {
    ...state,
    summary: {
      ...summary,
      shippingCost: shippingCost,
      shippingVat: shippingVat,
      cartVat: cartVat,
      hasShippingCostCalculated: hasShippingCostCalculated,
      isCalculatingShippingCost: false
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const handleShippingCostCalculationFailure = ( state, action ) => {
  const { summary, shipping } = state

  return {
    ...state,
    summary: {
      ...summary,
      shippingCost: 0,
      shippingVat: 0,
      cartVat: 0,
      hasShippingCostCalculated: false,
      isCalculatingShippingCost: false
    },
    shipping: {
      ...shipping,
      hasAddress: false
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const updatePaymentPayload = ( state, action ) => {
  const { summary } = state

  return {
    ...state,
    summary: {
      ...summary,
      paymentPayload: action.payload
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const lockSummary = ( state, action ) => {
  const { summary } = state

  return {
    ...state,
    summary: {
      ...summary,
      isLocked: action.payload
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const updateSavePayload = ( state, action ) => {
  const { summary } = state

  return {
    ...state,
    summary: {
      ...summary,
      savePayload: action.payload
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const emptySummaryItems = ( state, action ) => {
  const { summary } = state

  return {
    ...state,
    summary: {
      ...summary,
      basePledge: null,
      optionalBuys: [],
      shippingCost: 0,
      shippingVat: 0,
      cartVat: 0,
      hasBasePledgeSelected: false,
      hasOptionalBuysSelected: false,
      hasShippingCostCalculated: false,
      isLocked: false
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const replaceSummaryItemsByWave = ( state, action ) => {
  const { summary, project } = state
  const { availableBundles, availableAddOns } = project

  if ( summary.hasBasePledgeSelected ) {
    const equivalent = find( availableBundles, { name: summary.basePledge.name } )
    if ( equivalent && equivalent.id !== summary.basePledge.id ) summary.basePledge = new SummaryItem( {
      id: equivalent.id,
      base: true,
      bundle: equivalent
    }, {
      amount: summary.basePledge.amount,
      initialAmount: summary.basePledge.initialAmount
    } )
  }

  if ( summary.hasOptionalBuysSelected ) {
    summary.optionalBuys = summary.optionalBuys.map( item => {
      const equivalent = item.isBundle ? find( availableBundles, { name: item.name.substr( 6 ) } ) : find( availableAddOns, { name: item.name } )

      if ( equivalent && equivalent.id !== item.id ) {
        const model = {
          id: equivalent.id,
          [ item.isBundle ? 'bundle' : 'addOn' ]: equivalent
        }

        const replaced = new SummaryItem( model, {
          amount: item.amount,
          initialAmount: item.initialAmount
        } )

        return replaced
      }

      return item
    } )
  }

  return { ...state, summary: { ...summary } }
}










// SHOWCASE = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

const parseBasePledgeOptions = ( state, action ) => {
  const { project, summary, showcase } = state

  const getChain = ( collection, baseItem ) => {
    if ( baseItem.upgradesTo ) {
      const item = find( project.bundles, bundle => bundle.id === baseItem.upgradesTo.id )
      const notIncludedYet = !find( collection, collectionItem => collectionItem.id === item.id )

      if ( item && notIncludedYet ) {
        collection.push( item )
        collection = getChain( collection, item )
      }
    }

    if ( baseItem.downgradesTo ) {
      const item = find( project.bundles, bundle => bundle.id === baseItem.downgradesTo.id )
      const notIncludedYet = !find( collection, collectionItem => collectionItem.id === item.id )

      if ( item && notIncludedYet ) {
        collection.unshift( item )
        collection = getChain( collection, item )
      }
    }

    return collection
  }

  if ( project.availableBundles.length === 0 ) return {
    ...state,
    showcase: {
      ...showcase,
      hasBasePledges: false,
      basePledgeOptions: []
    }
  }

  if ( !summary.hasBasePledgeSelected || project.isLateConfirms || project.isLatePledge ) return {
    ...state,
    showcase: {
      ...showcase,
      hasBasePledges: true,
      basePledgeOptions: cloneDeep( project.availableBundles ).map( item => {
        const isSelected = summary.hasBasePledgeSelected ? ( item.id === summary.basePledge.id ) : false
        return new ShowcaseBasePledge( item, { isSelected: isSelected } )
      } )
    }
  }

  let basePledgeOptionsCollection = [ summary.basePledge.bundle ]
  basePledgeOptionsCollection = getChain( basePledgeOptionsCollection, summary.basePledge.bundle )

  const invalidBasePledges = _getInvalidRewards( basePledgeOptionsCollection, state )
  basePledgeOptionsCollection = filter( basePledgeOptionsCollection, item => invalidBasePledges.indexOf( item.id ) < 0 )

  basePledgeOptionsCollection = basePledgeOptionsCollection.map( item => {
    const instance = new ShowcaseBasePledge( item )
    instance.isSelected = item.id === summary.basePledge.id
    instance.initiallySelected = true
    return instance
  } )

  return {
    ...state,
    showcase: {
      ...showcase,
      hasBasePledges: true,
      basePledgeOptions: basePledgeOptionsCollection
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const parseOptionalBuys = ( state, action ) => {
  const { project, showcase } = state
  const { hasMultipleWaves, bundles, isLatePledge, isLateConfirms, availableAddOns } = project

  const parse = ( isBundle, item ) => {
    const instance = new ShowcaseOptionalBuy( item, { isBundle: isBundle } )

    if ( instance.requiredBundles ) {
      instance.requiredBundles = instance.requiredBundles.map( rItem => {
        const bundle = find( project.bundles, { id: rItem.uid } )
        if ( bundle ) rItem.name = bundle.name
        return rItem
      } )
    }

    return instance
  }

  if ( isLatePledge ) return {
    ...state,
    optionalBuys: []
  }

  const invalidBundles = hasMultipleWaves ? _getInvalidRewards( bundles, state ) : []

  const bundlesSalableAsExtra = cloneDeep( bundles )
    .filter( item => ( isLateConfirms ? ( item.lateConfirmAvailable && item.soldAsExtra ) : item.soldAsExtra ) )
    .filter( item => invalidBundles.indexOf( item.id ) < 0 )
    .map( parse.bind( this, true ) )

  const addOns = cloneDeep( availableAddOns )
    .filter( item => {
      if (
        item.requiredBundles.length === 0 ||
        intersectionWith( showcase.basePledgeOptions, item.requiredBundles, ( nItem, rItem ) => {
          return nItem.uid === rItem.uid
        } ).length > 0 ||
        intersectionWith( bundlesSalableAsExtra, item.requiredBundles, ( nItem, rItem ) => {
          return nItem.uid === rItem.uid
        } ).length > 0
      ) return true

      return false
    } )
    .map( parse.bind( this, false ) )

  const optionalBuys = [].concat( bundlesSalableAsExtra, addOns )

  return {
    ...state,
    showcase: {
      ...state.showcase,
      optionalBuys: optionalBuys,
      hasOptionalBuys: optionalBuys.length > 0
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const checkLimitedStockItemsPresence = ( state, action ) => {
  const { optionalBuys } = state.summary
  const has = !!find( optionalBuys, item => item.hasStockLimit )

  return {
    ...state,
    showcase: {
      ...state.showcase,
      hasLimitedStockItemsSelected: has
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const updateAmounts = ( state, action ) => {
  const { showcase, summary } = state
  const { optionalBuys, basePledgeOptions } = showcase
  const { basePledge } = summary

  basePledgeOptions.map( item => {
    if ( item.uid === basePledge?.uid ) {

      item.amount = basePledge.amount
      item.isSelected = true

    } else {

      item.amount = 0
      item.isSelected = false

    }

  } )

  optionalBuys.map( item => {
    const itemOnSummary = find( summary.optionalBuys, { uid: item.uid } )
    if ( itemOnSummary ) {
      item.amount = itemOnSummary.amount
      item.isSelected = true
    } else {
      item.isSelected = false
    }
    return item
  } )

  return {
    ...state,
    showcase: {
      ...showcase,
      basePledgeOptions: basePledgeOptions,
      optionalBuys: optionalBuys
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const checkRequirements = ( state, action ) => {
  const { showcase, summary } = state

  const optionalBuys = showcase.optionalBuys.map( item => {
    if ( showcase.hasBasePledges && item.requiredBundles ) {
      const hasBasePledge = summary.hasBasePledgeSelected ? some( item.requiredBundles, { uid: summary.basePledge.uid } ) : false

      const hasOptionalBuy = intersectionWith( summary.optionalBuys, item.requiredBundles, ( nItem, rItem ) => {
        return nItem.uid === rItem.uid
      } ).length > 0

      item.isDisabled = ( !hasBasePledge && !hasOptionalBuy )
    }

    if ( item.limitedByBasePledge ) {
      item.isDisabled = !summary.hasBasePledgeSelected
    }

    return item
  } )

  return {
    ...state,
    showcase: {
      ...showcase,
      optionalBuys: optionalBuys
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const setInitialAmounts = ( state, action ) => {
  const { summary, showcase } = state
  let { optionalBuys } = showcase

  forEach( summary.optionalBuys, summaryOptionalBuy => {
    const index = findIndex( optionalBuys, optionalBuy => optionalBuy.uid === summaryOptionalBuy.uid )

    if ( index >= 0 ) {
      optionalBuys[ index ].initialAmount = summaryOptionalBuy.amount
      optionalBuys[ index ].initiallySelected = true
    }
  } )

  return {
    ...state,
    showcase: {
      ...showcase,
      optionalBuys: optionalBuys
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const setInitialLockedItems = ( state, action ) => {
  const { showcase, summary } = state

  let { optionalBuys, basePledgeOptions, isBasePledgeSelectedLocked, hasLockedOptionalBuysSelected } = showcase

  if ( summary.hasBasePledgeSelected ) {
    isBasePledgeSelectedLocked = summary.basePledge.isLockedToOrder

    const index = findIndex( basePledgeOptions, { id: summary.basePledge.id } )
    if ( index >= 0 ) basePledgeOptions[ index ].isLockedToOrder = summary.basePledge.isLockedToOrder
  }

  forEach( summary.optionalBuys, summaryOptionalBuy => {
    if ( summaryOptionalBuy.isLockedToOrder ) hasLockedOptionalBuysSelected = true

    const index = findIndex( optionalBuys, optionalBuy => optionalBuy.uid === summaryOptionalBuy.uid )
    if ( index >= 0 ) optionalBuys[ index ].isLockedToOrder = summaryOptionalBuy.isLockedToOrder
  } )

  return {
    ...state,
    showcase: {
      ...showcase,
      isBasePledgeSelectedLocked: isBasePledgeSelectedLocked,
      hasLockedOptionalBuysSelected: hasLockedOptionalBuysSelected,
      basePledgeOptions: basePledgeOptions,
      optionalBuys: optionalBuys
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const checkStep = ( state, action ) => {
  const { showcase, pledge, summary, shipping } = state

  if ( pledge.isCustomerMissedDeadline ) return {
    ...state,
    showcase: {
      ...state.showcase,
      step: STEP.CUSTOMER_MISSED_DEADLINE
    }
  }

  if ( pledge.isRetailerMissedDeadline ) return {
    ...state,
    showcase: {
      ...state.showcase,
      step: STEP.RETAILER_MISSED_DEADLINE
    }
  }

  if ( pledge.isCanceled ) return {
    ...state,
    showcase: {
      ...state.showcase,
      step: STEP.CANCELED
    }
  }

  if ( pledge.isReadyToShip || pledge.isShipped ) return {
    ...state,
    showcase: {
      ...state.showcase,
      step: STEP.SHIPPED
    }
  }

  if ( pledge.isConfirmed && pledge.isLocallyLocked ) return {
    ...state,
    showcase: {
      ...showcase,
      step: STEP.LOCKED
    }
  }

  if ( shipping.hasAddress && summary.hasBalanceDue && ( summary.hasBasePledgeSelected || summary.hasOptionalBuysSelected ) ) return {
    ...state,
    showcase: {
      ...showcase,
      step: pledge.hasLocalChanges ? STEP.SHIPPING : STEP.PAYMENT
    }
  }

  return {
    ...state,
    showcase: {
      ...showcase,
      step: showcase.hasBasePledges ? STEP.BASE : STEP.OPTIONALS
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const setStep = ( state, action ) => {
  const { showcase } = state
  const { payload } = action

  return {
    ...state,
    showcase: {
      ...showcase,
      step: payload
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const setInitialStep = ( state, action ) => {
  const { showcase } = state

  return {
    ...state,
    showcase: {
      ...showcase,
      step: showcase.hasBasePledges ? STEP.BASE : STEP.OPTIONALS
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const checkNavigation = ( state, action ) => {
  const { project, pledge, showcase, summary, shipping } = state
  const { loggedUser } = action.payload
  const { isLogged, isResolved, data } = loggedUser
  const isConfirmed = ( isLogged && isResolved ) ? data.confirmed : false

  if ( !isLogged || !isConfirmed ) {
    return {
      ...state,
      showcase: {
        ...showcase,
        isLocked: true,
        renderBreadcrumb: true
      }
    }
  }

  if ( pledge.isLocallyLocked || pledge.isCanceled || pledge.isReadyToShip || pledge.isShipped || pledge.isRetailerMissedDeadline || pledge.isCustomerMissedDeadline ) {
    return {
      ...state,
      showcase: {
        ...showcase,
        isLocked: true,
        renderBreadcrumb: false
      }
    }
  }

  if ( project.isLatePledge ) {
    return {
      ...state,
      showcase: {
        ...showcase,
        renderBreadcrumb: true,
        isLocked: false,
        optionalBuysLocked: true,
        shippingLocked: true,
        paymentLocked: !summary.hasBalanceDue
      }
    }
  }

  return {
    ...state,
    showcase: {
      ...showcase,
      renderBreadcrumb: true,
      isLocked: false,
      optionalBuysLocked: pledge.requiresBasePledge ? !summary.hasBasePledgeSelected : false,
      shippingLocked: pledge.requiresBasePledge ? !summary.hasBasePledgeSelected : ( showcase.hasBasePledges ? ( !summary.hasBasePledgeSelected && !summary.hasOptionalBuysSelected ) : !summary.hasOptionalBuysSelected ),
      paymentLocked: pledge.hasLocalChanges || ( !shipping.hasAddress || ( !summary.hasBalanceDue || ( pledge.requiresBasePledge ? !summary.hasBasePledgeSelected : !summary.hasOptionalBuysSelected ) ) )
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const preventEmptyPledge = ( state, action ) => {
  const { pledge, showcase, summary } = state

  let step = showcase.step
  if ( pledge.requiresBasePledge && !summary.hasBasePledgeSelected ) step = STEP.BASE
  if ( !pledge.requiresBasePledge && !summary.hasOptionalBuysSelected ) step = STEP.OPTIONALS

  return {
    ...state,
    showcase: {
      ...showcase,
      step: step,
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const updateShowcaseBasePledgeSelected = ( state, action ) => {
  const { showcase, summary } = state
  const { basePledgeOptions } = showcase

  const updatedList = basePledgeOptions.map( item => {
    item.isSelected = summary.hasBasePledgeSelected && ( item.id === summary.basePledge.id )
    return item
  } )

  return {
    ...state,
    showcase: {
      ...showcase,
      basePledgeOptions: updatedList
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const updateShowcaseMaxAllowed = ( state, action ) => {
  const { summary, showcase } = state
  let { optionalBuys } = showcase

  optionalBuys = optionalBuys.map( optionalBuy => {
    const itemOnSummary = find( summary.optionalBuys, item => item.id === optionalBuy.id )

    if ( itemOnSummary ) {

      optionalBuy.maxAllowed = itemOnSummary.maxAllowed

    } else {

      if ( optionalBuy.isSoldOut ) optionalBuy.maxAllowed = optionalBuy.initialAmount

    }

    if ( optionalBuy.limitedByBasePledge ) {
      const { basePledge, hasBasePledgeSelected } = summary

      if ( !hasBasePledgeSelected ) {
        optionalBuy.maxAllowed = 0
        optionalBuy.limitPerBasePledge = null
        return optionalBuy
      }

      optionalBuy.maxAllowed = ( basePledge.quantityOfLimitedAddOns * basePledge.amount )
      optionalBuy.limitPerBasePledge = basePledge.quantityOfLimitedAddOns
    }

    return optionalBuy
  } )

  return {
    ...state,
    showcase: {
      ...showcase,
      optionalBuys: optionalBuys
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const lockShowcase = ( state, action ) => {
  const { showcase } = state

  return {
    ...state,
    showcase: {
      ...showcase,
      isLocked: action.payload
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const replaceLockedBasePledge = ( state, action ) => {
  const { showcase, summary } = state
  let { basePledgeOptions } = showcase

  if ( summary.hasBasePledgeSelected && summary.basePledge.isLockedToOrder ) {
    const selectedBasePledge = new ShowcaseBasePledge( summary.basePledge.bundle, { isSelected: true, isDisabled: true } )
    selectedBasePledge.isLockedToOrder = true

    basePledgeOptions = reject( showcase.basePledgeOptions, { name: summary.basePledge.name } )
    basePledgeOptions.push( selectedBasePledge )
  }

  return {
    ...state,
    showcase: {
      ...showcase,
      basePledgeOptions: basePledgeOptions
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const appendLockedOptionalBuys = ( state, action ) => {
  const { showcase, summary, project } = state
  const { optionalBuys } = showcase

  forEach( summary.optionalBuys, optionalBuy => {
    if ( optionalBuy.isLockedToOrder ) {

      const lockedItem = new ShowcaseOptionalBuy( optionalBuy.bundle || optionalBuy.addOn, {
        isDisabled: true,
        isSelected: true,
        isBundle: !!optionalBuy.bundle,
        amount: optionalBuy.amount,
        initialAmount: optionalBuy.amount,
        initiallySelected: true
      } )
      lockedItem.isLockedToOrder = true

      optionalBuys.push( lockedItem )

    }
  } )

  return {
    ...state,
    showcase: {
      ...showcase,
      optionalBuys: optionalBuys
    }
  }
}









// SHIPPING = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

const setCountries = ( state, action ) => {
  const { shipping } = state

  return {
    ...state,
    shipping: {
      ...shipping,
      countries: action.payload
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const setProvinces = ( state, action ) => {
  const { shipping } = state

  return {
    ...state,
    shipping: {
      ...shipping,
      provinces: action.payload
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const parseShippingInfo = ( state, action ) => {
  const { pledge, shipping } = state
  const { address } = pledge
  const countryId = ( address && address.countryId ) || null
  const currentCountry = countryId ? find( shipping.countries, { id: parseInt( countryId ) } ) : null

  const hasAddress = !!(
    address &&
    address.name &&
    address.phone &&
    address.addressLine1 &&
    address.postalCode &&
    address.city &&
    address.stateId &&
    address.countryId
  )

  return {
    ...state,
    shipping: {
      ...shipping,
      address: address,
      hasAddress: hasAddress,
      currentCountry: currentCountry,
      form: {
        id: ( address && address.id ) || '',
        name: ( address && address.name ) || pledge.name || '',
        phone: ( address && address.phone ) || pledge.phone || '',
        taxpayerId: ( address && address.taxpayerId ) || pledge.taxpayerId || '',
        addressLine1: ( address && address.addressLine1 ) || '',
        addressLine2: ( address && address.addressLine2 ) || '',
        postalCode: ( address && address.postalCode ) || '',
        complement: ( address && address.complement ) || '',
        alternateCharset: ( address && address.alternateCharset ) || '',
        city: ( address && address.city ) || '',
        stateId: ( address && address.stateId ) || '',
        countryId: countryId || ''
      }
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const updateShippingInfo = ( state, action ) => {
  const { shipping } = state
  const { payload } = action

  const currentCountry = find( shipping.countries, { id: parseInt( payload.countryId ) } )

  return {
    ...state,
    shipping: {
      ...shipping,
      form: {
        ...shipping.form,
        ...payload
      },
      currentCountry: currentCountry,
      hasAddress: true
    }
  }
}









// REDUCER = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

export const checkoutReducer = ( state, action ) => {
  switch ( action.type ) {

    case MAIN.RELEASE_RENDER:
      return releaseRender( state, action )

    case MAIN.RESOLVE_MULTIWAVE:
      return resolveMultiwave( state, action )




    case PROJECT.FETCHED:
      return parseProject( state, action )

    case PROJECT.PARSE_AVAILABLE_BUNDLES_AND_ADD_ONS:
      return parseAvailableBundlesAndAddOns( state, action )

    case PROJECT.HIDDEN:
      return handleHiddenProject( state, action )

    case PROJECT.NOT_FOUND:
      return handleNotFoundProject( state, action )



    case PLEDGE.EMPTY:
      return parseEmptyPledge( state, action )

    case PLEDGE.FETCHED:
      return parsePledge( state, action )

    case PLEDGE.SET_MULTIWAVE:
      return setMultiwave( state, action )

    case PLEDGE.SAVING:
      return setSaving( state, action )

    case PLEDGE.LOCK_PLEDGE_LOCALLY:
      return lockPledgeLocally( state, action )

    case PLEDGE.SET_LOCAL_CHANGES:
      return setLocalChanges( state, action )




    case SUMMARY.INITIAL_PARSE:
      return parseInitialSummary( state, action )

    case SUMMARY.UPDATE_BASE_PLEDGE_SELECTED:
      return updateSummaryBasePledgeSelected( state, action )

    case SUMMARY.UPDATE_BASE_PLEDGE_AMOUNT:
      return updateSummaryBasePledgeAmount( state, action )

    case SUMMARY.INCLUDE_OPTIONAL_BUY:
      return includeOptionalBuy( state, action )

    case SUMMARY.UPDATE_OPTIONAL_BUY_AMOUNT:
      return updateSummaryOptionalBuyAmount( state, action )

    case SUMMARY.UPDATE_ORDER_TOTAL:
      return updateOrderTotal( state, action )

    case SUMMARY.UPDATE_PAYMENT_PAYLOAD:
      return updatePaymentPayload( state, action )

    case SUMMARY.UPDATE_SHIPPING_COST:
      return updateShippingCost( state, action )

    case SUMMARY.WILL_CALCULATE_SHIPPING_COST:
      return willCalculateShippingCost( state, action )

    case SUMMARY.LOCK_SUMMARY:
      return lockSummary( state, action )

    case SUMMARY.UPDATE_SAVE_PAYLOAD:
      return updateSavePayload( state, action )

    case SUMMARY.HANDLE_SHIPPING_COST_CALCULATION_FAILURE:
      return handleShippingCostCalculationFailure( state, action )

    case SUMMARY.EMPTY_SUMMARY_ITEMS:
      return emptySummaryItems( state, action )

    case SUMMARY.REPLACE_SUMMARY_ITEMS_BY_WAVE:
      return replaceSummaryItemsByWave( state, action )




    case SHOWCASE.PARSE_BASE_PLEDGE_OPTIONS:
      return parseBasePledgeOptions( state, action )

    case SHOWCASE.PARSE_OPTIONAL_BUYS:
      return parseOptionalBuys( state, action )

    case SHOWCASE.CHECK_STEP:
      return checkStep( state, action )

    case SHOWCASE.SET_STEP:
      return setStep( state, action )

    case SHOWCASE.SET_INITIAL_STEP:
      return setInitialStep( state, action )

    case SHOWCASE.CHECK_NAVIGATION:
      return checkNavigation( state, action )

    case SHOWCASE.PREVENT_EMPTY_PLEDGE:
      return preventEmptyPledge( state, action )

    case SHOWCASE.CHECK_LIMITED_STOCK_ITEMS_PRESENCE:
      return checkLimitedStockItemsPresence( state, action )

    case SHOWCASE.UPDATE_AMOUNTS:
      return updateAmounts( state, action )

    case SHOWCASE.CHECK_REQUIREMENTS:
      return checkRequirements( state, action )

    case SHOWCASE.SET_INITIAL_AMOUNTS:
      return setInitialAmounts( state, action )

    case SHOWCASE.SET_INITIAL_LOCKED_ITEMS:
      return setInitialLockedItems( state, action )

    case SHOWCASE.UPDATE_BASE_PLEDGE_SELECTED:
      return updateShowcaseBasePledgeSelected( state, action )

    case SHOWCASE.UPDATE_MAX_ALLOWED:
      return updateShowcaseMaxAllowed( state, action )

    case SHOWCASE.LOCK_SHOWCASE:
      return lockShowcase( state, action )

    case SHOWCASE.REPLACE_LOCKED_BASE_PLEDGE:
      return replaceLockedBasePledge( state, action )

    case SHOWCASE.APPEND_LOCKED_OPTIONAL_BUYS:
      return appendLockedOptionalBuys( state, action )




    case SHIPPING.PARSE_SHIPPING_INFO:
      return parseShippingInfo( state, action )

    case SHIPPING.SET_COUNTRIES:
      return setCountries( state, action )

    case SHIPPING.SET_PROVINCES:
      return setProvinces( state, action )

    case SHIPPING.UPDATE_SHIPPING_INFO:
      return updateShippingInfo( state, action )



    default:
      return state
  }
}

export const initialState = {
  isReady: false,
  isMultiwaveResolved: true,

  project: {
    initialAvailableBundles: [],
    initialAvailableAddOns: [],
    isFetched: false,
    isLatePledge: false,
    isLateConfirms: false,
    isHidden: false,
    isNotFound: false,
    hiddenDisclaimer: null,
    isOpened: false,
    isClosed: false,
    hasMultipleWaves: false
  },

  pledge: {
    id: null,
    bundles: [],
    addOns: [],
    credit: null,
    shippingCost: null,
    shippingVat: null,
    cartVat: null,
    total: null,
    requiresBasePledge: false,
    basePledgeSelected: null,
    trackingNumbers: [],

    address: '',
    addressWaves: [],

    isFetched: false,
    hasData: false,
    isSaving: false,
    isLocallyLocked: false,
    hasLocalChanges: false,
    hasBasePledgeSelected: false,
    hasShippingAddress: false,
    hasShippingCostCalculated: false,

    isMultiwave: null,
    state: null,
    isPending: false,
    isConfirmed: false,
    isLateConfirmed: false,
    isUnlocked: false,
    isShipped: false,
    isReadyToShip: false,
    isErrored: false,
    isCanceled: false,
    isCustomerMissedDeadline: false,
    isRetailerMissedDeadline: false
  },

  summary: {
    basePledge: null,
    optionalBuys: [],
    shippingCost: null,
    credit: null,
    subTotal: null,
    orderTotal: null,
    balance: null,

    hasRemainingCredit: false,
    hasBalanceDue: false,
    hasBasePledgeSelected: false,
    hasOptionalBuysSelected: false,
    hasShippingCostCalculated: false,
    isCalculatingShippingCost: false,
    isLocked: false,
    paymentPayload: null,
    savePayload: null
  },

  showcase: {
    basePledgeOptions: [],
    optionalBuys: [],
    hasBasePledges: false,
    hasOptionalBuys: false,
    hasLimitedStockItemsSelected: false,
    hasLockedOptionalBuysSelected: false,
    step: STEP.BASE,
    isLocked: false,
    isBasePledgeSelectedLocked: false,
    optionalBuysLocked: true,
    shippingLocked: true,
    paymentLocked: true,
    renderBreadcrumb: false
  },

  shipping: {
    address: null,

    hasAddress: false,

    countries: [],
    provinces: [],

    currentCountry: null,

    form: {
      id: '',
      name: '',
      phone: '',
      taxpayerId: '',
      addressLine1: '',
      addressLine2: '',
      postalCode: '',
      complement: '',
      city: '',
      stateId: '',
      countryId: '',
      alternateCharset: ''
    }
  }
}