import React from 'react'

import imgLoader from '../../assets/images/loader-2.gif'

import './loading-message.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const LoadingMessage = props => {

  const { className = '', children, onlyIcon = false } = props

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <div className={ `pd-loading-message ${ className }` }>
      <img src={ imgLoader } alt="Loading" />
      { onlyIcon ? null : ( <span className="pd-title-4">{ children || 'Loading, please wait...' }</span> ) }
    </div>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default LoadingMessage