import React, { useEffect, useContext, useState } from 'react'
import { Prompt } from 'react-router-dom'
import { Formik, Form, ErrorMessage } from 'formik'
import { cloneDeep } from 'lodash'

import { DashboardContext } from '../../context/dashboard-state'
import { UIContext } from '../../context/ui-state'

import { sUploader } from '../../config/fetch'
import { ACCEPT_ONLY_FLOAT_REGEX } from '../../config/validators'
import { getImageSrc, getYouTubeSrc, getCurrencyValue } from '../../config/adapters'
import { PREVENT_NAVIGATION_MESSAGE, PLEDGE_MANAGER_STATE } from '../../config/constants'

import LoadingMessage from '../../components/loading-message/loading-message'
import CustomField from '../../components/custom-field/custom-field'
import CheckboxField from '../../components/checkbox-field/checkbox-field'
import DateTimePickerField from '../../components/date-time-picker-field/date-time-picker-field'
import ProgressBar from '../../components/progress-bar/progress-bar'

import imgRatio from '../../assets/images/ratio-16x9.gif'
import './project-basics-form.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const ProjectBasicsForm = props => {

  const dashboardContext = useContext( DashboardContext )
  const UI = useContext( UIContext )
  const { project, updateProject } = dashboardContext
  const [ isUploading, setUploading ] = useState( false )
  const [ progress, setProgress ] = useState( 0 )
  const [ currentImage, setCurrentImage ] = useState( null )
  const [ hasChanges, setHasChanges ] = useState( false )
  const [ forceLatePledge, setForceLatePledge ] = useState( false )
  const [ preview, setPreview ] = useState( {
    image: null,
    video: null
  } )
  const { className = '' } = props

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleProjectLoad = () => {
    if ( project.isFetched && !project.isUpdating ) {
      const { video, image, state, pledgeManagerState } = project.details

      setPreview( {
        image: preview.image ? preview.image : ( image ? getImageSrc( image, { w: 640, h: 360, resizeTo: 'fill', quality: 60 } ) : null ),
        video: video ? getYouTubeSrc( video ) : null
      } )

      if ( state === 'draft' && pledgeManagerState === PLEDGE_MANAGER_STATE.LATE_PLEDGE ) {
        setForceLatePledge( true )
      }
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleImageUpload = async ( payload ) => {
    setUploading( true )
    setProgress( 0 )

    let formData = new FormData()
    formData.append( 'image', currentImage )

    const newImage = await sUploader( '/images', formData, percent => setProgress( percent ) )
    payload.imageId = newImage.id

    setUploading( false )

    delete payload.image
    return payload
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSave = async ( basePayload, actions ) => {

    try {

      let payload = cloneDeep( basePayload )
      if ( payload.image ) payload = await handleImageUpload( payload )
      if ( payload.depositFee ) payload.depositFee = getCurrencyValue( payload.depositFee )
      if ( payload.latePledgeDisclaimer == '' ) payload.latePledgeDisclaimer = null

      await updateProject( payload )

      actions.setSubmitting( false )
      setHasChanges( false )
      UI.showSuccessDisclaimer( 'Your project was saved successfully!' )

    } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleChange = async ( fProps, event ) => {
    const { name, value, files } = event.target

    if ( name === 'video' ) setPreview( {
      ...preview,
      video: getYouTubeSrc( value )
    } )

    if ( name === 'image' && files.length > 0 ) {
      const file = files[ 0 ]
      setPreview( { ...preview, image: window.URL.createObjectURL( file ) } )
      setCurrentImage( file )
    }

    fProps.handleChange( event )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )
  useEffect( handleProjectLoad, [ project ] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <section className={ `pd-project-basics-form ${ className }` }>
      <Prompt when={ hasChanges } message={ location => PREVENT_NAVIGATION_MESSAGE } />
      { project.isFetched ? (

        <Formik
          initialValues={ {
            name: project.details.name || '',
            headline: project.details.headline || '',
            description: project.details.description || '',
            retailer: project.details.retailer || false,
            depositFee: parseInt( project.details.depositFee ) || '',
            termsAndConditions: project.details.termsAndConditions || '',
            latePledgeTerms: project.details.latePledgeTerms || '',
            latePledgeDisclaimer: project.details.latePledgeDisclaimer || '',
            kickstarterUrl: project.details.kickstarterUrl || '',
            supportUrl: project.details.supportUrl || '',
            supportEmail: project.details.supportEmail || '',
            shippingCostMultiplier: project.details.shippingCostMultiplier || '',
            requiresBaseBundle: project.details.requiresBaseBundle || false,
            acceptLatePledge: forceLatePledge ? true : ( project.details.acceptLatePledge || false ),
            latePledgeUntil: project.details.latePledgeUntil,
            pledgeManagerDeadline: project.details.pledgeManagerDeadline,
            video: project.details.video || '',
            retailerInfoUrl: project.details.retailerInfoUrl || ''
          } }
          onSubmit={ handleSave }
          validateOnChange={ false }
          validateOnBlur={ false }>
          { fProps => (

            <Form className={ `pd-project-basics-form__main` } onChange={ event => setHasChanges( true ) }>
              <div className="pd-project-basics-form__column">
                <CustomField label="Project name" name="name" />

                <CustomField label="Headline" component="textarea" name="headline" id="headline" maxLength={ 250 } autoSize={ true } />

                <CheckboxField name="retailer">Exclusive for retailers</CheckboxField>

                <CustomField label="Deposit fee" component="currency" name="depositFee" disabled={ !fProps.values.retailer } />

                <div className="pd-field">
                  <label className="pd-label" htmlFor="pledgeManagerDeadline">Pledge Manager deadline</label>
                  <DateTimePickerField
                    name="pledgeManagerDeadline"
                    value={ fProps.values.pledgeManagerDeadline }
                    onChange={ ( name, value ) => { fProps.setFieldValue( name, value ) } }
                  />
                </div>

                <CheckboxField name="requiresBaseBundle">Requires a base pledge</CheckboxField>

                <CheckboxField name="acceptLatePledge">Accept late pledge</CheckboxField>

                <div className="pd-field">
                  <label className="pd-label" htmlFor="latePledgeUntil">Late pledge until</label>
                  <DateTimePickerField
                    name="latePledgeUntil"
                    value={ fProps.values.latePledgeUntil }
                    onChange={ ( name, value ) => { fProps.setFieldValue( name, value ) } }
                  />
                </div>

                <CustomField label="Kickstarter url" name="kickstarterUrl" />

                { fProps.values.retailer && ( <CustomField label="Retailer info url" name="retailerInfoUrl" /> ) }

                <CustomField label="Support url" name="supportUrl" />

                <CustomField label="Support e-mail" name="supportEmail" />

                <CustomField label="Shipping cost multiplier" name="shippingCostMultiplier" filter={ ACCEPT_ONLY_FLOAT_REGEX } />
              </div>

              <div className="pd-project-basics-form__column">
                <div className="pd-field">
                  <label className="pd-label" htmlFor="name">Image <span className="pd-text-sm pd-text--tip">1280x720px</span></label>
                  <input type="file" name="image" className="pd-textfield" onChange={ handleChange.bind( this, fProps ) } accept="image/jpeg,image/pjpeg,image/png" />
                  <ErrorMessage name="image" component="span" className="pd-text-sm pd-text--error" />
                  <div className="pd-project-basics-form__preview" style={ preview.image ? { backgroundImage: `url(${ preview.image })` } : null }>
                    <ProgressBar className="pd-project-basics-form__progress" percent={ progress } active={ isUploading } />
                    <img src={ imgRatio } alt="Preview" className="pd-project-basics-form__ratio" />
                  </div>
                </div>

                <div className="pd-field">
                  <label className="pd-label" htmlFor="name">YouTube video URL</label>
                  <input type="text" name="video" className="pd-textfield" value={ fProps.values.video } onChange={ handleChange.bind( this, fProps ) } />
                  <ErrorMessage name="video" component="span" className="pd-text-sm pd-text--error" />
                  <div className="pd-project-basics-form__preview">
                    { preview.video ? (
                      <iframe title={ 'video' } src={ preview.video } frameBorder="0" allowFullScreen></iframe>
                    ) : null }
                    <img src={ imgRatio } alt="Preview" className="pd-project-basics-form__ratio" />
                  </div>
                </div>
              </div>

              <div className="pd-project-basics-form__column-full">
                <CustomField label="Project Story" component="rich-text" name="description" minHeight={ 300 } />

                <CustomField label="Regular Terms and conditions" component="rich-text" name="termsAndConditions" minHeight={ 300 } />

                { fProps.values.retailer ? null : (

                  <>
                    <CustomField label="Late pledge Terms and conditions" component="rich-text" name="latePledgeTerms" minHeight={ 300 } />
                    <CustomField label="Late pledge disclaimer" component="rich-text" name="latePledgeDisclaimer" minHeight={ 300 } />
                  </>

                ) }
              </div>

              <button className="pd-button pd-button--navy" type="submit" disabled={ fProps.isSubmitting }>{ fProps.isSubmitting ? 'Processing...' : 'Save' }</button>
            </Form>

          ) }
        </Formik>

      ) : <LoadingMessage /> }
    </section>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default ProjectBasicsForm