import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { isObject, orderBy, kebabCase, isUndefined } from 'lodash'

import './table.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const Table = props => {

  const {
    className = false,
    cols = [],
    rows = [],
    footer = null
  } = props

  const [ dataset, setDataset ] = useState( rows )
  const [ orderCol, setOrderCol ] = useState( null )
  const [ orderDir, setOrderDir ] = useState( true )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const renderColItem = ( item, index ) => {
    const colLabel = item.label || item
    const itemClassName = className ? `${ className.split( ' ' )[ 0 ] }__${ kebabCase( colLabel ) }-th` : ''

    if ( !isObject( item ) ) return (
      <th className={ `pd-table__td pd-title-4 ${ itemClassName }` } key={ index }>
        <span className="pd-ellipsis">{ item }</span>
      </th>
    )

    const isClicable = item.onClick || item.sortable

    return (
      <th
        key={ index }
        className={ `pd-table__th pd-title-4 ${ itemClassName } ${ isClicable ? 'pd-table__th--clicable' : '' }` }
        onClick={ item.onClick ? item.onClick.bind( this, item ) : ( item.sortable ? handleColItemClick.bind( this, index ) : () => null ) }>
        <span className="pd-ellipsis">{ item.label }</span>
      </th>
    )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const renderRowItem = ( item, index ) => {
    const colLabel = cols[ index ].label || cols[ index ]
    const itemClassName = className ? `${ className.split( ' ' )[ 0 ] }__${ kebabCase( colLabel ) }-td` : ''

    if ( isObject( item ) && item.value ) {
      return (
        <td className={ `pd-table__td pd-text ${ itemClassName }` } key={ index }>
          { item.link ? ( <Link to={ item.link }>{ item.value }</Link> ) : <>{ item.value }</> }
        </td>
      )
    }

    return ( <td className={ `pd-table__td pd-text ${ itemClassName }` } key={ index }>{ item }</td> )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleColItemClick = ( index, event ) => {
    setOrderCol( index )
    setOrderDir( orderCol !== index ? true : !orderDir )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleColOrderChange = () => {
    const ordered = orderBy( rows, ( row ) => {
      const rowItem = row[ orderCol ]
      return ( isObject( rowItem ) ? ( isUndefined( rowItem.sortValue ) ? rowItem.value : rowItem.sortValue ) : rowItem )
    }, [ orderDir ? 'asc' : 'desc' ] )

    setDataset( ordered )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )
  useEffect( () => setDataset( rows ), [ rows ] )
  useEffect( handleColOrderChange, [ orderCol, orderDir ] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <div className={ `pd-table ${ className ? className : '' }` }>
      <table className={ `pd-table__table` }>
        <thead className="pd-table__thead">
          <tr>
            { cols.map( renderColItem ) }
          </tr>
        </thead>
        <tbody>
          { dataset.map( ( row, rowIndex ) => (

            <tr className="pd-table__tr" key={ rowIndex }>
              { row.map( renderRowItem ) }
            </tr>

          ) ) }
        </tbody>
        { footer ? (

          <tfoot>
            <tr className="pd-table__tr">
              { footer.map( ( item, index ) => <td className={ `pd-table__td pd-title-4` } key={ index }>{ item }</td> ) }
            </tr>
          </tfoot>

        ) : null }
      </table>
    </div>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default Table