import React, { useContext } from 'react'

import { UIContext } from '../../context/ui-state'

import { ReactComponent as IconSuccess } from '../../assets/icons/check-mark.svg'
import { ReactComponent as IconInfo } from '../../assets/icons/info.svg'

import './disclaimer.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const Disclaimer = props => {

  const UI = useContext( UIContext )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const onClick = ( createdAt, event ) => {
    UI.hideDisclaimer( createdAt )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <div className="pd-disclaimers">
      { UI.disclaimers.map( ( item, index ) => {
        const { type, message, createdAt } = item

        return (
          <div key={ index } onClick={ onClick.bind( this, createdAt ) } className={ `pd-disclaimer pd-disclaimer--${ type }` }>
            { type === 'success' ? <IconSuccess className="pd-icon" /> : null }
            { type === 'warning' ? <IconInfo className="pd-icon" /> : null }
            { type === 'error' ? <IconInfo className="pd-icon" /> : null }
            { type === 'info' ? <IconInfo className="pd-icon" /> : null }
            <p className="pd-text">{ message }</p>
          </div>
        )
      } ) }
    </div>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default Disclaimer