import React, { useContext, useState, useEffect } from 'react'

import { GlobalContext } from '../../context/global-state'
import { UIContext } from '../../context/ui-state'

import UserPledgesList from '../../sections/user-pledges-list/user-pledges-list'
import CompanyProjectsList from '../../sections/company-projects-list/company-projects-list'
import ProfileForm from '../../sections/profile-form/profile-form'
import PasswordForm from '../../sections/password-form/password-form'
import { WarningTip } from '../../components/tip/tip'
import LoadingMessage from '../../components/loading-message/loading-message'

import './profile.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const Profile = props => {

  const context = useContext( GlobalContext )
  const UI = useContext( UIContext )
  const { loggedUser, secureRender, sendConfirmationEmail, fetchPledges, pledgeList } = context
  const [ isSending, setSending ] = useState( false )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => {
    fetchPledges()
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleResendClick = async event => {
    setSending( true )

    try {

      await sendConfirmationEmail()
      UI.showSuccessDisclaimer( 'Email successfully sent!' )

    } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }

    setSending( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return secureRender(

    <div>
      { loggedUser.isFetched ?
        (
          <>
            <section className="pd-profile pd-page">
              <header className="pd-profile__header">
                <h3 className="pd-title-2">Welcome { loggedUser.data.name }</h3>
              </header>

              <div className="pd-profile__content">
                { loggedUser.data.confirmed ? null : (

                  <div className="pd-profile__confirmation pd-white-box">
                    <WarningTip micro>Your account is not confirmed yet.</WarningTip>
                    <button className="pd-button pd-button--navy" onClick={ handleResendClick } disabled={ isSending }>{ isSending ? 'Sending...' : 'Resend confirmation email' } </button>
                  </div>

                ) }

                <ProfileForm />
                <PasswordForm />
              </div>
            </section>

            <UserPledgesList collection={ pledgeList } />
            <CompanyProjectsList collection={ loggedUser.projects } />

          </>
        ) : <LoadingMessage /> }
    </div>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default Profile