import React, { useEffect, useContext, useState } from 'react'
import { Prompt, useHistory } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { useParams } from 'react-router-dom'

import { UIContext } from '../../context/ui-state'
import { DashboardContext } from '../../context/dashboard-state'

import { formatCurrency } from '../../config/adapters'
import { PREVENT_NAVIGATION_MESSAGE } from '../../config/constants'
import { CURRENCY_REGEX, NOT_EMPTY_REGEX } from '../../config/validators'

import PledgeOrderDetails from '../../modules/pledge-order-details/pledge-order-details'
import PledgeOrderContents from '../../modules/pledge-order-contents/pledge-order-contents'

import CustomField from '../../components/custom-field/custom-field'

import './discount-pledge.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const DiscountPledge = props => {

  const UI = useContext( UIContext )
  const dashboardContext = useContext( DashboardContext )
  const { discountPledge, pledge } = dashboardContext
  const [ hasChanges, setHasChanges ] = useState( false )
  const { className = '' } = props
  const { pledgeId } = useParams()
  const history = useHistory()

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleValidation = values => {
    let errors = {}

    if ( !CURRENCY_REGEX.test( values.amount ) ) errors.amount = 'Invalid amount'
    if ( !NOT_EMPTY_REGEX.test( values.reason ) ) errors.reason = 'Invalid reason'

    return errors
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSave = async ( payload, actions ) => {
    if ( !window.confirm( `Are you sure you want to discount ${ formatCurrency( payload.amount ) }?\n\nThis action can't be undone.` ) ) return

    actions.setSubmitting( true )

    try {

      await discountPledge( pledgeId, payload )

      setHasChanges( false )
      UI.showSuccessDisclaimer( 'The discount was applied successfully!' )
      history.push( `/dashboard/pledge/${ pledgeId }/transaction-history` )

    } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }

    actions.setSubmitting( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <section className={ `pd-discount-pledge ${ className }` }>
      <Prompt when={ hasChanges } message={ location => PREVENT_NAVIGATION_MESSAGE } />

      <PledgeOrderDetails details={ pledge.details } className="pd-discount-pledge__order" />

      <Formik
        initialValues={ { amount: '', reason: '' } }
        onSubmit={ handleSave }
        validate={ handleValidation }
        validateOnChange={ false }
        validateOnBlur={ false }>
        { fProps => (

          <Form className={ `pd-discount-pledge__main pd-white-box` } onChange={ event => setHasChanges( true ) }>
            <CustomField label="Amount" name="amount" required />
            <CustomField label="Reason" name="reason" maxLength={ 250 } required />

            <button
              className="pd-button pd-button--navy"
              type="submit"
              disabled={ fProps.isSubmitting }>
              { fProps.isSubmitting ? 'Processing...' : 'Apply discount' }
            </button>
          </Form>

        ) }
      </Formik>

      <PledgeOrderContents details={ pledge.details } className="pd-discount-pledge__contents" />
    </section>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default DiscountPledge