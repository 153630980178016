import React, { useEffect, useContext, useState } from 'react'

import { DashboardContext } from '../../context/dashboard-state'

import { sFetch } from '../../config/fetch'

import ActivityTable from '../../modules/activity-table/activity-table'
import MetaPagination from '../../components/meta-pagination/meta-pagination'

import { InfoTip } from '../../components/tip/tip'
import LoadingMessage from '../../components/loading-message/loading-message'

import './activity.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const Activity = props => {

  const dashboardContext = useContext( DashboardContext )
  const { project } = dashboardContext
  const { className = '', match } = props
  const { projectId } = match.params
  const [ action, setAction ] = useState( null )
  const [ activities, setActivities ] = useState( [] )
  const [ isFetching, setFetching ] = useState( false )
  const [ page, setPage ] = useState( 1 )
  const [ meta, setMeta ] = useState( null )
  const ACTIONS = {
    CREATE: 'create',
    UPDATE: 'update',
    DESTROY: 'destroy'
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const getReportUrl = () => {
    const paramAction = action ? `&actions=${ action }` : ''
    return `/projects/${ projectId }/activities?per_page=50&page=${ page }${ paramAction }`
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handlePageChange = () => {
    if ( isFetching ) return

    const doFetch = async () => {
      setFetching( true )
      const payload = await sFetch( getReportUrl() )
      setActivities( payload.activities )
      setMeta( payload.meta )
      setFetching( false )
    }

    doFetch()
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handlePaginationChange = page => setPage( page )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )
  useEffect( handlePageChange, [ page, action ] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <section className={ `pd-activity ${ className }` }>
      { project.isFetched ? <>

        <div className="pd-activity__filters">
          <select className="pd-select" onChange={ event => setAction( event.target.value ) }>
            <option value="">All status</option>
            <option value={ ACTIONS.CREATE }>New</option>
            <option value={ ACTIONS.UPDATE }>Updated</option>
            <option value={ ACTIONS.DESTROY }>Dropped</option>
          </select>
        </div>

        { !isFetching && activities.length === 0 ? ( <InfoTip>No items found.</InfoTip> ) : null }

        { isFetching ? <LoadingMessage /> : (

          <ActivityTable rows={ activities } />

        )
        }

        { meta && ( <MetaPagination meta={ meta } onChange={ handlePaginationChange } /> ) }

      </> : <LoadingMessage /> }
    </section>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default Activity