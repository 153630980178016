import React, { useEffect } from 'react'
import { Formik, Form } from 'formik'

import { NOT_EMPTY_REGEX } from '../../config/validators'

import CustomField from '../../components/custom-field/custom-field'

import './new-country-form.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const NewCountryForm = props => {

  const { className = '', countries = [], onSave, onChange } = props

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleValidation = values => {
    let errors = {}

    if ( !NOT_EMPTY_REGEX.test( values.id ) ) errors.id = 'Select a country'

    return errors
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSave = async ( payload, actions ) => {
    actions.setSubmitting( true )

    try {

      await onSave( payload )
      actions.resetForm()

    } catch ( exception ) { throw exception }

    actions.setSubmitting( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <Formik
      initialValues={ { id: '' } }
      enableReinitialize={ true }
      onSubmit={ handleSave }
      validate={ handleValidation }
      validateOnChange={ false }
      validateOnBlur={ false }>
      { fProps => (

        <Form className={ `pd-new-country-form pd-tpl-lightbox-form pd-white-box ${ className }` } onChange={ onChange }>
          <span className="pd-text-sm pd-text--tip pd-tpl-lightbox-form__required">
            <span className="pd-text--info">*</span> Required fields
          </span>

          <CustomField name="id" label="Country" component="select" required>
            <option>Select</option>
            { countries.map( ( country, index ) => <option key={ index } value={ country.id }>{ country.name }</option> ) }
          </CustomField>

          <button className="pd-tpl-lightbox-form__submit pd-button pd-button--navy" type="submit" disabled={ fProps.isSubmitting }>
            { fProps.isSubmitting ? 'Processing...' : 'Add new country' }
          </button>
        </Form>

      ) }
    </Formik>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default NewCountryForm