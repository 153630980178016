import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'

import { formatCurrency } from '../../config/adapters'
import { PLEDGE_STATE } from '../../config/constants'

import { WarningTip } from '../../components/tip/tip'

import './pledge-order-details.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const PledgeOrderDetails = props => {

  const { className = '', details = null, handleForceConfirmClick, isProcessingConfirmation } = props
  const showsForceConfirmAction = (
    details.state !== PLEDGE_STATE.FINISHED &&
    details.state !== PLEDGE_STATE.READY_TO_SHIP &&
    details.state !== PLEDGE_STATE.SHIPPED
  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <div className={ `pd-pledge-order-details pd-white-box ${ className }` }>
      <h3 className="pd-title-3">Pledge #{ details.id } information</h3>
      { details ?
        (

          <p className="pd-text">
            Created in { moment( details.createdAt ).format( 'MMMM Do YYYY, HH:mm' ) }<br />
            Updated in { moment( details.updatedAt ).format( 'MMMM Do YYYY, HH:mm' ) }<br />
            Imported from Kickstarter: { details.ksPledge ? 'Yes' : 'No' } <br />
            Kickstarter pledge number: { details.ksPledgeNumber || 'Empty' } <br />
            Legacy ID: { details.legacyId || 'Empty' } <br />
            Is Late Pledge: { details.latePledged ? 'Yes' : 'No' } <br />
            Is Late Confirm: { details.lateConfirmed ? 'Yes' : 'No' } <br />
            Credit: { formatCurrency( details.credit ) }
          </p>

        ) : (

          <WarningTip>No Information available.</WarningTip>

        )
      }

      <Link
        to={ `/dashboard/project/${ details.project.id }/pledge-editor/${ details.id }` }
        className="pd-button pd-button--warning">
        Edit pledge information
      </Link>

      { showsForceConfirmAction && (

        <button
          disabled={ isProcessingConfirmation }
          className="pd-button pd-button--danger"
          onClick={ handleForceConfirmClick }>
          { isProcessingConfirmation ? 'Processing...' : 'Force confirm' }
        </button>

      ) }
    </div>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default PledgeOrderDetails