import React, { useContext, useEffect, useState } from 'react'

import { CheckoutContext } from '../../context/checkout-state'
import { UIContext } from '../../context/ui-state'

import ShippingForm from '../../modules/shipping-form/shipping-form'
import CheckoutShippingInfo from '../../modules/checkout-shipping-info/checkout-shipping-info'
import CheckoutShippingTrackingNumberInfo from '../../modules/checkout-tracking-number-info/checkout-tracking-number-info'
import TabPanel, { TabPanelItem } from '../../components/tab-panel/tab-panel'
import { InfoTip, ErrorTip, WarningTip } from '../../components/tip/tip'

import './checkout-shipping.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const CheckoutShipping = props => {

  const { className = '' } = props
  const checkoutContext = useContext( CheckoutContext )
  const {
    project,
    pledge,
    shipping,
    summary,
    showcase,
    savePledgeData,
    updateProvinces,
    updateShippingInfo
  } = checkoutContext
  const UI = useContext( UIContext )
  const [ isEditing, setEditing ] = useState( !shipping.hasAddress )
  const [ isCurrentCountryInvalid, setCurrentCountryInvalid ] = useState( false )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSubmit = async ( payload, fProps ) => {
    UI.hideAllDisclaimers()

    try {

      await updateShippingInfo( payload )
      setEditing( false )

    } catch ( exception ) { UI.showErrorDisclaimer( exception ) }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleCountryChange = event => {
    const { value } = event.target
    updateProvinces( value )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleConfirmClick = async event => {
    try {

      UI.hideAllDisclaimers()
      await savePledgeData()

    } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleEditClick = event => {
    setEditing( true )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )
  useEffect( () => {
    setCurrentCountryInvalid( project.hasMultipleWaves && pledge.isMultiwave && !shipping.currentCountry?.multiwaveShipping )
  }, [ project.hasMultipleWaves, pledge.isMultiwave, shipping.currentCountry ] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <div className={ `pd-checkout-shipping pd-checkout-module ${ className }` }>
      <header className="pd-checkout-module__header">
        <h3 className="pd-title-3">{ showcase.hasBasePledges && showcase.hasOptionalBuys ? '03' : '02' }. Shipping information</h3>
        <p className="pd-text">Provide your shipping address below. Make sure you verify the address is correct and any additional information (door code, apartment #, etc) is correctly provided.</p>
      </header>

      { isEditing ?
        (

          <ShippingForm
            initialValues={ shipping.form }
            countries={ shipping.countries }
            provinces={ shipping.provinces }
            pledgeIsMultiwave={ pledge.isMultiwave }
            waves={ project.waves }
            onSubmit={ handleSubmit }
            onCountryChange={ handleCountryChange }
          />

        ) : (

          <>

            <TabPanel className="pd-tab-panel--cyan">
              <TabPanelItem label="Shipping address">
                <div className="pd-checkout-shipping__current">
                  <div className="pd-checkout-shipping__info pd-white-box">
                    <CheckoutShippingInfo shippingInfo={ shipping } />

                    { isCurrentCountryInvalid ? (

                      <ErrorTip>
                        <p className="pd-text">{ project.waves }-Wave Expedited Shipping not available for { shipping.currentCountry?.name }.</p>
                      </ErrorTip>

                    ) : null }

                    { pledge.addressWaves.length === 0 ? (

                      <button
                        className="pd-button pd-button--cyan"
                        onClick={ handleEditClick }
                        disabled={ isEditing || pledge.isSaving }>
                        { pledge.isSaving ? 'Processing...' : 'Change information' }
                      </button>

                    ) : (

                      <WarningTip>
                        The deadline to edit your { project.waves }-Wave Expedited Shipping address has passed. If you need further assistance,
                        please contact our <a className="pd-button pd-button--text pd-button--text-inline" href="https://support.cmon.com" target="_blank" rel="noopener noreferrer">Customer Support</a>.
                      </WarningTip>

                    ) }
                  </div>

                  { summary.hasBalanceDue ? null : (
                    <InfoTip>
                      You have enough credits to confirm your order. By pressing the button below your order will be confirmed and locked.
                    </InfoTip>
                  ) }

                  <button
                    className="pd-button pd-button--navy pd-checkout-shipping__save"
                    disabled={ pledge.isSaving || summary.isCalculatingShippingCost || isCurrentCountryInvalid }
                    onClick={ handleConfirmClick }>
                    { pledge.isSaving ? 'Saving...' : ( summary.isCalculatingShippingCost ? 'Updating shipping cost...' : 'Confirm and save shipping address' ) }
                  </button>
                </div>
              </TabPanelItem>

              {/* { pledge.addressWaves.map( addressWave => (
                <TabPanelItem key={ addressWave.wave } label={ `Wave ${ addressWave.wave }` }>
                  <CheckoutShippingInfo className="pd-white-box" shippingInfo={ {
                    form: addressWave.address,
                    countries: shipping.countries,
                    provinces: shipping.provinces
                  } } />
                </TabPanelItem>
              ) ) } */}

              { pledge.trackingNumbers.length ? (

                <TabPanelItem className="pd-white-box" label="Tracking information">
                  <CheckoutShippingTrackingNumberInfo trackingNumbers={ pledge.trackingNumbers } />
                </TabPanelItem>

              ) : null }
            </TabPanel>
          </>

        )
      }
    </div>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default CheckoutShipping