import React, { useEffect, useContext } from 'react'
import { PortalWithState } from 'react-portal'

import { UIContext } from '../../context/ui-state'

import { WarningTip } from '../../components/tip/tip'
import Lightbox from '../../components/lightbox/lightbox'

import './checkout-cart-warning-lightbox.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const CheckoutCartWarningLightbox = props => {

  const UI = useContext( UIContext )
  const { className = '', cartWarning } = props

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <PortalWithState defaultOpen>
      { portalBag => portalBag.portal(

        <Lightbox portalBag={ portalBag } isLocked={ true }>
          <WarningTip>
            <div className="pd-checkout-cart-warning-lightbox">
              <p>{ cartWarning }</p>
              <button className="pd-button pd-button--warning" onClick={ evt => UI.resolveConfirmationCallback( true ) }>Ok</button>
            </div>
          </WarningTip>
        </Lightbox>

      ) }
    </PortalWithState>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default CheckoutCartWarningLightbox