export const NOT_EMPTY_REGEX = /([^\s])/
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const NUMBER_REGEX = /^[0-9]+$/
export const FLOAT_REGEX = /^[+-]?\d+(\.\d+)?$/
export const PHONE_REGEX = /^[0-9\-()/+ ]+$/
export const PHONE_REPLACE_REGEX = /[^\d-().]/g
export const CURRENCY_REGEX = /^(?:\d{1,3}(?:,\d{3})+|\d+)(?:\.\d+)?$/
export const SOFT_PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z]).{8,}$/
export const HEAVY_PASSWORD_REGEX = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z]).{8,}$/
export const URL_REGEX = /[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/
export const YT_URL_REGEX = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
export const ACCEPT_ONLY_INT_REGEX = /[^0-9]/g
export const ACCEPT_ONLY_FLOAT_REGEX = /[^0-9.,]/g
export const ACCEPT_ONLY_PHONE_REGEX = /[^0-9\-()/+ ]/g

export function isEmpty( error, value ) {
  return NOT_EMPTY_REGEX.test( value ) ? null : error
}

export function isEmail( error, email ) {
  return EMAIL_REGEX.test( email ) ? null : error
}

export const isCurrency = ( error, value ) => {
  if ( !CURRENCY_REGEX.test( value ) ) return error
}

export const isNumber = ( error, value ) => {
  if ( !NUMBER_REGEX.test( value ) ) return error
}

export function isSoftPassword( error, password ) {
  return SOFT_PASSWORD_REGEX.test( password ) ? null : error
}

export function CHECKED_VALIDATOR( checked ) {
  return checked
}

export function ALMOST_ONE_CHECKED_VALIDATOR( value ) {
  return !!value.length
}

export function RICH_EDITOR_NOT_EMPTY( value ) {
  return value
    .replace( /<br data-mce-bogus="1">/g, '' )
    .replace( /\s|&nbsp;/g, '' )
    .replace( /<br>/g, '' )
    .replace( /<p><\/p>/g, '' )
    .replace( /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '' )
    .length > 0
}