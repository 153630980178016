export const ipadBreakpoint = 768
export const desktopBreakpoint = 1200
export const layoutBreakpoint = 1366

export const ROLE = {
  PRODUCER: 'producer',
  FINANCIAL: 'financial',
  RETAILER_MANAGER: 'retailer_manager',
  CUSTOMER_SERVICE: 'customer_service',
  CS_MANAGER: 'cs_manager',
  SUPER_ADMIN: 'super_admin'
}

export const PLEDGE_STATE = {
  PENDING: 'pending',
  AUTHORIZED: 'authorized',
  PROCESSING: 'processing',
  FINISHED: 'finished',
  UNLOCKED: 'unlocked',
  ERRORED: 'errored',
  DROPPED: 'dropped',
  REFUNDED: 'refunded',
  CANCELED: 'canceled',
  READY_TO_SHIP: 'ready_to_ship',
  SHIPPED: 'shipped',
  RETAILER_MISSED_DEADLINE: 'missed_deadline',
  CUSTOMER_MISSED_DEADLINE: 'customer_missed_deadline',
}

export const PLEDGE_MANAGER_STATE = {
  DRAFT: 'draft',
  LATE_PLEDGE: 'late_pledge',
  OPENED: 'opened',
  LATE_CONFIRMS: 'late_confirms',
  CLOSED: 'closed'
}

export const STOCK = {
  NORMAL: 'normal',
  LIMITED: 'limited_stock',
  LOW: 'low_stock',
  SOLD_OUT: 'sold_out'
}

export const ALLOWED_ADDRESS_TYPES = [ 'premise', 'street_address', 'subpremise' ]

export const GATEWAY_REFUND_STATUS = {
  MANUAL_REFUND: 'MANUAL_REFUND'
}

export const PREVENT_NAVIGATION_MESSAGE = 'You have not saved changes.\nAre you sure you want to continue?'
export const PREVENT_DELETION_MESSAGE = 'You have items that require pledge PLEDGE_NAME to be present, if you remove it those items will be removed as well.'
export const PREVENT_SOLD_OUT_DELETION_MESSAGE = 'You have items reserved in your order that are already sold-out. If you remove them from your order they could be taken by other users and you won\'t be able to re-add them to your order at a later time.'
