import React, { useContext, useEffect } from 'react'
import { Link, withRouter, useLocation, useHistory } from 'react-router-dom'
import { Formik, Form } from 'formik'
import queryString from 'query-string'

import { GlobalContext } from '../../context/global-state'
import { UIContext } from '../../context/ui-state'

import { SOFT_PASSWORD_REGEX } from '../../config/validators'

import CustomField from '../../components/custom-field/custom-field'

import './password-reset.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const PasswordReset = props => {

  const context = useContext( GlobalContext )
  const UI = useContext( UIContext )
  const { resetPassword } = context
  const location = useLocation()
  const history = useHistory()

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => {
    const qs = queryString.parse( location.search )

    if ( qs.error ) {
      UI.showErrorDisclaimer( 'Your password reset token expired, please request another password reset.' )
      history.push( '/password/recovery' )
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleValidation = values => {
    let errors = {}

    if ( !SOFT_PASSWORD_REGEX.test( values.password ) ) errors.password = 'Password must be at least 8 characters long and include one letter and one number.'
    if ( values.password !== values.passwordConfirmation ) errors.passwordConfirmation = 'Password doesn\'t match.'

    return errors
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSubmit = async ( payload, actions ) => {
    try {

      await resetPassword( payload )
      UI.showSuccessDisclaimer( 'Password successfully changed' )
      props.history.push( '/' )

    } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }

    actions.setSubmitting( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <section className="pd-password-reset pd-page pd-form-box-page">
      <Formik
        initialValues={ { password: '', passwordConfirmation: '' } }
        onSubmit={ handleSubmit }
        validate={ handleValidation }
        validateOnChange={ false }
        validateOnBlur={ false }>
        { fProps => (

          <Form className="pd-form-box-page__form">
            <header className="pd-form-box-page__header">
              <h4 className="pd-title-3">Password reset</h4>
              <p className="pd-text">Provide your new password and confirm it below to change it (Min. 8 characters).</p>
            </header>

            <CustomField name="password" type="password" label="Password" required />
            <CustomField name="passwordConfirmation" type="password" label="Confirm password" required />

            <nav className="pd-form-box-page__actions">
              <button className="pd-button pd-button--navy" type="submit" disabled={ fProps.isSubmitting }>{ fProps.isSubmitting ? 'Processing...' : 'Reset' }</button>
              <Link className="pd-button pd-button--link" to="/">or cancel</Link>
            </nav>
          </Form>

        ) }
      </Formik>
    </section>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default withRouter( PasswordReset )