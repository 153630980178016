import React, { useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { capitalize, find } from 'lodash'
import moment from 'moment-timezone'

import { DashboardContext } from '../../context/dashboard-state'

import { GATEWAY_REFUND_STATUS } from '../../config/constants'
import { formatCurrency, defaultCurrencyLocale, defaultCurrencyOptions } from '../../config/adapters'

import Table from '../../components/table/table'
import { ErrorTip } from '../../components/tip/tip'

import './transaction-history.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const TransactionHistory = props => {

  const dashboardContext = useContext( DashboardContext )
  const { details } = dashboardContext.pledge
  const { className = '' } = props
  const { pledgeId } = useParams()

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <section className={ `pd-transaction-history ${ className }` }>
      <Table
        className="pd-transaction-history__table"
        cols={ [ 'Date', 'Amount', 'Gateway' ] }
        rows={ details.charges.map( item => {
          const detailsLink = `/dashboard/pledge/${ pledgeId }/transaction-history/${ item.id }`
          const hasManualRefunds = find( item.refunds, { gatewayRefund: { status: GATEWAY_REFUND_STATUS.MANUAL_REFUND } } )

          return [
            { value: moment( item.createdAt ).format( 'MMM DD, YYYY, HH:mm' ), link: detailsLink },
            { value: `${ formatCurrency( item.amount, defaultCurrencyLocale, Object.assign( {}, defaultCurrencyOptions, { currency: item.currency } ) ) } (${ item.currency })`, link: detailsLink },
            {
              value: hasManualRefunds ?
                (
                  <>
                    <ErrorTip micro>{ capitalize( item.gateway ) } { item.gatewayChargeId ? `(${ item.gatewayChargeId })` : `` } -  <strong>Requires manual refund</strong></ErrorTip>
                  </>
                ) : (

                  <>{ capitalize( item.gateway ) } { item.gatewayChargeId ? `(${ item.gatewayChargeId })` : `` }</>
                ),
              link: detailsLink
            }
          ]
        } ) }
      />
    </section>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default TransactionHistory

