import React, { useEffect, useState } from 'react'
import { intersection } from 'lodash'
import { PortalWithState } from 'react-portal'

import Env from '../../config/env'
import { NOT_EMPTY_REGEX } from '../../config/validators'
import { checkStatus, parseJson } from '../../config/fetch'

import Lightbox from '../../components/lightbox/lightbox'

import './address-decoder-field.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const AddressDecoderField = props => {

  const {
    className = '',
    disabled = false,
    allowedTypes = [],
    deniedTypes = [],
    initialAddress = '',
    decodeInitialAddress = false,
    label = 'Address',
    buttonLabel = 'Search',
    processingLabel = 'Searching...',
    notFoundMessage = 'Address not found. Please make sure you input a street name, street number, postal code, city and country at least.',
    onDecodeStart = () => null,
    onDecodeSuccess = () => null,
    onDecodeError = () => null,
    showInlineError = true,
    showMiniMap = true,
    required = false,
    placeholder = ''
  } = props

  const [ isDisabled, setDisabled ] = useState( disabled )
  const [ isProcessing, setProcessing ] = useState( false )
  const [ address, setAddress ] = useState( initialAddress )
  const [ decodedAddress, setDecodedAddress ] = useState( '' )
  const [ isInitialAddressDecoded, setInitialAddressDecoded ] = useState( false )
  const [ miniMap, setMiniMap ] = useState( null )
  const [ error, setError ] = useState( null )
  const [ multipleResults, setMultipleResults ] = useState( null )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const decode = async address => {
    const endpoint = `https://maps.googleapis.com/maps/api/geocode/json?address=${ encodeURIComponent( address ) }&key=${ Env.googleApiKey }&language=en`
    return fetch( endpoint ).then( checkStatus.bind( this, false ) ).then( parseJson ).catch( parseJson )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const onSubmit = async event => {
    event && event.preventDefault()

    if ( !NOT_EMPTY_REGEX.test( address ) ) {
      setError( 'An address is required.' )
      return
    }

    setError( null )
    setProcessing( true )
    setMiniMap( null )
    onDecodeStart()

    // TODO: Prevent timeout
    const decoded = await decode( address )

    if ( decoded.status === 'OK' ) {

      let results = decoded.results
      if ( allowedTypes.length ) results = results.filter( result => intersection( allowedTypes, result.types ).length > 0 )
      if ( deniedTypes.length ) results = results.filter( result => intersection( deniedTypes, result.types ).length <= 0 )

      if ( results.length > 1 ) {

        setMultipleResults( results )

      } else if ( results.length === 1 ) {

        handleDecodeSuccess( results[ 0 ] )

      } else {

        onDecodeError( notFoundMessage, address )
        if ( showInlineError ) setError( notFoundMessage )

      }
    }

    if ( decoded.status === 'ZERO_RESULTS' ) {
      onDecodeError( notFoundMessage, address )
      if ( showInlineError ) setError( notFoundMessage )
    }

    setProcessing( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleResultClick = ( result, event ) => {
    handleDecodeSuccess( result )
    setMultipleResults( null )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleDecodeSuccess = result => {
    onDecodeSuccess( result, address )
    setDecodedAddress( result.formattedAddress )

    if ( showMiniMap ) {
      const { lat, lng } = result.geometry.location
      setMiniMap( `https://maps.googleapis.com/maps/api/staticmap?center=${ lat },${ lng }&zoom=16&size=640x280&scale=2&markers=color:red%7Clabel:a%7C${ lat },${ lng }&key=${ Env.googleApiKey }&language=en` )
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const onKeyPress = event => {
    if ( event.charCode === 13 ) onSubmit( event )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleAutoDecode = () => {
    if ( !decodeInitialAddress ) return

    const wrapper = async () => {
      if ( address && decodeInitialAddress && !isInitialAddressDecoded ) {
        await onSubmit()
        setInitialAddressDecoded( true )
      }
    }
    wrapper()
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )
  useEffect( handleAutoDecode, [ address, decodeInitialAddress ] )
  useEffect( () => setDisabled( disabled ), [ disabled ] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <div className={ `pd-address-decoder-field ${ className }` }>
      <label className="pd-address-decoder-field__label pd-label">{ label } { required ? ( <span className="pd-text--info">*</span> ) : null }</label>
      <div className="pd-address-decoder-field__row">
        <input
          type="text"
          name="address"
          value={ address }
          className="pd-address-decoder-field__field pd-textfield"
          disabled={ isDisabled || isProcessing }
          onChange={ event => setAddress( event.target.value ) }
          onKeyPress={ onKeyPress }
          placeholder={ placeholder }
        />

        <button
          className="pd-address-decoder-field__button pd-button pd-button--navy"
          onClick={ onSubmit }
          disabled={ isDisabled || isProcessing }>
          { isProcessing ? processingLabel : buttonLabel }
        </button>
      </div>

      { error && (
        <span className="pd-address-decoder-field__error pd-field-error pd-text-sm pd-text--error">{ error }</span>
      ) }

      { miniMap && (
        <figure className="pd-address-decoder-field__mini-map">
          <img src={ miniMap } alt="Google Maps" />
          <figcaption className="pd-text-sm pd-text--info">{ decodedAddress || address }</figcaption>
        </figure>
      ) }

      { multipleResults ? (

        <PortalWithState defaultOpen>
          { portalBag => (
            <>
              { portalBag.portal(
                <Lightbox portalBag={ portalBag } isLocked={ true }>
                  <div className="pd-address-decoder-field__results pd-white-box">
                    <h4 className="pd-title-4">Please choose a result</h4>
                    <ul>
                      { multipleResults.map( ( result, index ) => (
                        <li key={ index }>
                          <button
                            onClick={ handleResultClick.bind( this, result ) }
                            className="pd-button pd-button--navy pd-button--text">
                            { result.formattedAddress }
                          </button>
                        </li>
                      ) ) }
                    </ul>

                  </div>
                </Lightbox>
              ) }
            </>
          ) }
        </PortalWithState>
      ) : null }


    </div>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default AddressDecoderField