import React, { useEffect, useState, useContext } from 'react'
import { Redirect } from 'react-router-dom'

import { GlobalContext } from '../../context/global-state'
import { UIContext } from '../../context/ui-state'

import { rFetch } from '../../config/fetch'

import KsUpdates from '../../modules/ks-updates/ks-updates'
import AvailableProjectsList from '../../sections/available-projects-list/available-projects-list'
import CompanyProjectsList from '../../sections/company-projects-list/company-projects-list'
import LoadingMessage from '../../components/loading-message/loading-message'

import './home.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const Home = props => {
  const context = useContext( GlobalContext )
  const UI = useContext( UIContext )
  const { loggedUser, isRetailer } = context
  const { isLogged } = loggedUser
  const [ projectList, setProjectList ] = useState( [] )
  const [ ksUpdates, setKsUpdates ] = useState( [] )
  const [ isReady, setReady ] = useState( false )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => {
    fetchData()
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const fetchData = async () => {

    fetchKsUpdates()

    if ( !isRetailer ) await fetchProjects()

    setReady( true )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const fetchKsUpdates = async () => {
    try {

      const updates = await rFetch( '/home' )
      setKsUpdates( updates )

    } catch ( exception ) {

      UI.showErrorDisclaimer( exception.message )

    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const fetchProjects = async () => {
    try {

      const projects = await rFetch( `/projects/list?retailer=${ isRetailer }` )
      setProjectList( projects )

    } catch ( exception ) {

      UI.showErrorDisclaimer( exception.message )

    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  if ( isRetailer && !isLogged ) return <Redirect to="/sign-in" />

  return (

    <section className="pd-home">
      { isReady ?
        (

          <>
            { !isRetailer && ( <>


              { ksUpdates.length ? ( <KsUpdates items={ ksUpdates } /> ) : null }
              <AvailableProjectsList collection={ projectList } />

            </> ) }

            { isLogged && (

              <CompanyProjectsList collection={ loggedUser.projects } />

            ) }
          </>

        ) : (

          <div className="pd-container">
            <LoadingMessage className="pd-home__loading" />
          </div>

        )
      }
    </section>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default Home