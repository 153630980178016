import React from 'react'
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';

import { ReactComponent as IconDrag } from '../../assets/icons/drag.svg'

import './sortable-list.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const DragHandle = sortableHandle( props => {
  const { className = '' } = props

  return (
    <IconDrag className={ `pd-icon ${ className }` } />
  )
} )

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const SortableItem = sortableElement( props => {
  const { children, className = '', tagName = 'div', onClick } = props
  const Element = tagName

  return (
    <Element className={ className } onClick={ onClick }>
      { children }
    </Element>
  )
} )

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const SortableContainer = sortableContainer( props => {
  const { children, className = '', tagName = 'div' } = props
  const Element = tagName

  return (
    <Element className={ className }>{ children }</Element>
  )
} )
