import React, { useEffect } from 'react'
import { Formik, Form, Field } from 'formik'
import { capitalize } from 'lodash'

import { NOT_EMPTY_REGEX } from '../../config/validators'

import CustomField from '../../components/custom-field/custom-field'
import CheckboxField from '../../components/checkbox-field/checkbox-field'

import './new-hub-form.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const UNIT_LENGTH = {
  CM: 'centimeters',
  IN: 'inches'
}

export const UNIT_WEIGHT = {
  KG: 'kilos',
  LB: 'pounds'
}

export const emptyHub = {
  name: '',
  unitLength: '',
  unitWeight: '',
  slipCost: 'slip_cost',
  formula: '',
  multiwaveShipping: '',
  factorFormula: '',
  printSlipCosts: true,
  chargeVat: false
}

const NewHubForm = props => {

  const { className = '', hub, onSave, onChange } = props

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleValidation = values => {
    let errors = {}

    if ( !NOT_EMPTY_REGEX.test( values.name ) ) errors.name = 'Invalid name'
    if ( !NOT_EMPTY_REGEX.test( values.unitLength ) ) errors.unitLength = 'Select an unit length'
    if ( !NOT_EMPTY_REGEX.test( values.unitWeight ) ) errors.unitWeight = 'Select an unit weight'
    if ( !NOT_EMPTY_REGEX.test( values.slipCost ) ) errors.slipCost = 'Select a slip cost'
    if ( !NOT_EMPTY_REGEX.test( values.formula ) ) errors.formula = 'Invalid formula'
    if ( !NOT_EMPTY_REGEX.test( values.factorFormula ) ) errors.factorFormula = 'Invalid factor formula'

    return errors
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSave = async ( payload, actions ) => {
    actions.setSubmitting( true )

    try {

      await onSave( payload )
      actions.resetForm()

    } catch ( exception ) { throw exception }

    actions.setSubmitting( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <Formik
      initialValues={ hub || emptyHub }
      enableReinitialize={ true }
      onSubmit={ handleSave }
      validate={ handleValidation }
      validateOnChange={ false }
      validateOnBlur={ false }>
      { fProps => (

        <Form className={ `pd-new-hub-form pd-tpl-lightbox-form pd-white-box ${ className }` } onChange={ onChange }>
          { hub ? ( <Field name="id" type="hidden" /> ) : null }

          <span className="pd-text-sm pd-text--tip pd-tpl-lightbox-form__required">
            <span className="pd-text--info">*</span> Required fields
          </span>

          <Field name="slipCost" type="hidden" />

          <CustomField className="pd-new-hub-form__name" name="name" label="Hub Name" required />

          <CustomField className="pd-new-hub-form__unit-length" name="unitLength" label="Length unit" component="select" required>
            <option>Select</option>
            <option value={ UNIT_LENGTH.CM }>{ capitalize( UNIT_LENGTH.CM ) }</option>
            <option value={ UNIT_LENGTH.IN }>{ capitalize( UNIT_LENGTH.IN ) }</option>
          </CustomField>

          <CustomField className="pd-new-hub-form__unit-weight" name="unitWeight" label="Weight unit" component="select" required>
            <option>Select</option>
            <option value={ UNIT_WEIGHT.KG }>{ capitalize( UNIT_WEIGHT.KG ) }</option>
            <option value={ UNIT_WEIGHT.LB }>{ capitalize( UNIT_WEIGHT.LB ) }</option>
          </CustomField>

          <CheckboxField className="pd-new-hub-form__print-slip-cost" name="printSlipCosts">
            Print slip costs
          </CheckboxField>

          <CheckboxField className="pd-new-hub-form__charge-vat" name="chargeVat">
            Charge VAT
          </CheckboxField>

          <CheckboxField className="pd-new-hub-form__multiwave" name="multiwaveShipping">
            Multiwave Shipping
          </CheckboxField>

          <CustomField className="pd-new-hub-form__formula" name="formula" label="Weight Formula" required />

          <CustomField className="pd-new-hub-form__factor-formula" name="factorFormula" label="Factor formula" required />

          <button className="pd-new-hub-form__submit pd-tpl-lightbox-form__submit pd-button pd-button--navy" type="submit" disabled={ fProps.isSubmitting }>
            { fProps.isSubmitting ? 'Processing...' : ( hub ? 'Update hub' : 'Create new hub' ) }
          </button>
        </Form>

      ) }
    </Formik>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default NewHubForm