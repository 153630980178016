import React, { useEffect, useContext } from 'react'
import moment from 'moment-timezone'

import { DashboardContext } from '../../context/dashboard-state'

import Table from '../../components/table/table'

import './pledge-tracking-numbers.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const PledgeTrackingNumbers = props => {

  const dashboardContext = useContext( DashboardContext )
  const { trackingNumbers } = dashboardContext.pledge.details

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <section className={ `pd-pledge-tracking-numbers` }>
      <Table
        className={ `pd-pledge-tracking-numbers__table` }
        cols={ [ 'Carrier', 'Date shipped', 'Shipping Type', 'Tracking number' ] }
        rows={ trackingNumbers.map( item => [
          item.carrier,
          ( item.dateShipped ? moment( item.dateShipped ).format( 'MMM DD, YYYY' ) : ( <em>Not informed</em> ) ),
          ( item.shippingType ? item.shippingType : ( <em>Not informed</em> ) ),
          ( item.trackingUrl ? (

            <a href={ item.trackingUrl } target="_blank" className="pd-button pd-button--text pd-button--text-inline pd-button--navy">{ item.trackingNumber }</a>

          ) : item.trackingNumber )
        ] ) }
      />
    </section>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default PledgeTrackingNumbers