import React, { useContext } from 'react'
import moment from 'moment-timezone'

import { GlobalContext } from '../../context/global-state'
import { CheckoutContext } from '../../context/checkout-state'

// import InlineShare from '../../components/inline-share/inline-share'
import CheckoutShippingInfo from '../../modules/checkout-shipping-info/checkout-shipping-info'
import CheckoutShippingTrackingNumberInfo from '../../modules/checkout-tracking-number-info/checkout-tracking-number-info'
import TabPanel, { TabPanelItem } from '../../components/tab-panel/tab-panel'
import { InfoTip } from '../../components/tip/tip'

import './checkout-locked-disclaimer.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const CheckoutLockedDisclaimer = props => {

  const context = useContext( GlobalContext )
  const checkoutContext = useContext( CheckoutContext )
  const { isRetailer } = context
  const { project, unlockPledge, shipping, pledge } = checkoutContext
  const { isLatePledge, isLateConfirms, pledgeManagerDeadline, supportUrl, supportEmail, waves, hasMultipleWaves, retailer, isClosed } = project
  const { isMultiwave } = pledge
  const { className = '' } = props
  const canUnlock = isClosed ? false : ( pledgeManagerDeadline ? moment().isBefore( pledgeManagerDeadline ) : true )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return isLatePledge ? (

    <div className={ `pd-checkout-locked-disclaimer pd-checkout-generic-disclaimer ${ className }` }>
      <h3 className="pd-title-3">Your late pledge is confirmed!</h3>

      <p className="pd-text">
        Please allow us some time to setup the full pledge manager where you’ll be able to customize the contents of your
        order and enter your shipping information. <br />

        We will send you an e-mail when the system goes live, which should happen in about 3 weeks. Make sure to add
        "no-reply@pledg.it" to your contact list to ensure this important email doesn't end up in your junk folder.<br />

        Thank you for your patience and support!
      </p>

      {/* <InfoTip className="pd-checkout-generic-disclaimer__shipping-strategy" micro>
        <strong>{ isMultiwave ? `${ waves }-Wave Expedited Shipping` : 'Single-Wave Shipping' }</strong>
      </InfoTip> */}

      {/* <InlineShare fbUrl="#" twUrl="#" /> */ }
    </div>

  ) : (

    <div className={ `pd-checkout-locked-disclaimer pd-checkout-generic-disclaimer ${ className }` }>
      <h3 className="pd-title-3">Your pledge is confirmed and locked.</h3>

      { canUnlock ?
        (

          <>
            <p className="pd-text">
              You may edit your pledge until the Pledge Manager deadline, but make sure you go through the entire process to confirm it again.
            </p>

            <button className="pd-button pd-button--navy" onClick={ unlockPledge }>Unlock</button>
          </>

        ) : ( supportUrl || supportEmail ? (

          <p className="pd-text">
            If you have any questions, please contact this project's
            <a
              href={ supportUrl || `mailto:${ supportEmail }` }
              className="pd-button pd-button--navy pd-button--text"
              target="_blank"
              rel="noopener noreferrer">
              { isRetailer ? 'retailer support' : 'customer support' }
            </a>.
          </p>

        ) : null )
      }

      { hasMultipleWaves && !isLateConfirms && !isLatePledge && !retailer && (
        <InfoTip className="pd-checkout-generic-disclaimer__shipping-strategy" micro>
          <strong>{ isMultiwave ? `${ waves }-Wave Expedited Shipping` : 'Single-Wave Shipping' }</strong>
        </InfoTip>
      ) }

      <TabPanel className="pd-tab-panel--cyan">
        <TabPanelItem label="Shipping address">
          <CheckoutShippingInfo className="pd-white-box" shippingInfo={ shipping } />
        </TabPanelItem>

        {/* { pledge.addressWaves.map( addressWave => (
          <TabPanelItem key={ addressWave.wave } label={ `Wave ${ addressWave.wave }` }>

            <CheckoutShippingInfo className="pd-white-box" shippingInfo={ {
              form: addressWave.address,
              countries: shipping.countries,
              provinces: shipping.provinces
            } } />

          </TabPanelItem>
        ) ) } */}

        { pledge.trackingNumbers.length ? (

          <TabPanelItem className="pd-white-box" label="Tracking information">
            <CheckoutShippingTrackingNumberInfo trackingNumbers={ pledge.trackingNumbers } />
          </TabPanelItem>

        ) : null }
      </TabPanel>

      {/* <InlineShare fbUrl="#" twUrl="#" /> */ }
    </div>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default CheckoutLockedDisclaimer