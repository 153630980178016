import React, { useEffect, useContext, useState } from 'react'
import { useParams, useHistory } from 'react-router'
import { find } from 'lodash'

import { DashboardContext } from '../../context/dashboard-state'
import { UIContext } from '../../context/ui-state'

import { sFetch } from '../../config/fetch'

import UserPicker from '../../modules/user-picker/user-picker'

import { ErrorTip } from '../../components/tip/tip'

import './pledge-transferer.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const PledgeTransferer = props => {

  const dashBoardContext = useContext( DashboardContext )
  const UI = useContext( UIContext )
  const { pledge, transferPledge } = dashBoardContext
  const [ destination, setDestination ] = useState( {
    user: null,
    isNew: false,
    currentPledge: null
  } )
  const [ isTransfering, setTransfering ] = useState( false )
  const { className = '' } = props
  const { pledgeId } = useParams()
  const history = useHistory()

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleUserSelect = async userPayload => {
    const { id } = userPayload
    const isNewUser = !id

    if ( id === pledge.details.user.id ) {
      UI.showErrorDisclaimer( `${ userPayload.name } (${ userPayload.email }) is already the current owner of this pledge.` )
      return
    }

    if ( isNewUser ) {
      setDestination( {
        user: userPayload,
        isNew: true,
        currentPledge: null
      } )

      return
    }

    try {

      const fullUser = await sFetch( `/users/report/${ id }` )
      const currentPledge = find( fullUser.geeks, { projectId: pledge.details.project.id } )

      setDestination( {
        user: userPayload,
        isNew: false,
        currentPledge: currentPledge
      } )

    } catch ( exception ) {

      setDestination( {
        user: userPayload,
        isNew: false,
        currentPledge: null
      } )

    }

  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleTabChange = index => {
    resetDestination()
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSearchStart = index => {
    resetDestination()
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleTransfer = async event => {
    if ( !window.confirm( `REMEMBER: Are you sure you want to transfer this pledge?\nThis action can't be undone. ` ) ) return

    setTransfering( true )

    try {

      await transferPledge( pledgeId, { geek: { id: pledgeId, user: destination.user } } )
      history.push( `/dashboard/pledge/${ pledge.details.id }` )
      UI.showSuccessDisclaimer( 'Pledge successfully transfered.' )

    } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }

    setTransfering( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const resetDestination = index => {
    setDestination( {
      user: null,
      isNew: false,
      currentPledge: null
    } )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <section className={ `pd-pledge-transferer ${ className }` }>
      <UserPicker
        onSelect={ handleUserSelect }
        onTabChange={ handleTabChange }
        onSearchStart={ handleSearchStart }
        disabled={ isTransfering }
      />

      { destination.user ? (

        <div className="pd-pledge-transferer__confirmation pd-white-box">
          <div className="pd-pledge-transferer__confirmation-details">
            { destination.isNew ?
              (

                <p className="pd-text">You are creating a new user and transfering this pledge to him.</p>

              ) : (
                <>
                  <p className="pd-text">You are transfering this pledge to <strong>{ destination.user.name }</strong>.</p>

                  { destination.currentPledge ? (

                    <p className="pd-text"><strong>{ destination.user.name }</strong> already has a pledge, it will merge both pledges.</p>

                  ) : null }
                </>

              ) }
          </div>

          <ErrorTip>
            <div className="pd-pledge-transferer__confirmation-alert">
              <p className="pd-text">
                Are you sure you want to transfer this pledge? <br />
                This action can't be undone.
              </p>

              <button className="pd-button pd-button--danger" onClick={ handleTransfer } disabled={ isTransfering }>
                { isTransfering ? 'Transfering...' : 'Transfer pledge' }
              </button>
            </div>
          </ErrorTip>
        </div>

      ) : null }
    </section>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default PledgeTransferer