import React, { useState, useEffect } from 'react'
import { isEqual } from 'lodash'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const UIContext = React.createContext()

const disclaimerDuration = ( 13 * 1000 )
const disclaimerInterval = 200
let disclaimersHandler = null
let confirmationCallback = null

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const UIState = props => {

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  

  const [ newDisclaimer, setNewDisclaimer ] = useState( null )
  const [ disclaimers, setDisclaimers ] = useState( [] )
  const [ menuIsOpened, setMenuIsOpened ] = useState( false )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => {
    window.addEventListener( 'ontouchstart' in window ? 'touchstart' : 'click', event => setMenuIsOpened( false ) )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const showSuccessDisclaimer = ( message, delay = 0 ) => showDisclaimer( message, 'success', delay )
  const showErrorDisclaimer = ( message, delay = 0 ) => showDisclaimer( message, 'error', delay )
  const showWarningDisclaimer = ( message, delay = 0 ) => showDisclaimer( message, 'warning', delay )
  const showInfoDisclaimer = ( message, delay = 0 ) => showDisclaimer( message, 'info', delay )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const showDisclaimer = ( message, type, delay ) => {
    setTimeout( () => {
      setNewDisclaimer( {
        message: message,
        type: type,
        createdAt: Date.now()
      } )
    }, delay )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const hideDisclaimer = createdAt => {
    setDisclaimers( disclaimers.filter( item => {
      if ( createdAt === item.createdAt ) return null
      return item
    } ) )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const hideAllDisclaimers = () => {
    setDisclaimers( [] )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const checkDisclaimersDuration = () => {
    clearInterval( disclaimersHandler )

    disclaimersHandler = setInterval( () => {
      if ( disclaimers.length === 0 ) {
        clearInterval( disclaimersHandler )
        return
      }

      const filtered = disclaimers.filter( item => {
        const diff = Date.now() - item.createdAt
        if ( diff > disclaimerDuration ) return null
        return item
      } )

      if ( !isEqual( filtered, disclaimers ) ) setDisclaimers( filtered )
    }, disclaimerInterval )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleNewDisclaimer = () => {
    if ( !newDisclaimer ) return
    setDisclaimers( disclaimers.concat( [ newDisclaimer ] ) )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const setConfirmationCallback = callback => {
    confirmationCallback = callback
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const resolveConfirmationCallback = async response => {
    confirmationCallback( response )
    confirmationCallback = null
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )
  useEffect( checkDisclaimersDuration, [ disclaimers ] )
  useEffect( handleNewDisclaimer, [ newDisclaimer ] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <UIContext.Provider value={ {
      disclaimers: disclaimers,

      menuIsOpened: menuIsOpened,
      setMenuIsOpened: setMenuIsOpened,

      setConfirmationCallback: setConfirmationCallback,
      resolveConfirmationCallback: resolveConfirmationCallback,

      showSuccessDisclaimer: showSuccessDisclaimer,
      showWarningDisclaimer: showWarningDisclaimer,
      showErrorDisclaimer: showErrorDisclaimer,
      showInfoDisclaimer: showInfoDisclaimer,
      hideDisclaimer: hideDisclaimer,
      hideAllDisclaimers: hideAllDisclaimers
    } }>
      { props.children }
    </UIContext.Provider >

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  
}