import React, { useEffect, useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment-timezone'
import { kebabCase } from 'lodash'

import { DashboardContext } from '../../context/dashboard-state'

import { sFetch, eFetch, checkHeaders, getHeaders, parseText } from '../../config/fetch'
import { formatCurrency, getReadbleOrderState } from '../../config/adapters'

import BackerReportFilter from '../../modules/backer-report-filter/backer-report-filter'
import Table from '../../components/table/table'
import MetaPagination from '../../components/meta-pagination/meta-pagination'
import LoadingMessage from '../../components/loading-message/loading-message'
import { InfoTip } from '../../components/tip/tip'

import './backer-report.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const BackerReport = props => {

  const dashboardContext = useContext( DashboardContext )
  const { project } = dashboardContext
  const { projectId } = useParams()

  const [ filters, setFilters ] = useState( null )
  const [ orderBy, setOrderBy ] = useState( null )
  const [ page, setPage ] = useState( 1 )
  const [ geeks, setGeeks ] = useState( null )
  const [ meta, setMeta ] = useState( null )
  const [ isFetching, setFetching ] = useState( false )
  const [ isFetched, setFetched ] = useState( false )
  const [ isDownloading, setDownloading ] = useState( false )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const getReportUrl = ( csv = false ) => {
    const status = filters.status ? `&by_state=${ filters.status }` : ''
    const search = filters.search ? `&by_text=${ filters.search }` : ''
    const ksNumber = filters.ksNumber ? `&by_ks_pledge_number=${ filters.ksNumber }` : ''
    const bundle = filters.bundle ? `&by_bundle=${ filters.bundle }` : ''
    const addOn = filters.addOn ? `&by_add_on=${ filters.addOn }` : ''
    const order = orderBy ? `&order_by=${ orderBy }` : ''
    return `/geeks/report${ csv ? '.csv' : '' }?project_id=${ projectId }&page=${ page }&per_page=50${ search }${ ksNumber }${ order }${ status }${ bundle }${ addOn }`
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const getNormalizedRows = rows => {
    return rows.map( row => {

      const pledgeLink = `/dashboard/pledge/${ row.id }`

      const idCol = {
        value: row.id,
        link: pledgeLink
      }

      const ksCol = {
        value: <span className="pd-ellipsis">{ row.ksPledgeNumber || '-' }</span>,
        link: pledgeLink
      }

      const stateCol = {
        value: <span className="pd-ellipsis">{ getReadbleOrderState( row.state ) }</span>,
        link: pledgeLink
      }

      const totalCol = {
        value: formatCurrency( row.total ),
        link: pledgeLink
      }

      const baseBundleCol = {
        value: <span className="pd-ellipsis">{ row.baseBundle ? row.baseBundle.name : '-' }</span>,
        link: pledgeLink
      }

      const userCol = {
        value: <span className="pd-ellipsis">{ `${ row.user.name } (${ row.user.email })` }</span>,
        link: pledgeLink
      }

      return [ idCol, ksCol, stateCol, totalCol, userCol, baseBundleCol ]
    } )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handlePageChange = () => {
    if ( isFetching || !filters ) return

    setFetching( true )
    setFetched( false )

    const doFetch = async () => {
      const report = await sFetch( getReportUrl() )
      setGeeks( report.geeks )
      setMeta( report.meta )
      setFetching( false )
      setFetched( true )
    }

    doFetch()
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleDownloadClick = async event => {
    setDownloading( true )
    const report = await eFetch( getReportUrl( true ), { headers: getHeaders() } ).then( checkHeaders ).then( parseText )
    const data = new Blob( [ report ], { type: 'text/csv' } )
    const textFile = window.URL.createObjectURL( data )

    let link = document.createElement( 'a' )
    link.href = textFile
    link.download = moment().format( `[${ kebabCase( project.details.name ) }]-YYYYMMDD-[report]` )
    link.target = '_blank'
    link.click()

    setDownloading( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleFilterChange = payload => {
    setFilters( payload )
    setPage( 1 )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleColumClick = column => setOrderBy( column.key )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handlePaginationChange = page => setPage( page )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )
  useEffect( handlePageChange, [ page, filters, orderBy ] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <section className="pd-backer-report">
      { project.isFetched &&
        (

          <>
            <nav className="pd-backer-report__nav">
              <BackerReportFilter project={ project.details } onChange={ handleFilterChange } className="_pd-backer-report__filters" />

              <button
                disabled={ isDownloading || !isFetched || geeks.length <= 0 }
                className="pd-button pd-button--navy"
                onClick={ handleDownloadClick }>
                { isDownloading ? 'Processing...' : 'Download report' }
              </button>
            </nav>

            { isFetched ?
              (

                geeks && geeks.length > 0 ?
                  (

                    <>
                      <Table
                        className="pd-backer-report__table pd-table--x-scroll"
                        cols={ [
                          { label: 'Pledge #', key: 'id', onClick: handleColumClick },
                          { label: 'KS #', key: 'ks_pledge_number', onClick: handleColumClick },
                          { label: 'Status', key: 'state', onClick: handleColumClick },
                          { label: 'Order total', key: 'total', onClick: handleColumClick },
                          'Backer',
                          'Base pledge'
                        ] }
                        rows={ getNormalizedRows( geeks ) }
                      />

                      { meta && ( <MetaPagination meta={ meta } onChange={ handlePaginationChange } /> ) }
                    </>

                  ) : <InfoTip>No results found.</InfoTip>

              ) : <LoadingMessage />
            }
          </>

        ) }
    </section>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default BackerReport