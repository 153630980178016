import React, { useEffect, useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { find } from 'lodash'

import { NOT_EMPTY_REGEX, PHONE_REGEX, ACCEPT_ONLY_PHONE_REGEX } from '../../config/validators'

import CustomField from '../../components/custom-field/custom-field'

import './shipping-form.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const ShippingForm = props => {

  const {
    className = '',
    initialValues = {},
    countries = [],
    provinces = [],
    pledgeIsMultiwave = false,
    waves = 0,
    onCountryChange,
    onSubmit,
    onChange = null,
    skipValidation = false
  } = props
  const [ isTaxpayerIdRequired, setTaxpayerIdRequired ] = useState( false )
  const [ taxpayerIdLabel, setTaxpayerIdLabel ] = useState( null )
  const [ disableSecondaryFields, setDisableSecondaryFields ] = useState( true )
  const [ disableProvincesField, setDisableProvincesField ] = useState( false )
  const [ hasAlternateCharsetAddress, setAlternateCharsetAddress ] = useState( false )


  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => {
    handleCountryRequirements( initialValues.countryId )
    setDisableSecondaryFields( !initialValues.countryId )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleValidation = values => {
    let errors = {}

    if ( !NOT_EMPTY_REGEX.test( values.name ) ) errors.name = 'Invalid name'
    if ( !PHONE_REGEX.test( values.phone ) || values.phone.length < 6 ) errors.phone = 'Invalid phone number'
    if ( isTaxpayerIdRequired && !NOT_EMPTY_REGEX.test( values.taxpayerId ) ) errors.taxpayerId = `Invalid ${ taxpayerIdLabel || 'taxpayer ID' }`
    if ( !NOT_EMPTY_REGEX.test( values.addressLine1 ) ) errors.addressLine1 = 'Invalid address line'
    if ( !NOT_EMPTY_REGEX.test( values.postalCode ) ) errors.postalCode = 'Invalid postal code'
    if ( !NOT_EMPTY_REGEX.test( values.city ) ) errors.city = 'Invalid city'
    if ( !NOT_EMPTY_REGEX.test( values.stateId ) ) errors.stateId = 'Select a state'
    if ( !NOT_EMPTY_REGEX.test( values.countryId ) ) errors.countryId = 'Select a country'

    const countryShipmentStatus = validateCountryShipment( values.countryId )
    if ( countryShipmentStatus ) errors.countryId = countryShipmentStatus

    setDisableSecondaryFields( !!countryShipmentStatus )

    return errors
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleCountryRequirements = countryId => {
    const selectedCountry = find( countries, { id: parseInt( countryId ) } )

    if ( selectedCountry ) {

      setAlternateCharsetAddress( selectedCountry.acceptsAlternateCharsetAddress )
      setTaxpayerIdRequired( !!selectedCountry.requiresTaxpayerId )
      setTaxpayerIdLabel( selectedCountry.taxpayerIdName )

    } else {
      setAlternateCharsetAddress( false )
      setTaxpayerIdRequired( false )
      setTaxpayerIdLabel( null )

    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const validateCountryShipment = countryId => {
    const country = find( countries, { id: parseInt( countryId ) } )
    if ( country && !country.multiwaveShipping && pledgeIsMultiwave ) return `${ waves }-Wave Expedited Shipping not available for this country.`
    return false
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleCountryChange = ( fProps, event ) => {
    fProps.setFieldValue( 'stateId', '' )
    handleCountryRequirements( event.target.value )
    onCountryChange( event )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )
  useEffect( () => {
    setDisableProvincesField( !provinces.length )
  }, [ provinces ] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <Formik
      initialValues={ initialValues }
      onSubmit={ onSubmit }
      validate={ skipValidation ? null : handleValidation }
      validateOnMount={ true }
      initialTouched={ {
        countryId: true
      } }
      enableReinitialize={ true }>
      { fProps => (

        <Form className={ `pd-shipping-form pd-white-box ${ className }` } disabled={ fProps.isSubmitting || disableProvincesField } onChange={ onChange }>
          <Field type="hidden" name="id" />

          <CustomField name="countryId" label="Country" className="pd-shipping-form__country" component="select" onChange={ handleCountryChange.bind( this, fProps ) } required>
            <option value="">Please select</option>
            { countries.map( ( country, index ) => <option key={ index } value={ country.id }>{ country.name }</option> ) }
          </CustomField>

          <fieldset className="pd-shipping-form__fieldset" disabled={ disableSecondaryFields }>
            <div className="pd-shipping-form__secondary">
              <CustomField name="name" label="Recipient name" className="pd-shipping-form__name" required />
              <CustomField name="phone" label="Phone" className="pd-shipping-form__phone" filter={ ACCEPT_ONLY_PHONE_REGEX } required />
              <CustomField name="taxpayerId" label={ `Taxpayer ID ${ taxpayerIdLabel ? `(${ taxpayerIdLabel })` : '' }` } className="pd-shipping-form__taxpayer-id" required={ isTaxpayerIdRequired } />
              <CustomField name="addressLine1" label="Address line 1" className="pd-shipping-form__address-line-1" required />
              <CustomField name="addressLine2" label="Address line 2" className="pd-shipping-form__address-line-2" />
              <CustomField name="postalCode" label="Postal code" className="pd-shipping-form__postal-code" required />
              <CustomField name="complement" label="Address Complement" complement="(Door code, apartment #, etc)" className="pd-shipping-form__complement" />

              <CustomField name="stateId" label="State/Province" className="pd-shipping-form__province" component="select" disabled={ provinces.length === 0 } required>
                <option value="">Please select</option>
                { provinces.map( ( province, index ) => <option key={ index } value={ province.id }>{ province.name }</option> ) }
              </CustomField>

              <CustomField name="city" label="City" className="pd-shipping-form__city" required />

              { hasAlternateCharsetAddress && ( <CustomField name="alternateCharset" label="Local language address" className="pd-shipping-form__alternate" /> ) }
            </div>
          </fieldset>

          <p className="pd-shipping-form__po-box pd-text-xs pd-text--tip">Please note that we are currently not able to ship to P.O. Boxes. If you have a P.O. Box, we recommend selecting an alternate address for a family member, a work address, or maybe a trusted friend.</p>

          <button
            className="pd-button pd-button--navy pd-shipping-form__submit"
            type="submit"
            disabled={ fProps.isSubmitting }>
            { fProps.isSubmitting ? 'Processing...' : 'Continue' }
          </button>

          <span className="pd-shipping-form__required pd-text-sm pd-text--tip"><span className="pd-text--info">*</span> Required fields</span>
        </Form>

      ) }
    </Formik>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default ShippingForm