import React, { useEffect } from 'react'
import { PortalWithState } from 'react-portal'

import CheckoutProductContents from '../../modules/checkout-product-contents/checkout-product-contents'
import { WarningTip, ErrorTip, InfoTip } from '../../components/tip/tip'
import Lightbox from '../../components/lightbox/lightbox'

import { getImageSrc, formatCurrency } from '../../config/adapters'

import { ReactComponent as IconBasket } from '../../assets/icons/basket.svg'

import './checkout-product.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const CheckoutProduct = props => {

  const {
    className = '',
    item = {},
    showWaveInfo = false,
    children = null
  } = props

  const unavailablePlaceholder = 'https://via.placeholder.com/640x360?text=UNAVAILABLE+IMAGE'

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleImageLoadError = event => event.target.src = unavailablePlaceholder

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <div className={ `pd-checkout-product ${ className } notranslate` }>
      <PortalWithState>
        { portalBag => (
          <>
            <figure className="pd-checkout-product__figure">
              <img
                className="pd-checkout-product__image"
                src={ getImageSrc( item.image, { w: 640, h: 360, resizeTo: 'fill', quality: 60 } ) || '' }
                alt={ item.name }
                onError={ handleImageLoadError }
                onClick={ portalBag.openPortal }
              />
            </figure>

            <div className="pd-checkout-product__wrapper">
              <h4 className="pd-title-4">{ item.name }</h4>
              <p className="pd-title-4">{ formatCurrency( item.price ) }</p>
              <p className="pd-text">{ item.description }</p>

              <button
                onClick={ portalBag.openPortal }
                className="pd-button pd-button--text-sm pd-button--navy pd-button--icon">
                <IconBasket className="pd-icon" /> What's included?
              </button>

              { item.requiredBundles ? <InfoTip micro={ true }>Requires: <strong>{ item.requiredBundles.map( _item => <>{ item.requiredBundles.length > 1 ? <br /> : null }{ _item.name }</> ) }</strong></InfoTip> : null }
              { item.limitedByBasePledge ? <InfoTip micro={ true }>Requires a base pledge</InfoTip> : null }
              { item.limitPerBasePledge ? <InfoTip micro={ true }>Limit per base pledge: <strong>{ item.limitPerBasePledge }</strong></InfoTip> : null }
              { item.limitPerUser ? <InfoTip micro={ true }>Limit per user: <strong>{ item.limitPerUser }</strong></InfoTip> : null }
              { item.limitPerBundle ? <InfoTip micro={ true }>Limit per pledge: <strong>{ item.limitPerBundle }</strong></InfoTip> : null }
              {/* { item.isLimitedStock ? ( <InfoTip micro={ true }>Limited stock</InfoTip> ) : null } */ }
              { item.isLowStock ? ( <WarningTip micro={ true }>Low stock</WarningTip> ) : null }
              { item.isSoldOut ? ( <ErrorTip micro={ true }>Sold out</ErrorTip> ) : null }
              {/* { item.isSoldOut && isOriginal && !isSelected ? ( <WarningTip micro={ true }>#TODO: Sold out item on pledge copy</WarningTip> ) : null } */ }
              { item.isLockedToOrder ? ( <WarningTip micro={ true }>Wave locked</WarningTip> ) : null }

              { children }
            </div>

            { portalBag.portal(
              <Lightbox portalBag={ portalBag }>
                <CheckoutProductContents product={ item } showWaveInfo={ showWaveInfo } />
              </Lightbox>
            ) }
          </>
        ) }
      </PortalWithState>
    </div>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default CheckoutProduct