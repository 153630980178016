import React, { useEffect, useContext } from 'react'

import { DashboardContext } from '../../context/dashboard-state'

import ActivityTable from '../../modules/activity-table/activity-table'

import './audits.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const Audits = props => {

  const dashboardContext = useContext( DashboardContext )
  const { audits } = dashboardContext.pledge.details

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <section className="pd-audits">
      <ActivityTable rows={ audits } />
    </section>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default Audits