import React, { useEffect, useContext, useState } from 'react'
import { Form, Formik } from 'formik'

import { GlobalContext } from '../../context/global-state'
import { UIContext } from '../../context/ui-state'
import { EMAIL_REGEX, NOT_EMPTY_REGEX, SOFT_PASSWORD_REGEX } from '../../config/validators'
import { sFetch } from '../../config/fetch'
import { toSnakeCase } from '../../config/adapters'

import CustomField from '../../components/custom-field/custom-field'
import { ReactComponent as IconLock } from '../../assets/icons/lock.svg'

import './account-security.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const AccountSecurity = props => {

  const context = useContext( GlobalContext )
  const UI = useContext( UIContext )
  const { loggedUser, updateUser } = context
  const { className = '' } = props
  const [ isUpdating, setUpdating ] = useState( false )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSave = async ( payload, actions ) => {
    try {

      setUpdating( true )

      await sFetch( '/auth/password', { method: 'put', body: JSON.stringify( toSnakeCase( payload ) ) } )

      actions.resetForm()
      actions.setSubmitting( false )
      UI.showSuccessDisclaimer( 'You have updated your password successfully!' )
      setUpdating( false )

    } catch ( exception ) {

      UI.showErrorDisclaimer( exception.message )

    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleValidation = values => {
    let errors = {}

    if ( !NOT_EMPTY_REGEX.test( values.currentPassword ) ) errors.currentPassword = 'Please inform your current password.'
    if ( !SOFT_PASSWORD_REGEX.test( values.password ) ) errors.password = 'Please inform a valid password. Password must be at least 8 characters length, including letters, digits and special characters.'
    if ( values.password !== values.passwordConfirmation ) errors.passwordConfirmation = 'Password doesn\'t match.'

    return errors
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <section className={ `pd-account-security pd-account__section pd-white-box pd-white-box--no-padding pd-text ${ className }` }>
      <header className="pd-account__header">
        <IconLock className="pd-icon" />
        <h3 className="pd-title-3">Account Security</h3>
      </header>

      <div className="pd-account__container">
        <Formik
          initialValues={ { currentPassword: '', password: '', passwordConfirmation: '' } }
          onSubmit={ handleSave }
          validate={ handleValidation }
          validateOnChange={ false }
          validateOnBlur={ false }>
          { fProps => (

            <Form className="pd-account-security__form">
              <CustomField name="currentPassword" type="password" label="Current Password" disabled={ isUpdating } className="pd-account-security__current-password-field" required />
              <CustomField name="password" type="password" label="New Password" disabled={ isUpdating } required />
              <CustomField name="passwordConfirmation" type="password" label="New Password confirmation" disabled={ isUpdating } required />

              <nav className="pd-account-security__nav">
                <button
                  type="submit"
                  className="pd-button pd-button--navy"
                  disabled={ fProps.isSubmitting || isUpdating }>
                  { fProps.isSubmitting || isUpdating ? 'Updating...' : 'Update' }
                </button>

                <span className="pd-text-sm pd-account-security__req">
                  <span>*</span> required fields
                </span>
              </nav>
            </Form>

          ) }
        </Formik>
      </div>
    </section>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default AccountSecurity