import React, { useState, useContext, useEffect, createRef } from 'react'
import { PortalWithState } from 'react-portal'
import { Link, useParams } from 'react-router-dom'
import { Formik, Form } from 'formik'

import { DashboardContext } from '../../context/dashboard-state'
import { ShippingTableState, ShippingTableContext } from '../../context/shipping-table-state'
import { UIContext } from '../../context/ui-state'

import { PLEDGE_MANAGER_STATE } from '../../config/constants'

import ShippingCostSimulator from '../../modules/shipping-cost-simulator/shipping-cost-simulator'
import NewShippingTableForm from '../../modules/new-shipping-table-form/new-shipping-table-form'
import LoadingMessage from '../../components/loading-message/loading-message'
import CustomField from '../../components/custom-field/custom-field'
import Lightbox from '../../components/lightbox/lightbox'

import './shipping-tables.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

let lightboxRef

const ShippingTables = props => {

  const dashboardContext = useContext( DashboardContext )
  const shippingTableContext = useContext( ShippingTableContext )
  const UI = useContext( UIContext )
  const { project, updateProject } = dashboardContext
  const { fetchTables, createNewTable, tables } = shippingTableContext
  const [ currentTable, setCurrentTable ] = useState( null )
  const [ currentLateConfirmTable, setCurrentLateConfirmTable ] = useState( null )
  const { projectId } = useParams()

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => {
    fetchTables()
    lightboxRef = createRef()
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleProjectLoad = () => {
    const { isFetched, isUpdating, details } = project
    const { shippingTableId, lateConfirmShippingTableId } = details

    if ( isFetched && !isUpdating ) {
      setCurrentTable( shippingTableId || '' )
      setCurrentLateConfirmTable( lateConfirmShippingTableId || '' )
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleNewTableSave = async payload => {
    try {

      lightboxRef.current.lock()

      await createNewTable( payload )

      UI.showSuccessDisclaimer( 'New table created successfully!' )
      lightboxRef.current.close()

    } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleCurrentTableSubmit = async ( payload, actions ) => {
    try {
      await updateProject( payload )

      actions.setSubmitting( false )
      UI.showSuccessDisclaimer( 'Your project was saved successfully!' )

    } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const preventEditLoss = () => {
    lightboxRef.current.askBeforeClose( true )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )
  useEffect( handleProjectLoad, [ project ] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <section className="pd-shipping-tables">
      { project.isFetched ? (
        <>
          <PortalWithState>
            { portalBag => (
              <>
                <button className="pd-button pd-button--cyan pd-shipping-tables__new-cta" onClick={ portalBag.openPortal }>
                  Create new table
                </button>

                { portalBag.portal(
                  <Lightbox portalBag={ portalBag } ref={ lightboxRef }>
                    <NewShippingTableForm
                      onChange={ preventEditLoss }
                      onSave={ handleNewTableSave }
                    />
                  </Lightbox>
                ) }
              </>
            ) }
          </PortalWithState>



          <Formik
            initialValues={ { shippingTableId: currentTable || '' } }
            enableReinitialize={ true }
            onSubmit={ handleCurrentTableSubmit }
            validateOnBlur={ false }>
            { fProps => (

              <Form className={ `pd-shipping-tables__form pd-shipping-tables__regular-form pd-white-box` }>
                <label className="pd-label">Shipping table</label>

                <CustomField name="shippingTableId" component="select" onChange={ event => setCurrentTable( event.target.value ) }>
                  <option value="">Select one</option>
                  { tables.map( ( table, index ) => <option key={ index } value={ table.id }>{ table.name }</option> ) }
                </CustomField>

                <button className="pd-button pd-button--navy" type="submit" disabled={ fProps.isSubmitting }>
                  { fProps.isSubmitting ? 'Processing...' : 'Associate' }
                </button>

                <Link disabled={ !currentTable || fProps.isSubmitting } className="pd-button pd-button--cyan" to={ `/shipping-table-editor/${ projectId }/${ currentTable }` }>Edit</Link>
              </Form>

            ) }
          </Formik>

          <Formik
            initialValues={ { lateConfirmShippingTableId: currentLateConfirmTable || '' } }
            enableReinitialize={ true }
            onSubmit={ handleCurrentTableSubmit }
            validateOnBlur={ false }>
            { fProps => (

              <Form className={ `pd-shipping-tables__form pd-shipping-tables__late-confirm-form pd-white-box` }>
                <label className="pd-label">Late confirm shipping table</label>
                <CustomField name="lateConfirmShippingTableId" component="select" onChange={ event => setCurrentLateConfirmTable( event.target.value ) }>
                  <option value="">Select one</option>
                  { tables.map( ( table, index ) => <option key={ index } value={ table.id }>{ table.name }</option> ) }
                </CustomField>

                <button className="pd-button pd-button--navy" type="submit" disabled={ fProps.isSubmitting }>
                  { fProps.isSubmitting ? 'Processing...' : 'Associate' }
                </button>

                <Link disabled={ !currentLateConfirmTable || fProps.isSubmitting } className="pd-button pd-button--cyan" to={ `/shipping-table-editor/${ projectId }/${ currentLateConfirmTable }` }>Edit</Link>
              </Form>

            ) }
          </Formik>

          <ShippingCostSimulator
            className="pd-shipping-tables__simulator"
            tables={ tables }
            currentTable={ project.details.pledgeManagerState === PLEDGE_MANAGER_STATE.LATE_CONFIRMS ? currentLateConfirmTable : currentTable }
          />
        </>
      ) : <LoadingMessage /> }

    </section>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

const ShippingTablesWrapper = props => (
  <ShippingTableState>
    <ShippingTables { ...props } />
  </ShippingTableState>
)

export default ShippingTablesWrapper