import React, { useState, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { DashboardContext } from '../../context/dashboard-state'
import { UIContext } from '../../context/ui-state'

import { WarningTip, ErrorTip } from '../../components/tip/tip'

import './initial-invite-checker.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const InitialInviteChecker = props => {

  const { className = '', project } = props
  const UI = useContext( UIContext )
  const dashboardContext = useContext( DashboardContext )
  const { sendInitialInvite } = dashboardContext
  const [ shouldRender, setShouldRender ] = useState( false )
  const [ shouldRenderAction, setShouldRenderAction ] = useState( false )
  const [ shouldRenderDisclaimer, setShouldRenderDisclaimer ] = useState( false )
  const [ isProcessing, setProcessing ] = useState( false )
  const { projectId } = useParams()

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleProjectLoad = () => {
    const { isFetched, details } = project
    const { initialInvitesSent, pledgeManagerState } = details

    if ( isFetched ) {
      const isOpened = pledgeManagerState === 'opened'
      const isDraft = pledgeManagerState === 'draft'
      setShouldRender( !initialInvitesSent && ( isOpened || isDraft ) )
      setShouldRenderAction( isOpened )
      setShouldRenderDisclaimer( isDraft )
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleClick = async event => {
    if ( !window.confirm( 'Are you sure?' ) ) return

    setProcessing( true )

    try {

      await sendInitialInvite( projectId )
      UI.showSuccessDisclaimer( 'Initial invites sent successfully!' )

    } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }

    setProcessing( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )
  useEffect( handleProjectLoad, [ project ] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return shouldRender ? (

    <div className={ `pd-initial-invite-checker ${ className }` }>
      { shouldRenderAction ? (

        <WarningTip>
          <div className="pd-initial-invite-checker__wrapper">
            <div>
              <h3 className="pd-title-4">Initial invites not sent.</h3>
              <p>This action will send the invite to all backers of this campaign (KS backers as well as Late Pledgers)
              and will move the pledge manager to its next phase, making all pledges and add-ons available for checkout
              as well as allowing users to finalize their orders by entering their shipping info and paying for their
                shipping costs.</p>
            </div>
            <button className="pd-button pd-button--warning" onClick={ handleClick } disabled={ isProcessing }>
              { isProcessing ? 'Sending...' : 'Send initial invite now' }
            </button>
          </div>
        </WarningTip>

      ) : null }

      { shouldRenderDisclaimer ? (

        <ErrorTip>
          <div className="pd-initial-invite-checker__wrapper">
            <h3 className="pd-title-4">Initial invites not sent.</h3>
            <p>This project is still in draft mode. It must first be published into Late Pledge mode before invites can be sent.</p>
          </div>
        </ErrorTip>

      ) : null }
    </div>

  ) : null

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default InitialInviteChecker