import React, { useEffect, useContext } from 'react'
import { isNull } from 'lodash'

import { CheckoutContext } from '../../context/checkout-state'
import { UIContext } from '../../context/ui-state'

import { getRichTextContent } from '../../config/adapters'

import './checkout-shipping-strategy-selector.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const CheckoutShippingStrategySelector = props => {

  const UI = useContext( UIContext )
  const checkoutContext = useContext( CheckoutContext )
  const { pledge, project } = checkoutContext
  const { isMultiwave } = pledge
  const { waves, multiwaveDescription, singleWaveDescription } = project
  const { className = '' } = props
  const isFirstTime = isNull( isMultiwave )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <div className={ `pd-checkout-shipping-strategy-selector pd-white-box pd-scroll-panel ${ className }` }>
      <header className="pd-checkout-shipping-strategy-selector__header">
        <h4 className="pd-title-3">Shipping options</h4>
        <p className="pd-text">Please select your shipping option. You’ll be able to change your shipping option until the Expedited Shipping deadline. After that, only the Single Wave shipment will be available.</p>
      </header>

      <ul className="pd-checkout-shipping-strategy-selector__options">
        <li className="pd-checkout-shipping-strategy-selector__option">
          <h4 className="pd-title-4">Single-Wave Shipping</h4>
          <p className="pd-text" dangerouslySetInnerHTML={ getRichTextContent( singleWaveDescription ) }></p>
          <button className="pd-button pd-button--navy" onClick={ event => { UI.resolveConfirmationCallback( false ) } }>
            { isFirstTime ? 'Select Single-Wave Shipping' : ( isMultiwave ? 'Change to Single-Wave Shipping' : 'Keep Single-Wave Shipping' ) }
          </button>
        </li>
        <li className="pd-checkout-shipping-strategy-selector__option">
          <h4 className="pd-title-4">{ waves }-Wave Expedited Shipping</h4>
          <p className="pd-text" dangerouslySetInnerHTML={ getRichTextContent( multiwaveDescription ) }></p>
          <button className="pd-button pd-button--navy" onClick={ event => { UI.resolveConfirmationCallback( true ) } }>
            { isFirstTime ? `Select Expedited Shipping` : ( isMultiwave ? `Keep Expedited Shipping` : `Change to Expedited Shipping` ) }
          </button>
        </li>
      </ul>
    </div>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default CheckoutShippingStrategySelector