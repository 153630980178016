import React, { useContext, useState } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'

import { UIContext } from '../../context/ui-state'
import { isEmpty, isSoftPassword } from '../../config/validators'
import { sFetch } from '../../config/fetch'
import { toSnakeCase } from '../../config/adapters'
import './password-form.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const PasswordForm = props => {

  const UI = useContext( UIContext )
  const [ isUpdating, setUpdating ] = useState( false )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSave = async ( payload, actions ) => {
    setUpdating( true )

    try {

      await sFetch( '/auth/password', { method: 'put', body: JSON.stringify( toSnakeCase( payload ) ) } )
      actions.resetForm()
      UI.showSuccessDisclaimer( 'You have updated your password successfully!' )

    } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }

    setUpdating( false )
    actions.setSubmitting( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <section className={ `pd-password-form pd-profile-section` }>
      <header className="pd-profile-section__header">
        <h4 className="pd-title-3">Change password</h4>
        <p className="pd-text">Provide your password and its confirmation below to change it.</p>
      </header>
      <div className="pd-profile-section__content">
        <Formik
          initialValues={ { currentPassword: '', password: '', passwordConfirmation: '' } }
          onSubmit={ handleSave }
          validateOnChange={ false }
          validateOnBlur={ false }>
          { fProps => (

            <Form className={ `pd-password-form__main` }>
              <div className="pd-field">
                <label className="pd-label">Current Password</label>
                <Field
                  disabled={ isUpdating }
                  className="pd-textfield"
                  type="password"
                  name="currentPassword"
                  validate={ isEmpty.bind( this, 'Please inform your current password.' ) }
                />
                <ErrorMessage name="currentPassword" component="span" className="pd-text-sm pd-text--error" />
              </div>

              <div className="pd-field">
                <label className="pd-label">Password</label>
                <Field
                  disabled={ isUpdating }
                  className="pd-textfield"
                  type="password"
                  name="password"
                  validate={ isSoftPassword.bind( this, 'Please inform a valid password. Password must be at least 8 characters length, including letters, digits and special characters.' ) }
                />
                <ErrorMessage name="password" component="span" className="pd-text-sm pd-text--error" />
              </div>

              <div className="pd-field">
                <label className="pd-label">Confirm your password</label>
                <Field
                  disabled={ isUpdating }
                  className="pd-textfield"
                  type="password"
                  name="passwordConfirmation"
                  validate={ value => value !== fProps.values.password ? 'Password doesn\'t match.' : null }
                />
                <ErrorMessage name="passwordConfirmation" component="span" className="pd-text-sm pd-text--error" />
              </div>

              <button
                type="submit"
                className="pd-button pd-button--navy"
                disabled={ fProps.isSubmitting || isUpdating }>
                { fProps.isSubmitting || isUpdating ? 'Processing...' : 'Update' }
              </button>
            </Form>

          ) }
        </Formik>
      </div>
    </section>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default PasswordForm