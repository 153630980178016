import React, { useEffect, useContext, useState } from 'react'
import { PortalWithState } from 'react-portal'
import { Prompt } from 'react-router-dom'
import { round } from 'lodash'

import Env from '../../config/env'

import { CheckoutContext } from '../../context/checkout-state'
import { UIContext } from '../../context/ui-state'

import { getRichTextContent } from '../../config/adapters'

import { InfoTip } from '../../components/tip/tip'
import Lightbox from '../../components/lightbox/lightbox'
import LoadingMessage from '../../components/loading-message/loading-message'

import imgCards from '../../assets/images/accepted-cards.png'

import './checkout-payment-options.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

let stripeHandler

const CheckoutPaymentOptions = props => {

  const { className = '' } = props

  const UI = useContext( UIContext )
  const checkoutContext = useContext( CheckoutContext )
  const {
    project,
    pledge,
    showcase,
    summary,
    savePledgePayment,
    lockShowcase,
    STEP
  } = checkoutContext

  const [ termsAccepted, setTermsAccepted ] = useState( false )
  const [ isProcessing, setProcessing ] = useState( false )
  const [ hasToken, setHasToken ] = useState( false )
  const [ isClosed, setClosed ] = useState( false )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const preparePaymentState = () => {
    UI.hideAllDisclaimers()
    lockShowcase( true )
    setProcessing( true )
    setHasToken( false )
    setClosed( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const setupPaypalButton = () => {
    const paypal = window.paypal
    if ( !paypal ) return

    const id = '#paypal-button-container'
    const el = document.querySelector( id )
    if ( el ) el.innerHTML = ''

    paypal.Buttons( {
      style: {
        layout: 'horizontal',
        color: 'black',
        shape: 'rect',
        label: 'pay',
        tagline: false,
        height: 30
      },
      createOrder: ( data, actions ) => {
        preparePaymentState()
        return actions.order.create( {
          application_context: {
            shipping_preference: 'NO_SHIPPING'
          },
          purchase_units: [ {
            amount: {
              value: parseFloat( project.retailer ? project.depositFee : summary.balance ).toFixed( 2 )
            },
            description: `Pledge confirmation for ${ project.name } by ${ project.company.name }`
          } ]
        } )
      },
      onApprove: async ( data, actions ) => {
        const authorization = await actions.order.authorize()
        const authorizationID = authorization.purchase_units[ 0 ].payments.authorizations[ 0 ].id
        handlePledgeSave( { gateway: 'paypal', orderId: data.orderID, authorizationID: authorizationID } )
      },
      onCancel: handlePaypalRejection,
      onError: handlePaypalRejection
    } ).render( id )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handlePaypalRejection = () => {
    setProcessing( false )
    lockShowcase( false )
    setHasToken( false )
    setClosed( true )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const setupStripeDialog = async () => {
    return new Promise( ( resolve, reject ) => {

      stripeHandler = window.StripeCheckout.configure( {
        name: 'Pledg.it',
        description: project.name,
        key: Env.stripePk,
        image: 'https://stripe.com/img/documentation/checkout/marketplace.png',
        locale: 'auto',
        token: token => {
          handlePledgeSave( { gateway: 'stripe', token: token.id } )
        },
        closed: () => {
          setClosed( true )
        }
      } )

      resolve()
    } )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleStripeButtonClick = async event => {
    preparePaymentState()
    await setupStripeDialog()

    stripeHandler.open( {
      amount: round( ( project.retailer ? project.depositFee : summary.balance ) * 100, 2 )
    } )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleStripeClose = () => {
    if ( isClosed ) {
      setProcessing( hasToken )
      lockShowcase( hasToken )
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handlePaypalSetup = () => {
    if ( showcase.step === STEP.PAYMENT ) setupPaypalButton()
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handlePledgeSave = async paymentInfo => {
    try {

      setHasToken( true )
      await savePledgePayment( paymentInfo )

    } catch ( exception ) {

      setHasToken( false )
      UI.showErrorDisclaimer( exception.message )

    }

    setProcessing( false )
    setClosed( true )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )
  useEffect( handleStripeClose, [ hasToken, isClosed ] )
  useEffect( handlePaypalSetup, [ showcase.step, summary.balance ] )
  useEffect( () => {

    if ( isProcessing && !summary.hasBalanceDue ) {
      setProcessing( false )
    }

  }, [ isProcessing, pledge.isSaving, summary.hasBalanceDue ] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <div className={ `pd-checkout-payment-options pd-checkout-module ${ termsAccepted ? '' : 'pd-checkout-payment-options--disabled' } ${ className } ` }>
      <Prompt when={ isProcessing || pledge.isSaving } message={ location => 'Please choose cancel to return to the page while your payment processing, navigating away may make your payment fail to be processed correctly.' } />

      <header className="pd-checkout-module__header">
        <h3 className="pd-title-3">{ project.isLatePledge ? '02' : ( showcase.hasBasePledges && showcase.hasOptionalBuys ? '04' : '03' ) }. Payment</h3>
        <p className="pd-text">Please choose your preferred gateway for payment.</p>
      </header>

      {/* { showcase.hasLimitedStockItemsSelected ? (

        <InfoTip className="pd-checkout-payment-options__limited-disclaimer">
          You have limited stock items in your order. These items are only reserved for 10 minutes,
          you must pay and confirm your order to guarantee said items.
        </InfoTip>

      ) : null } */}

      <div className="pd-checkout-payment-options__wrapper">
        <div className="pd-checkout-payment-options__terms pd-field pd-checkbox-field">
          <input type="checkbox" className="pd-checkbox" id="termsAndConditions" onChange={ event => setTermsAccepted( !termsAccepted ) } />
          <PortalWithState>
            { portalBag => (
              <label htmlFor="termsAndConditions" className="pd-checkout-payment-options__terms-label pd-text-sm">
                I acknowledge that I have read and accepted the <button
                  className="pd-button pd-button--text-sm pd-button--text-inline pd-button--navy"
                  onClick={ portalBag.openPortal }>
                  terms and conditions.
                  </button>

                { portalBag.portal(
                  <Lightbox portalBag={ portalBag }>
                    <div
                      className="pd-checkout-payment-options__lightbox-terms pd-scroll-panel"
                      dangerouslySetInnerHTML={ getRichTextContent( project.isLatePledge ? project.latePledgeTerms : project.termsAndConditions ) } >
                    </div>
                  </Lightbox>
                ) }
              </label>
            ) }
          </PortalWithState>
        </div>

        <div className={ `
          pd-checkout-payment-options__method
          pd-checkout-payment-options__method--stripe
          ${ Env.disableStripePaymentMethod ? 'pd-checkout-payment-options__method--disabled' : '' }
        `}>
          <h4 className="pd-title-4">Pay with Stripe</h4>
          <p className="pd-text">Open Stripe's interface for payment.</p>
          <button className="pd-button pd-button--navy" onClick={ handleStripeButtonClick } >Pay with Stripe</button>
        </div>

        <span className="pd-checkout-payment-options__separator">
          <span className="pd-text-sm">or</span>
        </span>

        <div className={ `
          pd-checkout-payment-options__method
          pd-checkout-payment-options__method--paypal
          ${ Env.disablePaypalPaymentMethod ? 'pd-checkout-payment-options__method--disabled' : '' }
        `}>
          <h4 className="pd-title-4">Pay with Paypal</h4>
          <p className="pd-text">Open Paypal's interface for payment.</p>
          <div id="paypal-button-container"></div>
        </div>

        <figure className="pd-checkout-payment-options__cards">
          <img src={ imgCards } alt="Accepted cards" />
        </figure>

        <InfoTip className="pd-checkout-payment-options__disclaimer">
          <p className="pd-text-sm">We utilize industry-leading standards to ensure the security of your order. Pledg.it does not store nor process your payment information directly.</p>
        </InfoTip>

        <div className={ `pd-checkout-payment-options__loading-disclaimer ${ isProcessing || pledge.isSaving ? 'pd-checkout-payment-options__loading-disclaimer--active' : '' }` }>
          <LoadingMessage>Processing, please wait...</LoadingMessage>
        </div>
      </div>

    </div>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default CheckoutPaymentOptions