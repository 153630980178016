import React, { useEffect, useContext, useState } from 'react'
import { Prompt } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { cloneDeep } from 'lodash'

import { DashboardContext } from '../../context/dashboard-state'
import { UIContext } from '../../context/ui-state'

import { PLEDGE_MANAGER_STATE, PREVENT_NAVIGATION_MESSAGE } from '../../config/constants'

import LoadingMessage from '../../components/loading-message/loading-message'
import CustomField from '../../components/custom-field/custom-field'
import CheckboxField from '../../components/checkbox-field/checkbox-field'
import { WarningTip, ErrorTip } from '../../components/tip/tip'

import './shipping-wave-management.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const ShippingWaveManagement = props => {

  const dashboardContext = useContext( DashboardContext )
  const UI = useContext( UIContext )
  const { project, updateProject, cleanUpPledges } = dashboardContext
  const [ hasChanges, setHasChanges ] = useState( false )
  const [ isUpdating, setUpdating ] = useState( false )
  const { className = '' } = props

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSave = async ( basePayload, actions ) => {

    try {

      let payload = cloneDeep( basePayload )
      await updateProject( payload )

      actions.setSubmitting( false )
      setHasChanges( false )
      UI.showSuccessDisclaimer( 'Your project was saved successfully!' )

    } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  // const handleWaveProgression = async event => {
  //   if ( !window.confirm( 'REMINDER: This is a destructive irreversible action, make sure you are ready to proceed.\nAre you sure?' ) ) return

  //   setUpdating( true )

  //   try {

  //     await advanceProjectShippingWave()
  //     UI.showSuccessDisclaimer( 'Your project was updated successfully!' )

  //   } catch ( exception ) {

  //     UI.showErrorDisclaimer( exception.message )

  //   }

  //   setUpdating( false )
  // }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleCleanUpPledges = async event => {
    if ( !window.confirm( 'REMINDER: This is a destructive irreversible action, make sure you are ready to proceed.\nAre you sure?' ) ) return

    setUpdating( true )

    try {

      await cleanUpPledges()
      UI.showSuccessDisclaimer( 'Your project was updated successfully!' )

    } catch ( exception ) {

      UI.showErrorDisclaimer( exception.message )

    }

    setUpdating( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <section className={ `pd-shipping-wave-management ${ className }` }>
      <Prompt when={ hasChanges } message={ location => PREVENT_NAVIGATION_MESSAGE } />
      { project.isFetched ? (
        <>
          <div>
            { project.details.pledgeManagerState !== PLEDGE_MANAGER_STATE.LATE_CONFIRMS ? (


              <WarningTip>
                <div className="pd-shipping-wave-management__progression-disclaimer">
                  <h4 className="pd-title-4">Pending pledges migration</h4>
                  <p>This process will migrate all unconfirmed pledges from the current wave to the next one.</p>
                  <button
                    className="pd-button pd-button--warning"
                    onClick={ handleCleanUpPledges }
                    disabled={ isUpdating }>
                    { isUpdating ? 'Processing...' : `Migrate pending pledges` }
                  </button>
                </div>
              </WarningTip>

            ) : (

              <ErrorTip>
                <div className="pd-shipping-wave-management__progression-disclaimer">
                  <h4 className="pd-title-4">Unconfirmed pledges clean-up</h4>
                  <p>This process will empty the carts of all pledges that haven’t been confirmed or updated in the last 2 days.</p>
                  <button
                    className="pd-button pd-button--danger"
                    onClick={ handleCleanUpPledges }
                    disabled={ isUpdating }>
                    { isUpdating ? 'Processing...' : `Empty unconfirmed pledges` }
                  </button>
                </div>
              </ErrorTip>

            ) }
          </div>

          {/* { project.details.waves > 1 && (
            project.details.currentWave < project.details.waves ? (

              <WarningTip>
                <div className="pd-shipping-wave-management__progression-disclaimer">
                  <h4 className="pd-title-4">Shipping wave progression</h4>
                  <p>This is a destructive irreversible action, make sure you are ready to proceed.</p>
                  <p>Current wave: <strong>{ project.details.currentWave }</strong></p>
                  <p>Total waves: <strong>{ project.details.waves }</strong></p>
                  <button
                    className="pd-button pd-button--warning"
                    disabled={ isUpdating }
                    onClick={ handleWaveProgression }>
                    { isUpdating ? 'Processing...' : `Progress to wave ${ project.details.currentWave + 1 }` }
                  </button>
                </div>
              </WarningTip>

            ) : (

              <InfoTip>
                <div className="pd-shipping-wave-management__progression-disclaimer">
                  <h4 className="pd-title-4">Shipping wave progression</h4>
                  <p>Current wave: <strong>{ project.details.currentWave }</strong></p>
                  <p>Total waves: <strong>{ project.details.waves }</strong></p>
                </div>
              </InfoTip>

            )
          ) } */}

          <Formik
            initialValues={ {
              multiwaveMultiplier: project.details.multiwaveMultiplier || '',
              singleWaveDescription: project.details.singleWaveDescription || '',
              multiwaveDescription: project.details.multiwaveDescription || '',
              allowMultiwaveSelection: project.details.allowMultiwaveSelection,
            } }
            onSubmit={ handleSave }
            validateOnChange={ false }
            validateOnBlur={ false }>
            { fProps => (

              <Form className={ `pd-shipping-wave-management__main pd-white-box` } onChange={ event => setHasChanges( true ) }>
                <CheckboxField name="allowMultiwaveSelection" className="pd-shipping-wave-management__field-allow-multiwave-selection">Allow multiwave selection</CheckboxField>
                <CustomField label="Multiwave multiplier" name="multiwaveMultiplier" type="number" min="0.0" step="0.0000001" className="pd-shipping-wave-management__field-multiplier" />
                <CustomField label="Single wave description" name="singleWaveDescription" component="rich-text" className="pd-shipping-wave-management__field-single-wave" />
                <CustomField label="Multiwave description" name="multiwaveDescription" component="rich-text" className="pd-shipping-wave-management__field-multiwave" />
                <button className="pd-button pd-button--navy pd-shipping-wave-management__submit" type="submit" disabled={ fProps.isSubmitting }>
                  { fProps.isSubmitting ? 'Processing...' : 'Save' }
                </button>
              </Form>

            ) }
          </Formik>
        </>

      ) : <LoadingMessage /> }
    </section>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default ShippingWaveManagement