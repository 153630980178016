import React, { useEffect, useState, useRef } from 'react'
import { useKeenSlider } from 'keen-slider/react'

import Env from '../../config/env'
import { getImageUrl } from '../../config/fetch'
import { getNormalizedHref } from '../../config/adapters'

import { ReactComponent as IconLeft } from '../../assets/icons/left.svg'
import { ReactComponent as IconRight } from '../../assets/icons/right.svg'

import 'keen-slider/keen-slider.min.css'
import './ks-updates.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const KsUpdates = props => {
  const { className = '', items = [] } = props
  const [ currentSlide, setCurrentSlide ] = useState( 0 )
  const [ pause, setPause ] = useState( false )
  const [ sliderRef, slider ] = useKeenSlider( {
    loop: true,
    initial: 0,
    slidesPerView: 1,
    slideChanged: slide => { setCurrentSlide( slide.details().relativeSlide ) },
    dragStart: () => setPause( true ),
    dragEnd: () => setPause( false )
  } )
  const timer = useRef()

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handlePauseOnHover = () => {
    const { current } = sliderRef
    current && current.addEventListener( 'mouseover', () => { setPause( true ) } )
    current && current.addEventListener( 'mouseout', () => { setPause( false ) } )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleAutoPlay = () => {
    if ( slider && slider.details().size <= 1 ) return

    timer.current = setInterval( () => {
      if ( !pause && slider ) slider.next()
    }, ( Env.sliderAutoplayDuration * 1000 ) )

    return () => { clearInterval( timer.current ) }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )
  useEffect( handlePauseOnHover, [ sliderRef ] )
  useEffect( handleAutoPlay, [ pause, slider ] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <div className={ `pd-ks-updates ${ className }` }>
      <div ref={ sliderRef } className="pd-ks-updates__slider keen-slider">
        { items.map( ( item, index ) => (

          <div key={ index } className="pd-ks-updates__slide keen-slider__slide">
            <div className="pd-ks-updates__container pd-container">
              <figure className="pd-ks-updates__figure">
                <a href={ getNormalizedHref( item.url ) } rel="noopener noreferrer" target="blank">
                  <img src={ getImageUrl( item.image, { w: 640, h: 360, resizeTo: 'fill', quality: 60 } ) } alt={ item.title } />
                </a>
              </figure>

              <div className="pd-ks-updates__content">
                <h3 className="pd-title-1">{ item.title }</h3>
                <p className="pd-text">{ item.headline }</p>
                <a href={ getNormalizedHref( item.url ) } rel="noopener noreferrer" target="blank" className="pd-button pd-button--navy">Check it now</a>
              </div>
            </div>
          </div>

        ) ) }
      </div>

      { slider && slider.details().size > 1 && (
        <>
          <IconLeft onClick={ e => e.stopPropagation() || slider.prev() } className={ `pd-ks-updates__arrow pd-ks-updates__arrow--left` } />
          <IconRight onClick={ e => e.stopPropagation() || slider.next() } className={ `pd-ks-updates__arrow pd-ks-updates__arrow--right` } />

          <div className="pd-ks-updates__dots">
            { [ ...Array( slider.details().size ).keys() ].map( index => (
              <button
                key={ index }
                onClick={ () => {
                  slider.moveToSlideRelative( index )
                } }
                className={ `pd-ks-updates__dot ${ currentSlide === index ? 'pd-ks-updates__dot--active' : '' } ` }
              />
            ) ) }
          </div>
        </>
      ) }
    </div>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default KsUpdates