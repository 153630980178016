import { getCookie, setCookie, removeCookie, getAllCookies } from 'tiny-cookie'

export default class Cookie {

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  static get( key ) {
    return getCookie( key )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  static set( key, value, expires = '1Y' ) {
    if ( typeof value === 'object' ) value = JSON.stringify( value )
    return setCookie( key, value, { expires: expires } )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  static remove( key ) {
    return removeCookie( key )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  static removeAll() {
    for ( let key in getAllCookies() ) removeCookie( key )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}