import React, { useEffect, useContext, useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { Prompt } from 'react-router-dom'
import { useParams, useHistory } from 'react-router-dom'
import { find, cloneDeep, isEmpty } from 'lodash'

import { GlobalContext } from '../../context/global-state'
import { DashboardContext } from '../../context/dashboard-state'
import { UIContext } from '../../context/ui-state'

import { sFetch } from '../../config/fetch'
import { toSnakeCase } from '../../config/adapters'
import { EMAIL_REGEX, NOT_EMPTY_REGEX, PHONE_REGEX } from '../../config/validators'
import { PREVENT_NAVIGATION_MESSAGE } from '../../config/constants'

import CustomField from '../../components/custom-field/custom-field'
import CheckboxField from '../../components/checkbox-field/checkbox-field'

import { WarningTip } from '../../components/tip/tip'

import './pledge-editor.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const PledgeEditor = props => {

  const { projectId, pledgeId } = useParams()
  const context = useContext( GlobalContext )
  const { fetchProvinces } = context
  const dashboardContext = useContext( DashboardContext )
  const UI = useContext( UIContext )
  const { project } = dashboardContext
  const { className = '' } = props
  const [ userFound, setUserFound ] = useState( null )
  const [ userNotFound, setUserNotFound ] = useState( false )
  const [ currentPledge, setCurrentPledge ] = useState( null )
  const [ newUser, setNewUser ] = useState( false )
  const [ email, setEmail ] = useState( '' )
  const [ newUserName, setNewUserName ] = useState( '' )
  const [ isSearching, setSearching ] = useState( false )
  const [ cameFromBackerReport, setCameFromBackerReport ] = useState( false )
  const [ hasChanges, setHasChanges ] = useState( false )
  const inputIsValid = EMAIL_REGEX.test( email )

  const [ addressPayload, setAddressPayload ] = useState( {
    name: '',
    phone: '',
    taxpayerId: '',
    addressLine1: '',
    addressLine2: '',
    postalCode: '',
    complement: '',
    city: '',
    stateId: '',
    countryId: ''
  } )

  const [ pledgePayload, setPledgePayload ] = useState( {
    bundles: [],
    addOns: [],
    multiwave: true
  } )

  const [ countries, setCountries ] = useState( {
    isFetched: false,
    list: []
  } )
  const [ provinces, setProvinces ] = useState( {
    isFetching: false,
    list: []
  } )

  const [ renderForm, setRenderForm ] = useState( false )
  const [ basePledge, setBasePledge ] = useState( null )
  const [ includesAddress, setIncludesAddress ] = useState( false )
  const [ isPostalCodeRequired, setPostalCodeRequired ] = useState( true )
  const [ isTaxpayerIdRequired, setTaxpayerIdRequired ] = useState( false )
  const [ taxpayerIdLabel, setTaxpayerIdLabel ] = useState( null )

  const history = useHistory()

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => {

    fetchCountries()

    if ( pledgeId ) {
      fetchPledge( pledgeId )
      setCameFromBackerReport( true )
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const fetchCountries = async () => {
    const countries = await sFetch( `/countries/${ projectId }` )

    setCountries( {
      isFetched: true,
      list: countries
    } )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const fetchPledge = async pledgeId => {
    setRenderForm( false )

    try {

      const fullPledge = await sFetch( `/geeks/report/${ pledgeId }` )
      setCurrentPledge( fullPledge )
      setUserFound( fullPledge.user )
      setUserNotFound( false )

    } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const fetchSearch = async () => {
    setSearching( true )
    setUserFound( null )
    setCurrentPledge( null )
    setUserNotFound( false )
    setNewUser( false )

    setRenderForm( false )

    try {

      const { users } = await sFetch( `/users/report?by_text=${ encodeURIComponent( email ) }` )

      if ( users.length > 0 ) {
        const user = await sFetch( `/users/report/${ users[ 0 ].id }` )
        const pledge = find( user.geeks, { projectId: parseInt( projectId ) } )

        if ( pledge ) {

          const fullPledge = await sFetch( `/geeks/report/${ pledge.id }` )
          setCurrentPledge( fullPledge )

        } else {

          fillAddressPayload()
          fillPledgePayload( {
            user: {
              id: user.id,
              email: email
            }
          } )
          setRenderForm( true )

        }

        setUserFound( user )
        setUserNotFound( false )

      } else { setUserNotFound( true ) }

    } catch ( exception ) {
      // HERE
      if ( !exception.code === 20 ) UI.showErrorDisclaimer( exception )

    }

    setSearching( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const fetchProvincesByCountryId = async countryId => {
    setProvinces( { list: [], isFetching: true } )
    const provinces = await fetchProvinces( countryId )
    setProvinces( { list: provinces, isFetching: false } )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const fillAddressPayload = ( pledge = {} ) => {
    setAddressPayload( {
      name: pledge.name || '',
      phone: pledge.phone || '',
      taxpayerId: pledge.taxpayerId || '',
      addressLine1: ( pledge.address && pledge.address.addressLine1 ) || '',
      addressLine2: ( pledge.address && pledge.address.addressLine2 ) || '',
      postalCode: ( pledge.address && pledge.address.postalCode ) || '',
      complement: ( pledge.address && pledge.address.complement ) || '',
      city: ( pledge.address && pledge.address.city ) || '',
      stateId: ( pledge.address && pledge.address.stateId ) || '',
      countryId: ( pledge.address && pledge.address.countryId ) || ''
    } )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const fillPledgePayload = ( pledge = {} ) => {
    const { details } = project

    const payload = {

      bundles: details.bundles
        .filter( bundle => bundle.soldAsExtra )
        .map( bundle => {
          const bundleOnPledge = find( pledge.bundles, itemOnPledge => {
            return itemOnPledge.id === bundle.id && itemOnPledge.base !== true
          } )

          return {
            id: bundle.id,
            name: bundle.name,
            amount: bundleOnPledge ? bundleOnPledge.amount : 0,
            base: false
          }
        } ),

      addOns: details.addOns.map( addOn => {
        const addOnOnPledge = find( pledge.addOns, itemOnPledge => itemOnPledge.id === addOn.id )

        return {
          id: addOn.id,
          name: addOn.name,
          amount: addOnOnPledge ? addOnOnPledge.amount : 0
        }
      } ),

      multiwave: pledge.multiwave
    }

    const basePledgeItem = find( pledge.bundles, itemOnPledge => itemOnPledge.base )
    if ( basePledgeItem ) setBasePledge( basePledgeItem.id )

    if ( pledge.user ) payload.user = pledge.user

    setPledgePayload( payload )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleCurrentPledgeLoad = () => {
    if ( currentPledge ) {

      const countryId = currentPledge.address && currentPledge.address.countryId
      if ( countryId ) fetchProvincesByCountryId( countryId )

      fillAddressPayload( currentPledge )
      fillPledgePayload( currentPledge )
      setRenderForm( true )

    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleCountryInitialRequirements = () => {
    if ( countries.isFetched && currentPledge && currentPledge.address ) {
      handleTaxpayerIdRequirement( currentPledge.address.countryId )
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSearchClick = event => {
    if ( hasChanges ) if ( !window.confirm( PREVENT_NAVIGATION_MESSAGE ) ) return

    fetchSearch()
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSearchChange = () => {
    setUserFound( null )
    setCurrentPledge( null )
    setUserNotFound( false )
    setNewUser( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleEmailKeyUp = event => { if ( event.keyCode === 13 && inputIsValid ) fetchSearch() }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleNewAccountClick = event => {
    fillAddressPayload()
    fillPledgePayload( { user: { email: email, name: newUserName } } )
    setNewUser( true )
    setRenderForm( true )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleCountryChange = ( fProps, event ) => {
    fProps.setFieldValue( 'address.stateId', '' )
    fetchProvincesByCountryId( event.target.value )
    handleTaxpayerIdRequirement( event.target.value )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleEmailChange = event => {
    if ( hasChanges ) if ( !window.confirm( PREVENT_NAVIGATION_MESSAGE ) ) return
    setRenderForm( false )
    setEmail( event.target.value )
    setHasChanges( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleValidation = values => {
    let errors = {}

    if ( includesAddress ) {
      let addressErrors = {}

      if ( !NOT_EMPTY_REGEX.test( values.address.name ) ) addressErrors.name = 'Invalid name'
      if ( !PHONE_REGEX.test( values.address.phone ) || values.address.phone.length < 6 ) addressErrors.phone = 'Invalid phone number'
      if ( isTaxpayerIdRequired && !NOT_EMPTY_REGEX.test( values.address.taxpayerId ) ) addressErrors.taxpayerId = `Invalid ${ taxpayerIdLabel || 'taxpayer ID' }`
      if ( !NOT_EMPTY_REGEX.test( values.address.addressLine1 ) ) addressErrors.addressLine1 = 'Invalid address line'
      if ( isPostalCodeRequired && !NOT_EMPTY_REGEX.test( values.address.postalCode ) ) addressErrors.postalCode = 'Invalid postal code'
      if ( !NOT_EMPTY_REGEX.test( values.address.city ) ) addressErrors.city = 'Invalid city'
      if ( !NOT_EMPTY_REGEX.test( values.address.stateId ) ) addressErrors.stateId = 'Select a state'
      if ( !NOT_EMPTY_REGEX.test( values.address.countryId ) ) addressErrors.countryId = 'Select a country'

      if ( !isEmpty( addressErrors ) ) errors.address = addressErrors
    }

    return errors
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleTaxpayerIdRequirement = countryId => {
    const selectedCountry = find( countries.list, { id: parseInt( countryId ) } )

    if ( selectedCountry ) {
      setPostalCodeRequired( selectedCountry.code !== 'HK' )
      setTaxpayerIdRequired( !!selectedCountry.requiresTaxpayerId )
      setTaxpayerIdLabel( selectedCountry.taxpayerIdName )

    } else {

      setPostalCodeRequired( true )
      setTaxpayerIdRequired( false )
      setTaxpayerIdLabel( null )

    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const sanitizeGeekPayload = _payload => {
    const payload = cloneDeep( _payload )

    payload.bundles = payload.bundles
      .filter( item => item.amount > 0 )
      .map( item => {
        item.amount = parseInt( item.amount )
        delete item.base
        delete item.name
        return item
      } )

    payload.addOns = payload.addOns
      .filter( item => item.amount > 0 )
      .map( item => {
        item.amount = parseInt( item.amount )
        delete item.name
        return item
      } )

    if ( basePledge ) payload.bundles.push( { id: basePledge, amount: 1, base: true } )

    return payload
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSubmit = async ( basePayload, actions ) => {
    actions.setSubmitting( true )

    try {
      const payload = cloneDeep( basePayload )
      payload.geek = sanitizeGeekPayload( payload.geek )
      if ( !includesAddress ) delete payload.address

      const savedPledge = await sFetch( `/projects/${ projectId }/geeks/admin_create`, { method: 'post', body: JSON.stringify( toSnakeCase( payload ) ) } )

      setHasChanges( false )
      UI.showSuccessDisclaimer( 'Pledge successfully saved!' )
      history.push( `/dashboard/pledge/${ savedPledge.id }` )

    } catch ( exception ) {

      UI.showErrorDisclaimer( exception.message )

    }

    actions.setSubmitting( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )
  useEffect( handleSearchChange, [ email ] )
  useEffect( handleCountryInitialRequirements, [ countries, currentPledge ] )
  useEffect( handleCurrentPledgeLoad, [ currentPledge ] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -


  return (

    <section className={ `pd-pledge-editor ${ className }` }>
      <Prompt when={ hasChanges } message={ location => PREVENT_NAVIGATION_MESSAGE } />
      { cameFromBackerReport ? (
        currentPledge ? (

          <WarningTip>
            You are <strong>editing the pledge</strong> of { currentPledge.user.name } ({ currentPledge.user.email })
          </WarningTip>

        ) : null ) : (

        <div className="pd-pledge-editor__user-form">
          <h3 className="pd-title-3">01. Find a user</h3>

          <div className="pd-pledge-editor__user-form-wrapper pd-white-box">
            <div className="pd-pledge-editor__field-wrapper">
              <div className="pd-field">
                <label htmlFor="email" className="pd-label">E-mail</label>
                <input
                  type="email"
                  disabled={ isSearching }
                  className="pd-textfield" value={ email }
                  onKeyUp={ handleEmailKeyUp }
                  onChange={ handleEmailChange }
                />
              </div>

              <button
                className="pd-button pd-button--navy"
                onClick={ handleSearchClick }
                disabled={ !inputIsValid || isSearching }>
                { isSearching ? 'Searching...' : 'Search' }
              </button>
            </div>

            { userFound ?
              (
                currentPledge ?
                  (

                    <WarningTip>
                      You are <strong>editing the pledge</strong> of { userFound.name } ({ userFound.email })
                    </WarningTip>

                  ) : (

                    <WarningTip>
                      You are <strong>creating a new pledge</strong> for { userFound.name } ({ userFound.email })
                    </WarningTip>

                  )

              ) : (
                userNotFound ?
                  (
                    newUser ?
                      (

                        <WarningTip>
                          <p>You are <strong>creating a new account and pledge</strong> for { newUserName } ({ email }).</p>
                        </WarningTip>

                      ) : (

                        <WarningTip>
                          <div className="pd-pledge-editor__disclaimer-wrapper">
                            <p>No user found with the e-mail { email }.</p>
                            <div className="pd-field">
                              <label className="pd-label">Name</label>
                              <input type="text" className="pd-textfield" onChange={ evt => setNewUserName( evt.target.value ) } />
                              <button className="pd-button pd-button--warning" disabled={ newUserName.length < 2 } onClick={ handleNewAccountClick }>Create account &amp; pledge</button>
                            </div>
                          </div>
                        </WarningTip>

                      )
                  ) : ( null )
              )
            }
          </div>
        </div>

      ) }

      {
        renderForm ? (
          <>

            <h3 className="pd-title-3">0{ cameFromBackerReport ? 1 : 2 }. Edit pledge information</h3>

            <Formik
              initialValues={ {
                geek: pledgePayload,
                address: addressPayload
              } }
              onSubmit={ handleSubmit }
              validate={ handleValidation }
              validateOnChange={ false }
              validateOnBlur={ false }>
              { fProps => (

                <Form className="pd-pledge-editor__pledge-form pd-white-box" onChange={ evt => setHasChanges( true ) }>
                  <CheckboxField name="geek.multiwave">Multiwave</CheckboxField>

                  <div className="pd-pledge-editor__base-pledge">
                    <h4 className="pd-label">Base pledge</h4>

                    <ul className="pd-pledge-editor__base-pledge-list">
                      { project.details.bundles.map( ( bundle, index ) => (
                        <li key={ index } className="pd-pledge-editor__base-pledge-item">
                          <input type="radio" id={ `base${ index }` } value={ bundle.id } onChange={ evt => setBasePledge( parseInt( evt.target.value ) ) } checked={ basePledge === bundle.id } />
                          <label htmlFor={ `base${ index }` } className="pd-text">{ bundle.name }</label>
                        </li>
                      ) ) }
                    </ul>
                  </div>

                  <div className="pd-pledge-editor__additional-buys">
                    <h4 className="pd-label">Additional buys</h4>
                    <ul className="pd-pledge-editor__additional-buys-list">
                      { ( fProps.values.geek.addOns || [] ).map( ( addOn, index ) => (
                        <li key={ `a-${ index }` } className="pd-pledge-editor__additional-buys-item">
                          <Field name={ `geek.addOns[${ index }].amount` } className="pd-textfield" />
                          <label className="pd-text">{ addOn.name }</label>
                        </li>
                      ) ) }

                      { ( fProps.values.geek.bundles || [] ).map( ( bundle, index ) => (
                        <li key={ `b-${ index }` } className="pd-pledge-editor__additional-buys-item">
                          <Field name={ `geek.bundles[${ index }].amount` } className="pd-textfield" />
                          <label className="pd-text">Extra { bundle.name }</label>
                        </li>
                      )
                      ) }
                    </ul>
                  </div>

                  <div className="pd-pledge-editor__include-address">
                    <input className="pd-checkbox" type="checkbox" id="addressFields" onChange={ evt => setIncludesAddress( !includesAddress ) } />
                    <label htmlFor="addressFields" className="pd-text">{ includesAddress ? 'Hide' : 'Include' } address fields</label>
                  </div>

                  { includesAddress ? (

                    <div className="pd-pledge-editor__address-fields">
                      <CheckboxField name="geek.keepShippingCosts" className="pd-pledge-editor__field-calc-shipping">Don't recalculate shipping cost</CheckboxField>
                      <CustomField name="address.name" label="Recipient name" className="pd-pledge-editor__field-name" required />
                      <CustomField name="address.phone" label="Phone" className="pd-pledge-editor__field-phone" required />
                      <CustomField name="address.taxpayerId" label={ `Taxpayer ID ${ taxpayerIdLabel ? `(${ taxpayerIdLabel })` : '' }` } className="pd-pledge-editor__field-taxpayer-id" required={ isTaxpayerIdRequired } />
                      <CustomField name="address.addressLine1" label="Address line 1" className="pd-pledge-editor__field-address-line-1" required />
                      <CustomField name="address.addressLine2" label="Address line 2" className="pd-pledge-editor__field-address-line-2" />
                      <CustomField name="address.postalCode" label="Postal code" className="pd-pledge-editor__field-postal-code" required={ isPostalCodeRequired } />
                      <CustomField name="address.complement" label="Address Complement" complement="(Door code, apartment #, etc)" className="pd-pledge-editor__field-complement" />

                      <CustomField name="address.countryId" label="Country" className="pd-pledge-editor__field-country" component="select" onChange={ handleCountryChange.bind( this, fProps ) } required>
                        <option value="">Please select</option>
                        { countries.list.map( ( country, index ) => <option key={ index } value={ country.id }>{ country.name }</option> ) }
                      </CustomField>

                      <CustomField name="address.stateId" label="State/Province" className="pd-pledge-editor__field-province" component="select" disabled={ provinces.isFetching || !provinces.list.length } required>
                        <option value="">{ provinces.isFetching ? 'Fetching...' : 'Please select' }</option>
                        { provinces.list.map( ( province, index ) => <option key={ index } value={ province.id }>{ province.name }</option> ) }
                      </CustomField>

                      <CustomField label="City" name="address.city" className="pd-pledge-editor__field-city" />
                    </div>

                  ) : null }

                  <button type="submit" disabled={ fProps.isSubmitting } className="pd-button pd-button--navy pd-pledge-editor__save">{ fProps.isSubmitting ? 'Saving...' : 'Save' }</button>
                </Form>

              ) }
            </Formik>

          </>
        ) : null
      }
    </section>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default PledgeEditor

// HK