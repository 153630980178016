import React, { useEffect, useContext, useState } from 'react'
import moment from 'moment-timezone'
import { useParams } from 'react-router-dom'
import { find, capitalize, isNil } from 'lodash'
import { PortalWithState } from 'react-portal'

import { GlobalContext } from '../../context/global-state'
import { DashboardContext } from '../../context/dashboard-state'
import { UIContext } from '../../context/ui-state'

import Env from '../../config/env'
import { formatCurrency } from '../../config/adapters'
import { PLEDGE_STATE, GATEWAY_REFUND_STATUS } from '../../config/constants'
import { sFetch } from '../../config/fetch'

import PledgeOrderDetails from '../../modules/pledge-order-details/pledge-order-details'
import PledgeOrderContents from '../../modules/pledge-order-contents/pledge-order-contents'
import CancelAndRefundForm from '../../modules/cancel-and-refund-form/cancel-and-refund-form'

import Lightbox from '../../components/lightbox/lightbox'
import LoadingMessage from '../../components/loading-message/loading-message'
import { SuccessTip, ErrorTip } from '../../components/tip/tip'

import './dashboard-pledge-index.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const DashboardPledgeIndex = props => {

  const context = useContext( GlobalContext )
  const dashboardContext = useContext( DashboardContext )
  const UI = useContext( UIContext )
  const { fetchCountries, fetchProvinces, countries, provinces } = context
  const { pledge, arbitraryPledgeConfirm } = dashboardContext
  const [ isConfirming, setConfirming ] = useState( false )
  const [ passwordReset, setPasswordReset ] = useState( { url: null, isGenerating: false } )
  const [ countryAddressLine, setCountryAddressLine ] = useState( '' )
  const [ hasUnrefundableCharges, setUnrefundableCharges ] = useState( false )
  const [ manualRefunds, setManualRefunds ] = useState( null )
  const { pledgeId } = useParams()
  const CANCELABLE_STATES = [
    PLEDGE_STATE.PENDING,
    PLEDGE_STATE.AUTHORIZED,
    PLEDGE_STATE.PROCESSING,
    PLEDGE_STATE.FINISHED,
    PLEDGE_STATE.UNLOCKED,
    PLEDGE_STATE.ERRORED,
    PLEDGE_STATE.REFUNDED,
    PLEDGE_STATE.READY_TO_SHIP
  ]

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const fetchCountryAndProvinceInfo = async () => {
    const { address } = pledge.details

    if ( address ) {
      await fetchCountries()
      await fetchProvinces( address.countryId )
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handlePledgeLoad = () => {
    if ( pledge.isFetched ) {
      fetchCountryAndProvinceInfo()
      handleUnrefundableCharges()
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleUnrefundableCharges = () => {
    const { charges } = pledge.details
    setManualRefunds( charges.filter( c => c.refunds.filter( r => r.gatewayRefund.status === GATEWAY_REFUND_STATUS.MANUAL_REFUND ).length > 0 ) )
    setUnrefundableCharges( !!charges.filter( c => c.gateway === 'paypal' && moment().diff( moment( c.createdAt ), 'days' ) >= parseInt( Env.paypalTransactionRefundMaxAge ) ).length )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleCountryInformation = () => {
    const { details, isFetched } = pledge
    const { address } = details

    if ( isFetched && address && countries.length ) {

      const countryItem = find( countries, { id: parseInt( address.countryId ) } )
      const provinceItem = find( provinces[ address.countryId ], { id: parseInt( address.stateId ) } )
      setCountryAddressLine( `${ address.city ? `${ address.city }, ` : '' }${ provinceItem && provinceItem.code } ${ address.postalCode }\n${ countryItem && countryItem.name }` )

    } else { setCountryAddressLine( '' ) }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handlePasswordResetGeneration = async () => {
    try {

      setPasswordReset( { url: null, isGenerating: true } )
      const resetPayload = await sFetch( `/geeks/${ pledgeId }/password_reset_link` )
      setPasswordReset( { url: resetPayload.passwordResetLink, isGenerating: false } )
      copyToClipboard( resetPayload.passwordResetLink )

    } catch ( exception ) {

      UI.showErrorDisclaimer( exception.message )
      setPasswordReset( { url: null, isGenerating: false } )

    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleForceConfirmClick = async event => {
    if ( !window.confirm( 'IMPORTANT\n\nThis will force this order into a confirmed state, without checking for funds or the integrity of its contents.\n\nAre you sure you want to do this?' ) ) return

    setConfirming( true )

    try {

      await arbitraryPledgeConfirm( pledgeId )
      UI.showSuccessDisclaimer( 'The arbitrary order confirmation was processed successfully!' )


    } catch ( exception ) {

      UI.showErrorDisclaimer( exception.message )

    }

    setConfirming( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const copyToClipboard = text => {
    var textarea = document.createElement( 'textarea' )
    textarea.style.opacity = '0.2'
    document.body.appendChild( textarea )
    textarea.value = text
    textarea.select()
    document.execCommand( 'copy' )
    document.body.removeChild( textarea )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )
  useEffect( handlePledgeLoad, [ pledge.isFetched ] )
  useEffect( handleCountryInformation, [ pledge, countries, provinces ] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  const { details } = pledge
  const { address } = details

  return pledge.isFetched ? (

    <section className="pd-dashboard-pledge-index">

      { manualRefunds?.length > 0 ? (

        <PortalWithState defaultOpen>
          { portalBag => portalBag.portal(
            <Lightbox portalBag={ portalBag }>
              <ErrorTip>
                <div className="pd-dashboard-pledge-index__manual-refunds-disclaimer">
                  <h3 className="pd-title-4">Important notice</h3>
                  <p>
                    This Pledge was refunded and at least some of the refunded
                    transactions <strong>have to be processed manually</strong>,
                    make sure these transactions have been manually refunded:
                  </p>
                  <ul>
                    { manualRefunds.map( item => ( <li key={ item.gatewayChargeId }>{ item.gatewayChargeId } ({ capitalize( item.gateway ) })</li> ) ) }
                  </ul>
                </div>
              </ErrorTip>
            </Lightbox>
          ) }
        </PortalWithState>

      ) : null }


      <div className="pd-dashboard-pledge-index__col pd-dashboard-pledge-index__col-1">
        <PledgeOrderDetails
          details={ details }
          handleForceConfirmClick={ handleForceConfirmClick }
          isProcessingConfirmation={ isConfirming }
          className="pd-dashboard-pledge-index__order"
        />

        <div className="pd-dashboard-pledge-index__module pd-white-box pd-dashboard-pledge-index__shipping">
          <h3 className="pd-title-3">Shipping information</h3>
          <p className="pd-text">
            c/o { details.name || details.user.name }<br />
            { details.user.email }<br />
            Taxpayer ID: { details.taxpayerId || ( <em>No taxpayerId information.</em> ) } <br />
            { address ? (
              <>
                { address.addressLine1 }{ address.complement ? ( ` — ${ address.complement }` ) : '' } <br />
                { address.addressLine2?.length ? <>{ address.addressLine2 }<br /></> : null }
                { countryAddressLine.split( /\n/g ).map( ( i, index ) => ( <span key={ index }>{ i }<br /></span> ) ) }
                { address.alternateCharset?.length ? <span>{ address.alternateCharset }<br /></span> : null }
              </>
            ) : ( <>No address information.<br /></> ) }
            Phone: { details.phone || 'No phone information.' }<br />
            Shipping cost: { formatCurrency( details.shippingCost ) }<br />
            Is multiwave: { isNil( details.multiwave ) ? 'Not defined' : ( details.multiwave ? 'Yes' : 'No' ) }
          </p>
        </div>

        { CANCELABLE_STATES.indexOf( details.state ) >= 0 ? (

          <CancelAndRefundForm hasUnrefundableCharges={ hasUnrefundableCharges } />

        ) : null }
      </div>

      <div className="pd-dashboard-pledge-index__col pd-dashboard-pledge-index__col-2">
        <PledgeOrderContents className="pd-dashboard-pledge-index__module pd-dashboard-pledge-index__contents" />

        <div className="pd-dashboard-pledge-index__module pd-white-box pd-dashboard-pledge-index__password-reset">
          <h3 className="pd-title-3">Reset password link</h3>
          { passwordReset.url ? (

            <SuccessTip>
              <p className="pd-dashboard-pledge-index__password-reset__link">{ passwordReset.url }</p>
              <p className="pd-text-xs">Already copied to clipboard.</p>
            </SuccessTip>

          ) : null }

          <button
            className="pd-button pd-button--cyan"
            onClick={ handlePasswordResetGeneration }
            disabled={ passwordReset.isGenerating }>
            { passwordReset.isGenerating ? 'Generating...' : 'Generate' }
          </button>
        </div>
      </div>

    </section>

  ) : <LoadingMessage />

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default DashboardPledgeIndex