import React from 'react'
import { useField, ErrorMessage } from 'formik'

import './checkbox-field.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const CheckboxField = ( { children, className = '', id, confirmation, ...props } ) => {
  const [ field, meta, helpers ] = useField( { ...props, type: 'checkbox' } )
  const { name } = props
  const { setValue } = helpers

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleChange = event => {
    const { value } = event.target

    if ( confirmation && !window.confirm( confirmation ) ) {
      event.preventDefault()
      event.stopPropagation()
      return
    }

    setValue( !( value === 'true' ) )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <div className={ `pd-field pd-checkbox-field ${ className }` }>
      <input type="checkbox" className="pd-checkbox" id={ id || name } { ...field } { ...props } onChange={ handleChange } />
      <label className="pd-label" htmlFor={ id || name }>{ children }</label>
      <ErrorMessage name={ name } component="span" className="pd-text-sm pd-text--error" />
    </div>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default CheckboxField
