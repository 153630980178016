import React, { useContext, useEffect, createRef } from 'react'
import { Link } from 'react-router-dom'
import { PortalWithState } from 'react-portal'

import { GlobalContext } from '../../context/global-state'
import { DashboardState } from '../../context/dashboard-state'

import DashboardProjectsList from '../../modules/dashboard-projects-list/dashboard-projects-list'
import NewProjectForm from '../../modules/new-project-form/new-project-form'
import UserSearch from '../../modules/user-search/user-search'
import PledgeSearch from '../../modules/pledge-search/pledge-search'
import CollaboratorsList from '../../modules/collaborators-list/collaborators-list'

import Lightbox from '../../components/lightbox/lightbox'

import './dashboard-gateway.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

let lightboxRef

const DashboardGateway = props => {

  const context = useContext( GlobalContext )
  const { loggedUser, secureRender } = context
  const { className = '' } = props

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => {
    lightboxRef = createRef()
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const preventEditLoss = () => {
    lightboxRef.current.askBeforeClose( true )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return secureRender(

    <section className={ `pd-tpl-dashboard pd-dashboard-gateway pd-page ${ className }` }>
      <header className="pd-tpl-dashboard__header">
        <h3 className="pd-title-2">Dashboard</h3>
      </header>

      <nav className="pd-tpl-dashboard__nav">
        <ul className="pd-tpl-dashboard__nav-list">
          { loggedUser.isFetched ? (
            <>
              <li className="pd-tpl-dashboard__nav-item">
                <PortalWithState>
                  { portalBag => (
                    <>
                      <button className="pd-button pd-button--cyan" onClick={ portalBag.openPortal }>Create new project</button>

                      { portalBag.portal(
                        <Lightbox portalBag={ portalBag } ref={ lightboxRef }>
                          <NewProjectForm onChange={ preventEditLoss } onCancel={ () => { lightboxRef.current.safeClose() } } />
                        </Lightbox>
                      ) }
                    </>
                  ) }
                </PortalWithState>
              </li>
              <li className="pd-tpl-dashboard__nav-item">
                <Link to="/dashboard/tracking-numbers" className="pd-button pd-button--cyan">Tracking Numbers</Link>
              </li>
              <li className="pd-tpl-dashboard__nav-item">
                <Link to="/dashboard/ks-updates" className="pd-button pd-button--cyan">Carousel items</Link>
              </li>
            </>

          ) : null }
        </ul>
      </nav>

      <div className="pd-tpl-dashboard__container pd-dashboard-gateway__container">
        <UserSearch className="pd-dashboard-gateway__user-search" />
        <PledgeSearch className="pd-dashboard-gateway__pledge-search" />
        <DashboardProjectsList className="pd-dashboard-gateway__project-list" />
        <CollaboratorsList className="pd-dashboard-gateway__collaborators-list" />
      </div>
    </section>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

const DashboardGatewayWrapper = props => {
  const { params } = props.match

  return (
    <DashboardState params={ params }>
      <DashboardGateway { ...props } />
    </DashboardState>
  )
}

export default DashboardGatewayWrapper
