import moment from 'moment-timezone'
import React, { useEffect } from 'react'

import './checkout-tracking-number-info.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const CheckoutTrackingNumberInfo = props => {

  const { className = '', trackingNumbers = [] } = props

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return trackingNumbers.length ? (

    <div className={ `pd-checkout-tracking-number-info ${ className }` }>
      <h4 className="pd-title-4">Tracking information</h4>
      <ul className="pd-checkout-tracking-number-info__list">
        { trackingNumbers.map( item => (
          <li className="pd-text" key={ item.trackingNumber }>
            { item.trackingUrl ? (

              <a href={ item.trackingUrl } className="pd-code pd-code--link pd-code--navy" rel="noopener noreferrer" target="_blank">
                { item.trackingNumber }
              </a>

            ) : (
              <p className="pd-code">{ item.trackingNumber }</p>
            ) }
            <p className="pd-text-sm">
              { item.carrier } { item.dateShipped && `on ${ moment( item.dateShipped ).format( 'MMMM Do YYYY' ) }` } { item.shippingType && `(${ item.shippingType })` }
            </p>
          </li>
        ) ) }
      </ul>
    </div>

  ) : null

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default CheckoutTrackingNumberInfo