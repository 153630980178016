import React from 'react'

import { ReactComponent as PledgitLogo } from '../../assets/images/pledgit-logo-large.svg'

import './footer.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const Footer = props => {

  const { className = '', isRetailer = false } = props

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <div className={ `pd-footer ${ className }` }>
      <figure className="pd-footer__figure">
        <PledgitLogo className="pd-footer__image" />
        <figcaption className="pd-footer__caption pd-text-sm">
          All rights reserved ©{ new Date().getFullYear() } PLEDG.IT<br />
        </figcaption>
      </figure>
      <nav className="pd-footer__social">
        <a href={ isRetailer ? 'mailto:retailerpledge@cmon.com' : 'https://support.cmon.com/' } target="_blank" className="pd-button pd-button--info" rel="noopener noreferrer">
          { isRetailer ? 'Retailer support' : 'Customer support' }
        </a>
      </nav>
    </div>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default Footer