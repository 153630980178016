import React, { useEffect, useContext, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { capitalize, find } from 'lodash'
import moment from 'moment-timezone'

import { DashboardContext } from '../../context/dashboard-state'

import { formatCurrency, defaultCurrencyLocale, defaultCurrencyOptions } from '../../config/adapters'

import RefundForm from '../../modules/refund-form/refund-form'

import Table from '../../components/table/table'
import { ErrorTip, InfoTip } from '../../components/tip/tip'

import './transaction-details.scss'
import { GATEWAY_REFUND_STATUS } from '../../config/constants'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const TransactionDetails = props => {

  const dashboardContext = useContext( DashboardContext )
  const { details, isFetched } = dashboardContext.pledge

  const [ charge, setCharge ] = useState( null )

  const { pledgeId, transactionId } = useParams()
  const history = useHistory()

  const { className = '' } = props

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handlePledgeFetched = () => {
    if ( !isFetched ) return
    const current = find( details.charges, { id: parseInt( transactionId ) } )

    if ( !current ) history.push( `/dashboard/pledge/${ pledgeId }/transaction-history` )

    setCharge( current )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const getCurrencyValue = ( amount, currency ) => (
    `${ formatCurrency( amount, defaultCurrencyLocale, Object.assign( {}, defaultCurrencyOptions, { currency: currency } ) ) }`
  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )
  useEffect( handlePledgeFetched, [ isFetched ] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return charge ? (

    <section className={ `pd-transaction-details ${ className }` }>
      <div className="pd-transaction-details__overview pd-white-box">
        <h3 className="pd-title-3">Charge details</h3>
        <p className="pd-text">
          Gateway: { capitalize( charge.gateway ) } <br />
          { charge.gatewayChargeId ? (
            <>
              ({ charge.gatewayChargeId })<br />
            </>
          ) : null }
          Amount: { getCurrencyValue( charge.amount, charge.currency ) } <br />
          Refundable amount: { getCurrencyValue( charge.refundableAmount, charge.currency ) } <br />
          Currency: { charge.currency } <br />
          Created at: { moment( charge.createdAt ).format( 'MMM DD, YYYY, HH:mm' ) }
        </p>
      </div>

      <div className="pd-transaction-details__refunds">
        <h3 className="pd-title-3">Refund history</h3>
        { charge.refunds.length > 0 ? (

          <Table
            className="pd-transaction-details__table"
            cols={ [ 'Date', 'Amount', 'Fee', 'Reason' ] }
            rows={ charge.refunds.map( refund => ( [
              moment( refund.createdAt ).format( 'MMM DD, YYYY, HH:mm' ),
              getCurrencyValue( refund.amount, charge.currency ),
              getCurrencyValue( refund.withheldFee, charge.currency ),
              <>
                { refund.reason }
                { refund.gatewayRefund.status === GATEWAY_REFUND_STATUS.MANUAL_REFUND ? (
                  <ErrorTip micro>Requires manual refund.</ErrorTip>
                ) : null }
              </>
            ] ) ) }
          />

        ) : (

            <InfoTip>No refund history.</InfoTip>
          )
        }
      </div>

      <RefundForm charge={ charge } className="pd-transaction-details__refund-form pd-white-box" />
    </section>

  ) : null

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default TransactionDetails
