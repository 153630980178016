import React, { useEffect, useContext, useState, createRef } from 'react'
import { Prompt } from 'react-router-dom'
import { Formik, Field, Form } from 'formik'
import arrayMove from 'array-move'

import { GlobalContext } from '../../context/global-state'
import { UIContext } from '../../context/ui-state'

import { getImageUrl, sFetch, sUploader } from '../../config/fetch'
import { getImageSrc, toSnakeCase } from '../../config/adapters'
import { NOT_EMPTY_REGEX, URL_REGEX } from '../../config/validators'
import { PREVENT_NAVIGATION_MESSAGE } from '../../config/constants'

import { InfoTip } from '../../components/tip/tip'
import CustomField from '../../components/custom-field/custom-field'
import CheckboxField from '../../components/checkbox-field/checkbox-field'
import ImageUploadField from '../../components/image-upload-field/image-upload-field'
import { SortableItem, SortableContainer, DragHandle } from '../../components/sortable-list/sortable-list'

import './dashboard-ks-updates.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const DashboardKsUpdates = props => {

  const context = useContext( GlobalContext )
  const { secureRender } = context
  const UI = useContext( UIContext )
  const { className = '' } = props
  const [ ksUpdates, setKsUpdates ] = useState( [] )
  const [ isNew, setNew ] = useState( false )
  const [ currentItem, setCurrentItem ] = useState( null )
  const [ hasChanges, setHasChanges ] = useState( false )
  const uploaderRef = createRef()

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => {
    fetchKsUpdates()
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const fetchKsUpdates = async () => {
    try {

      const updates = await sFetch( '/carousel_items' )
      setKsUpdates( updates )

    } catch ( exception ) { throw exception }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSortEnd = async event => {
    const { oldIndex, newIndex } = event
    const item = ksUpdates[ oldIndex ]
    setKsUpdates( arrayMove( ksUpdates, oldIndex, newIndex ) )

    const options = { method: 'put', body: JSON.stringify( { position: ( newIndex + 1 ) } ) }
    const endpoint = `/carousel_items/${ item.id }/insert_at`
    sFetch( endpoint, options )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleItemClick = item => {
    if ( hasChanges ) if ( !window.confirm( PREVENT_NAVIGATION_MESSAGE ) ) return

    setCurrentItem( item )
    setNew( item.id === 0 )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleImageUpload = async ( payload ) => {
    const { setUploading, setProgress } = uploaderRef.current

    setUploading( true )
    setProgress( 0 )

    let formData = new FormData()
    formData.append( 'image', payload.image )

    const newImage = await sUploader( '/images', formData, percent => setProgress( percent ) )
    payload.imageId = newImage.id

    setUploading( false )

    delete payload.image
    return payload
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleValidation = values => {
    let errors = {}

    if ( !NOT_EMPTY_REGEX.test( values.title ) ) errors.title = 'Invalid title'
    if ( !NOT_EMPTY_REGEX.test( values.headline ) ) errors.headline = 'Invalid headline'
    if ( !URL_REGEX.test( values.url ) ) errors.url = 'Invalid URL'
    if ( !NOT_EMPTY_REGEX.test( values.image ) ) errors.image = 'Image is required'

    return errors
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSave = async ( payload, actions ) => {
    try {

      if ( payload.image ) payload = await handleImageUpload( payload )

      await sFetch( `/carousel_items/${ isNew ? '' : payload.id }`, { method: isNew ? 'post' : 'put', body: JSON.stringify( toSnakeCase( { carouselItem: payload } ) ) } )

      setCurrentItem( null )
      setHasChanges( false )
      setNew( false )

      await fetchKsUpdates()

      actions.setSubmitting( false )
      actions.resetForm()

      UI.showSuccessDisclaimer( 'Your carousel item was saved successfully!' )

    } catch ( exception ) {

      UI.showErrorDisclaimer( exception.message )

    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleDelete = async event => {
    event.preventDefault()

    try {

      if ( !window.confirm( 'Are you sure?' ) ) return

      await sFetch( `/carousel_items/${ currentItem.id }`, { method: 'delete' } )
      await fetchKsUpdates()

      setCurrentItem( null )
      setHasChanges( false )

      UI.showSuccessDisclaimer( 'Your carousel item was deleted successfully!' )

    } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleCancel = event => {
    if ( hasChanges ) if ( !window.confirm( PREVENT_NAVIGATION_MESSAGE ) ) {
      event.preventDefault()
      return false
    }

    setCurrentItem( null )
    setNew( false )
    setHasChanges( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleCurrentItemChange = () => {
    setHasChanges( false )
    const { current } = uploaderRef

    if ( current ) {
      current.setPreview( currentItem.image ? getImageSrc( currentItem.image, { w: 320, h: 180, resizeTo: 'fill', quality: 60 } ) : null )
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )
  useEffect( handleCurrentItemChange, [ currentItem ] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return secureRender(

    <section className={ `pd-tpl-dashboard pd-tpl-dashboard--without-nav pd-dashboard-ks-updates pd-page ${ className }` }>
      <Prompt when={ hasChanges } message={ location => PREVENT_NAVIGATION_MESSAGE } />
      <header className="pd-tpl-dashboard__header">
        <h3 className="pd-title-2">Carousel items</h3>
      </header>

      <div className="pd-tpl-nav-content pd-tpl-dashboard__container">
        <nav className="pd-tpl-nav-content__nav">
          <button
            className={ `pd-button pd-button--navy ${ isNew ? 'pd-button--active' : '' }` }
            onClick={ handleItemClick.bind( this, { id: 0 } ) }>
            Add new item
          </button>

          <SortableContainer onSortEnd={ handleSortEnd } className="pd-tpl-nav-content__list" tagName="ul" useDragHandle>
            { ksUpdates.map( ( item, index ) => (
              <SortableItem
                index={ index }
                key={ index }
                className={ `pd-text pd-tpl-nav-content__item ${ item.id === ( currentItem && currentItem.id ) && 'pd-tpl-nav-content__item--active' } ` }
                onClick={ handleItemClick.bind( this, item ) }
                tagName="li">
                <DragHandle />
                <span className="pd-ellipsis">{ item.title }</span>
              </SortableItem>
            ) ) }
          </SortableContainer>
        </nav>

        { currentItem ?
          (

            <Formik
              initialValues={ {
                id: currentItem.id || '',
                title: currentItem.title || '',
                headline: currentItem.headline || '',
                url: currentItem.url || '',
                active: currentItem.active || false
              } }
              enableReinitialize={ true }
              onSubmit={ handleSave }
              validate={ handleValidation }
              validateOnChange={ false }
              validateOnBlur={ false }>
              { fProps => (

                <Form className="pd-tpl-nav-content__main" onChange={ event => setHasChanges( true ) }>
                  <div className="pd-tpl-nav-content__box pd-dashboard-ks-updates__box">
                    <span className="pd-text-sm pd-text--tip pd-tpl-nav-content__required">
                      <span className="pd-text--info">*</span> Required fields
                    </span>

                    <Field type="hidden" name="id" />
                    <CustomField label="Title" name="title" className="pd-dashboard-ks-updates__title" required />
                    <CustomField label="Headline" component="textarea" name="headline" className="pd-dashboard-ks-updates__headline" autoSize required maxLength="200" />
                    <CustomField label="URL" name="url" className="pd-dashboard-ks-updates__url" required />
                    <CheckboxField name="active" className="pd-dashboard-ks-updates__active">Visible in home page</CheckboxField>

                    <ImageUploadField
                      ref={ uploaderRef }
                      label="Image"
                      complement="1280x720px"
                      initialPreview={ currentItem.image ? getImageUrl( currentItem.image ) : null }
                      name="image"
                      className="pd-field pd-dashboard-ks-updates__image"
                      required
                    />

                    <nav className="pd-tpl-nav-content__actions pd-dashboard-ks-updates__actions">
                      <button className="pd-button pd-button--navy" type="submit" disabled={ fProps.isSubmitting }>{ fProps.isSubmitting ? 'Processing...' : 'Save' }</button>
                      <span className="pd-tpl-nav-content__actions-wrapper">
                        { isNew ? null : <button className="pd-button pd-button--danger" onClick={ handleDelete }>Delete</button> }
                        <button className="pd-button pd-button--glass-dark" onClick={ handleCancel }>Cancel</button>
                      </span>
                    </nav>
                  </div>
                </Form>

              ) }
            </Formik>

          ) : (

            <div className="pd-tpl-nav-content__main">
              <InfoTip>Use the carousel list to select an item to edit.</InfoTip>
            </div>

          )
        }
      </div>
    </section>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default DashboardKsUpdates