import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { GlobalContext } from '../../context/global-state'
import { CheckoutContext } from '../../context/checkout-state'

import { formatCurrency, getReadbleOrderState, getReadbleStockStatus, getPadNumber } from '../../config/adapters'
import { STOCK, PLEDGE_STATE } from '../../config/constants'

import { InfoTip, WarningTip, SuccessTip, ErrorTip } from '../../components/tip/tip'
import AmountSelector from '../../components/amount-selector/amount-selector'
import Accordion from '../../components/accordion/accordion'

import { ReactComponent as IconDown } from '../../assets/icons/down.svg'
import { ReactComponent as IconRight } from '../../assets/icons/right.svg'
import { ReactComponent as IconLock } from '../../assets/icons/lock.svg'

import './checkout-summary.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const CheckoutSummary = props => {

  const { className, ...rest } = props
  const context = useContext( GlobalContext )
  const checkoutContext = useContext( CheckoutContext )
  const { loggedUser } = context
  const { summary, pledge, project, shipping, updateOptionalBuyAmount } = checkoutContext
  const [ hasScroll, setScroll ] = useState( false )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => {
    window.removeEventListener( 'resize', handleSummaryScroll )
    window.addEventListener( 'resize', handleSummaryScroll )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const renderPledgeState = () => {
    if ( pledge.state === PLEDGE_STATE.FINISHED && pledge.hasLocalChanges ) return (
      <WarningTip>Order <strong>{ pledge.id }</strong> status: <strong>Not saved</strong></WarningTip>
    )

    switch ( pledge.state ) {
      case PLEDGE_STATE.FINISHED:
      case PLEDGE_STATE.READY_TO_SHIP:
      case PLEDGE_STATE.SHIPPED:
        return ( <SuccessTip>Order <strong>{ pledge.id }</strong> status: <strong>{ getReadbleOrderState( pledge.state, project.pledgeManagerState ) }</strong></SuccessTip> )

      case PLEDGE_STATE.PENDING:
      case PLEDGE_STATE.UNLOCKED:
        return ( <WarningTip>Order <strong>{ pledge.id }</strong> status: <strong>{ getReadbleOrderState( pledge.state ) }</strong></WarningTip> )

      case PLEDGE_STATE.REFUNDED:
      case PLEDGE_STATE.CANCELED:
      case PLEDGE_STATE.ERRORED:
      case PLEDGE_STATE.RETAILER_MISSED_DEADLINE:
        return ( <ErrorTip>Order <strong>{ pledge.id }</strong> status: <strong>{ getReadbleOrderState( pledge.state ) }</strong></ErrorTip> )

      case PLEDGE_STATE.CUSTOMER_MISSED_DEADLINE:
        return ( <ErrorTip>Order <strong>{ pledge.id }</strong> status: <strong>Expired. Project closed.</strong></ErrorTip> )

      default:
        return null
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const renderContents = ( contents, isLockedToOrder, cutoffWave ) => {
    return (
      <>
        <ul className="pd-checkout-summary__contents">
          { contents.map( ( content, index ) => (

            <li key={ index } className="pd-checkout-summary__content pd-text-sm">
              <span className="pd-text-sm">{ content.quantity }x</span>
              { content.product.name } { project.hasMultipleWaves && pledge.isMultiwave ? `(wave ${ content.product.wave })` : '' }
            </li>

          ) ) }
        </ul>

        { isLockedToOrder && (

          <WarningTip className="pd-checkout-summary__content-locked-disclaimer" micro>
            This item is locked as it contains products that are part of Wave { cutoffWave }
          </WarningTip>

        ) }
      </>
    )

  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const renderSummary = () => {
    if ( !loggedUser.isLogged ) return (

      <Link to={ `/sign-in?redirect=${ document.location.pathname }` } className="pd-button pd-button--navy">Please log-in to continue</Link>

    )

    if ( !loggedUser.isResolved ) return null


    if ( !loggedUser.data.confirmed ) return (

      <Link to={ `/profile` } className="pd-button pd-button--navy">Please confirm your account</Link>

    )

    if ( !summary.hasOptionalBuysSelected && !summary.hasBasePledgeSelected ) return (

      <p className="pd-text">No items selected. <br />Credit: { formatCurrency( summary.credit ) }</p>

    )

    return (

      <>
        <table className="pd-checkout-summary__table">
          <thead className="pd-title-4">
            <tr>
              <th>Description</th>
              <th>Qty</th>
              <th>Cost</th>
            </tr>
          </thead>
          <tbody className="pd-text">
            { summary.basePledge ? (

              <tr className="pd-checkout-summary__row">
                <td>
                  <Accordion
                    className="pd-checkout-summary__accordion"
                    indicator90={ <IconRight className={ `pd-icon pd-checkout-summary__expand-indicator` } /> }
                    heading={
                      <div className="pd-checkout-summary__accordion-heading">
                        { summary.basePledge.name }
                        { summary.basePledge.isLockedToOrder && ( <IconLock className="pd-icon pd-checkout-summary__icon-lock" /> ) }
                      </div>
                    }
                    content={ renderContents(
                      summary.basePledge.bundle.bundleProducts,
                      summary.basePledge.isLockedToOrder,
                      summary.basePledge.bundle.cutoffWave
                    ) }
                  />
                </td>
                <td>{ getPadNumber( summary.basePledge.amount ) }</td>
                <td>{ formatCurrency( summary.basePledge.price * summary.basePledge.amount ) }</td>
              </tr>

            ) : null }

            { summary.optionalBuys.map( ( item, index ) => {

              return (

                <tr key={ `${ item.id }.${ index }` } className="pd-checkout-summary__row">
                  <td>
                    <Accordion
                      className="pd-checkout-summary__accordion"
                      indicator90={ <IconRight className={ `pd-icon pd-checkout-summary__expand-indicator` } /> }
                      heading={
                        <div className="pd-checkout-summary__accordion-heading">
                          { item.name }
                          { item.isLockedToOrder && ( <IconLock className="pd-icon pd-checkout-summary__icon-lock" /> ) }
                        </div>
                      }
                      content={
                        <>
                          { item.bundle && renderContents(
                            item.bundle.bundleProducts,
                            item.isLockedToOrder,
                            item.bundle.cutoffWave
                          ) }

                          { item.addOn && renderContents(
                            item.addOn.addOnProducts,
                            item.isLockedToOrder,
                            item.addOn.cutoffWave
                          ) }
                        </>
                      }
                    />
                    <div className="pd-checkout-summary__disclaimers">
                      { item.minimumPerUser ? (
                        <WarningTip micro>Minimum units: <strong>{ item.minimumPerUser }</strong></WarningTip>
                      ) : null }

                      {/*
                      { item.stockStatus === STOCK.LIMITED ? (
                        <InfoTip micro>{ getReadbleStockStatus( item.stockStatus ) }</InfoTip>
                      ) : null }
                      */}

                      { item.stockStatus === STOCK.LOW || item.stockStatus === STOCK.SOLD_OUT ? (
                        <WarningTip micro>{ getReadbleStockStatus( item.stockStatus ) }</WarningTip>
                      ) : null }
                    </div>
                  </td>
                  <td>
                    { summary.isLocked || item.isLockedToOrder ?
                      (

                        getPadNumber( item.amount )

                      ) : (

                        <AmountSelector
                          mini={ true }
                          max={ item.maxAllowed }
                          initialValue={ item.amount }
                          onChange={ value => updateOptionalBuyAmount( item.uid, value ) }
                        />

                      ) }
                  </td>
                  <td>{ formatCurrency( item.price * item.amount ) }</td>
                </tr>

              )
            } ) }

            { shipping.hasAddress ? (

              <>

                <tr className={ `pd-checkout-summary__row` }>
                  <td><strong>Sub-total</strong></td>
                  <td></td>
                  <td><strong>{ formatCurrency( summary.subTotal ) }</strong></td>
                </tr>

                { summary.cartVat !== null && summary.cartVat > 0 ? (

                  <tr className={ `pd-checkout-summary__row ${ summary.isCalculatingShippingCost ? 'pd-checkout-summary__row--processing' : '' }` }>
                    <td>VAT</td>
                    <td></td>
                    <td>{ formatCurrency( summary.cartVat ) }</td>
                  </tr>

                ) : null }

                <tr className={ `pd-checkout-summary__row ${ summary.isCalculatingShippingCost ? 'pd-checkout-summary__row--processing' : '' }` }>
                  <td>Shipping + Handling fees</td>
                  <td></td>
                  <td className={ summary.shippingCost !== null ? '' : 'pd-checkout-summary__cell--negative' }>{ summary.shippingCost !== null ? formatCurrency( summary.shippingCost ) : <p className="pd-text-sm"><strong>Pending</strong></p> }</td>
                </tr>

                { summary.shippingVat !== null && summary.shippingVat > 0 ? (

                  <tr className={ `pd-checkout-summary__row ${ summary.isCalculatingShippingCost ? 'pd-checkout-summary__row--processing' : '' }` }>
                    <td>VAT (Shipping + Handling fees)</td>
                    <td></td>
                    <td>{ formatCurrency( summary.shippingVat ) }</td>
                  </tr>

                ) : null }

              </>

            ) : null }

            { summary.orderTotal ? (

              <tr className={ `pd-checkout-summary__row ${ pledge.isSaving ? 'pd-checkout-summary__row--processing' : '' } ` }>
                <td><strong>Order total</strong></td>
                <td></td>
                <td><strong>{ formatCurrency( summary.orderTotal ) }</strong></td>
              </tr>

            ) : null }

            { summary.credit ? (

              <tr>
                <td>Credit</td>
                <td></td>
                <td>({ formatCurrency( summary.credit ) })</td>
              </tr>

            ) : null }

            { project.retailer ? (

              <tr>
                <td><strong>Deposit Fee</strong></td>
                <td></td>
                <td>{ formatCurrency( project.depositFee ) }</td>
              </tr>

            ) : null }

            <tr className={ `pd-checkout-summary__row ${ pledge.isSaving ? 'pd-checkout-summary__row--processing' : '' }` }>
              <td><strong>{ summary.hasRemainingCredit ? 'Credit remaining' : 'Balance due' }</strong></td>
              <td></td>
              <td className={ `
                ${ summary.hasRemainingCredit ? 'pd-checkout-summary__cell--positive' : '' }
                ${ summary.hasBalanceDue ? 'pd-checkout-summary__cell--negative' : '' }
                ` }><strong>{ formatCurrency( Math.abs( summary.balance ) ) }</strong></td>
            </tr>

            { project.retailer && !pledge.hasLocalChanges && (
              pledge.state === PLEDGE_STATE.FINISHED ||
              pledge.state === PLEDGE_STATE.READY_TO_SHIP ||
              pledge.state === PLEDGE_STATE.SHIPPED
            ) ? (

              <tr>
                <td><strong>Remaining</strong></td>
                <td></td>
                <td className="pd-checkout-summary__cell--negative">
                  <strong>{ formatCurrency( summary.orderTotal - summary.credit ) }</strong>
                </td>
              </tr>

            ) : null }


          </tbody>
        </table>

        { summary.hasRemainingCredit ? (

          <InfoTip>
            You have remaining credit in this order. Either select more product to consume these credits
            or contact support for a refund once your order is confirmed.
          </InfoTip>

        ) : null }

        { project.retailer && project.depositFee ? (

          <InfoTip micro>
            We’re only collecting the commitment deposit to confirm your order. The remaining balance will be due and you will
            receive a CMON and/or a PayPal invoice prior to your final pledge being shipped.
          </InfoTip>

        ) : null }
      </>

    )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSummaryScroll = () => {
    const el = document.getElementById( 'pd-checkout__summary' )

    if ( !el ) return

    const { clientHeight, scrollHeight, scrollTop } = el
    const hasScroll = scrollHeight > clientHeight
    const hasDiff = ( ( scrollHeight - clientHeight ) - scrollTop ) > 10
    setScroll( hasScroll && hasDiff )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )
  useEffect( handleSummaryScroll, [ summary ] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <div onScroll={ handleSummaryScroll } className={ `pd-checkout-summary notranslate ${ className }` } { ...rest }>
      { renderPledgeState() }

      <h3 className="pd-title-3">Summary</h3>

      { renderSummary() }

      <IconDown className={ `pd-icon pd-checkout-summary__scroll-indicator ${ hasScroll ? 'pd-checkout-summary__scroll-indicator--visible' : '' }` } />
    </div>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default CheckoutSummary