import React, { useContext } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Formik, Field, Form } from 'formik'

import { UIContext } from '../../context/ui-state'

import { rFetch } from '../../config/fetch'
import { toSnakeCase } from '../../config/adapters'
import { EMAIL_REGEX } from '../../config/validators'

import CustomField from '../../components/custom-field/custom-field'

import './password-recovery.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const PasswordRecovery = props => {

  const UI = useContext( UIContext )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleValidation = values => {
    let errors = {}

    if ( !EMAIL_REGEX.test( values.email ) ) errors.email = 'Invalid e-mail'

    return errors
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSubmit = async ( payload, actions ) => {
    try {

      const req = await rFetch( '/auth/password', { method: 'post', body: JSON.stringify( toSnakeCase( payload ) ) } )
      UI.showSuccessDisclaimer( req.message )

    } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }

    actions.setSubmitting( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <section className="pd-password-recovery pd-page pd-form-box-page">
      <Formik
        initialValues={ { email: '', redirectUrl: `${ document.location.origin }/password/reset` } }
        onSubmit={ handleSubmit }
        validate={ handleValidation }
        validateOnChange={ false }
        validateOnBlur={ false }>
        { fProps => (

          <Form className="pd-form-box-page__form">
            <Field type="hidden" name="redirectUrl" />

            <header className="pd-form-box-page__header">
              <h4 className="pd-title-3">Password recovery</h4>
              <p className="pd-text">Please enter your registered email below, we will send you instructions to reset your password.</p>
            </header>

            <CustomField name="email" type="email" label="E-mail" required />

            <nav className="pd-form-box-page__actions">
              <button className="pd-button pd-button--navy" type="submit" disabled={ fProps.isSubmitting }>{ fProps.isSubmitting ? 'Processing...' : 'Recovery' }</button>
              <Link className="pd-button pd-button--link" to="/sign-in">or back to sign-in</Link>
            </nav>
          </Form>

        ) }
      </Formik>
    </section>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default withRouter( PasswordRecovery )