import React, { useContext } from 'react'

import { GlobalContext } from '../../context/global-state'
import { CheckoutContext } from '../../context/checkout-state'

import './checkout-canceled-disclaimer.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const CheckoutCanceledDisclaimer = props => {

  const context = useContext( GlobalContext )
  const checkoutContext = useContext( CheckoutContext )
  const { isRetailer } = context
  const { project } = checkoutContext
  const { supportUrl, supportEmail } = project
  const { className = '' } = props

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <div className={ `pd-checkout-canceled-disclaimer ${ className }` }>
      <h3 className="pd-title-3">Your pledge has been canceled.</h3>
      { supportUrl || supportEmail ? (

        <p className="pd-text">
          If you’d like to pledge again to this project, or have any other questions, please contact this project's
          <a
            href={ supportUrl || `mailto:${ supportEmail }` }
            className="pd-button pd-button--navy pd-button--text"
            target="_blank"
            rel="noopener noreferrer">
            { isRetailer ? 'retailer support' : 'customer support' }
          </a>.
        </p>

      ) : null }
    </div>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default CheckoutCanceledDisclaimer