import React, { useContext, useEffect } from 'react'

import { CheckoutContext } from '../../context/checkout-state'

import { getImageSrc, getYouTubeID, getRichTextContent } from '../../config/adapters'

import { ReactComponent as KsLogo } from '../../assets/images/ks-logo.svg'
import imgRatio from '../../assets/images/ratio-16x9.gif'

import './checkout-project-info.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const CheckoutProjectInfo = props => {

  const checkoutContext = useContext( CheckoutContext )
  const { project } = checkoutContext
  const { className = '' } = props

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => {
    setupVideo()
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const setupVideo = async () => {
    if ( project.video ) {

      const YT = await getYT()
      const player = new YT.Player( 'player', {
        width: '640',
        height: '360',
        videoId: getYouTubeID( project.video ),
        events: {
          'onStateChange': event => {
            if ( event.data === YT.PlayerState.ENDED ) player.stopVideo()
          }
        }
      } )

    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const getYT = async () => new Promise( ( resolve, reject ) => {
    const interval = setInterval( () => {
      if ( window.YT ) {
        clearInterval( interval )
        resolve( window.YT )
      }
    }, 300 )
  } )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <div className={ `pd-checkout-project-info ${ className }` }>
      <header className="pd-checkout-project-info__header">
        <h3 className="pd-title-2">{ project.name }</h3>
        { project.description && ( <div className="pd-checkout-project-info__content" dangerouslySetInnerHTML={ getRichTextContent( project.description ) }></div> ) }
      </header>

      <div className="pd-checkout-project-info__contents">
        { project.video ? (

          <div className="pd-checkout-project-info__video">
            <img src={ imgRatio } alt={ project.name } />
            <div id="player"></div>
          </div>

        ) : (

            <figure className="pd-checkout-project-info__figure">
              <img src={ getImageSrc( project.image, { w: 640, h: 360, resizeTo: 'fill', quality: 60 } ) } alt={ project.name } />
            </figure>

          )
        }

        { project.kickstarterUrl && (

          <a href={ project.kickstarterUrl } className="pd-button pd-button--navy pd-button--icon pd-checkout-project-info__ks-cta" target="_blank" rel="noopener noreferrer">
            <KsLogo className="pd-icon" /> Visit this project on Kickstarter!
          </a>

        ) }

        { project.retailerInfoUrl && (

          <a href={ project.retailerInfoUrl } className="pd-button pd-button--cyan pd-button--icon pd-checkout-project-info__retailers-cta" target="_blank" rel="noopener noreferrer">
            Information for retailers
          </a>

        ) }
      </div>
    </div>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default CheckoutProjectInfo