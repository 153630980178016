import React, { useState, useEffect, useReducer } from 'react'
import { Redirect } from 'react-router-dom'
import { flattenDeep } from 'lodash'
import queryString from 'query-string'

import Env from '../config/env'
import cookie from '../config/cookie'
import { eFetch, sFetch, rFetch, storeHeaders, checkStatus, parseJson, KEYS } from '../config/fetch'
import { toSnakeCase } from '../config/adapters'

import LoadingMessage from '../components/loading-message/loading-message'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const GlobalContext = React.createContext()

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const GlobalState = props => {

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const checkCookie = () => !!cookie.get( 'access-token' )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  const [ worker, setWorker ] = useState( null )
  const [ isOnline, setOnlineState ] = useState( navigator.onLine )
  const [ isServerOffline, setServerOfflineState ] = useState( false )
  const [ hasUpdateAvailable, setUpdateAvailable ] = useState( false )
  const [ showRetailerDisclaimer, setRetailerDisclaimer ] = useState( false )

  const [ loggedUser, setLoggedUser ] = useState( {
    isLogged: checkCookie(),
    isFetching: false,
    isFetched: false,
    isResolved: !checkCookie(),
    isAdmin: false,
    isRetailer: false,
    data: null,
    projects: []
  } )
  const [ countries, setCountries ] = useState( [] )

  const provincesReducer = ( state, action ) => ( { ...state, [ action.countryId ]: action.provinces } )
  const [ provinces, dispatchProvinces ] = useReducer( provincesReducer, {} )
  const [ pledgeList, setPledgeList ] = useState( [] )
  const [ userPledges, setUserPledges ] = useState( {
    list: [],
    isFetching: false,
    isFetched: false
  } )

  const isRetailer = document.location.hostname.substr( 0, 8 ) === 'retailer'

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => {
    handleServiceWorker()
    checkServerState()

    window.addEventListener( 'online', () => {
      if ( !isOnline ) document.location.reload()
      setOnlineState( true )
    } )
    window.addEventListener( 'offline', () => setOnlineState( false ) )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const checkServerState = async () => {
    try {

      const req = await fetch( '/manifest.json' )
      setServerOfflineState( !req.ok )

    } catch ( exception ) {

      setServerOfflineState( true )

    }

    setTimeout( checkServerState, ( parseInt( Env.applicationStatusCheckerInterval || 30 ) ) * 1000 )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleServiceWorker = async () => {
    const { serviceWorker } = navigator
    let refreshing = false

    if ( serviceWorker && Env.registerServiceWorker ) {
      serviceWorker.register( '/service-worker.js' ).then( reg => {
        reg.onupdatefound = () => {
          cookie.set( 'sw-update-available', 1 )

          const installingWorker = reg.installing
          setWorker( installingWorker )
          installingWorker.onstatechange = () => {
            if ( installingWorker.state === 'installed' ) {
              installingWorker.postMessage( { type: 'SKIP_WAITING' } )
              setUpdateAvailable( true )
            }
          }
        }
      } )

      serviceWorker.addEventListener( 'controllerchange', () => {
        if ( refreshing ) return
        cookie.remove( 'sw-update-available' )
        window.location.reload()
        refreshing = true
      } )
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const checkLoginState = () => {
    setLoggedUser( { ...loggedUser, isLogged: checkCookie() } )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const checkLoggedUser = () => {
    if ( loggedUser.isLogged && !loggedUser.isFetched && !loggedUser.isFetching ) fetchUser()
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const signUp = async payload => {
    try {

      setLoggedUser( { ...loggedUser, isFetching: true } )

      const json = await eFetch( '/auth', { method: 'post', body: JSON.stringify( payload ) }, true )
        .then( storeHeaders )
        .then( parseJson )
      storeLoggedUser( json )

    } catch ( exception ) { throw exception }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const signIn = async credentials => {
    try {

      setLoggedUser( { ...loggedUser, isFetching: true } )
      setRetailerDisclaimer( false )

      const json = await eFetch( '/auth/sign_in', { method: 'post', body: JSON.stringify( credentials ) }, true )
        .then( storeHeaders )
        .then( parseJson )
      storeLoggedUser( json )

      if ( isRetailer && !json.retailer && !json.companies.length ) {
        await signOut()
        setRetailerDisclaimer( true )
        return false
      }

      return true

    } catch ( exception ) { throw exception }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const signOut = () => {
    return new Promise( resolve => {
      cookie.removeAll()

      setLoggedUser( {
        isLogged: false,
        isFetched: false,
        isFetching: false,
        isResolved: true,
        isAdmin: false,
        isRetailer: false,
        data: null,
        projects: []
      } )

      resolve()
    } )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const fetchUser = async () => {
    try {

      setLoggedUser( { ...loggedUser, isFetching: true, isResolved: false } )

      const json = await sFetch( '/profile', {}, false, true )
      storeLoggedUser( json )

    } catch ( exception ) {

      if ( exception.status === 401 ) setLoggedUser( {
        isLogged: false,
        isFetching: false,
        isFetched: false,
        isResolved: true,
        isAdmin: false,
        isRetailer: false,
        data: null,
        projects: []
      } )

      cookie.removeAll()
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const updateUser = async payload => {
    try {

      setLoggedUser( { ...loggedUser, isFetching: true } )

      const json = await sFetch( '/auth', { method: 'put', body: JSON.stringify( payload ) } )
      storeLoggedUser( json )

    } catch ( exception ) { throw exception }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const resetPassword = async payload => {

    try {
      let headers = {}
      const urlParams = queryString.parse( document.location.search )
      KEYS.forEach( key => headers[ key ] = urlParams[ key ] || '' )

      setLoggedUser( { ...loggedUser, isFetching: true } )

      const json = await eFetch( '/auth/password', { method: 'put', headers: headers, body: JSON.stringify( toSnakeCase( payload ) ) } )
        .then( storeHeaders )
        .then( parseJson )
      storeLoggedUser( json )

    } catch ( exception ) { throw exception }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const sendConfirmationEmail = async () => {
    try {

      await sFetch( '/profile/resend_confirmation_email', { method: 'post' } )

    } catch ( exception ) { throw exception }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const secureRender = component => {
    const { isLogged, isFetched, isAdmin } = loggedUser
    const { pathname } = document.location
    const atDashboard = pathname.substr( 0, 10 ) === '/dashboard'

    if ( isLogged && !isFetched ) return <div key={ component.key } className="pd-tpl-dashboard pd-page"><header className="pd-tpl-dashboard__header"><LoadingMessage /></header></div>
    if ( isLogged && isFetched && atDashboard && !isAdmin ) return <Redirect to={ `/` } />
    if ( !isLogged ) return <Redirect to={ `/sign-in?redirect=${ pathname }` } />
    return component
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const fetchCountries = async () => {
    const list = await rFetch( `/countries` )
    setCountries( list )
    return list
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const fetchProvinces = async countryId => {
    let newProvinces = await rFetch( `/states?country_id=${ countryId }` )
    if ( isFinite( parseInt( newProvinces[ 0 ].code ) ) ) newProvinces = newProvinces.map( p => ( { ...p, code: p.name } ) )
    dispatchProvinces( { countryId: countryId, provinces: newProvinces } )
    return newProvinces
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const geocodeAddress = async address => {
    try {

      const endpoint = `https://maps.googleapis.com/maps/api/geocode/json?address=${ encodeURIComponent( address ) }&key=${ Env.googleApiKey }&language=en`
      return fetch( endpoint ).then( checkStatus.bind( this, false ) ).then( parseJson ).catch( parseJson )

    } catch ( exception ) { throw exception }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const fetchPledges = async () => {
    try {

      setUserPledges( {
        list: [],
        isFetching: true,
        isFetched: false
      } )

      const pledges = await sFetch( `/geeks?retailer=${ isRetailer }`, {}, false, true )

      setPledgeList( pledges )
      setUserPledges( {
        list: pledges,
        isFetching: false,
        isFetched: true
      } )

    } catch ( exception ) {

      setUserPledges( {
        list: [],
        isFetching: false,
        isFetched: false
      } )

    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const storeLoggedUser = userData => {
    setLoggedUser( {
      isLogged: true,
      isFetched: true,
      isFetching: false,
      isResolved: true,
      isAdmin: userData.companies.length > 0,
      isRetailer: userData.retailer,
      data: userData,
      projects: flattenDeep( userData.companies.map( company => company.projects ) )
    } )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <GlobalContext.Provider value={ {
      worker: worker,
      isOnline: isOnline,
      isServerOffline: isServerOffline,
      isRetailer: isRetailer,
      showRetailerDisclaimer: showRetailerDisclaimer,
      hasUpdateAvailable: hasUpdateAvailable,

      loggedUser: loggedUser,

      signUp: signUp,
      signIn: signIn,
      signOut: signOut,
      fetchUser: fetchUser,
      updateUser: updateUser,
      resetPassword: resetPassword,
      sendConfirmationEmail: sendConfirmationEmail,
      checkLoggedUser: checkLoggedUser,
      checkLoginState: checkLoginState,
      fetchCountries: fetchCountries,
      fetchProvinces: fetchProvinces,
      fetchPledges: fetchPledges,
      geocodeAddress: geocodeAddress,
      countries: countries,
      provinces: provinces,
      pledgeList: pledgeList,
      userPledges: userPledges,

      secureRender: secureRender

    } }>{ props.children }</GlobalContext.Provider>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  
}