import React, { useContext, useEffect, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { isBoolean } from 'lodash'

import { renderRoute } from '../../config/routes'
import { GlobalContext } from '../../context/global-state'
import { UIContext } from '../../context/ui-state'
import { DashboardContext, DashboardState } from '../../context/dashboard-state'

import { PLEDGE_MANAGER_STATE } from '../../config/constants'
import { getReadblePledgeManagerState } from '../../config/adapters'

import NotFound from '../../pages/not-found/not-found'
import LoadingMessage from '../../components/loading-message/loading-message'
import { InfoTip, WarningTip } from '../../components/tip/tip'
import Switch from '../../components/switch/switch'

import './dashboard-project.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const DashboardProject = props => {

  const context = useContext( GlobalContext )
  const UI = useContext( UIContext )
  const dashboardContext = useContext( DashboardContext )
  const { secureRender } = context
  const { fetchProject, publishProject, closeProject, updateProject, openProjectPledgeManager, project } = dashboardContext
  const { routes } = props
  const { projectId } = useParams()
  const [ isPublishing, setPublishing ] = useState( false )
  const [ isClosing, setClosing ] = useState( false )
  const [ isVisible, setVisible ] = useState( null )
  const sideNav = [
    { label: 'Overview', url: '', essencial: true },
    { label: 'Activity', url: 'activity', essencial: false },
    { label: 'Backer report', url: 'backer-report', essencial: false },
    { label: 'Backer data import', url: 'backer-data-import', essencial: false },
    { label: 'Pledge editor', url: 'pledge-editor', essencial: false },
    { label: 'Packing slips', url: 'packing-slips', essencial: false },
    { label: 'Shipping tables', url: 'shipping-tables', essencial: false },
    { label: 'Shipping waves', url: 'shipping-waves', essencial: false },
    { label: 'Notifications', url: 'notifications', essencial: false },
    { label: 'Basic information', url: 'basic-information', essencial: true },
    { label: 'Rewards summary', url: 'rewards-summary', essencial: true },
    { label: 'Rewards & Add-ons', url: 'rewards', essencial: true },
    { label: 'Products', url: 'products', essencial: true },
  ]

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => {
    fetchProject( projectId )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handlePublish = async () => {
    if ( !window.confirm( 'Are you sure?' ) ) return

    setPublishing( true )

    try {

      await publishProject()
      UI.showSuccessDisclaimer( 'Your project was published successfully!' )

    } catch ( exception ) {

      UI.showErrorDisclaimer( exception.message )

    }

    setPublishing( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleVisibilityChange = async payload => {
    try {

      await updateProject( { hidden: !payload } )

    } catch ( exception ) {

      UI.showErrorDisclaimer( exception.message )
      setVisible( false )

    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleOpenPledgeManagerClick = async event => {
    if ( !window.confirm( 'Are you sure?' ) ) return

    setPublishing( true )

    try {

      await openProjectPledgeManager()


    } catch ( exception ) {

      UI.showErrorDisclaimer( exception )

    }

    setPublishing( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleProjectCloseClick = async event => {
    if ( !window.confirm( 'Are you sure?' ) ) return

    setClosing( true )

    try {

      await closeProject()
      UI.showSuccessDisclaimer( 'Your project was closed successfully!' )

    } catch ( exception ) {

      UI.showErrorDisclaimer( exception.message )

    }

    setClosing( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )
  useEffect( () => {
    if ( project.isFetched ) setVisible( !project.details.hidden )
  }, [ project ] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  if ( project.error && project.error.status === 404 ) return <NotFound />

  return secureRender(

    <section className={ `pd-dashboard-project pd-tpl-dashboard pd-page` }>
      { project.isFetched ? (
        <>
          <header className="pd-tpl-dashboard__header">
            <div className="pd-tpl-dashboard__breadcrumb">
              <h2 className="pd-title-2">{ project.details.name }</h2>
            </div>

            { project.details.waves > 1 ? (

              <InfoTip>
                Wave <strong>{ project.details.currentWave }</strong> of <strong>{ project.details.waves }</strong>
              </InfoTip>

            ) : null }

            <InfoTip>
              <span>Pledge manager state: <strong>{ getReadblePledgeManagerState( project.details.pledgeManagerState ) }</strong></span>
              { project.details.pledgeManagerState === PLEDGE_MANAGER_STATE.LATE_PLEDGE ? (

                <button
                  onClick={ handleOpenPledgeManagerClick }
                  disabled={ isPublishing }
                  className="pd-button pd-button--navy">
                  { isPublishing ? 'Opening...' : 'Open Pledge Manager' }
                </button>

              ) : null }
            </InfoTip>
          </header>

          <nav className="pd-tpl-dashboard__nav">
            { isBoolean( isVisible ) ? (

              <div className={ `pd-dashboard-project__visibility ${ isVisible ? '' : 'pd-dashboard-project__visibility--hidden' }` }>
                <h5 className="pd-label">Project visibility</h5>
                <Switch
                  label={ project.isUpdating ? 'Updating...' : [ 'Visible', 'Hidden' ] }
                  confirm="Are you sure?"
                  checked={ isVisible }
                  disabled={ project.isUpdating }
                  onChange={ handleVisibilityChange }
                />
              </div>

            ) : null }

            <ul className="pd-tpl-dashboard__nav-list">
              { sideNav
                .filter( item => project.details.pledgeManagerState === PLEDGE_MANAGER_STATE.DRAFT ? item.essencial : true )
                .map( ( item, index ) => (

                  <li key={ index } className="pd-tpl-dashboard__nav-item">
                    <NavLink
                      to={ `/dashboard/project/${ projectId }/${ item.url }` }
                      className="pd-button pd-button--cyan"
                      activeClassName="pd-button--active"
                      exact={ true }>{ item.label }</NavLink>
                  </li>

                ) ) }
            </ul>

            { project.details.pledgeManagerState === PLEDGE_MANAGER_STATE.DRAFT ? (
              <div className="pd-tpl-dashboard__publish-cta">
                <WarningTip>This project is not published yet.</WarningTip>
                <button
                  className="pd-button pd-button--navy"
                  onClick={ handlePublish }
                  disabled={ isPublishing }>
                  { isPublishing ? 'Publishing...' : 'Publish now' }
                </button>
              </div>
            ) : null }

            { project.details.pledgeManagerState === PLEDGE_MANAGER_STATE.LATE_CONFIRMS ||
              project.details.pledgeManagerState === PLEDGE_MANAGER_STATE.OPENED ? (

              <button
                className="pd-button pd-button--danger pd-dashboard-project__close-cta"
                onClick={ handleProjectCloseClick }
                disable={ isClosing }>
                { isClosing ? 'Processing...' : 'Close project' }
              </button>

            ) : null }
          </nav>

          <div className="pd-tpl-dashboard__container">
            { routes.map( ( route, index ) => secureRender( renderRoute( route, index ) ) ) }
          </div>
        </>
      ) : <LoadingMessage /> }
    </section>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

const DashboardProjectWrapper = props => {
  const { params } = props.match

  return (
    <DashboardState params={ params }>
      <DashboardProject { ...props } />
    </DashboardState>
  )
}

export default DashboardProjectWrapper