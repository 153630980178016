import React, { useEffect, useContext, useState } from 'react'
import { PortalWithState } from 'react-portal'
import moment from 'moment-timezone'

import { UIContext } from '../../context/ui-state'
import { sFetch } from '../../config/fetch'

import { InfoTip } from '../../components/tip/tip'
import LoadingMessage from '../../components/loading-message/loading-message'
import Lightbox from '../../components/lightbox/lightbox'

import './tracking-numbers.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const TrackingNumbers = props => {

  const UI = useContext( UIContext )
  const [ currentItem, setCurrentItem ] = useState( null )
  const [ list, setList ] = useState( [] )
  const [ isFetched, setFetched ] = useState( false )
  const [ isUploading, setUploading ] = useState( false )
  const [ csvFile, setCsvFile ] = useState( null )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => {
    fetchList()
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const fetchList = async () => {
    try {

      const list = await sFetch( `/geeks/tracking_numbers` )
      setList( list )
      setFetched( true )
      if ( list.length > 0 ) setCurrentItem( list[ 0 ] )

    } catch ( exception ) {

      setFetched( false )

    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const renderListItem = item => {
    const itemClassName = `pd-text pd-tpl-nav-content__item ${ item.id === ( currentItem && currentItem.id ) ? 'pd-tpl-nav-content__item--active' : '' }`
    return (
      <li
        key={ item.id }
        className={ itemClassName }
        onClick={ handleItemClick.bind( this, item ) }>
        <span>{ moment( item.createdAt ).format( 'MMMM Do YYYY hh:mm' ) }</span>
      </li>
    )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleUpload = async event => {
    if ( !csvFile ) return null

    setUploading( true )

    UI.showInfoDisclaimer( 'Start uploading.' )

    const formData = new FormData()
    formData.append( 'file', csvFile )

    try {

      await sFetch( `/geeks/tracking_numbers`, { method: 'post', body: formData }, true )
      await fetchList()

      UI.showSuccessDisclaimer( 'Import data in progress disclaimer' )

    } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }

    setUploading( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleItemClick = item => setCurrentItem( item )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleCurrenItemChange = () => {
    if ( currentItem && !currentItem.finishedAt ) setTimeout( fetchList, 10000 )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )
  useEffect( handleCurrenItemChange, [ currentItem ] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <section className="pd-tracking-numbers pd-tpl-nav-content pd-tpl-dashboard pd-tpl-dashboard--without-nav pd-page">
      { isFetched ? (
        <>
          <nav className="pd-tpl-nav-content__nav">
            <ul className="pd-tpl-nav-content__list">
              <li className="pd-tracking-numbers__item pd-tpl-nav-content__item">
                <input
                  disabled={ isUploading }
                  type="file"
                  accept="text/csv"
                  className="pd-text-sm pd-tracking-numbers__uploader"
                  onChange={ event => setCsvFile( event.target.files[ 0 ] ) }
                />
                <button className="pd-button pd-button--navy" onClick={ handleUpload } disabled={ isUploading }>{ isUploading ? 'Processing...' : 'Import' }</button>
              </li>
              { list.map( renderListItem ) }
            </ul>
          </nav>

          <div className="pd-tpl-nav-content__main">
            {
              currentItem ? (

                <div className="pd-tpl-nav-content__box pd-tracking-numbers__box">
                  <InfoTip><strong>Import progress:</strong> { Math.round( currentItem.progressPercentage * 100 ).toFixed( 2 ) }%</InfoTip>
                  <div className="pd-tracking-numbers__details">
                    <p className="pd-text">Created at: { moment( currentItem.createdAt ).format( 'MMMM Do YYYY hh:mm' ) }</p>
                    <p className="pd-text">Updated at: { moment( currentItem.updatedAt ).format( 'MMMM Do YYYY hh:mm' ) }</p>
                    { currentItem.finishedAt && ( <p className="pd-text">Finished at: { moment( currentItem.finishedAt ).format( 'MMMM Do YYYY hh:mm' ) }</p> ) }
                    <p className="pd-text">Total numbers: { currentItem.totalNumbers || '0' } </p>
                  </div>

                  <PortalWithState>
                    { portalBag => (
                      <>
                        <button onClick={ portalBag.openPortal } className="pd-button pd-button--cyan">View full message</button>

                        { portalBag.portal(
                          <Lightbox portalBag={ portalBag }>
                            <pre className="pd-tracking-numbers__full pd-text">{ currentItem.status }</pre>
                          </Lightbox>
                        ) }
                      </>
                    ) }
                  </PortalWithState>
                </div>

              ) : (

                <InfoTip>{ list.length ? 'Use the following list to get import details.' : 'Tracking numbers data not available yet.' }</InfoTip>

              )
            }
          </div>
        </>

      ) : <LoadingMessage /> }
    </section>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default TrackingNumbers