import React, { useState, useReducer } from 'react'

import { sFetch } from '../config/fetch'
import { toSnakeCase } from '../config/adapters'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const DashboardContext = React.createContext()

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const DashboardState = props => {

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const [ user, setUser ] = useState( {
    details: {},
    isFetching: false,
    isFetched: false,
    isUpdating: false,
    error: null
  } )

  const [ project, setProject ] = useState( {
    details: {},
    isFetching: false,
    isFetched: false,
    isUpdating: false,
    error: null
  } )

  const [ pledge, setPledge ] = useState( {
    details: {},
    isFetching: false,
    isFetched: false,
    isUpdating: false,
    error: null
  } )

  const [ collaborators, setCollaborators ] = useState( {
    list: [],
    isFetching: false,
    isFetched: false,
    error: null
  } )

  const STATISTICS = {
    FETCH_TOTAL: 'FETCH_TOTAL',
    FETCH_TOTAL_SUCCESS: 'FETCH_TOTAL_SUCCESS',
    FETCH_CONFIRMED: 'FETCH_CONFIRMED',
    FETCH_CONFIRMED_SUCCESS: 'FETCH_CONFIRMED_SUCCESS',
    FETCH_ORDERED: 'FETCH_ORDERED',
    FETCH_ORDERED_SUCCESS: 'FETCH_ORDERED_SUCCESS',
    FETCH_HUB: 'FETCH_HUB',
    FETCH_HUB_SUCCESS: 'FETCH_HUB_SUCCESS',
    FETCH_TRANSACTIONS: 'FETCH_TRANSACTIONS',
    FETCH_TRANSACTIONS_SUCCESS: 'FETCH_TRANSACTIONS_SUCCESS'
  }

  const [ statistics, dispatchStatistics ] = useReducer( ( state, action ) => {

    switch ( action.type ) {
      case STATISTICS.FETCH_TOTAL:
        const fetchTotal = ( state, action ) => ( {
          ...state,
          total: {
            isFetching: true,
            isFetched: false,
            data: null
          }
        } )

        return fetchTotal( state, action )

      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

      case STATISTICS.FETCH_TOTAL_SUCCESS:
        const fetchTotalSuccess = ( state, action ) => ( {
          ...state,
          total: {
            isFetching: false,
            isFetched: true,
            data: action.payload
          }
        } )

        return fetchTotalSuccess( state, action )

      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

      case STATISTICS.FETCH_CONFIRMED:
        const fetchConfirmed = ( state, action ) => ( {
          ...state,
          totalConfirmed: {
            isFetching: true,
            isFetched: false,
            data: null
          }
        } )

        return fetchConfirmed( state, action )

      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

      case STATISTICS.FETCH_CONFIRMED_SUCCESS:
        const fetchConfirmedSuccess = ( state, action ) => ( {
          ...state,
          totalConfirmed: {
            isFetching: false,
            isFetched: true,
            data: action.payload
          }
        } )

        return fetchConfirmedSuccess( state, action )

      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

      case STATISTICS.FETCH_ORDERED:
        const fetchOrdered = ( state, action ) => ( {
          ...state,
          totalOrdered: {
            isFetching: true,
            isFetched: false,
            data: null
          }
        } )

        return fetchOrdered( state, action )

      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

      case STATISTICS.FETCH_ORDERED_SUCCESS:
        const fetchOrderedSuccess = ( state, action ) => ( {
          ...state,
          totalOrdered: {
            isFetching: false,
            isFetched: true,
            data: action.payload
          }
        } )

        return fetchOrderedSuccess( state, action )

      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

      case STATISTICS.FETCH_HUB:
        const fetchHub = ( state, action ) => ( {
          ...state,
          totalByHub: {
            isFetching: true,
            isFetched: false,
            data: null
          }
        } )

        return fetchHub( state, action )

      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

      case STATISTICS.FETCH_HUB_SUCCESS:
        const fetchHubSuccess = ( state, action ) => ( {
          ...state,
          totalByHub: {
            isFetching: false,
            isFetched: true,
            data: action.payload
          }
        } )

        return fetchHubSuccess( state, action )

      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

      case STATISTICS.FETCH_TRANSACTIONS:
        const fetchTransactions = ( state, action ) => ( {
          ...state,
          transactions: {
            isFetching: true,
            isFetched: false,
            data: null
          }
        } )

        return fetchTransactions( state, action )

      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

      case STATISTICS.FETCH_TRANSACTIONS_SUCCESS:
        const fetchTransactionsSuccess = ( state, action ) => ( {
          ...state,
          transactions: {
            isFetching: false,
            isFetched: true,
            data: action.payload
          }
        } )

        return fetchTransactionsSuccess( state, action )

      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

      default:
        return state

      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    }

  }, {
    total: {
      data: null,
      isFetching: false,
      isFetched: false
    },
    totalConfirmed: {
      data: [],
      isFetching: false,
      isFetched: false
    },
    totalOrdered: {
      data: [],
      isFetching: false,
      isFetched: false
    },
    totalByHub: {
      data: [],
      isFetching: false,
      isFetched: false
    },
    transactions: {
      data: [],
      isFetching: false,
      isFetched: false
    }
  } )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const fetchUser = async () => {
    try {

      const { params } = props

      setUser( {
        ...user,
        details: {},
        isFetching: true,
        isFetched: false
      } )

      const details = await sFetch( `/users/report/${ params.userId }` )

      setUser( {
        ...user,
        details: details,
        isFetching: false,
        isFetched: true,
        error: null
      } )

    } catch ( exception ) {

      setUser( {
        details: {},
        isFetching: false,
        isFetched: false,
        error: exception
      } )

    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const fetchProject = async projectId => {
    try {

      setProject( {
        ...project,
        details: {},
        isFetching: true,
        isFetched: false
      } )

      const details = await sFetch( `/projects/${ projectId }` )

      setProject( {
        ...project,
        details: details,
        isFetching: false,
        isFetched: true,
        error: null
      } )

    } catch ( exception ) {

      setProject( {
        details: {},
        isFetching: false,
        isFetched: false,
        error: exception
      } )

    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const updateProject = async payload => {
    try {
      const { params } = props

      setProject( {
        ...project,
        isUpdating: true
      } )

      const details = await sFetch( `/projects/${ params.projectId }`, { method: 'put', body: JSON.stringify( { project: toSnakeCase( payload ) } ) } )

      setProject( {
        ...project,
        details: details,
        isUpdating: false
      } )

    } catch ( exception ) {

      setProject( {
        ...project,
        isUpdating: false
      } )

      throw exception

    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const publishProject = async () => {
    try {
      const { params } = props

      setProject( {
        ...project,
        isUpdating: true
      } )

      const details = await sFetch( `/projects/${ params.projectId }/launch`, { method: 'post' } )

      setProject( {
        ...project,
        details: details,
        isUpdating: false
      } )

    } catch ( exception ) { throw exception }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const closeProject = async () => {
    try {
      const { params } = props

      setProject( {
        ...project,
        isUpdating: true
      } )

      const details = await sFetch( `/projects/${ params.projectId }/close`, { method: 'post' } )

      setProject( {
        ...project,
        details: details,
        isUpdating: false
      } )

    } catch ( exception ) { throw exception }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const advanceProjectShippingWave = async () => {
    setProject( { ...project, isUpdating: true } )

    try {

      const { params } = props
      const details = await sFetch( `/projects/${ params.projectId }/advance_shipping_wave`, { method: 'post' } )
      setProject( { ...project, details: details, isUpdating: false } )

    } catch ( exception ) {

      setProject( { ...project, isUpdating: false } )
      throw exception

    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const cleanUpPledges = async () => {
    setProject( { ...project, isUpdating: true } )

    try {

      const { params } = props
      const details = await sFetch( `/projects/${ params.projectId }/clean_up_pledges`, { method: 'post' } )
      setProject( { ...project, details: details, isUpdating: false } )

    } catch ( exception ) {

      setProject( { ...project, isUpdating: false } )
      throw exception

    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const openProjectPledgeManager = async () => {
    try {
      const { params } = props

      setProject( {
        ...project,
        isUpdating: true
      } )

      const details = await sFetch( `/projects/${ params.projectId }/open_pledge_manager`, { method: 'post' } )

      setProject( {
        ...project,
        details: details,
        isUpdating: false
      } )

    } catch ( exception ) { throw exception }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const fetchCollaborators = async () => {
    try {
      setCollaborators( {
        list: [],
        isFetching: true,
        isFetched: false,
      } )

      const list = await sFetch( `/companies/${ project.details.company.id }/collaborators` )

      setCollaborators( {
        list: list,
        isFetching: false,
        isFetched: true
      } )

    } catch ( exception ) {
      setCollaborators( {
        list: [],
        isFetching: false,
        isFetched: false,
        error: exception
      } )
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const fetchPledge = async ( pledgeId ) => {
    try {

      setPledge( {
        ...pledge,
        details: {},
        isFetching: true,
        isFetched: false
      } )

      const details = await sFetch( `/geeks/report/${ pledgeId }` )

      setPledge( {
        ...pledge,
        details: details,
        isFetching: false,
        isFetched: true,
        error: null
      } )

    } catch ( exception ) {

      setPledge( {
        details: {},
        isFetching: false,
        isFetched: false,
        error: exception
      } )

      throw exception

    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const refundPledge = async payload => {
    try {

      payload.amount = parseFloat( payload.amount )
      payload.id = parseInt( payload.id )

      await sFetch( '/geeks/refund', {
        method: 'post',
        body: JSON.stringify( toSnakeCase( payload ) )
      } )

    } catch ( exception ) { throw exception }

  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const unlockPledge = async ( pledgeId, reason ) => {
    try {

      const unlockedPledge = await sFetch( `/geeks/${ pledgeId }/unlock`, { method: 'post', body: JSON.stringify( { reason: reason } ) } )
      setPledge( { ...pledge, details: unlockedPledge } )

    } catch ( exception ) { throw exception }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const discountPledge = async ( pledgeId, payload ) => {
    try {

      const unlockedPledge = await sFetch( `/geeks/${ pledgeId }/discount`, {
        method: 'post',
        body: JSON.stringify( toSnakeCase( payload ) )
      } )
      setPledge( { ...pledge, details: unlockedPledge } )

    } catch ( exception ) { throw exception }

  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const cancelAndRefundPledge = async ( pledgeId, payload ) => {
    try {

      const canceledPledge = await sFetch( `/geeks/${ pledgeId }/full_refund`, {
        method: 'post',
        body: JSON.stringify( toSnakeCase( payload ) )
      } )

      setPledge( { ...pledge, details: canceledPledge } )

    } catch ( exception ) { throw exception }

  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const transferPledge = async ( pledgeId, payload ) => {
    try {

      const updatedPledge = await sFetch( `/geeks/${ pledgeId }/transfer`, {
        method: 'post',
        body: JSON.stringify( toSnakeCase( payload ) )
      } )

      setPledge( { ...pledge, details: updatedPledge } )
      return updatedPledge

    } catch ( exception ) { throw exception }
  }


  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const transferPledgeCredit = async ( pledgeId, payload ) => {
    try {

      const updatedPledge = await sFetch( `/geeks/${ pledgeId }/geek_credit`, {
        method: 'post',
        body: JSON.stringify( toSnakeCase( payload ) )
      } )

      setPledge( { ...pledge, details: updatedPledge } )
      return updatedPledge

    } catch ( exception ) { throw exception }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const arbitraryPledgeConfirm = async pledgeId => {
    try {

      const confirmedPledge = await sFetch( `/geeks/${ pledgeId }/force_confirm`, { method: 'post' } )

      setPledge( { ...pledge, details: confirmedPledge } )

    } catch ( exception ) { throw exception }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const sendInitialInvite = async projectId => {
    try {
      setProject( {
        ...project,
        isUpdating: true
      } )

      const details = await sFetch( `/projects/${ projectId }/send_initial_invites`, { method: 'post' }, false, true )

      setProject( {
        ...project,
        details: details,
        isUpdating: false
      } )

    } catch ( exception ) { throw exception }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const fetchConsolidatedReport = async projectId => {
    try {

      dispatchStatistics( { type: STATISTICS.FETCH_TOTAL } )
      const all = await sFetch( `/projects/stats/${ projectId }` )
      dispatchStatistics( { type: STATISTICS.FETCH_TOTAL_SUCCESS, payload: all } )

    } catch ( exception ) { throw exception }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const fetchTotalConfirmedReport = async projectId => {
    try {

      dispatchStatistics( { type: STATISTICS.FETCH_CONFIRMED } )
      const totalConfirmed = await sFetch( `/projects/stats/${ projectId }/confirmed_stats` )
      dispatchStatistics( { type: STATISTICS.FETCH_CONFIRMED_SUCCESS, payload: totalConfirmed } )

    } catch ( exception ) { throw exception }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const fetchTotalOrderedReport = async projectId => {
    try {

      dispatchStatistics( { type: STATISTICS.FETCH_ORDERED } )
      const totalOrdered = await sFetch( `/projects/stats/${ projectId }/total_ordered_stats` )
      dispatchStatistics( { type: STATISTICS.FETCH_ORDERED_SUCCESS, payload: totalOrdered } )

    } catch ( exception ) { throw exception }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const fetchTotalByHubReport = async projectId => {
    try {

      dispatchStatistics( { type: STATISTICS.FETCH_HUB } )
      const totalByHub = await sFetch( `/projects/stats/${ projectId }/per_hub_stats` )
      dispatchStatistics( { type: STATISTICS.FETCH_HUB_SUCCESS, payload: totalByHub } )

    } catch ( exception ) { throw exception }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const fetchTransactionsReport = async projectId => {
    try {

      dispatchStatistics( { type: STATISTICS.FETCH_TRANSACTIONS } )
      const transactions = await sFetch( `/projects/stats/${ projectId }/transaction_stats` )
      dispatchStatistics( { type: STATISTICS.FETCH_TRANSACTIONS_SUCCESS, payload: transactions } )

    } catch ( exception ) { throw exception }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (
    <DashboardContext.Provider value={ {
      fetchUser: fetchUser,
      fetchCollaborators: fetchCollaborators,
      fetchProject: fetchProject,
      fetchPledge: fetchPledge,
      updateProject: updateProject,
      publishProject: publishProject,
      closeProject: closeProject,
      advanceProjectShippingWave: advanceProjectShippingWave,
      cleanUpPledges: cleanUpPledges,
      openProjectPledgeManager: openProjectPledgeManager,
      refundPledge: refundPledge,
      cancelAndRefundPledge: cancelAndRefundPledge,
      transferPledge: transferPledge,
      transferPledgeCredit: transferPledgeCredit,
      arbitraryPledgeConfirm: arbitraryPledgeConfirm,
      unlockPledge: unlockPledge,
      discountPledge: discountPledge,
      sendInitialInvite: sendInitialInvite,
      fetchConsolidatedReport: fetchConsolidatedReport,
      fetchTotalConfirmedReport: fetchTotalConfirmedReport,
      fetchTotalOrderedReport: fetchTotalOrderedReport,
      fetchTotalByHubReport: fetchTotalByHubReport,
      fetchTransactionsReport: fetchTransactionsReport,
      setProject: setProject,
      project: project,
      pledge: pledge,
      user: user,
      collaborators: collaborators,
      statistics: statistics
    } }>
      { props.children }
    </DashboardContext.Provider>
  )
}