import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { isFinite } from 'lodash'
import ReactTooltip from 'react-tooltip'


import { GlobalContext } from '../../context/global-state'
import { UIContext } from '../../context/ui-state'
import { sFetch } from '../../config/fetch'

import { ReactComponent as PledgitLogo } from '../../assets/images/pledgit-logo-large.svg'
import { ReactComponent as IconLogin } from '../../assets/icons/login.svg'
import { ReactComponent as IconLogout } from '../../assets/icons/logout.svg'
import { ReactComponent as IconDashboard } from '../../assets/icons/settings.svg'
import { ReactComponent as IconMenu } from '../../assets/icons/context-menu.svg'
import { ReactComponent as IconCart } from '../../assets/icons/shopping-cart.svg'
import { ReactComponent as IconLock } from '../../assets/icons/lock.svg'
import { ReactComponent as IconUser } from '../../assets/icons/user.svg'

import './header.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const Header = props => {

  const context = useContext( GlobalContext )
  const UI = useContext( UIContext )
  const { className = '' } = props
  const { loggedUser, isRetailer } = context
  const [ quickActionProcessing, setQuickActionProcessing ] = useState( false )
  const history = useHistory()
  const location = useLocation()

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleMenuOpen = event => {
    stopPropagation( event )
    UI.setMenuIsOpened( !UI.menuIsOpened )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const stopPropagation = event => {
    event.stopPropagation()
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSignOut = event => {
    event.preventDefault()
    context.signOut()
    if ( location.pathname.substr( 1, 8 ) === 'checkout' ) history.push( '/' )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleRouteChange = () => {
    UI.setMenuIsOpened( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleQuickJump = async event => {
    const { target } = event
    const { value } = target
    const key = event.keyCode || event.which

    if ( quickActionProcessing ) return

    if ( key === 13 ) {

      setQuickActionProcessing( true )

      if ( isFinite( parseInt( value ) ) ) {

        if ( event.shiftKey ) {
          window.open( `/dashboard/pledge/${ value }` )
        } else {
          history.push( `/dashboard/pledge/${ value }` )
        }

      } else {

        const { users } = await sFetch( `/users/report?page=1&per_page=1&by_text=${ value }` )

        if ( users.length ) {
          const { id } = users[ 0 ]

          if ( event.shiftKey ) {
            window.open( `/dashboard/user/${ id }` )
          } else {
            history.push( `/dashboard/user/${ id }` )
          }
        } else {
          UI.showErrorDisclaimer( 'User not found. Try different search params.' )
        }
      }

      target.blur()
      setQuickActionProcessing( false )
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( handleRouteChange, [ location ] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <header className={ `pd-header ${ className }` }>
      <div className="pd-container">
        <Link to="/" className="pd-header__logo">
          <figure>
            <PledgitLogo />
            { isRetailer ? <figcaption className="pd-title-4">Retailers</figcaption> : null }
          </figure>
        </Link>

        <div className="pd-header__admin-user">
          { loggedUser.isLogged && loggedUser.isFetched && loggedUser.isAdmin && (
            <>
              <input tabIndex="1" data-tip data-for="quick-action-tooltip" data-event="focus" data-event-off="blur" type="text" className="pd-textfield pd-textfield--glass" placeholder="Jump to..." onKeyUp={ handleQuickJump } disabled={ quickActionProcessing } />

              <ReactTooltip className="pd-dinamic-tooltip" id="quick-action-tooltip" place="bottom">
                <div className="pd-header__quick-action-tooltip">
                  <p className="pd-text">Enter a Pledge ID exactly and press ENTER to quickly jump to that pledge.</p>
                  <p className="pd-text">Enter a customer's name or email and press ENTER to quickly jump to the first match.</p>
                  <p className="pd-text">To open these results in a new window, press SHIFT+ENTER instead.</p>
                </div>
              </ReactTooltip>

              <Link to="/dashboard" className="pd-button pd-button--glass pd-button--icon  pd-button--icon-mobile">
                <IconDashboard className="pd-icon" />
                <span>Dashboard</span>
              </Link>
            </>
          ) }
        </div>

        <div className="pd-header__logged-user">
          { loggedUser.isResolved && (
            <>
              { loggedUser.isLogged && loggedUser.isFetched && loggedUser.data ?
                (

                  <>
                    <button
                      className="pd-header__btn-menu"
                      onClick={ handleMenuOpen }>
                      <IconMenu className="pd-icon" />
                    </button>

                    <nav
                      className={ `pd-header__menu ${ UI.menuIsOpened ? 'pd-header__menu--opened' : '' }` }
                      onClick={ stopPropagation }
                      onTouchStart={ stopPropagation }>
                      <header className="pd-header__menu-header">
                        <h3 className="pd-title-4">{ loggedUser.data.name }</h3>
                        <p className="pd-text-sm">{ loggedUser.data.email }</p>
                      </header>

                      <ul className="pd-header__menu-list">
                        <li className="pd-header__menu-item">
                          <Link to="/account/pledges" className="pd-button pd-button--icon pd-button--text pd-button--cyan pd-header__menu-link" title="Your pledges">
                            <IconCart className="pd-icon" />
                        Your pledges
                      </Link>
                        </li>
                        <li className="pd-header__menu-item">
                          <Link to="/account/information" className="pd-button pd-button--icon pd-button--text pd-button--cyan pd-header__menu-link" title="Change account information">
                            <IconUser className="pd-icon" />
                        Change account information
                      </Link>
                        </li>
                        <li className="pd-header__menu-item">
                          <Link to="/account/security" className="pd-button pd-button--icon pd-button--text pd-button--cyan pd-header__menu-link" title="Change password">
                            <IconLock className="pd-icon" />
                        Change password
                      </Link>
                        </li>
                      </ul>

                      <button onClick={ handleSignOut } className="pd-button pd-button--secondary pd-button--text pd-button--icon pd-button--icon-after">
                        <span>Logout</span>
                        <IconLogout className="pd-icon" />
                      </button>
                    </nav>
                  </>

                ) : (

                  <Link to="/sign-in" className="pd-button pd-button--navy pd-button--icon">
                    <IconLogin className="pd-icon" />
                    <span>Sign-in</span>
                  </Link>

                )
              }
            </>
          ) }
        </div>
      </div>
    </header>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default Header