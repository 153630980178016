import React, { useEffect, useContext, useState } from 'react'
import { Formik, Form } from 'formik'
import { Prompt, useParams } from 'react-router-dom'

import { UIContext } from '../../context/ui-state'
import { DashboardContext } from '../../context/dashboard-state'

import { getReadbleOrderState } from '../../config/adapters'
import { PLEDGE_STATE, PREVENT_NAVIGATION_MESSAGE } from '../../config/constants'

import PledgeOrderDetails from '../../modules/pledge-order-details/pledge-order-details'
import PledgeOrderContents from '../../modules/pledge-order-contents/pledge-order-contents'

import CustomField from '../../components/custom-field/custom-field'

import './unlock-pledge.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const UnlockPledge = props => {

  const UI = useContext( UIContext )
  const dashboardContext = useContext( DashboardContext )
  const { pledge, unlockPledge } = dashboardContext
  const { details } = pledge
  const [ hasChanges, setHasChanges ] = useState( false )
  const { pledgeId } = useParams()
  const isUnlocked = details.state === PLEDGE_STATE.UNLOCKED
  const canBeUnlocked = [
    PLEDGE_STATE.FINISHED,
    PLEDGE_STATE.CANCELED,
    PLEDGE_STATE.READY_TO_SHIP,
    PLEDGE_STATE.RETAILER_MISSED_DEADLINE
  ].indexOf( details.state ) >= 0

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleUnlock = async payload => {
    if ( !window.confirm( `Are you sure you want to unlock the pledge #${ details.id } of ${ details.user.name } (${ details.user.email })?\n\nThis action can't be undone.` ) ) return

    try {

      await unlockPledge( pledgeId, payload.reason )
      setHasChanges( false )
      UI.showSuccessDisclaimer( 'Pledge unlocked successfully!' )

    } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const renderStatusMessage = () => {
    switch ( details.state ) {
      case PLEDGE_STATE.CANCELED:
        return (
          <p className="pd-text">
            This order has been canceled and refunded. If you unlock this order the user will be able
            to re-confirm it. Unlocking this order will notify the user via email.
          </p>
        )

      case PLEDGE_STATE.RETAILER_MISSED_DEADLINE:
        return (
          <p className="pd-text">
            This order was canceled automatically by the system since it missed this project's deadline.
          </p>
        )

      default:
        return (
          <p className="pd-text">
            This order is locked and confirmed. If you unlock this order and the user does not re-confirm it, it will
            not be processed for shipping. Unlocking this order will notify the user via email.
          </p>
        )
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const renderUnlockButtonLabel = () => {
    switch ( details.state ) {
      case PLEDGE_STATE.CANCELED:
      case PLEDGE_STATE.RETAILER_MISSED_DEADLINE:
        return ( 'Reopen pledge' )

      default:
        return ( 'Unlock pledge' )
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -


  return (

    <section className="pd-unlock-pledge">
      <Prompt when={ hasChanges } message={ location => PREVENT_NAVIGATION_MESSAGE } />

      <PledgeOrderDetails details={ pledge.details } className="pd-unlock-pledge__order" />

      <div className="pd-unlock-pledge__main pd-white-box">
        <h3 className="pd-title-3">Current state: { getReadbleOrderState( details.state ) }</h3>

        { isUnlocked ?
          (

            <p className="pd-text">This pledge is already unlocked.</p>

          ) : (

            canBeUnlocked ?
              (

                <Formik
                  initialValues={ { reason: '' } }
                  onSubmit={ handleUnlock }
                  validateOnChange={ false }
                  validateOnBlur={ false }>
                  { fProps => (
                    <>
                      { renderStatusMessage() }

                      <Form className={ `pd-unlock-pledge__form` } onChange={ event => setHasChanges( true ) }>

                        <CustomField label="Reason" name="reason" maxLength={ 250 } />

                        <button
                          className="pd-button pd-button--navy"
                          type="submit"
                          disabled={ fProps.isSubmitting }>
                          { fProps.isSubmitting ? 'Processing...' : renderUnlockButtonLabel() }
                        </button>
                      </Form>
                    </>

                  ) }
                </Formik>

              ) : (

                <p className="pd-text">This order is currently pending and hence unlocked.</p>

              )
          )
        }
      </div>

      <PledgeOrderContents details={ pledge.details } className="pd-unlock-pledge__contents" />
    </section>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default UnlockPledge