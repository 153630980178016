import React, { useEffect } from 'react'
import { useField } from 'formik'
import autosize from 'autosize'
import { Editor } from '@tinymce/tinymce-react'
import { isArray } from 'lodash'

import Env from '../../config/env'

import CurrencyField from '../currency-field/currency-field'

import './custom-field.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const CUSTOMFIELD_DIRECTION = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical'
}

const CustomField = props => {
  const [ field, meta, helpers ] = useField( props.name )
  const { setValue } = helpers
  const {
    className = '',
    label,
    complement,
    filter = false,
    showCounter = true,
    autoSize = false,
    minHeight = 200,
    id = `id:${ props.name }`,
    children,
    options = [],
    direction = CUSTOMFIELD_DIRECTION.VERTICAL,
    required = false,
    onChange,
    ...rest
  } = props
  const { name, component, type, maxLength = false, multiple = false } = props

  const hasCounter = maxLength && showCounter
  const hasErrors = meta.error && meta.touched
  const maxLengthExceeded = hasCounter ? field.value.length >= maxLength : false

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => {
    setupAutoSize()
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const setupAutoSize = () => {
    if ( component === 'textarea' && autoSize ) {
      const element = document.getElementById( id )
      autosize.destroy( element )
      autosize( element )
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const getRichTextStyles = () => {
    return '' +
      'p {' +
      'font-family: \'Titillium Web\';' +
      'font-size: 0.9rem;' +
      'font-weight: 400;' +
      'line-height: 1.2rem;' +
      'letter-spacing: normal;' +
      '}' +

      'h1,h2,h3,h4 {' +
      '  font-family: \'Dosis\';' +
      '  font-weight: 700;' +
      '  text-transform: uppercase;' +
      '}' +

      'h1 {' +
      '  font-size: 1.8rem;' +
      '  letter-spacing: 0.1rem;' +
      '}' +

      'h2 {' +
      '  font-size: 1.4rem;' +
      '  letter-spacing: 0.1rem;' +
      '}' +

      'h3 {' +
      '  font-size: 1.1rem;' +
      '  letter-spacing: 0.06rem;' +
      '}' +

      'h4 {' +
      '  font-size: 0.9rem;' +
      '  letter-spacing: 0.06rem;' +
      '}' +
      '' +
      ''
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleChange = event => {
    if ( onChange ) onChange( event )
    let { value } = event.target

    if ( filter ) value = value.replace( filter, '' )
    if ( type === 'number' && value ) value = parseFloat( value )
    if ( component === 'select' && multiple ) {
      value = [].slice
        .call( event.target.selectedOptions )
        .map( option => option.value )
    }

    setValue( value )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleEditorChange = value => {
    if ( onChange ) onChange( value )
    setValue( filter ? value.replace( filter, '' ) : value )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const renderComponent = () => {
    switch ( component ) {

      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

      case 'currency':
        return (
          <CurrencyField id={ id } { ...field } { ...rest } onChange={ handleChange } />
        )

      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

      case 'textarea':
        return ( <textarea className="pd-textfield" id={ id } { ...field } { ...rest } onChange={ handleChange } /> )

      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

      case 'rich-text':
        return (
          <Editor
            apiKey={ Env.tinyApiKey }
            initialValue={ meta.initialValue }
            maxChars={ maxLength }
            init={ {
              content_css: [ 'https://fonts.googleapis.com/css?family=Dosis:600,700|Titillium+Web:400,700&display=swap' ],
              content_css_cors: true,
              content_style: getRichTextStyles(),
              plugins: 'link lists paste',
              toolbar: 'styleselect bold italic bullist numlist link autolink',
              statusbar: false,
              menubar: false,
              paste_as_text: true,
              min_height: minHeight
            } }
            onEditorChange={ handleEditorChange }
          />
        )

      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

      case 'select':
        return ( <select className="pd-select" id={ id } { ...field } { ...rest } onChange={ handleChange }>{ children }</select> )

      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

      case 'radio':
        return (
          <div className={ `pd-radio-button pd-radio-button--${ direction }` }>
            { options.map( ( option, index ) => {
              const label = isArray( option ) ? option[ 0 ] : option
              const value = isArray( option ) ? option[ 1 ] : option
              return (
                <div key={ index } className="pd-radio-button__item">
                  <input name={ name } type="radio" id={ `${ id }:${ index }` } value={ value } onChange={ handleChange } />
                  <label htmlFor={ `${ id }:${ index }` } className="pd-text-sm">{ label }</label>
                </div>
              )
            } ) }
          </div>
        )

      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

      default:
        return ( <input className="pd-textfield" id={ id } { ...field } { ...rest } onChange={ handleChange } /> )

      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <div className={ `pd-field pd-custom-field ${ className }` }>
      <label htmlFor={ name } className="pd-label">{ label } { complement && ( <span className="pd-text-sm pd-text--tip">{ complement }</span> ) } { required && <span className="pd-text--info">*</span> }</label>
      { renderComponent() }

      { hasCounter || hasErrors ? (

        <span className="pd-field__meta">
          <span className="pd-text-sm pd-text--error">{ meta.error || '' }</span>
          <span className={ `pd-text-sm ${ maxLengthExceeded ? 'pd-text--error' : 'pd-text--info' }` }>{ hasCounter ? `${ field.value.length }/${ maxLength }` : '' }</span>
        </span>

      ) : null }
    </div>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default CustomField