import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import './not-found.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const NotFound = props => {

  const { className = '', children } = props
  const location = useLocation()

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <section className={ `pd-not-found pd-page ${ className }` }>
      { children || (
        <>
          <h3 className="pd-title-3">Page not found.</h3>
          <p className="pd-text">The requested URL <strong>{ location.pathname }</strong> was not found on this server.</p>
          <Link to="/" className="pd-button pd-button--cyan">Back to home</Link>
        </>
      ) }
    </section>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default NotFound