import React, { useState, useEffect, cloneElement, useRef } from 'react'
import { debounce, isArray, flattenDeep } from 'lodash'

import { isMobile, mapLinear } from '../../config/utilities'

import './tab-panel.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const TabPanel = props => {

  const { className = '', children, selectedIndex = 0, title = null, onTabChange = null } = props
  const [ isCurrent, setCurrent ] = useState( selectedIndex )
  const elRef = useRef()
  const mousePosition = { x: 0, y: 0 }
  let nav, list, scrollOffset, enterFrameID

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => {
    if ( !isMobile ) setupMouseFx()
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const getTabs = () => isArray( children ) ? flattenDeep( children ).filter( child => child?.type === TabPanelItem ) : [ children ]

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleTabClick = ( index, event ) => {
    setCurrent( index )
    if ( onTabChange ) onTabChange( index )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const enterFrame = () => {
    const navRect = nav.getBoundingClientRect()
    const xBalance = 200
    const yBalance = 70

    if ( ( mousePosition.y - navRect.top ) > yBalance ) return

    const xMouse = mousePosition.x - navRect.left
    let xPos = Math.ceil( mapLinear( xMouse - xBalance, 0, navRect.width - xBalance, 0, scrollOffset + xBalance ) )
    xPos = xPos < 0 ? 0 : ( xPos > scrollOffset ? scrollOffset : xPos )

    list.style.left = `${ xPos * -1 }px`;

    enterFrameID = requestAnimationFrame( enterFrame )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const setupMouseFx = () => {
    nav = elRef.current
    list = elRef.current.firstChild.firstChild

    if ( list.scrollWidth > nav.offsetWidth ) {
      nav.firstChild.style.position = 'relative';
      nav.firstChild.style.overflow = 'hidden';
      nav.firstChild.style.height = `${ list.offsetHeight }px`;
      list.style.position = 'absolute';

      scrollOffset = list.scrollWidth - nav.offsetWidth

      nav.addEventListener( 'mousemove', event => {
        mousePosition.x = event.clientX;
        mousePosition.y = event.clientY;
      } )

      nav.addEventListener( 'mouseover', () => {
        enterFrameID = requestAnimationFrame( enterFrame )
      } )

      nav.addEventListener( 'mouseout', () => {
        cancelAnimationFrame( enterFrameID )
      } )
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <div className={ `pd-tab-panel ${ title ? 'pd-tab-panel--with-title' : '' } ${ className }` } ref={ elRef }>
      { title && (
        <h3 className="pd-tab-panel__title pd-title-4">{ title }</h3>
      ) }

      <nav className="pd-tab-panel__nav">
        <ul className="pd-tab-panel__nav-list">
          { getTabs().map( ( item, index ) => (
            <li
              onClick={ handleTabClick.bind( this, index ) }
              key={ index }
              className={ `pd-tab-panel__nav-item ${ index === isCurrent ? 'pd-tab-panel__nav-item--current' : '' } ${ item.props.disabled ? 'pd-tab-panel__nav-item--disabled' : '' }` }>
              <span>{ item.props.label }</span>
            </li>
          ) ) }
        </ul>
      </nav>

      <div className="pd-tab-panel__wrapper">
        { getTabs().map( ( child, index ) => cloneElement( child, { isCurrent: index === isCurrent } ) ) }
      </div>
    </div>

  )
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const TabPanelItem = props => {

  const { className = '', children, isCurrent, disabled = false } = props

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <div className={ `pd-tab-panel__item ${ isCurrent ? 'pd-tab-panel__item--current' : '' } ${ disabled ? 'pd-tab-panel__item--disabled' : '' } ${ className }` }>
      { children }
    </div>

  )
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export default TabPanel