import React, { useEffect, useState } from 'react'
import { debounce, toPairs, uniq } from 'lodash'
import pluralize from 'pluralize'

import { PLEDGE_STATE } from '../../config/constants'
import { getReadbleOrderState } from '../../config/adapters'

import './backer-report-filter.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const BackerReportFilter = props => {

  const { className = '', project, onChange = () => null } = props
  const [ reward, setReward ] = useState( '' )
  const [ status, setStatus ] = useState( '' )
  const [ search, setSearch ] = useState( '' )
  const [ ksNumber, setKsNumber ] = useState( '' )
  const [ bundles, setBundles ] = useState( [] )
  const [ addOns, setAddOns ] = useState( [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => {
    setBundles( project.bundles.map( parse ) )
    setAddOns( project.addOns.map( parse ) )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const parse = list => {
    let waves = uniq( ( list.bundleProducts || list.addOnProducts ).map( i => i.product.wave ) )
    return { id: list.id, name: list.name, waves: waves, waveInfo: project.waves > 1 ? `(${ pluralize( 'wave', waves.length ) } ${ waves.join( ', ' ) })` : '' }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleParamsChange = () => {
    let payload = {}
    if ( search ) payload.search = search
    if ( ksNumber ) payload.ksNumber = ksNumber
    if ( status ) payload.status = status
    if ( reward ) {
      const parsed = reward.split( ':' )
      payload[ parsed[ 0 ] ] = parsed[ 1 ]
    }
    onChange( payload )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleRewardChange = event => setReward( event.target.value )
  const handleStatusChange = event => setStatus( event.target.value )
  const handleSearchChange = value => setSearch( value )
  const handleKsNumberChange = value => setKsNumber( value )
  const debouncedSearchChange = debounce( value => handleSearchChange( value ), 1000 )
  const debouncedKsNumberChange = debounce( value => handleKsNumberChange( value ), 1000 )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSearchKeyPress = event => {
    if ( !isEnterKey( event ) ) return

    debouncedSearchChange.cancel()
    handleSearchChange( event.target.value )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleKsNumberKeyPress = event => {
    if ( !isEnterKey( event ) ) return

    debouncedKsNumberChange.cancel()
    handleKsNumberChange( event.target.value )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const isEnterKey = event => ( event.key === 'Enter' || event.keyCode === 13 )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )
  useEffect( handleParamsChange, [ reward, status, search, ksNumber ] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <div className={ `pd-backer-report-filter ${ className }` }>
      <select className="pd-select" name="reward" onChange={ handleRewardChange }>
        <option value="">All rewards</option>
        <optgroup label="Pledges">
          { bundles.map( item => <option key={ item.id } data-kind="bundle" value={ `bundle:${ item.id }` }>{ item.name } { item.waveInfo }</option> ) }
        </optgroup>
        { addOns.length > 0 ? (
          <optgroup label="Add-ons">
            { addOns.map( item => <option key={ item.id } data-kind="addOn" value={ `addOn:${ item.id }` }>{ item.name } { item.waveInfo }</option> ) }
          </optgroup>
        ) : null }
      </select>

      <select className="pd-select" name="status" onChange={ handleStatusChange }>
        <option value="">All status</option>
        { toPairs( PLEDGE_STATE ).map( item => <option key={ item[ 1 ] } value={ item[ 1 ] }>{ getReadbleOrderState( item[ 1 ] ) }</option> ) }
      </select>

      <input
        className="pd-textfield"
        type="text"
        name="search"
        placeholder="Search by name, email or pledge number"
        onKeyPress={ handleSearchKeyPress }
        onChange={ ( { target: { value } } ) => debouncedSearchChange( value ) }
      />

      <input
        className="pd-textfield"
        type="text"
        name="ksNumber"
        placeholder="Search by KS number"
        onKeyPress={ handleKsNumberKeyPress }
        onChange={ ( { target: { value } } ) => debouncedKsNumberChange( value ) }
      />
    </div>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default BackerReportFilter