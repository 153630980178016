import React, { useEffect, useContext, useState, createRef } from 'react'
import { Prompt, useParams } from 'react-router-dom'
import { Formik, Field, FieldArray, Form } from 'formik'
import { kebabCase, snakeCase, orderBy, find, findIndex, forEach, cloneDeep } from 'lodash'
import arrayMove from 'array-move'

import { DashboardContext } from '../../context/dashboard-state'
import { UIContext } from '../../context/ui-state'

import { sFetch, sUploader } from '../../config/fetch'
import { getImageSrc, toSnakeCase, getCurrencyValue } from '../../config/adapters'
import { NUMBER_REGEX, NOT_EMPTY_REGEX, ACCEPT_ONLY_INT_REGEX } from '../../config/validators'
import { PREVENT_NAVIGATION_MESSAGE } from '../../config/constants'

import { SortableItem, SortableContainer, DragHandle } from '../../components/sortable-list/sortable-list'
import { InfoTip } from '../../components/tip/tip'
import LoadingMessage from '../../components/loading-message/loading-message'
import CheckboxField from '../../components/checkbox-field/checkbox-field'
import CustomField from '../../components/custom-field/custom-field'
import ImageUploadField from '../../components/image-upload-field/image-upload-field'
import AmountSelector from '../../components/amount-selector/amount-selector'

import './rewards-form.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const RewardsForm = props => {

  const dashboardContext = useContext( DashboardContext )
  const UI = useContext( UIContext )
  const { project, setProject } = dashboardContext
  const { className = '' } = props
  const [ isNew, setNew ] = useState( false )
  const [ bundles, setBundles ] = useState( [] )
  const [ addOns, setAddOns ] = useState( [] )
  const [ currentItem, setCurrentItem ] = useState( null )
  const [ currentKind, setCurrentKind ] = useState( null )
  const [ hasChanges, setHasChanges ] = useState( false )
  const [ hasDefaultBundleSelected, setHasDefaultBundleSelected ] = useState( false )
  const { projectId } = useParams()
  const uploaderRef = createRef()
  const KIND = { BUNDLE: 'bundle', ADD_ON: 'addOn' }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleProjectLoad = () => {
    if ( project.isFetched && !project.isUpdating && !currentItem ) {
      const { details } = project
      const first = details.bundles[ 0 ]
      setCurrentItem( first )
      setCurrentKind( KIND.BUNDLE )
      setBundles( details.bundles )
      setAddOns( details.addOns )
      setHasDefaultBundleSelected( !!find( details.bundles, { defaultBundle: true } ) )
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleItemClick = ( item, kind ) => {
    if ( hasChanges ) if ( !window.confirm( PREVENT_NAVIGATION_MESSAGE ) ) return

    setCurrentItem( item )
    setCurrentKind( kind )
    setNew( item.id === 0 )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleImageUpload = async ( payload ) => {
    const { setUploading, setProgress } = uploaderRef.current

    setUploading( true )
    setProgress( 0 )

    let formData = new FormData()
    formData.append( 'image', payload.image )

    const newImage = await sUploader( '/images', formData, percent => setProgress( percent ) )
    payload.imageId = newImage.id

    setUploading( false )

    delete payload.image
    return payload
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleValidation = values => {
    let errors = {}

    if ( isNew && !NOT_EMPTY_REGEX.test( values.name ) ) errors.name = 'Invalid name'
    if ( !NUMBER_REGEX.test( getCurrencyValue( values.price ) ) ) errors.price = 'Invalid price'
    if ( values.amountLimit && !NUMBER_REGEX.test( values.amountLimit ) ) errors.amountLimit = 'Invalid number'
    if ( values.limitPerBundle && !NUMBER_REGEX.test( values.limitPerBundle ) ) errors.limitPerBundle = 'Invalid number'
    if ( values.limitPerUser && !NUMBER_REGEX.test( values.limitPerUser ) ) errors.limitPerUser = 'Invalid number'

    return errors
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSave = async ( _payload, actions ) => {
    try {

      const kindKey = `${ currentKind }s`
      let payload = cloneDeep( _payload )
      if ( isNew ) delete payload.id

      if ( payload.image ) payload = await handleImageUpload( payload )
      // if ( payload.price ) payload.price = getCurrencyValue( payload.price )

      if ( payload.downgradesTo ) {
        payload.downgradesToId = parseInt( payload.downgradesTo.id )
        delete payload.downgradesTo
      }

      if ( payload.upgradesTo ) {
        payload.upgradesToId = parseInt( payload.upgradesTo.id )
        delete payload.upgradesTo
      }

      payload.bundleRequirementsAttributes = payload.bundleRequirements.map( id => {
        const bundleId = parseInt( id )
        const initialBundle = find( currentItem.bundleRequirements, { bundleId: bundleId } )

        if ( initialBundle ) return {
          id: initialBundle.id,
          bundleId: bundleId
        }

        return {
          bundleId: bundleId
        }
      } )
      delete payload.bundleRequirements

      forEach( currentItem.bundleRequirements, initialBundle => {
        if ( find( payload.bundleRequirementsAttributes, { id: initialBundle.id } ) ) return

        payload.bundleRequirementsAttributes.push( {
          id: initialBundle.id,
          _destroy: true
        } )
      } )

      payload[ `${ currentKind }ProductsAttributes` ] = payload.products.map( item => {
        const baseItem = { quantity: item.quantity, id: item.id, productId: item.productId }
        return item.quantity <= 0 ? { id: item.id, _destroy: true } : baseItem
      } )
      delete payload.products

      const options = { method: isNew ? 'post' : 'put', body: JSON.stringify( toSnakeCase( { [ currentKind ]: payload } ) ) }
      const endpoint = `/projects/${ projectId }/${ snakeCase( kindKey ) }/${ isNew ? '' : payload.id }`
      let savedItem = await sFetch( endpoint, options )

      if ( savedItem.state === 'draft' ) savedItem = await sFetch(
        `/projects/${ projectId }/${ snakeCase( kindKey ) }/${ savedItem.id }/publish`,
        { method: 'post' }
      )

      const freshList = await sFetch( `/projects/${ projectId }/${ snakeCase( kindKey ) }` )

      setProject( { ...project, details: { ...project.details, [ kindKey ]: freshList } } )
      if ( currentKind === 'bundle' ) {
        setBundles( freshList )
        setHasDefaultBundleSelected( !!find( freshList, { defaultBundle: true } ) )

      } else {
        setAddOns( freshList )
      }

      setCurrentItem( null )
      setHasChanges( false )
      setNew( false )

      actions.setSubmitting( false )
      actions.resetForm()

      UI.showSuccessDisclaimer( 'Your reward was saved successfully!' )

    } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSortEnd = async ( kind, event ) => {
    const { oldIndex, newIndex } = event
    let item

    if ( kind === KIND.BUNDLE ) {

      item = bundles[ oldIndex ]
      setBundles( arrayMove( bundles, oldIndex, newIndex ) )

    } else {

      item = addOns[ oldIndex ]
      setAddOns( arrayMove( addOns, oldIndex, newIndex ) )

    }

    const options = { method: 'put', body: JSON.stringify( { position: ( newIndex + 1 ) } ) }
    const endpoint = `/projects/${ projectId }/${ snakeCase( kind ) }s/${ item.id }/insert_at`
    sFetch( endpoint, options )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleDelete = async event => {
    event.preventDefault()

    try {

      if ( !window.confirm( 'Are you sure?' ) ) return

      const options = { method: 'delete' }
      const endpoint = `/projects/${ projectId }/${ snakeCase( currentKind ) }s/${ currentItem.id }`
      await sFetch( endpoint, options )

      let updatedProject = Object.assign( {}, project.details )
      updatedProject[ `${ currentKind }s` ] = updatedProject[ `${ currentKind }s` ].filter( item => item.id !== currentItem.id )
      setProject( { ...project, details: updatedProject } )
      setBundles( updatedProject.bundles )
      setAddOns( updatedProject.addOns )
      setHasDefaultBundleSelected( !!find( updatedProject, { defaultBundle: true } ) )

      setCurrentItem( null )
      setHasChanges( false )

      UI.showSuccessDisclaimer( 'Your reward was deleted successfully!' )

    } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleCancel = event => {
    if ( hasChanges ) if ( !window.confirm( PREVENT_NAVIGATION_MESSAGE ) ) {
      event.preventDefault()
      return false
    }

    setCurrentItem( null )
    setCurrentKind( null )
    setNew( false )
    setHasChanges( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleCurrentItemChange = () => {
    const { current } = uploaderRef

    if ( current ) {
      current.setPreview( currentItem.image ? getImageSrc( currentItem.image, { w: 320, h: 180, resizeTo: 'fill', quality: 60 } ) : null )
    }

    setHasChanges( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const getProductsList = () => {
    if ( !currentItem ) return []
    const _map = item => {
      return ( { id: item.id, productId: item.product.id, name: item.product.name, quantity: item.quantity } )
    }
    if ( currentItem.bundleProducts ) return currentItem.bundleProducts.map( _map )
    if ( currentItem.addOnProducts ) return currentItem.addOnProducts.map( _map )
    return []
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )
  useEffect( handleProjectLoad, [ project ] )
  useEffect( handleCurrentItemChange, [ currentItem ] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <section className={ `pd-rewards-form pd-tpl-nav-content ${ className }` }>
      <Prompt when={ hasChanges } message={ location => PREVENT_NAVIGATION_MESSAGE } />
      { project.isFetched ? (
        <>
          <nav className="pd-tpl-nav-content__nav">
            <h4 className="pd-tpl-nav-content__type pd-title-4">Pledges</h4>

            <button
              className={ `pd-button pd-button--navy ${ isNew && currentKind === KIND.BUNDLE ? 'pd-button--active' : '' }` }
              onClick={ handleItemClick.bind( this, { id: 0 }, KIND.BUNDLE ) }>
              Add new pledge
            </button>

            <SortableContainer className="pd-tpl-nav-content__list" tagName="ul" onSortEnd={ handleSortEnd.bind( this, KIND.BUNDLE ) } useDragHandle>
              { bundles.map( ( item, index ) => {
                const isActive = item.id === ( currentItem && currentItem.id ) && currentKind === KIND.BUNDLE
                return (
                  <SortableItem
                    key={ index }
                    index={ index }
                    tagName="li"
                    className={ `pd-text pd-tpl-nav-content__item ${ isActive ? 'pd-tpl-nav-content__item--active' : '' }` }
                    onClick={ handleItemClick.bind( this, item, KIND.BUNDLE ) }>
                    <DragHandle className="pd-tpl-nav-content__sort" />
                    <span>{ item.name }</span>
                    { item.defaultBundle && <InfoTip micro>Default</InfoTip> }
                  </SortableItem>
                )
              } ) }
            </SortableContainer>

            <h4 className="pd-tpl-nav-content__type pd-title-4">Add-ons</h4>

            <button
              className={ `pd-button pd-button--navy ${ isNew && currentKind === KIND.ADD_ON ? 'pd-button--active' : '' }` }
              onClick={ handleItemClick.bind( this, { id: 0 }, KIND.ADD_ON ) }>
              Add new add-on
            </button>

            <SortableContainer className="pd-tpl-nav-content__list" tagName="ul" onSortEnd={ handleSortEnd.bind( this, KIND.ADD_ON ) } useDragHandle>
              { addOns.map( ( item, index ) => {
                const isActive = item.id === ( currentItem && currentItem.id ) && currentKind === KIND.ADD_ON
                return (
                  <SortableItem
                    key={ index }
                    index={ index }
                    tagName="li"
                    className={ `pd-text pd-tpl-nav-content__item ${ isActive ? 'pd-tpl-nav-content__item--active' : '' }` }
                    onClick={ handleItemClick.bind( this, item, KIND.ADD_ON ) }>
                    <DragHandle className="pd-tpl-nav-content__sort" />
                    <span>{ item.name }</span>
                  </SortableItem>
                )
              } ) }
            </SortableContainer>
          </nav>

          { currentItem ?
            (

              <Formik
                initialValues={ {
                  id: currentItem.id || '',
                  name: currentItem.name || '',
                  description: currentItem.description || '',
                  whatsIncluded: currentItem.whatsIncluded || '',
                  price: currentItem.price || '',
                  amountLimit: currentItem.amountLimit || '',
                  limitPerUser: currentItem.limitPerUser || '',
                  limitPerBundle: currentItem.limitPerBundle || '',
                  minimumPerUser: currentItem.minimumPerUser || 0,
                  upgradesTo: currentItem.upgradesTo || '',
                  downgradesTo: currentItem.downgradesTo || '',
                  quantityOfLimitedAddOns: currentItem.quantityOfLimitedAddOns || '',
                  stockAlertThreshold: currentItem.stockAlertThreshold || 0,
                  soldAsExtra: currentItem.soldAsExtra || false,
                  limitedByBasePledge: currentItem.limitedByBasePledge || false,
                  latePledgeAvailable: currentItem.latePledgeAvailable || false,
                  lateConfirmAvailable: currentItem.lateConfirmAvailable || false,
                  defaultBundle: currentItem.defaultBundle || false,
                  shippingCostMultiplier: currentItem.shippingCostMultiplier || 1,
                  kickstarterId: currentItem.kickstarterId || '',
                  cartWarning: currentItem.cartWarning || '',
                  products: getProductsList(),
                  bundleRequirements: currentItem.bundleRequirements ? currentItem.bundleRequirements.map( item => item.bundleId ) : [],
                  state: currentItem.state
                } }
                enableReinitialize={ true }
                onSubmit={ handleSave }
                validate={ handleValidation }
                validateOnChange={ false }
                validateOnBlur={ false }>
                { fProps => (

                  <Form className="pd-tpl-nav-content__main" onChange={ event => setHasChanges( true ) }>
                    <div className={ `pd-rewards-form__main pd-rewards-form__main--${ kebabCase( currentKind ) } pd-tpl-nav-content__box` }>
                      <span className="pd-text-sm pd-text--tip pd-tpl-nav-content__required">
                        <span className="pd-text--info">*</span> Required fields
                      </span>

                      <Field type="hidden" name="id" />

                      {/* <div className={ `pd-tpl-nav-content__fields pd-rewards-form__fields pd-rewards-form__fields--${ kebabCase( currentKind ) }` }> */ }
                      <CustomField name="name" label="Name" className="pd-rewards-form__field-name" required />
                      <CustomField name="description" label="Description" component="textarea" className="pd-rewards-form__field-description" autoSize />
                      <CustomField name="whatsIncluded" label="What's included" component="textarea" className="pd-rewards-form__field-included" autoSize />
                      <CustomField name="price" label="Price" type="number" className="pd-rewards-form__field-price" required />
                      <CustomField name="amountLimit" label="Stock" className="pd-rewards-form__field-amount" type="number" min="0" />
                      <CustomField name="limitPerUser" label="Limit per user" className="pd-rewards-form__field-per-user" type="number" min="0" />
                      <CustomField name="minimumPerUser" label="Min. per user" className="pd-rewards-form__field-min-per-user" type="number" min="0" />

                      { currentKind === KIND.ADD_ON ?
                        (
                          <>
                            <CustomField name="limitPerBundle" label="Limit per pledge" className="pd-rewards-form__field-per-bundle" type="number" min="0" />

                            <CheckboxField name="limitedByBasePledge" className="pd-rewards-form__field-limited-by">
                              Limited by base pledge
                              </CheckboxField>
                          </>

                        ) : (

                          <>
                            <CustomField name="upgradesTo.id" label="Upgrades to" component="select" className="pd-rewards-form__field-upgrade">
                              <option value="">None</option>
                              { project.details.bundles
                                .filter( item => {

                                  if ( currentItem.downgradesTo && currentItem.downgradesTo.id === item.id ) return false
                                  return item.id !== currentItem.id

                                } )
                                .map( item => (

                                  <option key={ item.id } value={ item.id }>{ item.name }</option>

                                ) ) }
                            </CustomField>

                            <CustomField name="downgradesTo.id" label="Downgrades to" component="select" className="pd-rewards-form__field-downgrade">
                              <option value="">None</option>
                              { project.details.bundles
                                .filter( item => {

                                  if ( currentItem.upgradesTo && currentItem.upgradesTo.id === item.id ) return false
                                  return item.id !== currentItem.id

                                } )
                                .map( item => (

                                  <option key={ item.id } value={ item.id }>{ item.name }</option>

                                ) ) }
                            </CustomField>

                            <CheckboxField name="soldAsExtra" className="pd-rewards-form__field-extra">
                              Can be sold as extra
                              </CheckboxField>

                            <CheckboxField
                              name="defaultBundle"
                              className="pd-rewards-form__field-default-bundle"
                              confirmation={ hasDefaultBundleSelected ? 'You are changing the Default Pledge for this project, are you sure you want to do this?' : false }>
                              Default pledge
                              </CheckboxField>

                            <CustomField name="shippingCostMultiplier" label="Shipping cost multiplier" className="pd-rewards-form__field-multiplier" />

                            <CustomField name="quantityOfLimitedAddOns" label="Qty. of limited add ons" className="pd-rewards-form__field-limited-add-ons" filter={ ACCEPT_ONLY_INT_REGEX } type="number" min="0" />
                          </>

                        ) }

                      <CustomField name="stockAlertThreshold" label="Stock alert threshold" className="pd-rewards-form__field-threshold" filter={ ACCEPT_ONLY_INT_REGEX } type="number" min="0" />

                      <CustomField name="kickstarterId" label="kickstarter ID" className="pd-rewards-form__field-ks-id" />

                      <CustomField
                        name="bundleRequirements"
                        label="Specific pledge required"
                        className="pd-rewards-form__field-required"
                        component="select"
                        disabled={ currentKind === KIND.ADD_ON ? false : !fProps.values.soldAsExtra }
                        multiple>
                        { project.details.bundles.map( item => (

                          <option key={ item.id } value={ parseInt( item.id ) }>{ item.name }</option>

                        ) ) }
                      </CustomField>

                      <CheckboxField name="lateConfirmAvailable" className="pd-rewards-form__field-late-confirm">
                        Late confirm phase only
                      </CheckboxField>

                      <CheckboxField name="latePledgeAvailable" className="pd-rewards-form__field-late-pledge">
                        Late pledge available
                      </CheckboxField>

                      <ImageUploadField ref={ uploaderRef } name="image" label="Image" complement="1280x720px" className="pd-rewards-form__image" />

                      <div className="pd-rewards-form__products pd-field">
                        <label className="pd-label">Products included</label>

                        { project.details.products.length > 0 ? (

                          <ul className="pd-rewards-form__products-list">
                            <FieldArray
                              name="products"
                              render={ fHelpers => orderBy( project.details.products, [ 'wave', 'name' ] ).map( item => {

                                const selectedItem = find( fProps.values.products, { productId: item.id } )
                                const isSelected = selectedItem && selectedItem.quantity > 0
                                const className = `pd-text-sm pd-rewards-form__product ${ isSelected ? 'pd-rewards-form__product--included' : '' }`

                                return (

                                  <li key={ item.id } className={ className }>
                                    { item.name } (wave { item.wave })
                                    <AmountSelector
                                      mini={ true }
                                      initialValue={ selectedItem ? selectedItem.quantity : 0 }
                                      onChange={ value => {
                                        setHasChanges( true )

                                        const selectedIndex = findIndex( fProps.values.products, { productId: item.id } )

                                        if ( selectedIndex >= 0 ) {

                                          const selectedItem = find( fProps.values.products, { productId: item.id } )
                                          const newItem = { ...selectedItem, quantity: value }
                                          fHelpers.replace( selectedIndex, newItem )

                                        } else {

                                          fHelpers.push( { productId: item.id, name: item.name, quantity: value } )

                                        }
                                      } }
                                    />
                                  </li>

                                )
                              } ) }
                            />
                          </ul>

                        ) : (

                          <>
                            <Field name="products" type="hidden" />
                            <InfoTip>No products setup for this project.</InfoTip>
                          </>

                        ) }
                      </div>

                      <CustomField name="cartWarning" label="Cart warning disclaimer" className="pd-rewards-form__field-cart-warning" component="textarea" autoSize />

                      <nav className="pd-tpl-nav-content__actions pd-rewards-form__actions">
                        <button className="pd-button pd-button--navy" type="submit" disabled={ fProps.isSubmitting }>{ fProps.isSubmitting ? 'Processing...' : 'Save' }</button>
                        <span className="pd-tpl-nav-content__actions-wrapper">
                          { isNew ? null : <button className="pd-button pd-button--danger" onClick={ handleDelete }>Delete</button> }
                          <button className="pd-button pd-button--glass-dark" onClick={ handleCancel }>Cancel</button>
                        </span>
                      </nav>
                    </div>
                  </Form>

                ) }
              </Formik>

            ) : (

              <div className="pd-tpl-nav-content__main">
                <InfoTip>Use the rewards list to select an item to edit.</InfoTip>
              </div>

            )
          }
        </>
      ) : <LoadingMessage /> }
    </section>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default RewardsForm