import React, { useEffect, useState, useRef, useImperativeHandle, forwardRef } from 'react'
import { useField } from 'formik'

import ProgressBar from '../progress-bar/progress-bar'

import imgRatio from '../../assets/images/ratio-16x9.gif'

import './image-upload-field.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const ImageUploadField = ( props, ref ) => {
  const [ field, meta, helpers ] = useField( props.name )
  const { setValue } = helpers
  const { value, ...baseField } = field
  const {
    className = '',
    label,
    complement,
    id = `id:${ props.name }`,
    children,
    required = false,
    onChange,
    accept = 'image/jpeg,image/pjpeg,image/png',
    initialPreview = null,
    ...rest
  } = props
  const { name } = props
  const hasErrors = meta.error && meta.touched
  const [ preview, setPreview ] = useState( initialPreview )
  const [ isUploading, setUploading ] = useState( false )
  const [ progress, setProgress ] = useState( 0 )
  // const thisRef = useRef()

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useImperativeHandle( ref, () => ( {
    setUploading: setUploading,
    setProgress: setProgress,
    setPreview: setPreview
  } ) )
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleChange = event => {
    if ( onChange ) onChange( event )

    const { files } = event.target
    if ( files.length <= 0 ) return

    const file = files[ 0 ]
    setPreview( window.URL.createObjectURL( file ) )
    setValue( file )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )
  useEffect( () => { setPreview( initialPreview ) }, [ initialPreview ] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <div className={ `pd-image-upload-field pd-field ${ className }` }>
      <label htmlFor={ name } className="pd-label">{ label } { complement && ( <span className="pd-text-sm pd-text--tip">{ complement }</span> ) } { required && <span className="pd-text--info">*</span> }</label>
      <input type="file" className="pd-textfield" id={ id } { ...baseField } { ...rest } onChange={ handleChange } accept={ accept } />

      { hasErrors ? (

        <span className="pd-field__meta">
          <span className="pd-text-sm pd-text--error">{ meta.error || '' }</span>
        </span>

      ) : null }

      <div className="pd-image-upload-field__preview" style={ preview ? { backgroundImage: `url(${ preview })` } : null }>
        <ProgressBar className="pd-image-upload-field__progress" percent={ progress } active={ isUploading } />
        <img src={ imgRatio } alt="Preview" className="pd-image-upload-field__ratio" />
      </div>
    </div>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default forwardRef( ImageUploadField )