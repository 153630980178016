import React, { useEffect, useContext, useState } from 'react'
import { PortalWithState } from 'react-portal'
import moment from 'moment-timezone'
import { Formik, Form } from 'formik'

import { UIContext } from '../../context/ui-state'

import { InfoTip, ErrorTip, SuccessTip, WarningTip } from '../../components/tip/tip'
import Pagination from '../../components/pagination/pagination'
import MetaPagination from '../../components/meta-pagination/meta-pagination'
import AddressDecoderField from '../../components/address-decoder-field/address-decoder-field'
import Table from '../../components/table/table'
import DateTimePickerField from '../../components/date-time-picker-field/date-time-picker-field'
import ProgressBar from '../../components/progress-bar/progress-bar'
import Lightbox from '../../components/lightbox/lightbox'
import TabPanel, { TabPanelItem } from '../../components/tab-panel/tab-panel'

import { ReactComponent as IconLock } from '../../assets/icons/lock.svg'
import { ReactComponent as IconSearch } from '../../assets/icons/search.svg'
import { ReactComponent as IconRight } from '../../assets/icons/right.svg'
import { ReactComponent as IconSuccess } from '../../assets/icons/check-mark.svg'
import { ReactComponent as IconInfo } from '../../assets/icons/info.svg'

import './styleguide.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const Styleguide = props => {

  const UI = useContext( UIContext )
  const [ dateTime, setDateTime ] = useState( '2020/01/30' )
  const [ progress, setProgress ] = useState( 0 )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => {
    animateProgressBar()
    // registerLightboxes()
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const animateProgressBar = () => {
    const step = timestamp => {
      const v = Math.round( timestamp / 40 )
      if ( v !== progress ) {
        setProgress( v )
        if ( v < 100 ) window.requestAnimationFrame( step )
      }
    }

    window.requestAnimationFrame( step )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <section className="pd-styleguide pd-page">


      {/*
      // Tab panel
      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      */}
      <div className="pd-styleguide__section">
        <h4 className="pd-title-4">Tab panel</h4>
        <TabPanel className="pd-tab-panel--navy">
          { Array.from( { length: 3 } ).map( ( item, index ) => (

            <TabPanelItem key={ index } label={ `Item ${ index }` } disabled={ index === 2 }>
              <div className="pd-white-box">
                { Array.from( { length: index + 1 } ).map( ( other, index2 ) => (

                  <p key={ index2 } className="pd-text">Content of item { index }, line {index2 }</p>

                ) ) }
              </div>
            </TabPanelItem>

          ) ) }
        </TabPanel>

        <TabPanel className="pd-tab-panel--cyan pd-tab-panel--justify">
          { Array.from( { length: 3 } ).map( ( item, index ) => (

            <TabPanelItem key={ index } label={ `Item ${ index }` }>
              { Array.from( { length: index + 1 } ).map( ( other, index2 ) => (

                <p key={ index2 } className="pd-text">Content of item { index }, line {index2 }</p>

              ) ) }
            </TabPanelItem>

          ) ) }
        </TabPanel>

        <TabPanel className="pd-tab-panel--navy">
          { Array.from( { length: 10 } ).map( ( item, index ) => (

            <TabPanelItem key={ index } label={ `Item ${ index }` }>
              { Array.from( { length: index + 1 } ).map( ( other, index2 ) => (

                <p key={ index2 } className="pd-text">Content of item { index }, line {index2 }</p>

              ) ) }
            </TabPanelItem>

          ) ) }
        </TabPanel>

        <TabPanel className="pd-tab-panel--navy pd-tab-panel--justify">
          { Array.from( { length: 20 } ).map( ( item, index ) => (

            <TabPanelItem key={ index } label={ `Item ${ index }` }>
              { Array.from( { length: index + 10 } ).map( ( other, index2 ) => (

                <p key={ index2 } className="pd-text">Content of item { index }, line {index2 }</p>

              ) ) }
            </TabPanelItem>

          ) ) }
        </TabPanel>

      </div>


      {/*
      // Tips
      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      */}
      <div className="pd-styleguide__section">
        <h4 className="pd-title-4">Progress bar</h4>

        <div className="pd-styleguide__progress">
          <ProgressBar percent={ progress } active={ true } />
          <ProgressBar percent={ progress } active={ true } theme={ 'cyan' } />
        </div>

      </div>

      {/*
      // Buttons
      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      */}
      <div className="pd-styleguide__section">
        <h4 className="pd-title-4">Buttons</h4>
        <div className="pd-styleguide__buttons">
          <button className="pd-button pd-button--navy">Navy</button>
          <button className="pd-button pd-button--cyan">Cyan</button>
          <button className="pd-button pd-button--primary">Primary</button>
          <button className="pd-button pd-button--secondary">Secondary</button>
          <button className="pd-button pd-button--light">Light</button>
          <button className="pd-button pd-button--success">Success</button>
          <button className="pd-button pd-button--warning">Warning</button>
          <button className="pd-button pd-button--danger">Danger</button>
          <button className="pd-button pd-button--info">Info</button>
          <button className="pd-button pd-button--link">Link</button>
        </div>
        <div className="pd-styleguide__buttons">
          <button className="pd-button pd-button--primary pd-button--icon">
            <IconLock className="pd-icon" />
            Button with icon
          </button>

          <button className="pd-button pd-button--light pd-button--icon">
            <IconRight className="pd-icon" />
            Button with icon
          </button>

          <button className="pd-button pd-button--link pd-button--icon">
            <IconSearch className="pd-icon" />
            Link with icon
          </button>

          <button className="pd-button pd-button--secondary pd-button--icon pd-button--icon-after">
            Icon after text
            <IconRight className="pd-icon" />
          </button>
        </div>
        <h4 className="pd-title-4">Text Buttons</h4>
        <div className="pd-styleguide__buttons">
          <button className="pd-button pd-button--text pd-button--navy">Navy</button>
          <button className="pd-button pd-button--text pd-button--cyan">Cyan</button>
          <button className="pd-button pd-button--text pd-button--primary">Primary</button>
          <button className="pd-button pd-button--text pd-button--secondary">Secondary</button>
          <button className="pd-button pd-button--text pd-button--light">Light</button>
          <button className="pd-button pd-button--text pd-button--success">Success</button>
          <button className="pd-button pd-button--text pd-button--warning">Warning</button>
          <button className="pd-button pd-button--text pd-button--danger">Danger</button>
          <button className="pd-button pd-button--text pd-button--info">Info</button>
          <button className="pd-button pd-button--text pd-button--link">Link</button>
        </div>
        <div className="pd-styleguide__buttons">
          <button className="pd-button pd-button--text-sm pd-button--navy">Navy</button>
          <button className="pd-button pd-button--text-sm pd-button--cyan">Cyan</button>
          <button className="pd-button pd-button--text-sm pd-button--primary">Primary</button>
          <button className="pd-button pd-button--text-sm pd-button--secondary">Secondary</button>
          <button className="pd-button pd-button--text-sm pd-button--light">Light</button>
          <button className="pd-button pd-button--text-sm pd-button--success">Success</button>
          <button className="pd-button pd-button--text-sm pd-button--warning">Warning</button>
          <button className="pd-button pd-button--text-sm pd-button--danger">Danger</button>
          <button className="pd-button pd-button--text-sm pd-button--info">Info</button>
          <button className="pd-button pd-button--text-sm pd-button--link">Link</button>
        </div>
      </div>

      {/*
      // Lightboxes
      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      */}
      <div className="pd-styleguide__section">
        <h4 className="pd-title-4">Lightboxes</h4>
        <div className="pd-styleguide__lightboxes">
          <PortalWithState>
            { portalBag => (
              <>
                <button onClick={ portalBag.openPortal } className="pd-button pd-button--info">Lightbox sample #1</button>
                { portalBag.portal(
                  <Lightbox portalBag={ portalBag }>
                    <div className="pd-styleguide__lightbox-sample-1 pd-text">
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo, cum non, modi illo nulla dolore placeat quis corrupti nesciunt quasi voluptatum soluta expedita consectetur porro consequatur officiis, molestias maiores totam.</p>
                      <InfoTip>Soon: custom close, locking, close confirmation and events.</InfoTip>
                    </div>
                  </Lightbox>
                ) }
              </>
            ) }
          </PortalWithState>

          <PortalWithState>
            { portalBag => (
              <>
                <button onClick={ portalBag.openPortal } className="pd-button pd-button--info">Lightbox sample #2</button>
                { portalBag.portal(
                  <Lightbox portalBag={ portalBag }>
                    <div className="pd-styleguide__lightbox-sample-2 pd-text">
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo, cum non, modi illo nulla dolore placeat quis corrupti nesciunt quasi voluptatum soluta expedita consectetur porro consequatur officiis, molestias maiores totam.</p>
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo, cum non, modi illo nulla dolore placeat quis corrupti nesciunt quasi voluptatum soluta expedita consectetur porro consequatur officiis, molestias maiores totam.</p>
                    </div>
                  </Lightbox>
                ) }
              </>
            ) }
          </PortalWithState>

          <PortalWithState>
            { portalBag => (
              <>
                <button onClick={ portalBag.openPortal } className="pd-button pd-button--info">Lightbox sample #3</button>
                { portalBag.portal(
                  <Lightbox portalBag={ portalBag }>
                    <div className="pd-scroll-panel pd-styleguide__lightbox-sample-3 pd-text">
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo, cum non, modi illo nulla dolore placeat quis corrupti nesciunt quasi voluptatum soluta expedita consectetur porro consequatur officiis, molestias maiores totam.</p>
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo, cum non, modi illo nulla dolore placeat quis corrupti nesciunt quasi voluptatum soluta expedita consectetur porro consequatur officiis, molestias maiores totam.</p>
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo, cum non, modi illo nulla dolore placeat quis corrupti nesciunt quasi voluptatum soluta expedita consectetur porro consequatur officiis, molestias maiores totam.</p>
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo, cum non, modi illo nulla dolore placeat quis corrupti nesciunt quasi voluptatum soluta expedita consectetur porro consequatur officiis, molestias maiores totam.</p>
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo, cum non, modi illo nulla dolore placeat quis corrupti nesciunt quasi voluptatum soluta expedita consectetur porro consequatur officiis, molestias maiores totam.</p>
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo, cum non, modi illo nulla dolore placeat quis corrupti nesciunt quasi voluptatum soluta expedita consectetur porro consequatur officiis, molestias maiores totam.</p>
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo, cum non, modi illo nulla dolore placeat quis corrupti nesciunt quasi voluptatum soluta expedita consectetur porro consequatur officiis, molestias maiores totam.</p>
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo, cum non, modi illo nulla dolore placeat quis corrupti nesciunt quasi voluptatum soluta expedita consectetur porro consequatur officiis, molestias maiores totam.</p>
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo, cum non, modi illo nulla dolore placeat quis corrupti nesciunt quasi voluptatum soluta expedita consectetur porro consequatur officiis, molestias maiores totam.</p>
                    </div>
                  </Lightbox>
                ) }
              </>
            ) }
          </PortalWithState>
        </div>
      </div>

      {/*
      // Disclaimers
      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      */}
      <div className="pd-styleguide__section">
        <h4 className="pd-title-4">Disclaimers</h4>
        <div className="pd-styleguide__disclaimers">
          <button
            className="pd-button pd-button--success pd-button--icon"
            onClick={ event => UI.showSuccessDisclaimer( 'Success disclaimer sample.' ) }>
            <IconSuccess className="pd-icon" />
            Success disclaimer
          </button>

          <button
            className="pd-button pd-button--warning pd-button--icon"
            onClick={ event => UI.showWarningDisclaimer( 'Warning disclaimer sample. Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium rem est eaque dolor.' ) }>
            <IconInfo className="pd-icon" />
            Warning disclaimer
          </button>

          <button
            className="pd-button pd-button--danger pd-button--icon"
            onClick={ event => UI.showErrorDisclaimer( 'Error disclaimer sample.' ) }>
            <IconInfo className="pd-icon" />
            Error disclaimer
          </button>

          <button
            className="pd-button pd-button--info pd-button--icon"
            onClick={ event => UI.showInfoDisclaimer( 'Info disclaimer sample. Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium rem est eaque dolor. Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium rem est eaque dolor.' ) }>
            <IconInfo className="pd-icon" />
            Info disclaimer
          </button>
        </div>
      </div>

      {/*
      // Tips
      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      */}
      <div className="pd-styleguide__section">
        <h4 className="pd-title-4">Tips</h4>

        <h5 className="pd-label">Regular</h5>
        <div className="pd-styleguide__tips">
          <InfoTip>Information tip sample.</InfoTip>
          <ErrorTip>Error tip sample.</ErrorTip>
          <SuccessTip>Success tip sample.</SuccessTip>
          <WarningTip>Warning tip sample.</WarningTip>
        </div>

        <h5 className="pd-label">Micro</h5>
        <div className="pd-styleguide__tips">
          <InfoTip micro={ true }>Information tip sample.</InfoTip>
          <ErrorTip micro={ true }>Error tip sample.</ErrorTip>
          <SuccessTip micro={ true }>Success tip sample.</SuccessTip>
          <WarningTip micro={ true }>Warning tip sample.</WarningTip>
        </div>
      </div>

      {/*
      // Table
      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      */}
      <div className="pd-styleguide__section">
        <h4 className="pd-title-4">Table</h4>
        <div className="pd-styleguide__table">
          <Table
            className="pd-styleguide__table-sample"
            cols={ [
              'Name',
              { label: 'E-mail', sortable: true, onClick: label => console.log( label ) },
              { label: 'Age', sortable: true },
              { label: 'Cost', sortable: true },
              'Date'
            ] }
            rows={ [
              [ 'John Cena', 'john@cena.com', 30, { value: '$ 100.22', sortValue: 100.22 }, moment().days( 1 ).format( 'MMM DD, YYYY, HH:mm' ) ],
              [ { value: 'Herb Dean', link: '/' }, 'herb@dean.com', 20, { value: '$ 200.20', sortValue: 200.22 }, moment().days( 7 ).format( 'MMM DD, YYYY, HH:mm' ) ],
              [ 'Bob Green', 'bob@green.com', 34, { value: '$ 1300.30', sortValue: 1300.30 }, moment().months( 2 ).format( 'MMM DD, YYYY, HH:mm' ) ]
            ] }
          />
        </div>
      </div>

      {/*
      // Pagination
      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      */}
      <div className="pd-styleguide__section">
        <h4 className="pd-title-4">Pagination</h4>
        <div className="pd-styleguide__pagination">
          <Pagination pages={ 3 } />
          <Pagination itemClassName="pd-button pd-button--primary" pages={ 10 } />
          <Pagination itemClassName="pd-button pd-button--link" pages={ 40 } />
        </div>
        <div className="pd-styleguide__pagination">
          <MetaPagination meta={ { currentPage: 1, nextPage: 2, prevPage: null, totalPages: 5, totalCount: 49 } } />
        </div>
      </div>

      {/*
      // Address decoder
      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      */}
      <div className="pd-styleguide__section">
        <h4 className="pd-title-4">Address decoder</h4>
        <AddressDecoderField
          className="pd-styleguide__decoder"
          buttonLabel="Decode"
          processingLabel="Decoding..."
          onDecodeSuccess={ address => UI.showSuccessDisclaimer( `Address found: ${ address.formattedAddress }.` ) }
          onDecodeError={ () => UI.showErrorDisclaimer( 'Address not found.' ) }
        />
      </div>

      {/*
      // Date Time Picker field
      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      */}
      <div className="pd-styleguide__section">
        <h4 className="pd-title-4">DateTime Picker field</h4>
        <div className="pd-styleguide__date-time-field">
          <div className="pd-styleguide__date-time-field__regular pd-field">
            <label className="pd-label">Regular</label>
            <DateTimePickerField
              name="dateTime"
              value={ dateTime }
              onChange={ ( name, value ) => {
                setDateTime( value )
                UI.showInfoDisclaimer( `${ name }: ${ value }` )
              } }
            />
          </div>

          <Formik
            initialValues={ { dateTimeFormik: "3/5/1984" } }
            onSubmit={ ( values, { setSubmitting } ) => {
              setTimeout( () => {
                UI.showInfoDisclaimer( JSON.stringify( values, null, 2 ) )
                setSubmitting( false );
              }, 500 )
            } }>
            { fProps => (
              <Form className="pd-styleguide__date-time-field__formik">
                <div className="pd-field">
                  <label className="pd-label">With Formik</label>
                  <DateTimePickerField
                    name="dateTimeFormik"
                    value={ fProps.values.dateTimeFormik }
                    onChange={ ( name, value ) => {
                      fProps.setFieldValue( name, value )
                      UI.showInfoDisclaimer( `${ name }: ${ value }` )
                    } }
                  />
                </div>
                <button className="pd-button pd-button--primary">Submit</button>
              </Form>
            ) }
          </Formik>
        </div>
      </div>
    </section>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default Styleguide