import React, { useEffect, useContext, useState } from 'react'
import { Link, NavLink, useLocation, useParams } from 'react-router-dom'
import { find, isNil, isFinite } from 'lodash'

import { renderRoute } from '../../config/routes'
import { GlobalContext } from '../../context/global-state'
import { DashboardContext, DashboardState } from '../../context/dashboard-state'
import { UIContext } from '../../context/ui-state'

import { eFetch, getHeaders } from '../../config/fetch'
import { getReadbleOrderState, toSnakeCase } from '../../config/adapters'
import { PLEDGE_STATE } from '../../config/constants'
import { SuccessTip, WarningTip, ErrorTip } from '../../components/tip/tip'

import NotFound from '../../pages/not-found/not-found'
import LoadingMessage from '../../components/loading-message/loading-message'

import './dashboard-pledge.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const DashboardPledge = props => {

  const context = useContext( GlobalContext )
  const dashboardContext = useContext( DashboardContext )
  const UI = useContext( UIContext )
  const { secureRender } = context
  const { fetchPledge, pledge, fetchProject, project } = dashboardContext
  const { routes } = props
  const { pledgeId } = useParams()
  const [ isCheckingPackingSlip, setCheckingPackingSlip ] = useState( false )
  const [ isCheckingInvoice, setCheckingInvoice ] = useState( false )
  const [ sideNavList, setSideNavList ] = useState( [] )
  const [ currentSection, setCurrentSection ] = useState( null )
  const { pathname } = useLocation()

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handlePledgeFetch = async () => {
    try {

      await fetchPledge( pledgeId )

    } catch ( exception ) {

      UI.showErrorDisclaimer( exception.message )

    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handlePledgeLoad = () => {
    if ( pledge.isFetched && !project.isFetched && !project.isFetching ) {
      fetchProject( pledge.details.project.id )
      setSideNavList( getSideNavList( pledge.details.state ) )
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const renderPledgeState = pledgeState => {
    switch ( pledgeState ) {
      case PLEDGE_STATE.FINISHED:
      case PLEDGE_STATE.READY_TO_SHIP:
      case PLEDGE_STATE.SHIPPED:
        return ( <SuccessTip className="pd-tpl-dashboard__status">Order status: <strong>{ getReadbleOrderState( pledgeState ) }</strong></SuccessTip> )

      case PLEDGE_STATE.PENDING:
      case PLEDGE_STATE.UNLOCKED:
        return ( <WarningTip className="pd-tpl-dashboard__status">Order status: <strong>{ getReadbleOrderState( pledgeState ) }</strong></WarningTip> )

      case PLEDGE_STATE.ERRORED:
      case PLEDGE_STATE.CANCELED:
      case PLEDGE_STATE.REFUNDED:
      case PLEDGE_STATE.RETAILER_MISSED_DEADLINE:
        return ( <ErrorTip className="pd-tpl-dashboard__status">Order status: <strong>{ getReadbleOrderState( pledgeState ) }</strong></ErrorTip> )

      default:
        return null
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const getSideNavList = pledgeState => {
    let unlockLabel

    switch ( pledgeState ) {
      case PLEDGE_STATE.CANCELED:
      case PLEDGE_STATE.RETAILER_MISSED_DEADLINE:
        unlockLabel = 'Reopen'
        break

      default:
        unlockLabel = 'Unlock'
    }

    return [
      { label: 'Overview', url: '' },
      { label: 'Audits', url: 'audits' },
      { label: 'Transaction history', url: 'transaction-history' },
      { label: 'Tracking numbers', url: 'tracking-numbers' },
      { label: unlockLabel, url: 'unlock' },
      { label: 'Discount', url: 'discount' },
      { label: 'Pledge transfer', url: 'transfer' },
      { label: 'Credit transfer', url: 'credit-transfer' }
    ]
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const requestShippingWave = () => {
    const { waves } = project.details

    if ( waves <= 1 ) return {
      isInvalid: false,
      data: 1
    }

    const wave = prompt( 'Please inform the shipping wave (ex: 1):' )

    if ( isNil( wave ) ) return { isInvalid: true }

    if ( ( !isFinite( parseInt( wave ) ) ) || ( parseInt( wave ) > parseInt( waves ) ) ) {
      UI.showErrorDisclaimer( 'Invalid shipping wave.' )
      return { isInvalid: true }
    }

    return {
      isInvalid: false,
      data: wave
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const getLastPackingSlip = async () => {

    const selectedWave = requestShippingWave()
    if ( selectedWave.isInvalid ) return

    setCheckingPackingSlip( true )

    try {

      const slip = await eFetch( `/geeks/${ pledgeId }/packing_slip`, {
        headers: getHeaders(),
        method: 'post',
        body: JSON.stringify( toSnakeCase( {
          wave: selectedWave.data
        } ) )
      } )
      const binary = await slip.blob()
      window.open( URL.createObjectURL( binary ), '_blank' )

    } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }

    setCheckingPackingSlip( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const getInvoice = async () => {
    const selectedWave = requestShippingWave()
    if ( selectedWave.isInvalid ) return

    setCheckingInvoice( true )

    try {

      const invoice = await eFetch( `/geeks/${ pledgeId }/invoice`, {
        headers: getHeaders(),
        method: 'post',
        body: JSON.stringify( toSnakeCase( {
          wave: selectedWave.data
        } ) )
      } )
      const binary = await invoice.blob()
      window.open( URL.createObjectURL( binary ), '_blank' )

    } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }

    setCheckingInvoice( false )
  }


  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const renderBreadcrumbLink = ( link, label ) => {
    return (
      <Link className="pd-button pd-button--navy pd-button--text pd-button--text-inline" to={ `/dashboard/${ link }` }>{ label }</Link>
    )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleCurrentSectionChange = () => {
    if ( !sideNavList.length ) return
    const matchUrl = pathname.replace( `/dashboard/pledge/${ pledgeId }`, '' ).replace( '/', '' )
    setCurrentSection( find( sideNavList, { url: matchUrl } ) )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )
  useEffect( () => { handlePledgeFetch() }, [ pledgeId ] )
  useEffect( handlePledgeLoad, [ pledge, project ] )
  useEffect( handleCurrentSectionChange, [ pathname, sideNavList ] )

  useEffect( () => {
    if ( project.isFetched ) {

      console.log( project.details.waves )

    }
  }, [ project ] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  if ( pledge.error && pledge.error.request.status === 404 ) return (
    <NotFound>
      <h3 className="pd-title-3">Pledge not found.</h3>
      <p className="pd-text">
        There is no pledge with the id <strong>{ pledgeId }</strong> in our database.
      </p>
    </NotFound>
  )

  return secureRender(

    <section className="pd-tpl-dashboard pd-page pd-dashboard-pledge">
      { pledge.isFetched && project.isFetched ? (
        <>
          <header className="pd-tpl-dashboard__header">
            <div className="pd-tpl-dashboard__breadcrumb">
              <h2 className="pd-title-2">{ project.details.name }</h2>
              <div className="pd-tpl-dashboard__breadcrumb-items">
                { renderBreadcrumbLink( `project/${ project.details.id }`, 'Project home' ) }
                { renderBreadcrumbLink( `project/${ project.details.id }/backer-report`, 'Backer report' ) }
                { renderBreadcrumbLink( `pledge/${ pledgeId }/`, <>Pledge <strong>#{ pledgeId }</strong></> ) }
                { currentSection && <p className="pd-text">{ currentSection.label }</p> }
              </div>
              <div className="pd-tpl-dashboard__breadcrumb-items">
                { renderBreadcrumbLink( `user/${ pledge.details.user.id }`, <><strong>{ pledge.details.user.name }</strong> ({ pledge.details.user.email })</> ) }
              </div>
            </div>

            { renderPledgeState( pledge.details.state ) }
          </header>

          <nav className="pd-tpl-dashboard__nav">
            <ul className="pd-tpl-dashboard__nav-list">
              { sideNavList.map( ( item, index ) => (

                <li key={ index } className="pd-tpl-dashboard__nav-item">
                  <NavLink
                    to={ `/dashboard/pledge/${ pledgeId }/${ item.url }` }
                    className="pd-button pd-button--cyan"
                    activeClassName="pd-button--active"
                    exact={ true }>{ item.label }</NavLink>
                </li>

              ) ) }

              <li className="pd-tpl-dashboard__nav-item">
                <button
                  className="pd-button pd-button--cyan"
                  onClick={ getLastPackingSlip }
                  disabled={ isCheckingPackingSlip }>
                  { isCheckingPackingSlip ? 'Processing...' : 'Packing slip' }
                </button>
              </li>

              <li className="pd-tpl-dashboard__nav-item">
                <button
                  className="pd-button pd-button--cyan"
                  onClick={ getInvoice }
                  disabled={ isCheckingInvoice }>
                  { isCheckingInvoice ? 'Processing...' : 'Invoice' }
                </button>
              </li>
            </ul>
          </nav>

          <div className="pd-tpl-dashboard__container">
            { routes.map( ( route, index ) => secureRender( renderRoute( route, index ) ) ) }
          </div>
        </>
      ) : <LoadingMessage /> }
    </section>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

const DashboardPledgeWrapper = props => {
  const { params } = props.match

  return (
    <DashboardState params={ params }>
      <DashboardPledge { ...props } />
    </DashboardState>
  )
}

export default DashboardPledgeWrapper