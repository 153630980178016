import React from 'react'
import { Link } from 'react-router-dom'

import { getImageSrc } from '../../config/adapters'

import './available-projects-list.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const AvailableProjectsList = props => {

  const { className = '', collection = [] } = props
  const imageUnavailable = 'https://placehold.it/640x360?text=Unavailable+image'

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <section className={ `pd-available-projects-list ${ className }` }>
      <div className="pd-available-projects-list__container pd-container">
        { collection.length > 0 ?
          (
            <>
              <h2 className="pd-title-2">Projects available<br />to late pledge</h2>
              <ul className="pd-available-projects-list__list">
                { collection.map( ( project, index ) => (

                  <li key={ index } className="pd-available-projects-list__item">
                    <Link className="pd-available-projects-list__link" to={ `/checkout/${ project.slug }` }>
                      <img src={ getImageSrc( project.image, { w: 640, h: 360, resizeTo: 'fill', quality: 60 } ) || imageUnavailable } alt={ project.name } />
                      <div className="pd-available-projects-list__wrapper">
                        <h4 className="pd-title-3">{ project.name }</h4>
                        <p className="pd-text">{ project.headline }</p>
                      </div>
                    </Link>
                  </li>

                ) ) }
              </ul>
            </>
          ) : (

            <h2 className="pd-title-2">No projects available<br />to late pledge</h2>

          )
        }

      </div>
    </section>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default AvailableProjectsList