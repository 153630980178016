import React, { useEffect, useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'

import { GlobalContext } from '../../context/global-state'

import { sFetch } from '../../config/fetch'
import { getImageSrc, formatCurrency, getReadbleOrderState } from '../../config/adapters'
import { PLEDGE_STATE } from '../../config/constants'
import cookie from '../../config/cookie'

import Table from '../../components/table/table'
import LoadingMessage from '../../components/loading-message/loading-message'
import { WarningTip, SuccessTip, ErrorTip, InfoTip } from '../../components/tip/tip'

import { ReactComponent as IconCart } from '../../assets/icons/shopping-cart.svg'
import { ReactComponent as IconList } from '../../assets/icons/list-view.svg'
import { ReactComponent as IconColumn } from '../../assets/icons/column-view.svg'

import './account-pledges.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const AccountPledges = props => {
  const cookieKey = 'account-pledges-view-as'
  const context = useContext( GlobalContext )
  const { fetchPledges, userPledges, isRetailer } = context
  const { isFetching, list } = userPledges
  const { className = '' } = props
  const [ viewAs, setViewAs ] = useState( cookie.get( cookieKey ) || 'grid' )
  const [ gkPledges, setGkPledges ] = useState( {
    list: [],
    isFetching: false,
    isFetched: false,
    hasData: false
  } )
  const imageUnavailable = 'https://placehold.it/640x360?text=Unavailable+image'


  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => {
    fetchPledges()
    if ( !isRetailer ) fetchGKPledges()
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const fetchGKPledges = async () => {

    try {

      setGkPledges( { list: [], isFetching: true, isFetched: false, hasData: false } )

      const pledges = await sFetch( `/gk_pledges` )

      setGkPledges( { list: pledges, isFetching: false, isFetched: true, hasData: !!pledges.length } )

    } catch ( exception ) {

      setGkPledges( { list: [], isFetching: false, isFetched: false, hasData: false } )

    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleViewAsClick = ( viewAs, evt ) => {
    setViewAs( viewAs )
    cookie.set( cookieKey, viewAs )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const renderPledgeState = ( pledgeState, pledgeManagerState ) => {

    switch ( pledgeState ) {
      case PLEDGE_STATE.FINISHED:
      case PLEDGE_STATE.READY_TO_SHIP:
      case PLEDGE_STATE.SHIPPED:
        return ( <SuccessTip micro>{ getReadbleOrderState( pledgeState, pledgeManagerState ) }</SuccessTip> )

      case PLEDGE_STATE.PENDING:
      case PLEDGE_STATE.UNLOCKED:
        return ( <WarningTip micro>{ getReadbleOrderState( pledgeState ) }</WarningTip> )

      case PLEDGE_STATE.REFUNDED:
      case PLEDGE_STATE.CANCELED:
      case PLEDGE_STATE.RETAILER_MISSED_DEADLINE:
      case PLEDGE_STATE.ERRORED:
        return ( <ErrorTip micro>{ getReadbleOrderState( pledgeState ) }</ErrorTip> )

      default:
        return null
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const renderGkPledgeState = ( pledgeState, projectClosed = false ) => {
    if ( pledgeState === 'confirmed' ) return (
      <SuccessTip micro>Finished</SuccessTip>
    )

    if ( pledgeState === 'Not saved' ) return (
      <WarningTip micro>Pending</WarningTip>
    )

    if ( pledgeState === 'cancel' ) return (
      <ErrorTip micro>Canceled</ErrorTip>
    )

    if ( pledgeState === 'archived' && projectClosed === '0' ) return (
      <ErrorTip micro>Archived - Project Closed</ErrorTip>
    )

    return null
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <section className={ `pd-account-pledges pd-account__section pd-white-box pd-white-box--no-padding pd-text ${ className } pd-account-pledges--view-as-${ viewAs }` }>
      <header className="pd-account__header pd-account-pledges__header">
        <IconCart className="pd-icon" />
        <h3 className="pd-title-3">Pledges</h3>
        <nav className="pd-account-pledges__nav-view">
          <span className="pd-text-sm">View as:</span>
          <IconColumn className="pd-icon pd-account-pledges__nav-view-as-grid-icon" onClick={ handleViewAsClick.bind( this, 'grid' ) } />
          <IconList className="pd-icon pd-account-pledges__nav-view-as-table-icon" onClick={ handleViewAsClick.bind( this, 'table' ) } />
        </nav>
      </header>

      <div className="pd-account__container pd-account-pledges__container">
        { isFetching ?
          (
            <LoadingMessage className="pd-account-pledges__loading" />

          ) : list.length ? (

            <>
              <Table
                className="pd-account-pledges__table pd-table--x-scroll"
                cols={ [
                  { label: 'Project', sortable: true },
                  { label: 'Status', sortable: true },
                  { label: 'Date', sortable: true },
                  { label: 'Order total', sortable: true }
                ] }
                rows={ list.map( pledge => {
                  return ( [
                    { value: pledge.project.name, link: `/checkout/${ pledge.project.slug }` },
                    { value: <>{ renderPledgeState( pledge.state, pledge.project.pledgeManagerState ) }</>, sortValue: pledge.state },
                    { value: moment( pledge.createdAt ).format( 'MMM DD, YYYY, HH:mm' ), sortValue: pledge.createdAt },
                    { value: formatCurrency( pledge.total ), sortValue: parseFloat( pledge.total ) }
                  ] )
                }
                ) }
              />

              <ul className="pd-account-pledges__list">
                { list.map( ( pledge, index ) => (

                  <li key={ index } className="pd-account-pledges__item">
                    <Link className="pd-account-pledges__link" to={ `/checkout/${ pledge.project.slug }` }>
                      <img src={ getImageSrc( pledge.project.image, { w: 640, h: 360, resizeTo: 'fill', quality: 60 } ) || imageUnavailable } alt={ pledge.project.name } />
                      <div className="pd-account-pledges__wrapper">
                        { renderPledgeState( pledge.state, pledge.project.pledgeManagerState ) }
                        <h4 className="pd-title-4">{ pledge.project.name }</h4>
                        <p className="pd-text">
                          Order total: { formatCurrency( pledge.total ) } <br />
                          Ordered in: { moment( pledge.createdAt ).format( 'MMM DD, YYYY, HH:mm' ) }
                        </p>
                      </div>
                    </Link>
                  </li>

                ) ) }
              </ul>
            </>

          ) : (

            <InfoTip className="pd-account-pledges__empty-state">No pledges to display.</InfoTip>

          )
        }
      </div>

      { !isRetailer && gkPledges.isFetched && gkPledges.hasData ? (
        <>
          <header className="pd-account__header pd-account-pledges__header">
            <IconCart className="pd-icon" />
            <h3 className="pd-title-3">Geekfunder Pledges</h3>
            <nav className="pd-account-pledges__nav-view">
              <span className="pd-text-sm">View as:</span>
              <IconColumn className="pd-icon pd-account-pledges__nav-view-as-grid-icon" onClick={ handleViewAsClick.bind( this, 'grid' ) } />
              <IconList className="pd-icon pd-account-pledges__nav-view-as-table-icon" onClick={ handleViewAsClick.bind( this, 'table' ) } />
            </nav>
          </header>

          <div className="pd-account__container pd-account-pledges__container">
            <Table
              className="pd-account-pledges__table pd-table--x-scroll"
              cols={ [
                { label: 'Project', sortable: true },
                { label: 'Status', sortable: true },
                { label: 'Order total', sortable: true }
              ] }
              rows={ gkPledges.list.map( pledge => {
                return ( [
                  { value: pledge.projectTitle, link: pledge.url },
                  { value: <>{ renderGkPledgeState( pledge.status, pledge.projectClosed ) }</>, sortValue: pledge.state },
                  { value: formatCurrency( pledge.total ), sortValue: parseFloat( pledge.total ) }
                ] )
              }
              ) }
            />

            <ul className="pd-account-pledges__list">
              { gkPledges.list.map( ( pledge, index ) => (

                <li key={ index } className="pd-account-pledges__item">
                  <a className="pd-account-pledges__link" href={ pledge.url } target="_blank" rel="noopener noreferrer">
                    <img src={ pledge.projectImageUrl || imageUnavailable } alt={ pledge.projectTitle } />
                    <div className="pd-account-pledges__wrapper">
                      { renderGkPledgeState( pledge.status, pledge.projectClosed ) }
                      <h4 className="pd-title-4">{ pledge.projectTitle }</h4>
                      <p className="pd-text">
                        Order total: { formatCurrency( pledge.total ) }
                      </p>
                    </div>
                  </a>
                </li>

              ) ) }
            </ul>
          </div>
        </>
      ) : null }


    </section>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default AccountPledges