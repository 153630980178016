import React, { useEffect, useContext, useState } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import { useParams } from 'react-router-dom'
import moment from 'moment-timezone'
import autosize from 'autosize'

import { DashboardContext } from '../../context/dashboard-state'
import { UIContext } from '../../context/ui-state'

import { sFetch } from '../../config/fetch'
import { toSnakeCase } from '../../config/adapters'
import { URL_REGEX, NOT_EMPTY_REGEX } from '../../config/validators'

import InitialInviteChecker from '../../modules/initial-invite-checker/initial-invite-checker'
import { InfoTip } from '../../components/tip/tip'
import DateTimePickerField from '../../components/date-time-picker-field/date-time-picker-field'

import './notifications.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const Notifications = props => {

  const dashboardContext = useContext( DashboardContext )
  const UI = useContext( UIContext )
  const { project, updateProject } = dashboardContext
  const [ currentMode, setCurrenMode ] = useState( null )
  const [ isProcessingReminder, setProcessingReminder ] = useState( false )
  const { projectId } = useParams()
  const MODE = {
    CUSTOM_EMAIL: 0,
    REMINDER: 1,
    KS: 2,
    ENTRY: 3,
    SETTINGS: 4
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleProjectLoad = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleReminderClick = async event => {
    if ( !window.confirm( 'Are you sure?' ) ) return
    setProcessingReminder( true )

    try {

      await sFetch( `/projects/${ projectId }/send_packing_slips_reminder`, { method: 'post' } )
      UI.showSuccessDisclaimer( 'Reminder sent successfully!' )

    } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }

    setProcessingReminder( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleKsSubmit = async ( payload, actions ) => {
    if ( !window.confirm( 'Are you sure?' ) ) return

    try {

      await sFetch( `/projects/${ projectId }/send_ks_update_notification`, { method: 'post', body: JSON.stringify( toSnakeCase( payload ) ) } )
      UI.showSuccessDisclaimer( 'Kicstarter update sent successfully!' )
      actions.resetForm()

    } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }

    actions.setSubmitting( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleCustomEmailValidation = values => {
    let errors = {}

    if ( !NOT_EMPTY_REGEX.test( values.subject ) ) errors.subject = 'Please provide a subject.'
    if ( !NOT_EMPTY_REGEX.test( values.message ) ) errors.message = 'Please provide a content.'

    return errors
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleCustomEmailSubmit = async ( payload, actions ) => {
    if ( !window.confirm( 'Are you sure?' ) ) return

    try {

      await sFetch( `/projects/${ projectId }/send_custom_notification`, { method: 'post', body: JSON.stringify( payload ) } )
      UI.showSuccessDisclaimer( 'Custom e-mail sent successfully!' )
      actions.resetForm()

    } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }

    actions.setSubmitting( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSettingsValidation = values => {
    let errors = {}

    if ( !moment( new Date( values.reminderNotificationDeadline ) ).isValid() ) errors.reminderNotificationDeadline = 'Please provide a valid date.'
    if ( !moment( new Date( values.notifyUntil ) ).isValid() ) errors.notifyUntil = 'Please provide a valid date.'

    return errors
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSettingsSubmit = async ( payload, actions ) => {
    if ( !window.confirm( 'Are you sure?' ) ) return

    try {
      await updateProject( payload )

      UI.showSuccessDisclaimer( 'Notification settings saved successfully!' )

    } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }

    actions.setSubmitting( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const renderCustomEmailModule = () => currentMode === MODE.CUSTOM_EMAIL && (
    <div className="pd-tpl-nav-content__box">
      <Formik
        initialValues={ {
          subject: '',
          message: ''
        } }
        onSubmit={ handleCustomEmailSubmit }
        validate={ handleCustomEmailValidation }
        validateOnChange={ false }
        validateOnBlur={ false }>
        { fProps => (

          <Form className="pd-notifications__custom-email">
            <InfoTip>This function will send the customized message below to all backers of this campaign.</InfoTip>

            <div className="pd-field">
              <label className="pd-label" htmlFor="subject">Subject</label>
              <Field className="pd-textfield" name="subject" />
              <ErrorMessage name="subject" component="span" className="pd-text-sm pd-text--error" />
            </div>

            <div className="pd-field">
              <label className="pd-label" htmlFor="message">Body</label>
              <Field className="pd-textfield" component="textarea" name="message" id="message" />
              <ErrorMessage name="message" component="span" className="pd-text-sm pd-text--error" />
            </div>

            <button className="pd-button pd-button--navy" type="submit" disabled={ fProps.isSubmitting }>{ fProps.isSubmitting ? 'Sending...' : 'Send' }</button>
          </Form>

        ) }
      </Formik>
    </div>
  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const renderReminderModule = () => currentMode === MODE.REMINDER && (
    <div className="pd-tpl-nav-content__box pd-notifications__reminder">
      <InfoTip>This will send a notification reminding all backers that have NOT closed their pledges to come back and do so.</InfoTip>
      <button className="pd-button pd-button--navy" onClick={ handleReminderClick } disabled={ isProcessingReminder }>{ isProcessingReminder ? 'Sending...' : 'Send' }</button>
    </div>
  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const renderKSModule = () => currentMode === MODE.KS && (
    <div className="pd-tpl-nav-content__box">
      <Formik
        initialValues={ { ksUrl: '' } }
        onSubmit={ handleKsSubmit }
        validate={ values => URL_REGEX.test( values.ksUrl ) ? null : { ksUrl: 'Please provide a valid url.' } }
        validateOnChange={ false }
        validateOnBlur={ false }>
        { fProps => (

          <Form className="pd-notifications__ks-update">
            <InfoTip>This will send a notification to all backers that did NOT come from a Kickstarter import, linking to the update below.</InfoTip>

            <div className="pd-field">
              <label className="pd-label" htmlFor="ksUrl">Kickstarter update link</label>
              <Field className="pd-textfield" name="ksUrl" />
              <ErrorMessage name="ksUrl" component="span" className="pd-text-sm pd-text--error" />
            </div>

            <button className="pd-button pd-button--navy" type="submit" disabled={ fProps.isSubmitting }>{ fProps.isSubmitting ? 'Sending...' : 'Send' }</button>
          </Form>

        ) }
      </Formik>
    </div>
  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const renderSettingsModule = () => currentMode === MODE.SETTINGS && (
    <div className="pd-tpl-nav-content__box">
      <Formik
        initialValues={ {
          reminderNotificationDeadline: project.details.reminderNotificationDeadline,
          notifyUntil: project.details.notifyUntil
        } }
        onSubmit={ handleSettingsSubmit }
        validate={ handleSettingsValidation }
        validateOnChange={ false }
        validateOnBlur={ false }>
        { fProps => (

          <Form className="pd-notifications__settings">
            <div className="pd-field">
              <label className="pd-label" htmlFor="reminderNotificationDeadline">Reminder notification deadline</label>
              <DateTimePickerField
                name="reminderNotificationDeadline"
                value={ fProps.values.reminderNotificationDeadline }
                onChange={ ( name, value ) => { fProps.setFieldValue( name, value ) } }
              />
              <ErrorMessage name="reminderNotificationDeadline" component="span" className="pd-text-sm pd-text--error" />
            </div>


            <div className="pd-field">
              <label className="pd-label" htmlFor="notifyUntil">Notify until</label>
              <DateTimePickerField
                name="notifyUntil"
                value={ fProps.values.notifyUntil }
                onChange={ ( name, value ) => { fProps.setFieldValue( name, value ) } }
              />
              <ErrorMessage name="notifyUntil" component="span" className="pd-text-sm pd-text--error" />
            </div>

            <button className="pd-button pd-button--navy" type="submit" disabled={ fProps.isSubmitting }>{ fProps.isSubmitting ? 'Saving...' : 'Save' }</button>
          </Form>

        ) }
      </Formik>
    </div>
  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const renderNavItme = ( key, label, className = 'pd-button--navy' ) => (

    <button
      className={ `pd-button ${ currentMode === key ? 'pd-button--active' : '' } ${ className }` }
      onClick={ event => setCurrenMode( key ) }>
      { label }
    </button>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  

  const handleMessageFieldResize = () => {
    if ( currentMode === MODE.CUSTOM_EMAIL ) {
      const element = document.getElementById( 'message' )
      autosize.destroy( element )
      autosize( element )
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )
  useEffect( handleProjectLoad, [ project ] )
  useEffect( handleMessageFieldResize, [ currentMode ] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <>
      <InitialInviteChecker project={ project } />

      <section className="pd-notifications pd-tpl-nav-content">
        <nav className="pd-tpl-nav-content__nav">
          { renderNavItme( MODE.SETTINGS, 'Reminder settings', 'pd-button--cyan' ) }
          { renderNavItme( MODE.CUSTOM_EMAIL, 'Custom e-email' ) }
          { renderNavItme( MODE.REMINDER, 'Late Confirm notification' ) }
          { renderNavItme( MODE.KS, 'Kickstarter update' ) }
        </nav>

        <div className="pd-notifications__main pd-tpl-nav-content__main">
          { renderReminderModule() }
          { renderCustomEmailModule() }
          { renderKSModule() }
          { renderSettingsModule() }
        </div>
      </section>
    </>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default Notifications