import React, { useContext, useState } from 'react'
import { sortBy } from 'lodash'

import { CheckoutContext } from '../../context/checkout-state'
import { UIContext } from '../../context/ui-state'

import CheckoutProduct from '../checkout-product/checkout-product'
import CheckoutCartWarningLightbox from '../../modules/checkout-cart-warning-lightbox/checkout-cart-warning-lightbox'
import AmountSelector from '../../components/amount-selector/amount-selector'
import { InfoTip, WarningTip } from '../../components/tip/tip'

import './checkout-optional-buys-selector.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const CheckoutOptionalBuysSelector = props => {

  const checkoutContext = useContext( CheckoutContext )
  const UI = useContext( UIContext )
  const {
    project,
    pledge,
    showcase,
    addOptionalBuy,
    updateOptionalBuyAmount,
    stepTo,
    STEP
  } = checkoutContext
  const { waves, currentWave } = project
  const [ cartWarning, setCartWarning ] = useState( null )
  const { className = '' } = props

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const showCartWarning = async () => (
    new Promise( async resolve => {
      UI.setConfirmationCallback( resolve )
    } )
  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const includeOptionalBuy = async item => {
    const { cartWarning, uid } = item

    addOptionalBuy( uid )

    if ( cartWarning ) {
      setCartWarning( cartWarning )
      await showCartWarning()
      setCartWarning( null )
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <div className={ `pd-checkout-optional-buys-selector pd-checkout-module ${ className }` }>
      <header className="pd-checkout-module__header">
        <h3 className="pd-title-3">{ showcase.hasBasePledges ? '02. Optional buys' : '01. Products' }</h3>
        <p className="pd-text">
          { showcase.hasBasePledges ?

            'You may include additional items in your pledge, please note some of these items may have limited quantities in stock or per order.'

            :

            'Please include products in your order, please note some of these items may have limited quantities in stock or per order.'

          }
        </p>

        { showcase.hasLockedOptionalBuysSelected ? (

          <WarningTip>
            The deadline to edit {waves }-Wave Expedited Shipping products has passed, and therefore some Optional Buys are no longer editable as they include products in Wave {currentWave - 1 }.
          </WarningTip>

        ) : null }
      </header>

      <ul className="pd-checkout-module__list">
        { sortBy( showcase.optionalBuys, [ 'isBundle', 'position' ] ).map( ( item, index ) => {
          return (

            <li key={ index } className="pd-checkout-module__list-item">
              <CheckoutProduct item={ item } showWaveInfo={ pledge.isMultiwave && project.hasMultipleWaves }>
                { item.isSelected ?
                  (
                    <>
                      { item.minimumPerUser ? <InfoTip micro={ true }>Minimum units: <strong>{ item.minimumPerUser }</strong></InfoTip> : null }
                      <AmountSelector
                        max={ item.maxAllowed }
                        initialValue={ item.amount }
                        isDisabled={ item.isDisabled || item.isLockedToOrder || showcase.isLocked }
                        onChange={ value => updateOptionalBuyAmount( item.uid, value ) }
                      />
                    </>

                  ) : (

                    <button
                      className="pd-button pd-button--cyan"
                      disabled={ item.isDisabled || showcase.isLocked }
                      onClick={ event => includeOptionalBuy( item ) }>
                      Add to cart
                    </button>

                  )
                }
              </CheckoutProduct>
            </li>

          )
        }
        ) }
      </ul>

      <nav className="pd-checkout-module__nav">
        <button
          className="pd-button pd-button--navy"
          onClick={ event => stepTo( STEP.SHIPPING ) }
          disabled={ showcase.shippingLocked || showcase.isLocked }>
          Continue</button>
      </nav>

      { cartWarning && (
        <CheckoutCartWarningLightbox cartWarning={ cartWarning } />
      ) }

    </div>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default CheckoutOptionalBuysSelector