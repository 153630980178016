import React, { useEffect, useContext } from 'react'

import { GlobalContext } from '../../context/global-state'

import SimpleNavList from '../simple-nav-list/simple-nav-list'

import './dashboard-projects-list.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const DashboardProjectsList = props => {

  const context = useContext( GlobalContext )
  const { isRetailer, loggedUser } = context
  const { className = '' } = props

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <section className={ `pd-dashboard-projects-list pd-white-box ${ className }` }>
      <h3 className="pd-title-4 pd-dashboard-projects-list__title">Projects</h3>

      { loggedUser.data.companies.map( company => (

        <SimpleNavList
          key={ company.id }
          className="pd-dashboard-projects-list__nav"
          source={ company.projects
            .filter( project => ( project.retailer === isRetailer ) )
            .map( project => (
              { label: project.name, url: `/dashboard/project/${ project.id }` }
            ) )
          }
        />

      ) ) }
    </section>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default DashboardProjectsList