import React from 'react'
import { Link } from 'react-router-dom'
import { isArray } from 'lodash'

import './simple-nav-list.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const SimpleNavList = props => {

  const { className = '', source = null } = props

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return isArray( source ) && (

    <nav className={ `pd-simple-nav-list ${ className }` }>
      <ul className="pd-simple-nav-list__list">
        { source.map( ( item, index ) => (

          <li key={ index } className="pd-simple-nav-list__item">
            <Link className="pd-text" to={ item.url }>{ item.label }</Link>
          </li>

        ) ) }
      </ul>
    </nav>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default SimpleNavList