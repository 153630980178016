import { forIn, isPlainObject, isArray, camelCase, snakeCase, capitalize, lowerCase, padStart, startsWith } from 'lodash'
import queryString from 'query-string'

import Env from './env'
import { YT_URL_REGEX } from './validators'
import { PLEDGE_STATE, PLEDGE_MANAGER_STATE } from './constants'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export function toCamelCase ( source ) {

  if ( isArray( source ) ) return source.map( item => toCamelCase( item ) )

  let normalized = {}

  forIn( source, ( value, key ) => {
    if ( isPlainObject( value ) ) value = toCamelCase( value )
    if ( isArray( value ) ) value = value.map( item => isPlainObject( item ) ? toCamelCase( item ) : item )
    normalized[ camelCase( key ) ] = value
  } )

  return normalized
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export function toSnakeCase ( source ) {

  const _snakeCase = item => startsWith( item, '_' ) ? `_${ snakeCase( item ) }` : snakeCase( item )

  if ( isArray( source ) ) return source.map( item => toSnakeCase( item ) )

  let normalized = {}

  forIn( source, ( value, key ) => {
    if ( isPlainObject( value ) ) value = toSnakeCase( value )
    if ( isArray( value ) ) value = value.map( item => isPlainObject( item ) ? toSnakeCase( item ) : item )
    normalized[ _snakeCase( key ) ] = value
  } )

  return normalized
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export function getRichTextContent ( source ) {
  return {
    __html: ( source || '' )
      .replace( /<h1>/g, '<h1 class="pd-title-1">' )
      .replace( /<h2>/g, '<h2 class="pd-title-2">' )
      .replace( /<h3>/g, '<h3 class="pd-title-3">' )
      .replace( /<h4>/g, '<h4 class="pd-title-4">' )
      .replace( /<p>/g, '<p class="pd-text">' )
      .replace( /<a /g, '<a class="pd-button pd-button--text pd-button--text-inline pd-button--navy" ' )
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export function getImageSrc ( image, params = null ) {
  if ( !image ) return null
  params = isPlainObject( params ) ? `?${ queryString.stringify( toSnakeCase( params ) ) }` : ''
  return `${ Env.baseApiUrl }/images/${ image.id || image }/raw${ params }`
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export function getNormalizedHref ( baseUrl = '' ) {
  return baseUrl.substr( 0, 4 ) === 'http' ? baseUrl : `http://${ baseUrl }`
}
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export function getCurrencyValue ( value = '' ) {
  return value.toString().replace( /[$ .,]/g, '' )
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export function getYouTubeSrc ( rawUrl ) {
  return `https://www.youtube-nocookie.com/embed/${ getYouTubeID( rawUrl ) }`
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export function getYouTubeID ( rawUrl ) {
  if ( YT_URL_REGEX.test( rawUrl ) ) {
    const url = new URL( rawUrl )
    const param = url.searchParams.get( 'v' )
    if ( param ) return param
    if ( url.host === 'youtu.be' ) return url.pathname.substr( 1 )
  }

  return null
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export function getStaticMapUrl ( address ) {
  const { lat, lng } = address.geometry.location
  return `https://maps.googleapis.com/maps/api/staticmap?center=${ lat },${ lng }&zoom=16&size=400x280&scale=2&markers=color:red%7Clabel:a%7C${ lat },${ lng }&key=${ Env.googleApiKey }&language=en`
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export function getReadbleOrderState ( orderState, pledgeManagerState = null ) {
  if ( orderState === PLEDGE_STATE.FINISHED ) return pledgeManagerState === PLEDGE_MANAGER_STATE.LATE_PLEDGE ? 'Waiting for Pledge Manager release' : 'Confirmed'
  if ( orderState === PLEDGE_STATE.ERRORED ) return 'Payment error'
  if ( orderState === PLEDGE_STATE.RETAILER_MISSED_DEADLINE || orderState === PLEDGE_STATE.CUSTOMER_MISSED_DEADLINE ) return 'Missed deadline'
  if ( orderState === PLEDGE_STATE.READY_TO_SHIP ) return 'Preparing to ship'
  return capitalize( lowerCase( orderState ) )
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export function getReadblePledgeManagerState ( pledgeManagerState ) {
  return capitalize( lowerCase( pledgeManagerState ) )
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export function getReadbleStockStatus ( stockStatus ) {
  return capitalize( stockStatus.replace( /_/g, ' ' ) )
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export function getPadNumber ( value ) {
  return padStart( value, 2, '0' )
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const defaultCurrencyLocale = 'en-US'
export const defaultCurrencyOptions = {
  currencyDisplay: 'code',
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
}

export function formatCurrency ( value, locale = defaultCurrencyLocale, options = defaultCurrencyOptions ) {
  return new Intl.NumberFormat( locale, options ).format( value )
}

export function formatNumber ( value, locale = defaultCurrencyLocale, options = {} ) {
  return new Intl.NumberFormat( locale, options ).format( value )
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const pluralize = ( count, noun, suffix = 's' ) => `${ count } ${ noun }${ count !== 1 ? suffix : '' }`
