import React from 'react'

import './progress-bar.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const ProgressBar = props => {

  const { className = '', percent = 0, theme = 'navy', active = false } = props

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <div className={ `pd-progress-bar pd-progress-bar--${ theme } ${ active ? 'pd-progress-bar--active' : '' } ${ className }` }>
      <div className="pd-progress-bar__track">
        <div className="pd-progress-bar__bar" style={ { width: `${ percent }%` } }></div>
      </div>
    </div >

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default ProgressBar