import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import SignIn from '../pages/sign-in/sign-in'
import SignUp from '../pages/sign-up/sign-up'
import PasswordRecovery from '../pages/password-recovery/password-recovery'
import PasswordReset from '../pages/password-reset/password-reset'
import Home from '../pages/home/home'
import CheckoutWrapper from '../pages/checkout/checkout'
import Profile from '../pages/profile/profile'
import Account from '../pages/account/account'
import AccountPledges from '../sections/account-pledges/account-pledges'
import AccountInformation from '../sections/account-information/account-information'
import AccountSecurity from '../sections/account-security/account-security'
import DashboardGateway from '../pages/dashboard-gateway/dashboard-gateway'
import DashboardProjectWrapper from '../pages/dashboard-project/dashboard-project'
import NotFound from '../pages/not-found/not-found'
import DashboardProjectIndex from '../sections/dashboard-project-index/dashboard-project-index'
import DashboardUserWrapper from '../pages/dashboard-user/dashboard-user'
import DashboardUserIndex from '../sections/dashboard-user-index/dashboard-user-index'
import DashboardPledgeWrapper from '../pages/dashboard-pledge/dashboard-pledge'
import DashboardPledgeIndex from '../sections/dashboard-pledge-index/dashboard-pledge-index'
import DashboardKsUpdates from '../sections/dashboard-ks-updates/dashboard-ks-updates'
import Styleguide from '../pages/styleguide/styleguide'
import RewardsSummary from '../sections/rewards-summary/rewards-summary'
import RewardsForm from '../sections/rewards-form/rewards-form'
import ProductsForm from '../sections/products-form/products-form'
import ShippingWaveManagement from '../sections/shipping-wave-management/shipping-wave-management'
import BackerReport from '../sections/backer-report/backer-report'
import BackerDataImport from '../sections/backer-data-import/backer-data-import'
import TrackingNumbers from '../sections/tracking-numbers/tracking-numbers'
import Activity from '../sections/activity/activity'
import ProjectBasicsForm from '../sections/project-basics-form/project-basics-form'
import ShippingTables from '../sections/shipping-tables/shipping-tables'
import ShippingTableEditorWrapper from '../sections/shipping-table-editor/shipping-table-editor'
import Audits from '../sections/audits/audits'
import PledgeTrackingNumbers from '../sections/pledge-tracking-numbers/pledge-tracking-numbers'
import UnlockPledge from '../sections/unlock-pledge/unlock-pledge'
import DiscountPledge from '../sections/discount-pledge/discount-pledge'
import PackingSlips from '../sections/packing-slips/packing-slips'
import Notifications from '../sections/notifications/notifications'
import PledgeEditor from '../sections/pledge-editor/pledge-editor'
import TransactionHistory from '../sections/transaction-history/transaction-history'
import TransactionDetails from '../sections/transaction-details/transaction-details'
import PledgeTransferer from '../sections/pledge-transferer/pledge-transferer'
import PledgeCreditTransferer from '../sections/pledge-credit-transfer/pledge-credit-transfer'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const routes = [
  {
    path: '/',
    component: Home,
    exact: true
  }, {
    path: '/sign-in',
    component: SignIn,
    exact: true
  }, {
    path: '/sign-up',
    component: SignUp,
    exact: true
  }, {
    path: '/password/recovery',
    component: PasswordRecovery,
    exact: true
  }, {
    path: '/password/reset',
    component: PasswordReset,
    exact: true
  }, {
    path: '/checkout/:projectSlug',
    component: CheckoutWrapper,
    exact: true
  }, {
    path: '/profile',
    component: Profile,
    exact: true
  }, {
    path: '/account',
    component: Account,
    exact: true,
    routes: [
      {
        path: '/account',
        component: () => ( <Redirect key="x" to="/account/pledges" /> ),
        exact: true
      }, {
        path: '/account/pledges',
        component: AccountPledges,
        exact: true
      }, {
        path: '/account/information',
        component: AccountInformation,
        exact: true
      }, {
        path: '/account/security',
        component: AccountSecurity,
        exact: true
      }
    ]
  }, {
    path: '/shipping-table-editor/:projectId/:tableId',
    component: ShippingTableEditorWrapper,
    exact: true
  }, {
    path: '/dashboard',
    component: DashboardGateway,
    exact: true
  }, {
    path: '/dashboard/pledge/:pledgeId',
    component: DashboardPledgeWrapper,
    exact: true,
    routes: [
      {
        path: '/dashboard/pledge/:pledgeId',
        component: DashboardPledgeIndex,
        exact: true
      }, {
        path: '/dashboard/pledge/:pledgeId/audits',
        component: Audits,
        exact: true
      }, {
        path: '/dashboard/pledge/:pledgeId/tracking-numbers',
        component: PledgeTrackingNumbers,
        exact: true
      }, {
        path: '/dashboard/pledge/:pledgeId/unlock',
        component: UnlockPledge,
        exact: true
      }, {
        path: '/dashboard/pledge/:pledgeId/discount',
        component: DiscountPledge,
        exact: true
      }, {
        path: '/dashboard/pledge/:pledgeId/transaction-history',
        component: TransactionHistory,
        exact: true,
      }, {
        path: '/dashboard/pledge/:pledgeId/transaction-history/:transactionId',
        component: TransactionDetails,
        exact: true,
      }, {
        path: '/dashboard/pledge/:pledgeId/transfer',
        component: PledgeTransferer,
        exact: true,
      }, {
        path: '/dashboard/pledge/:pledgeId/credit-transfer',
        component: PledgeCreditTransferer,
        exact: true,
      }
    ]
  }, {
    path: '/dashboard/user/:userId',
    component: DashboardUserWrapper,
    exact: true,
    routes: [
      {
        path: '/dashboard/user/:userId',
        component: DashboardUserIndex,
        exact: true
      }
    ]
  }, {
    path: '/dashboard/project/:projectId',
    component: DashboardProjectWrapper,
    exact: true,
    routes: [
      {
        path: '/dashboard/project/:projectId',
        component: DashboardProjectIndex,
        exact: true
      }, {
        path: '/dashboard/project/:projectId/shipping-waves',
        component: ShippingWaveManagement,
        exact: true
      }, {
        path: '/dashboard/project/:projectId/rewards-summary',
        component: RewardsSummary,
        exact: true
      }, {
        path: '/dashboard/project/:projectId/rewards',
        component: RewardsForm,
        exact: true
      }, {
        path: '/dashboard/project/:projectId/products',
        component: ProductsForm,
        exact: true
      }, {
        path: '/dashboard/project/:projectId/basic-information',
        component: ProjectBasicsForm,
        exact: true
      }, {
        path: '/dashboard/project/:projectId/backer-report',
        component: BackerReport,
        exact: true,
        routes: [
          {
            path: '/dashboard/project/:projectId/backer-report/:pledgeId',
            component: BackerReport,
            exact: true
          }
        ]
      }, {
        path: '/dashboard/project/:projectId/backer-data-import',
        component: BackerDataImport,
        exact: true
      }, {
        path: '/dashboard/project/:projectId/activity',
        component: Activity,
        exact: true
      }, {
        path: '/dashboard/project/:projectId/shipping-tables',
        component: ShippingTables,
        exact: true
      }, {
        path: '/dashboard/project/:projectId/packing-slips',
        component: PackingSlips,
        exact: true
      }, {
        path: '/dashboard/project/:projectId/notifications',
        component: Notifications,
        exact: true
      }, {
        path: '/dashboard/project/:projectId/pledge-editor/:pledgeId',
        component: PledgeEditor,
        exact: true
      }, {
        path: '/dashboard/project/:projectId/pledge-editor',
        component: PledgeEditor,
        exact: true,
      }
    ]
  }, {
    path: '/dashboard/ks-updates',
    component: DashboardKsUpdates,
    exact: true
  }, {
    path: '/dashboard/tracking-numbers',
    component: TrackingNumbers,
    exact: true
  }, {
    path: '/styleguide',
    component: Styleguide,
    exact: true
  }, {
    path: '*',
    component: NotFound
  }
]

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const renderRoute = ( route, index ) => {
  const { custom, staticContext, routes, ...rest } = route

  if ( routes ) return (
    <Route
      key={ index }
      path={ route.path }
      render={ rProps => <route.component { ...rProps } routes={ routes } /> }
    />
  )

  return (
    <Route key={ index } { ...rest } />
  )
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -