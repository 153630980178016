import React, { useEffect, useContext } from 'react'
import { capitalize, startCase } from 'lodash'
import moment from 'moment-timezone'

import { DashboardContext } from '../../context/dashboard-state'

import Collaborators from '../../modules/collaborators/collaborators'

import LoadingMessage from '../../components/loading-message/loading-message'
import Table from '../../components/table/table'

import { formatCurrency } from '../../config/adapters'

import './dashboard-user-index.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const DashboardUserIndex = props => {

  const dashboardContext = useContext( DashboardContext )
  const { user } = dashboardContext
  const { isFetched, details } = user

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const getNormalizedRows = () => details.geeks.map( ( geek, index ) => {

    const link = `/dashboard/pledge/${ geek.id }`

    return [
      { link: link, value: geek.id },
      { link: link, value: <span className="pd-ellipsis">{ geek.projectName }</span> },
      {
        link: link, value: <>
          <span className="short">{ moment( geek.updatedAt ).format( 'MMM DD' ) }</span>
          <span className="large">{ moment( geek.updatedAt ).format( 'MMMM Do YYYY' ) }</span>
        </>
      },
      { link: link, value: formatCurrency( geek.total ) },
      { link: link, value: <span className="pd-ellipsis">{ capitalize( startCase( geek.state ) ) }</span> }
    ]
  } )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <section className="pd-dashboard-user-index">
      { isFetched ? (

        <>
          <div className="pd-dashboard-user-index__overview pd-white-box">
            <h4 className="pd-title-3">{ details.name }</h4>
            <div className="pd-text">
              <p>{ details.email }</p>
              <p>Last sign in at { moment( details.lastSignInAt ).format( 'MMMM Do YYYY, HH:mm' ) }</p>
              <p>{ details.confirmed ? 'Account confirmed' : 'Account not confirmed' }</p>
            </div>
          </div>

          <Collaborators />

          { details.geeks && !!details.geeks.length && (
            <div className="pd-dashboard-user-index__pledges">
              <h4 className="pd-title-4">Pledges</h4>

              <Table
                className="pd-dashboard-user-index__table"
                cols={ [ 'ID', 'Project', 'Date', 'Total', 'State' ] }
                rows={ getNormalizedRows() }
              />
            </div>
          ) }
        </>

      ) : (

        <LoadingMessage />

      )
      }
    </section>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default DashboardUserIndex