import React, { useContext, useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { BrowserRouter as Router, Switch, useLocation, useHistory } from 'react-router-dom'

import { GlobalState, GlobalContext } from '../context/global-state'
import { UIState, UIContext } from '../context/ui-state'

import Env from '../config/env'
import { routes, renderRoute } from '../config/routes'
// import cookie from '../config/cookie'

import Header from '../modules/header/header'
import Footer from '../modules/footer/footer'
import Disclaimer from '../components/disclaimer/disclaimer'

import { ReactComponent as PledgitLogo } from '../assets/images/pledgit-logo-large.svg'
import { ReactComponent as IconOffline } from '../assets/icons/offline.svg'
import { ReactComponent as IconMaintenance } from '../assets/icons/maintenance.svg'
import { ReactComponent as IconRefresh } from '../assets/icons/refresh.svg'

import './application.scss'

const Application = props => {

  const UI = useContext( UIContext )
  const context = useContext( GlobalContext )
  const { checkLoginState, checkLoggedUser, isRetailer, loggedUser, isOnline, isServerOffline, hasUpdateAvailable, worker } = context
  const location = useLocation()
  const history = useHistory()
  // const [ tooltipActive, setTooltipActive ] = useState( false )
  // const cookieKey = 'new-account-menu-tooltip'

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => {
    checkConfirmationAccountCallback()

    if ( Env.googleAnalyticsId ) {
      ReactGA.initialize( Env.googleAnalyticsId )
      ReactGA.pageview( window.location.pathname + window.location.search )
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const checkConfirmationAccountCallback = () => {
    const query = new URLSearchParams( location.search )

    if ( query.has( 'account_confirmation_success' ) ) {
      UI.showSuccessDisclaimer( 'Your email has been successfully confirmed!' )
      history.push( location.origin )
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleUpdateClick = event => {
    worker.postMessage( { type: 'SKIP_WAITING' } )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  // const handleTooltipShow = () => {
  //   if ( loggedUser.isLogged && loggedUser.isResolved && !cookie.get( cookieKey ) ) {
  //     setTimeout( () => {
  //       UI.setMenuIsOpened( true )
  //       setTooltipActive( true )
  //     }, 1000 )
  //   }
  // }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  // const handleTooltipDismiss = event => {
  //   setTooltipActive( false )
  //   cookie.set( cookieKey, Date.now() )
  // }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )
  useEffect( checkLoggedUser, [ loggedUser ] )
  useEffect( checkLoginState, [ location ] )
  // useEffect( handleTooltipShow, [ loggedUser.isLogged, loggedUser.isResolved ] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  if ( isServerOffline ) return (
    <div className="pd-application__offline-disclaimer">
      <figure className="pd-application__offline-logo">
        <PledgitLogo />
      </figure>

      <figure className="pd-application__offline-icon">
        <IconMaintenance />
      </figure>

      <p className="pd-text">
        Pledg.it is currently under maintenance to better serve you, please check back later.
      </p>
    </div>
  )

  if ( !isOnline ) return (
    <div className="pd-application__offline-disclaimer">
      <figure className="pd-application__offline-logo">
        <PledgitLogo />
      </figure>

      <figure className="pd-application__offline-icon">
        <IconOffline />
      </figure>

      <p className="pd-text">
        You are offline.<br />
        Please check your connection.
      </p>
    </div>
  )

  return (
    // <div className={ `pd-application ${ hasUpdateAvailable || tooltipActive ? 'pd-application--no-scroll' : '' } ${ Env.showStagingEnvWarning ? 'pd-application--staging' : '' }` }>
    <div className={ `pd-application ${ hasUpdateAvailable ? 'pd-application--no-scroll' : '' } ${ Env.showStagingEnvWarning ? 'pd-application--staging' : '' }` }>

      {/* <div className={ `pd-floating-tooltip ${ tooltipActive ? 'pd-floating-tooltip--active' : '' }` } onClick={ evt => evt.stopPropagation() } onTouchStart={ evt => evt.stopPropagation() }>
        <span className="pd-floating-tooltip__curtain"></span>
        <div className="pd-floating-tooltip__container">
          <h4 className="pd-title-4">Your pledges are here!</h4>
          <p className="pd-text-sm">You can access your pledges and personal information here.</p>
          <button className="pd-button pd-button--cyan pd-button--mini" onClick={ handleTooltipDismiss }>Got it!</button>
        </div>
      </div> */}

      <Disclaimer />
      <Header className="pd-application__header" />
      <div className="pd-application__content">
        <Switch>
          { routes.map( renderRoute ) }
        </Switch>
      </div>
      <Footer className="pd-application__footer" isRetailer={ isRetailer } />
      { hasUpdateAvailable ? (
        <div className="pd-application__update">
          <div className="pd-application__update__disclaimer">
            <figure className="pd-application__update__disclaimer-icon">
              <IconRefresh />
            </figure>
            <p className="pd-text">A new version of Pledg.it is available.</p>
            <button className="pd-button pd-button--navy" onClick={ handleUpdateClick }>Refresh</button>
          </div>
        </div>
      ) : null }
    </div>
  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}


const ApplicationWrapper = props => (
  <UIState>
    <GlobalState>
      <Router>
        <Application />
      </Router>
    </GlobalState>
  </UIState>
)

export default ApplicationWrapper