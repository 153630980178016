import React, { useEffect, useContext, useState } from 'react'
import { PortalWithState } from 'react-portal'
import moment from 'moment-timezone'

import { DashboardContext } from '../../context/dashboard-state'
import { UIContext } from '../../context/ui-state'

import { sFetch } from '../../config/fetch'

import { InfoTip } from '../../components/tip/tip'
import LoadingMessage from '../../components/loading-message/loading-message'
import Lightbox from '../../components/lightbox/lightbox'

import './backer-data-import.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const BackerDataImport = props => {

  const dashboardContext = useContext( DashboardContext )
  const UI = useContext( UIContext )
  const { project } = dashboardContext
  const { match } = props
  const { projectId } = match.params
  const [ currentItem, setCurrentItem ] = useState( null )
  const [ list, setList ] = useState( [] )
  const [ isFetched, setFetched ] = useState( false )
  const [ isUploading, setUploading ] = useState( false )
  const [ isAllowedEmptyBasePledge, setAllowedEmptyBasePledge ] = useState( false )
  const [ csvFile, setCsvFile ] = useState( null )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleProjectLoad = () => {
    if ( project.isFetched && !project.isUpdating ) {
      fetchList()
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const fetchList = async () => {
    try {

      const list = await sFetch( `/geeks/import?project_id=${ projectId }` )
      setList( list )
      setFetched( true )
      if ( list.length > 0 ) setCurrentItem( list[ 0 ] )

    } catch ( exception ) {

      setFetched( false )

    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const renderListItem = item => {
    const itemClassName = `pd-text pd-tpl-nav-content__item ${ item.id === ( currentItem && currentItem.id ) ? 'pd-tpl-nav-content__item--active' : '' }`
    return (
      <li
        key={ item.id }
        className={ itemClassName }
        onClick={ handleItemClick.bind( this, item ) }>
        <span>{ moment( item.createdAt ).format( 'MMMM Do YYYY hh:mm' ) }</span>
      </li>
    )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleUpload = async event => {
    if ( !csvFile ) return null

    setUploading( true )

    UI.showInfoDisclaimer( 'Start uploading.' )

    const formData = new FormData()
    formData.append( 'file', csvFile )
    formData.append( 'allow_empty_base_pledge', isAllowedEmptyBasePledge )

    try {

      await sFetch( `/geeks/import?project_id=${ projectId }`, { method: 'post', body: formData }, true )
      await fetchList()

      UI.showSuccessDisclaimer( 'Import data in progress disclaimer' )

    } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }

    setUploading( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleItemClick = item => setCurrentItem( item )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleCurrenItemChange = () => {
    if ( currentItem && !currentItem.finishedAt ) setTimeout( fetchList, 10000 )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )
  useEffect( handleProjectLoad, [ project ] )
  useEffect( handleCurrenItemChange, [ currentItem ] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <section className="pd-backer-data-import pd-tpl-nav-content">
      { project.isFetched && isFetched ? (
        <>

          <nav className="pd-tpl-nav-content__nav">
            <ul className="pd-tpl-nav-content__list">
              <li className="pd-backer-data-import__item pd-tpl-nav-content__item">
                <input
                  disabled={ isUploading }
                  type="file"
                  accept="text/csv"
                  className="pd-text-sm pd-backer-data-import__uploader"
                  onChange={ event => setCsvFile( event.target.files[ 0 ] ) }
                />
                <div className="pd-field pd-checkbox-field">
                  <input
                    disabled={ isUploading }
                    type="checkbox"
                    className="pd-checkbox"
                    id="allowedEmptyBasePledge"
                    onChange={ event => setAllowedEmptyBasePledge( !isAllowedEmptyBasePledge ) }
                  />
                  <label htmlFor="allowedEmptyBasePledge" className="pd-label">Allow empty base pledge</label>
                </div>
                <button className="pd-button pd-button--navy" onClick={ handleUpload } disabled={ isUploading }>{ isUploading ? 'Processing...' : 'Import' }</button>
              </li>

              { list.map( renderListItem ) }
            </ul>
          </nav>

          <div className="pd-tpl-nav-content__main">
            {
              currentItem ? (

                <div className="pd-tpl-nav-content__box pd-backer-data-import__box">
                  <InfoTip><strong>Import progress:</strong> { Math.round( currentItem.progressPercentage * 100 ).toFixed( 2 ) }%</InfoTip>

                  <div className="pd-backer-data-import__details">
                    <p className="pd-text">Created at: { moment( currentItem.createdAt ).format( 'MMMM Do YYYY hh:mm' ) }</p>
                    <p className="pd-text">Updated at: { moment( currentItem.updatedAt ).format( 'MMMM Do YYYY hh:mm' ) }</p>
                    { currentItem.finishedAt && ( <p className="pd-text">Finished at: { moment( currentItem.finishedAt ).format( 'MMMM Do YYYY hh:mm' ) }</p> ) }
                    <p className="pd-text">Imported backers: { currentItem.pledgesCreated || '0' } </p>
                    <p className="pd-text">Original file name: { currentItem.originalFilename || '-' }</p>
                  </div>

                  <PortalWithState>
                    { portalBag => (
                      <>
                        <button onClick={ portalBag.openPortal } className="pd-button pd-button--cyan">View full message</button>

                        { portalBag.portal(
                          <Lightbox portalBag={ portalBag }>
                            <pre className="pd-backer-data-import__full pd-text">{ currentItem.status }</pre>
                          </Lightbox>
                        ) }
                      </>
                    ) }
                  </PortalWithState>
                </div>

              ) : (

                <InfoTip>{ list.length ? 'Use the following list to get import details.' : 'Backer data not available yet.' }</InfoTip>

              )
            }
          </div>

        </>

      ) : <LoadingMessage /> }
    </section >

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default BackerDataImport