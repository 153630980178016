import React, { useEffect, useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { capitalize, assign, kebabCase, cloneDeep } from 'lodash'

import { NOT_EMPTY_REGEX } from '../../config/validators'

import CustomField, { CUSTOMFIELD_DIRECTION } from '../../components/custom-field/custom-field'
import CheckboxField from '../../components/checkbox-field/checkbox-field'
import { UNIT_LENGTH, UNIT_WEIGHT, emptyHub } from '../new-hub-form/new-hub-form'

import './new-hub-import-form.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const IMPORT_STRATEGY = {
  NEW_HUB: 'new-hub',
  OVERWRITE: 'overwrite'
}

export const IMPORT_TYPE = {
  COUNTRIES: 'countries',
  STATES: 'states',
  POSTAL_CODES: 'postal_codes',
  MIXED: 'mixed',
}

const NewHubImportForm = props => {

  const { className = '', hubs, countries, onSave, onChange } = props
  const [ strategy, setStrategy ] = useState( null )
  const [ importType, setImportType ] = useState( null )
  const [ file, setFile ] = useState( null )
  const emptyHubImport = assign( {}, emptyHub, {
    strategy: '',
    id: '',
    importType: '',
    countryId: '',
    file: ''
  } )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleValidation = values => {
    const isNew = strategy === IMPORT_STRATEGY.NEW_HUB
    const isOverwrite = strategy === IMPORT_STRATEGY.OVERWRITE
    const requireCountryId = importType !== IMPORT_TYPE.COUNTRIES

    let errors = {}

    if ( !NOT_EMPTY_REGEX.test( values.importType ) ) errors.importType = 'Select an import type'
    if ( !NOT_EMPTY_REGEX.test( values.file ) ) errors.file = 'Select a file'

    if ( requireCountryId ) {
      if ( !NOT_EMPTY_REGEX.test( values.countryId ) ) errors.countryId = 'Select a country'
    }

    if ( isNew ) {
      if ( !NOT_EMPTY_REGEX.test( values.name ) ) errors.name = 'Invalid name'
      if ( !NOT_EMPTY_REGEX.test( values.unitLength ) ) errors.unitLength = 'Select an unit length'
      if ( !NOT_EMPTY_REGEX.test( values.unitWeight ) ) errors.unitWeight = 'Select an unit weight'
      if ( !NOT_EMPTY_REGEX.test( values.slipCost ) ) errors.slipCost = 'Select a slip cost'
      if ( !NOT_EMPTY_REGEX.test( values.formula ) ) errors.formula = 'Invalid formula'
      if ( !NOT_EMPTY_REGEX.test( values.factorFormula ) ) errors.factorFormula = 'Invalid factor formula'
    }

    if ( isOverwrite ) {
      if ( !NOT_EMPTY_REGEX.test( values.id ) ) errors.id = 'Select a hub'
    }

    return errors
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSave = async ( basePayload, actions ) => {
    actions.setSubmitting( true )

    try {

      const payload = cloneDeep( basePayload )
      payload.file = file
      await onSave( payload )

    } catch ( exception ) { throw exception }

    actions.setSubmitting( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const isNew = strategy === IMPORT_STRATEGY.NEW_HUB
  const isOverwrite = strategy === IMPORT_STRATEGY.OVERWRITE

  return (

    <Formik
      initialValues={ emptyHubImport }
      enableReinitialize={ true }
      onSubmit={ handleSave }
      validate={ handleValidation }
      validateOnChange={ false }
      validateOnBlur={ false }>
      { fProps => (

        <Form onChange={ onChange } className={ `
          pd-new-hub-import-form pd-tpl-lightbox-form pd-white-box
          ${ strategy && importType ? `pd-new-hub-import-form--${ strategy }` : '' }  
          ${ importType ? `pd-new-hub-import-form--${ importType }` : '' }  
          ${ className }
        ` }>

          <span className="pd-text-sm pd-text--tip pd-tpl-lightbox-form__required">
            <span className="pd-text--info">*</span> Required fields
          </span>

          <Field name="slipCost" type="hidden" />

          <CustomField
            name="strategy"
            label="Import strategy"
            component="radio"
            options={ [
              [ 'Create a new hub', IMPORT_STRATEGY.NEW_HUB ],
              [ 'Overwrite an existing hub', IMPORT_STRATEGY.OVERWRITE ]
            ] }
            onChange={ event => setStrategy( event.target.value ) }
            className="pd-new-hub-import-form__strategy"
            direction={ CUSTOMFIELD_DIRECTION.VERTICAL }
            required
          />

          <CustomField
            name="importType"
            label="Import type"
            component="select"
            onChange={ event => setImportType( kebabCase( event.target.value || '' ) ) }
            className="pd-new-hub-import-form__import-type"
            required>
            <option value="">Select</option>
            <option value={ IMPORT_TYPE.COUNTRIES }>Countries</option>
            <option value={ IMPORT_TYPE.STATES }>States</option>
            <option value={ IMPORT_TYPE.POSTAL_CODES }>Postal codes</option>
            <option value={ IMPORT_TYPE.MIXED }>Mixed</option>
          </CustomField>

          <CustomField className="pd-new-hub-import-form__file" name="file" label="File" type="file" onChange={ event => setFile( event.target.files[ 0 ] ) } required />

          <CustomField className="pd-new-hub-import-form__hub" name="id" label="Hub" component="select" required={ isOverwrite }>
            <option value="">Select</option>
            { hubs.map( ( hub, index ) => <option key={ index } value={ hub.id }>{ hub.name }</option> ) }
          </CustomField>

          <CustomField className="pd-new-hub-import-form__country" name="countryId" label="Country" component="select" required>
            <option>Select</option>
            { countries.map( ( country, index ) => <option key={ index } value={ country.id }>{ country.name }</option> ) }
          </CustomField>

          <div className="pd-new-hub-import-form__new-hub-fields">
            <CustomField className="pd-new-hub-import-form__name" name="name" label="Hub Name" required={ isNew } />

            <CustomField className="pd-new-hub-import-form__unit-length" name="unitLength" label="Length unit" component="select" required={ isNew }>
              <option value="">Select</option>
              <option value={ UNIT_LENGTH.CM }>{ capitalize( UNIT_LENGTH.CM ) }</option>
              <option value={ UNIT_LENGTH.IN }>{ capitalize( UNIT_LENGTH.IN ) }</option>
            </CustomField>

            <CustomField className="pd-new-hub-import-form__unit-weight" name="unitWeight" label="Weight unit" component="select" required={ isNew }>
              <option value="">Select</option>
              <option value={ UNIT_WEIGHT.KG }>{ capitalize( UNIT_WEIGHT.KG ) }</option>
              <option value={ UNIT_WEIGHT.LB }>{ capitalize( UNIT_WEIGHT.LB ) }</option>
            </CustomField>

            <CheckboxField className="pd-new-hub-import-form__print-slip-cost" name="printSlipCosts">
              Print slip costs
            </CheckboxField>

            <CheckboxField className="pd-new-hub-import-form__charge-vat" name="chargeVat">
              Charge VAT
            </CheckboxField>

            <CheckboxField className="pd-new-hub-import-form__multiwave" name="multiwaveShipping">
              Multiwave Shipping
            </CheckboxField>

            <CustomField className="pd-new-hub-import-form__formula" name="formula" label="Weight Formula" required={ isNew } />

            <CustomField className="pd-new-hub-import-form__factor-formula" name="factorFormula" label="Factor formula" required={ isNew } />
          </div>

          <button className="pd-tpl-lightbox-form__submit pd-new-hub-import-form__submit pd-button pd-button--navy" type="submit" disabled={ fProps.isSubmitting }>
            { fProps.isSubmitting ? 'Processing...' : 'Import hub' }
          </button>
        </Form>

      ) }
    </Formik>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default NewHubImportForm