import React, { useEffect, useContext, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Formik, Field, Form, ErrorMessage } from 'formik'

import { GlobalContext } from '../../context/global-state'
import { UIContext } from '../../context/ui-state'

import { sFetch } from '../../config/fetch'
import { toSnakeCase } from '../../config/adapters'
import { NOT_EMPTY_REGEX } from '../../config/validators'

import './new-project-form.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const NewProjectForm = props => {

  const context = useContext( GlobalContext )
  const UI = useContext( UIContext )
  const [ hasCompany, setCompany ] = useState( false )
  const [ hasSeveralCompanies, setSeveralCompanies ] = useState( false )
  const { className = '', onChange, onCancel } = props
  const { loggedUser } = context
  const { isLogged, isFetched, data } = loggedUser

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleLoggedUser = () => {
    if ( isLogged && isFetched && data ) {

      setCompany( data.companies.length > 0 )
      setSeveralCompanies( data.companies.length > 1 )

    } else {

      setCompany( false )
      setSeveralCompanies( false )

    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSave = async ( payload, fProps ) => {
    if ( !window.confirm( 'Are you sure?' ) ) {
      fProps.setSubmitting( false )
      return
    }

    const newProject = await sFetch( `/projects/`, {
      method: 'post', body: JSON.stringify( toSnakeCase( {
        project: {
          name: payload.name,
          projectType: 'pre_order'
        },
        companyId: payload.company
      } ) )
    } )

    fProps.resetForm()

    props.history.push( `/dashboard/project/${ newProject.id }/basic-information` )

    UI.showSuccessDisclaimer( 'Your new project was created successfully!' )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleValidation = values => {
    let errors = {}

    if ( !NOT_EMPTY_REGEX.test( values.company ) ) errors.company = 'Invalid company'
    if ( !NOT_EMPTY_REGEX.test( values.name ) ) errors.name = 'Invalid name'

    return errors
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleCancel = event => {
    event.preventDefault()
    onCancel()
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )
  useEffect( handleLoggedUser, [ isLogged, isFetched, data ] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return isLogged && isFetched && hasCompany ? (

    <div className={ `pd-new-project-form ${ className }` }>
      <Formik
        initialValues={ {
          company: hasSeveralCompanies ? '' : data.companies[ 0 ].id,
          name: ''
        } }
        onSubmit={ handleSave }
        validate={ handleValidation }
        validateOnChange={ false }
        validateOnBlur={ false }>
        { fProps => (

          <Form className="pd-new-project-form__main" onChange={ onChange }>
            { hasSeveralCompanies ? (

              <div className="pd-field">
                <label className="pd-label" htmlFor="name">Company</label>
                <Field className="pd-select" name="company" component="select">
                  <option value=""></option>
                  { data.companies.map( company => ( <option key={ company.id } value={ company.id }>{ company.name }</option> ) ) }
                </Field>
                <ErrorMessage name="company" component="span" className="pd-text-sm pd-text--error" />
              </div>

            ) : ( <Field name="company" type="hidden" /> ) }

            <div className="pd-field">
              <label className="pd-label" htmlFor="name">Project name</label>
              <Field className="pd-textfield" name="name" />
              <ErrorMessage name="name" component="span" className="pd-text-sm pd-text--error" />
            </div>

            <nav className="pd-new-project-form__nav">
              <button className="pd-button pd-button--navy" type="submit" disabled={ fProps.isSubmitting }>{ fProps.isSubmitting ? 'Processing...' : 'Create' }</button>
              <button className="pd-button pd-button--link" onClick={ handleCancel }>Cancel</button>
            </nav>
          </Form>

        ) }
      </Formik>
    </div>

  ) : null

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default withRouter( NewProjectForm )