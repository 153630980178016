import React from 'react'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import './date-time-picker-field.scss'


// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const DateTimePickerField = props => {

  const {
    name,
    value,
    onChange = () => null,
    showTimeSelect = true,
    timeIntervals = 10,
    timeCaption = 'Hour',
    dateFormat = 'MMM d, yyyy HH:mm',
    timeFormat = 'HH:mm',
    className = ''
  } = props

  return (

    <DatePicker
      className={ `pd-date-time-picker-field pd-textfield ${ className }` }
      selected={ ( value && new Date( value ) ) || null }
      showTimeSelect={ showTimeSelect }
      timeIntervals={ timeIntervals }
      timeCaption={ timeCaption }
      dateFormat={ dateFormat }
      timeFormat={ timeFormat }
      onChange={ val => {
        onChange( name, val )
      } }
    />

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default DateTimePickerField