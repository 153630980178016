import React, { useEffect, useContext, useState } from 'react'
import JSONPretty from 'react-json-pretty'
import { Formik, Form } from 'formik'
import { cloneDeep, uniq } from 'lodash'

import { DashboardContext } from '../../context/dashboard-state'
import { UIContext } from '../../context/ui-state'

import { GlobalContext } from '../../context/global-state'

import { sFetch, rFetch } from '../../config/fetch'
import { toSnakeCase, formatCurrency } from '../../config/adapters'
import { NOT_EMPTY_REGEX } from '../../config/validators'

import CustomField from '../../components/custom-field/custom-field'
import { InfoTip } from '../../components/tip/tip'

import './shipping-cost-simulator.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const ShippingCostSimulator = props => {

  const dashboardContext = useContext( DashboardContext )
  const { project } = dashboardContext

  const context = useContext( GlobalContext )
  const { fetchProvinces } = context

  const UI = useContext( UIContext )

  const [ countries, setCountries ] = useState( {
    isFetched: false,
    list: []
  } )
  const [ provinces, setProvinces ] = useState( {
    isFetching: false,
    list: []
  } )
  const [ shippingCost, setShippingCost ] = useState( {
    value: null,
    debugInfo: null
  } )

  const { className = '', tables, currentTable } = props

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleCountriesFetch = async () => {
    try {

      const list = await sFetch( `/countries/${ project.details.id }` )
      setCountries( {
        isFetched: true,
        list: list
      } )

    } catch ( exception ) {

      UI.showErrorDisclaimer( exception.message )

    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleProjectLoad = () => {
    if ( project.isFetched && !countries.isFetched ) handleCountriesFetch()
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleChange = () => {
    setShippingCost( {
      value: null,
      debugInfo: null
    } )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleCountryChange = async ( fProps, event ) => {
    fProps.setFieldValue( 'address.stateId', '' )
    setProvinces( { list: [], isFetching: true } )
    const provinces = await fetchProvinces( event.target.value )
    setProvinces( { list: provinces, isFetching: false } )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleValidation = values => {
    let errors = {}

    if ( !NOT_EMPTY_REGEX.test( values.tableId ) ) errors.stateId = 'Select a shipping table'
    if ( !NOT_EMPTY_REGEX.test( values.address.countryId ) ) errors.address = { countryId: 'Select a country' }

    return errors
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSubmit = async ( _payload, actions ) => {
    actions.setSubmitting( true )

    setShippingCost( {
      value: null,
      debugInfo: null
    } )

    try {

      const payload = cloneDeep( _payload )
      payload.bundles = payload.bundles.filter( item => item.amount > 0 )
      payload.addOns = payload.addOns.filter( item => item.amount > 0 )
      payload.shippingTableId = payload.tableId
      delete payload.tableId

      const data = await rFetch( `/projects/${ project.details.id }/shipping_cost?debug=true`, { method: 'post', body: JSON.stringify( toSnakeCase( payload ) ) } )

      setShippingCost( {
        value: data.shippingCost,
        debugInfo: data.info
      } )

    } catch ( exception ) {

      UI.showErrorDisclaimer( exception.message )

    }

    actions.setSubmitting( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )
  useEffect( handleProjectLoad, [ project ] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const { bundles, addOns, waves } = project.details

  return project.isFetched ? (

    <div className={ `pd-shipping-cost-simulator ${ className }` }>

      <Formik
        initialValues={
          {
            bundles: bundles.map( item => ( { id: item.id, amount: 0 } ) ),
            addOns: addOns.map( item => ( { id: item.id, amount: 0 } ) ),
            address: {
              postalCode: '',
              city: '',
              stateId: '',
              countryId: ''
            },
            tableId: currentTable || ''
          } }
        validate={ handleValidation }
        enableReinitialize={ true }
        onSubmit={ handleSubmit }
        validateOnBlur={ false }>
        { fProps => (

          <Form className={ `pd-shipping-cost-simulator__form pd-white-box` } onChange={ handleChange }>
            <h4 className="pd-title-3 pd-shipping-cost-simulator__title">Shipping Cost simulator</h4>

            <dl className="pd-shipping-cost-simulator__bundles">
              <dt className="pd-label">Bundles</dt>
              { bundles.map( ( bundle, index ) => (

                <dd className="pd-shipping-cost-simulator__item" key={ index }>
                  <CustomField type="number" min="0" name={ `bundles[${ index }].amount` } />
                  <p className="pd-text">{ `${ bundle.name } ${ waves > 1 ? `(wave ${ uniq( bundle.bundleProducts.map( p => p.product.wave ) ).join( ',' ) })` : '' }` }</p>
                </dd>

              ) ) }
            </dl>

            <dl className="pd-shipping-cost-simulator__add-ons">
              <dt className="pd-label">Add-ons</dt>
              { addOns.map( ( addOn, index ) => (

                <dd className="pd-shipping-cost-simulator__item" key={ index }>
                  <CustomField type="number" min="0" name={ `addOns[${ index }].amount` } />
                  <p className="pd-text">{ `${ addOn.name } ${ waves > 1 ? `(wave ${ uniq( addOn.addOnProducts.map( p => p.product.wave ) ).join( ',' ) })` : '' }` }</p>
                </dd>

              ) ) }
            </dl>

            <div className="pd-shipping-cost-simulator__other">
              <CustomField name="tableId" label="Shipping table" component="select" required>
                <option value="">Please select</option>
                { tables.map( ( table, index ) => <option key={ index } value={ table.id }>{ table.name }</option> ) }
              </CustomField>

              <CustomField name="address.countryId" label="Country" component="select" onChange={ handleCountryChange.bind( this, fProps ) } required>
                <option value="">Please select</option>
                { countries.list.map( ( country, index ) => <option key={ index } value={ country.id }>{ country.name }</option> ) }
              </CustomField>

              <CustomField name="address.stateId" label="State/Province" component="select" disabled={ provinces.isFetching || !provinces.list.length }>
                <option value="">{ provinces.isFetching ? 'Fetching...' : 'Please select' }</option>
                { provinces.list.map( ( province, index ) => <option key={ index } value={ province.id }>{ province.name }</option> ) }
              </CustomField>

              <CustomField label="City" name="address.city" />

              <CustomField label="Postal code" name="address.postalCode" />

              <button className="pd-button pd-button--navy" type="submit" disabled={ fProps.isSubmitting }>
                { fProps.isSubmitting ? 'Simulating...' : 'Simulate' }
              </button>

              { shippingCost.value ? (

                <InfoTip>Shipping cost: <strong>{ formatCurrency( shippingCost.value ) }</strong></InfoTip>

              ) : null }
            </div>
          </Form>

        ) }
      </Formik>

      { shippingCost.debugInfo ? (


        <JSONPretty className="pd-shipping-cost-simulator__json pd-white-box pd-scroll-panel" data={ shippingCost.debugInfo } theme={ {
          key: 'color:#444444;',
          string: 'color:#28a745;',
          value: 'color:#5da8b9;',
          boolean: 'color:#5da8b9;',
        } } />

      ) : null }

    </div>

  ) : null

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default ShippingCostSimulator