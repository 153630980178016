import React, { useContext, useState } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'

import { GlobalContext } from '../../context/global-state'
import { UIContext } from '../../context/ui-state'
import { isEmail, isEmpty } from '../../config/validators'
// import { getImageUrl, sUploader } from '../../config/fetch'

// import ProgressBar from '../../components/progress-bar/progress-bar'

import './profile-form.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const ProfileForm = props => {

  const context = useContext( GlobalContext )
  const UI = useContext( UIContext )
  const { loggedUser, updateUser } = context
  const [ isUpdating, setUpdating ] = useState( false )
  // const [ isUploading, setUploading ] = useState( false )
  // const [ progress, setProgress ] = useState( 0 )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  // const onChangeClick = event => document.getElementById( 'pd-profile-form__avatar' ).click()
  // const onAvatarLoad = event => {
  //   if ( isUpdating ) setUpdating( false )
  //   if ( isUploading ) setUploading( false )
  // }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSave = async ( payload, actions ) => {
    setUpdating( true )

    await updateUser( {
      // avatar_id: loggedUser.data.avatar.id,
      name: payload.name,
      email: payload.email,
    } )

    setUpdating( false )
    actions.setSubmitting( false )
    UI.showSuccessDisclaimer( 'You have updated your profile successfully!' )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  // const handleAvatarUploadProgress = percent => setProgress( percent )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  // const handleAvatarChange = async event => {
  //   setProgress( 0 )
  //   setUploading( true )

  //   const file = event.target.files[ 0 ]

  //   if ( !file ) return null

  //   setUpdating( true )

  //   const formData = new FormData()
  //   formData.append( 'image', file )

  //   try {

  //     const newAvatar = await sUploader( '/images', formData, handleAvatarUploadProgress )
  //     await updateUser( {
  //       avatar_id: newAvatar.id,
  //       name: loggedUser.data.name,
  //       email: loggedUser.data.email,
  //       biography: '',
  //       timezone: '',
  //       website: ''
  //     } )

  //     UI.showSuccessDisclaimer( 'You have updated your avatar successfully!' )

  //   } catch ( exception ) {

  //     UI.showErrorDisclaimer( exception )
  //     setUpdating( false )
  //     setUploading( false )

  //   }
  // }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <section className={ `pd-profile-form pd-profile-section ${ isUpdating ? 'pd-profile-form__updating' : '' }` }>
      <header className="pd-profile-section__header">
        <h4 className="pd-title-3">Profile</h4>
        <p className="pd-text">You may edit your personal information here.</p>
      </header>

      <div className="pd-profile-section__content">
        {/* <div className="pd-profile-form__avatar">
          <figure className="pd-profile-form__image">
            <ProgressBar className="pd-profile-form__progress" percent={ progress } active={ isUploading } />
            <img onLoad={ onAvatarLoad } onClick={ onChangeClick } src={ getImageUrl( loggedUser.data.avatar, { w: 100, h: 100, resizeTo: 'fill', quality: 80 } ) } alt="Edit" />
          </figure>
          <input id="pd-profile-form__avatar" className="pd-profile-form__avatar" type="file" name="avatar" accept="image/png, image/jpeg" onChange={ handleAvatarChange } />
          <button className="pd-button pd-button--navy" disabled={ isUpdating } onClick={ onChangeClick }>Change</button>
        </div> */}

        <Formik
          initialValues={ { name: loggedUser.data.name, email: loggedUser.data.email, phone: '' } }
          onSubmit={ handleSave }
          validateOnChange={ false }
          validateOnBlur={ false }>
          { fProps => (

            <Form className={ `pd-profile-form__main` }>
              <div className="pd-field">
                <label className="pd-label">Name</label>
                <Field
                  className="pd-textfield"
                  type="text"
                  name="name"
                  disabled={ isUpdating }
                  validate={ isEmpty.bind( this, 'Name is required' ) }
                />
                <ErrorMessage name="name" component="span" className="pd-text-sm pd-text--error" />
              </div>

              <div className="pd-field">
                <label className="pd-label">E-mail</label>
                <Field
                  className="pd-textfield"
                  type="email"
                  name="email"
                  disabled={ isUpdating || loggedUser.isAdmin }
                  validate={ isEmail.bind( this, 'Invalid email' ) }
                />
                <ErrorMessage name="email" component="span" className="pd-text-sm pd-text--error" />
              </div>

              <button
                type="submit"
                className="pd-button pd-button--navy"
                disabled={ fProps.isSubmitting || isUpdating }>
                { fProps.isSubmitting || isUpdating ? 'Processing...' : 'Save' }
              </button>
            </Form>

          ) }
        </Formik>

      </div>
    </section>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default ProfileForm