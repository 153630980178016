import { merge, isObject, isArray, every, isString, trim, keys, upperFirst } from 'lodash'
import queryString from 'query-string'
import Env from './env'
import cookie from './cookie'
import { toCamelCase } from './adapters'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const KEYS = [ 'access-token', 'client', 'expiry', 'uid' ]

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

class CustomError extends Error {
  constructor( message, request ) {
    super( message )
    this.request = request
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export function storeHeaders( response ) {
  const { headers } = response

  KEYS.forEach( key => {
    const value = headers.get( key )
    if ( value ) cookie.set( key, value )
  } )

  return Promise.resolve( response )
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export function checkHeaders( response ) {
  const newExpiry = Number( response.headers.get( 'expiry' ) )
  const oldExpiry = Number( cookie.get( 'expiry' ) )

  if ( newExpiry >= oldExpiry ) storeHeaders( response )

  return Promise.resolve( response )
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export function getEndpoint( endpoint ) {
  return `${ Env.baseApiUrl }${ endpoint }`;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export function getImageUrl( image, params = null ) {
  params = isObject( params ) ? `?${ queryString.stringify( params ) }` : ''
  return getEndpoint( `/images/${ image.id || image }/raw${ params }` )
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export function getOptions( headers = {}, isUpload = false ) {
  return { mode: 'cors', headers: merge( headers, isUpload ? { 'Accept': 'application/json' } : { 'Accept': 'application/json', 'Content-Type': 'application/json' } ) }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export function getHeaders() {
  let headers = {}
  KEYS.forEach( key => {
    const value = cookie.get( key )
    if ( value ) headers[ key ] = value
  } )
  return headers
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const checkStatus = async ( preventRedirect, response ) => {
  if ( response.status >= 200 && response.status < 300 ) return Promise.resolve( response )

  if ( !preventRedirect && response.status === 401 && document.location.pathname !== '/sign-in' ) document.location.href = `/sign-in?redirect=${ document.location.pathname }`

  const parsed = await parseJson( response )

  if ( parsed.errors ) {

    if ( parsed.errors.fullMessages ) throw new CustomError( parsed.errors.fullMessages.join( '\n\n' ), response )
    if ( parsed.errors.base ) throw new CustomError( parsed.errors.base.map( item => upperFirst( item ) ).join( '\n\n' ), response )
    if ( parsed.errors.addOns || parsed.errors.bundles ) throw new CustomError( [].concat( parsed.errors.bundles || [], parsed.errors.addOns || [] ).join( '\n' ), response )
    if ( every( parsed.errors, isString ) ) throw new CustomError( parsed.errors.join( '\n\n' ), response )
    if ( isArray( parsed.errors ) && every( parsed.errors, isObject ) ) throw new CustomError( parsed.errors.map( error => upperFirst( trim( `${ error.field } ${ error.code }` ) ) ).join( '\n\n' ), response )
    if ( isObject( parsed.errors ) && every( parsed.errors, isObject ) ) throw new CustomError( keys( parsed.errors ).map( key => upperFirst( trim( `${ parsed.errors[ key ] }` ) ) ).join( '\n\n' ), response )
    throw new CustomError( parsed.errors, response )

  }

  throw new CustomError( parsed.message || 'Server error. Contact support.', response )
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export function parseJson( response ) {
  return response.json().then( json => Promise.resolve( toCamelCase( json ) ) )
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export function parseText( response ) {
  return response.text().then( text => Promise.resolve( text ) )
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export function eFetch( endpoint, requestOptions = {}, preventRedirect = false ) {
  const options = merge( requestOptions, getOptions() )
  return fetch( getEndpoint( endpoint ), options ).then( checkStatus.bind( this, preventRedirect ) )
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export function rFetch( endpoint, requestOptions = {}, preventRedirect = false ) {
  const options = merge( requestOptions, getOptions() )
  return fetch( getEndpoint( endpoint ), options ).then( checkStatus.bind( this, preventRedirect ) ).then( parseJson )
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export function sFetch( endpoint, requestOptions = {}, isUpload = false, preventRedirect = false ) {
  const options = merge( requestOptions, getOptions( getHeaders(), isUpload ) )
  return fetch( getEndpoint( endpoint ), options ).then( checkStatus.bind( this, preventRedirect ) ).then( checkHeaders ).then( parseJson )
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export function sUploader( endpoint, formData, onProgress = () => null ) {
  const setRequestHeaders = xhr => {
    KEYS.forEach( key => {
      const value = cookie.get( key )
      if ( value ) xhr.setRequestHeader( key, value )
    } )

    return xhr
  }

  const checkStatus = event => new Promise( ( resolve, reject ) => {
    const { target } = event

    if ( target.status >= 200 && target.status < 300 ) return resolve( event )
    if ( target.status === 401 ) document.location.href = `/sign-in?redirect=${ document.location.pathname }`

    reject( event )
  } )

  const checkHeaders = event => new Promise( ( resolve, reject ) => {
    const headers = {}

    event.target.getAllResponseHeaders().trim().split( /[\r\n]+/ ).forEach( item => {
      const raw = item.split( ': ' )
      headers[ raw[ 0 ] ] = raw[ 1 ]
    } )

    const newExpiry = Number( headers.expiry )
    const oldExpiry = Number( cookie.get( 'expiry' ) )
    if ( newExpiry >= oldExpiry ) {
      KEYS.forEach( key => {
        const value = headers[ key ]
        if ( value ) cookie.set( key, value )
      } )
    }

    resolve( event )
  } )

  const parseJson = event => new Promise( ( resolve, reject ) => resolve( JSON.parse( event.target.response ) ) )

  const upload = ( endpoint, formData ) => new Promise( ( resolve, reject ) => {
    let xhr = new XMLHttpRequest()
    xhr.open( 'POST', getEndpoint( endpoint ), true )

    setRequestHeaders( xhr )

    xhr.upload.addEventListener( 'progress', event => onProgress( Math.ceil( ( event.loaded / event.total ) * 100 ), event ) )
    xhr.addEventListener( 'load', event => {
      resolve( event )
    } )
    xhr.send( formData )
  } )

  return upload( endpoint, formData )
    .then( checkStatus )
    .then( checkHeaders )
    .then( parseJson )
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -