import React from 'react'

import { ReactComponent as IconSuccess } from '../../assets/icons/check-mark.svg'
import { ReactComponent as IconInfo } from '../../assets/icons/info.svg'

import './tip.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const Tip = ( { className, micro, children } ) => ( <div className={ `pd-tip ${ className } ${ micro ? 'pd-tip--micro pd-text-sm' : 'pd-text' }` }>{ children }</div> )
export const SuccessTip = ( { className = '', children, ...rest } ) => <Tip className={ `pd-tip--success ${ className }` } { ...rest }><IconSuccess className="pd-icon" /><span>{ children }</span></Tip>
export const WarningTip = ( { className = '', children, ...rest } ) => <Tip className={ `pd-tip--warning ${ className }` } { ...rest }><IconInfo className="pd-icon" /><span>{ children }</span></Tip>
export const ErrorTip = ( { className = '', children, ...rest } ) => <Tip className={ `pd-tip--error ${ className }` } { ...rest }><IconInfo className="pd-icon" /><span>{ children }</span></Tip>
export const InfoTip = ( { className = '', children, ...rest } ) => <Tip className={ `pd-tip--info ${ className }` } { ...rest }><IconInfo className="pd-icon" /><span>{ children }</span></Tip>