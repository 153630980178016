import React, { useState, useEffect } from 'react'

import { sFetch } from '../../config/fetch'
import { toSnakeCase } from '../../config/adapters'

import './pledge-search.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const PledgeSearch = props => {

  const [ pledgeNumber, setPledgeNumber ] = useState( '' )
  const [ geekfunderID, setGeekfunderID ] = useState( '' )
  const [ isSearching, setSearching ] = useState( false )
  const [ notFound, setNotFound ] = useState( false )
  const { className = '' } = props

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleKeyPress = async ( action, event ) => { if ( event.charCode === 13 ) action() }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const searchByPledgeNumber = async () => {
    setSearching( true )
    setNotFound( false )

    try {

      const pledgeFound = await sFetch( `/geeks/report/${ pledgeNumber }` )
      window.open( `/dashboard/pledge/${ pledgeFound.id }` )

    } catch ( exception ) { setNotFound( true ) }

    setSearching( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const searchByGeekfunderID = async () => {
    setSearching( true )
    setNotFound( false )

    try {

      const pledgeFound = await sFetch( `/geeks/report/legacy_id`, { method: 'post', body: JSON.stringify( toSnakeCase( { legacyId: parseInt( geekfunderID ) } ) ) } )
      window.open( `/dashboard/pledge/${ pledgeFound.id }` )

    } catch ( exception ) { setNotFound( true ) }

    setSearching( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <div className={ `pd-pledge-search ${ className }` }>
      <div className="pd-pledge-search__form">
        <h3 className="pd-title-4">Pledge search</h3>
        <div className="pd-pledge-search__field-group">
          <input
            className="pd-textfield"
            type="text"
            value={ pledgeNumber }
            onChange={ event => setPledgeNumber( event.target.value ) }
            onKeyPress={ handleKeyPress.bind( this, searchByPledgeNumber ) }
            placeholder="Pledge number"
          />

          <button className="pd-button pd-button--navy" onClick={ event => searchByPledgeNumber() } disabled={ isSearching }>
            { isSearching ? 'Processing...' : 'Search' }
          </button>
        </div>

        <div className="pd-pledge-search__field-group">
          <input
            className="pd-textfield"
            type="text"
            value={ geekfunderID }
            onChange={ event => setGeekfunderID( event.target.value ) }
            onKeyPress={ handleKeyPress.bind( this, searchByGeekfunderID ) }
            placeholder="Geekfunder ID"
          />

          <button className="pd-button pd-button--navy" onClick={ event => searchByGeekfunderID() } disabled={ isSearching }>
            { isSearching ? 'Processing...' : 'Search' }
          </button>
        </div>

        { isSearching ? ( <span className="pd-text-sm">Searching, please wait...</span> ) : null }
        { notFound ? ( <span className="pd-text-sm pd-text-error">Pledge not found. Please try a different ID.</span> ) : null }
      </div>
    </div>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default PledgeSearch