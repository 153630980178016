import React, { useContext } from 'react'

import { GlobalContext } from '../../context/global-state'
import { CheckoutContext } from '../../context/checkout-state'

import { InfoTip } from '../../components/tip/tip'

import './checkout-breadcrumb.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const CheckoutBreadcrumb = props => {
  const { className } = props
  const context = useContext( GlobalContext )
  const { loggedUser } = context
  const checkoutContext = useContext( CheckoutContext )
  const { project, pledge, summary, showcase, stepTo, changeShippingWaveStrategy, STEP } = checkoutContext
  const { isLatePledge, hasMultipleWaves, waves, allowMultiwaveSelection, isLateConfirms, retailer } = project
  const { isMultiwave } = pledge
  const { hasBasePledges, hasOptionalBuys, step, isLocked, optionalBuysLocked, shippingLocked, paymentLocked, renderBreadcrumb } = showcase

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const getActiveClassName = ( selectedStep, className ) => ( step === selectedStep ? className : '' )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleStepClick = ( step, event ) => {
    stepTo( step )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleShippingStrategyChangeClick = event => {
    if ( !window.confirm( 'Are you sure?' ) ) return
    changeShippingWaveStrategy( isMultiwave )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return renderBreadcrumb ? (

    <nav className={ `pd-checkout-breadcrumb ${ className }` }>
      <ul className="pd-checkout-breadcrumb__list">
        { hasBasePledges ? (

          <li className="pd-checkout-breadcrumb__item">
            <button
              className={ `pd-button pd-button--glass ${ getActiveClassName( STEP.BASE, 'pd-button--active' ) }` }
              disabled={ isLocked }
              onClick={ handleStepClick.bind( this, STEP.BASE ) }>
              01. Base pledge
            </button>
          </li>

        ) : null }

        { hasOptionalBuys ? (

          <li className="pd-checkout-breadcrumb__item">
            <button
              className={ `pd-button pd-button--glass ${ getActiveClassName( STEP.OPTIONALS, 'pd-button--active' ) }` }
              onClick={ handleStepClick.bind( this, STEP.OPTIONALS ) }
              disabled={ isLocked || optionalBuysLocked }>
              { hasBasePledges ? '02. Optional buys' : '01. Products' }
            </button>
          </li>

        ) : null }

        { isLatePledge ? null : (
          <li className="pd-checkout-breadcrumb__item">
            <button
              className={ `pd-button pd-button--glass ${ getActiveClassName( STEP.SHIPPING, 'pd-button--active' ) }` }
              onClick={ handleStepClick.bind( this, STEP.SHIPPING ) }
              disabled={ isLocked || shippingLocked }>
              { hasBasePledges && hasOptionalBuys ? '03' : '02' }. Shipping
            </button>
          </li>
        ) }

        <li className="pd-checkout-breadcrumb__item">
          <button
            className={ `pd-button pd-button--glass ${ getActiveClassName( STEP.PAYMENT, 'pd-button--active' ) }` }
            onClick={ handleStepClick.bind( this, STEP.PAYMENT ) }
            disabled={ isLocked || paymentLocked }>
            { isLatePledge ? '02' : ( hasBasePledges && hasOptionalBuys ? '04' : '03' ) }. Payment
          </button>
        </li>
      </ul>

      { loggedUser.isLogged && hasMultipleWaves && !isLateConfirms && !isLatePledge && !retailer && (

        <InfoTip className="pd-checkout-breadcrumb__shipping-strategy" micro>
          <div className="pd-checkout-breadcrumb__shipping-strategy-container">
            <strong>{ isMultiwave ? `${ waves }-Wave Expedited Shipping` : 'Single-Wave Shipping' }</strong>

            { allowMultiwaveSelection && (
              <button onClick={ handleShippingStrategyChangeClick } disabled={ summary.isLocked } className="pd-button pd-button--navy pd-button--mini">Change</button>
            ) }
          </div>
        </InfoTip>

      ) }

    </nav>

  ) : null

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default CheckoutBreadcrumb