import React from 'react'
import { Link } from 'react-router-dom'

import { getImageSrc, formatCurrency, getReadbleOrderState } from '../../config/adapters'
import { PLEDGE_STATE } from '../../config/constants'

import { WarningTip, SuccessTip, ErrorTip } from '../../components/tip/tip'

import './user-pledges-list.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const UserPledgesList = props => {

  const { className = '', collection = [] } = props
  const imageUnavailable = 'https://placehold.it/640x360?text=Unavailable+image'

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const renderPledgeState = ( pledgeState, pledgeManagerState ) => {

    switch ( pledgeState ) {
      case PLEDGE_STATE.FINISHED:
      case PLEDGE_STATE.READY_TO_SHIP:
      case PLEDGE_STATE.SHIPPED:
        return ( <SuccessTip micro>{ getReadbleOrderState( pledgeState, pledgeManagerState ) }</SuccessTip> )

      case PLEDGE_STATE.PENDING:
      case PLEDGE_STATE.UNLOCKED:
        return ( <WarningTip micro>{ getReadbleOrderState( pledgeState ) }</WarningTip> )

      case PLEDGE_STATE.REFUNDED:
      case PLEDGE_STATE.CANCELED:
      case PLEDGE_STATE.RETAILER_MISSED_DEADLINE:
      case PLEDGE_STATE.ERRORED:
        return ( <ErrorTip micro>{ getReadbleOrderState( pledgeState ) }</ErrorTip> )

      default:
        return null
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return collection.length > 0 ? (

    <section className={ `pd-user-pledges-list ${ className }` }>
      <div className="pd-user-pledges-list__container pd-container">
        <h2 className="pd-user-pledges-list__title pd-title-2">Your<br />pledges</h2>
        <ul className="pd-user-pledges-list__list">
          { collection.map( ( pledge, index ) => (

            <li key={ index } className="pd-user-pledges-list__item">
              <Link className="pd-user-pledges-list__link" to={ `/checkout/${ pledge.project.slug }` }>
                <img src={ getImageSrc( pledge.project.image, { w: 640, h: 360, resizeTo: 'fill', quality: 60 } ) || imageUnavailable } alt={ pledge.project.name } />
                <div className="pd-user-pledges-list__wrapper">
                  { renderPledgeState( pledge.state, pledge.project.pledgeManagerState ) }
                  <h4 className="pd-title-3">{ pledge.project.name }</h4>
                  <p className="pd-text">Order total: { formatCurrency( pledge.total ) }</p>
                </div>
              </Link>
            </li>

          ) ) }
        </ul>
      </div>
    </section>

  ) : null

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default UserPledgesList