import React, { useEffect, useContext, useState } from 'react'
import { Prompt, useParams } from 'react-router-dom'
import { Formik, Field, Form } from 'formik'
import { cloneDeep, find } from 'lodash'
import arrayMove from 'array-move'

import { DashboardContext } from '../../context/dashboard-state'
import { UIContext } from '../../context/ui-state'

import { sFetch } from '../../config/fetch'
import { toSnakeCase } from '../../config/adapters'
import { FLOAT_REGEX, CURRENCY_REGEX, NOT_EMPTY_REGEX } from '../../config/validators'
import { PREVENT_NAVIGATION_MESSAGE } from '../../config/constants'

import { SortableItem, SortableContainer, DragHandle } from '../../components/sortable-list/sortable-list'
import { InfoTip } from '../../components/tip/tip'
import LoadingMessage from '../../components/loading-message/loading-message'
import CustomField from '../../components/custom-field/custom-field'

import './products-form.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const ProductsForm = props => {

  const dashboardContext = useContext( DashboardContext )
  const UI = useContext( UIContext )
  const { project, setProject } = dashboardContext
  const { className = '' } = props
  const [ currentItem, setCurrentItem ] = useState( null )
  const [ isNew, setNew ] = useState( false )
  const [ hasChanges, setHasChanges ] = useState( false )
  const [ tariffCodes, setTariffCodes ] = useState( {
    list: [],
    isFetching: false,
    isFetched: false,
    error: null
  } )
  const { projectId } = useParams()

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleProjectLoad = () => {
    if ( project.isFetched && !project.isUpdating && !currentItem ) {
      const { products } = project.details
      setCurrentItem( products[ 0 ] || null )
      fetchTariffCodes()
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const fetchTariffCodes = async () => {
    setTariffCodes( {
      list: [],
      isFetching: true,
      isFetched: false,
      error: null
    } )

    try {

      const data = await sFetch( `/projects/${ projectId }/products/tariff_codes` )

      setTariffCodes( {
        list: data,
        isFetching: false,
        isFetched: true,
        error: null
      } )

    } catch ( exception ) {

      setTariffCodes( {
        list: [],
        isFetching: false,
        isFetched: false,
        error: exception
      } )

    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleItemClick = item => {
    if ( hasChanges ) if ( !window.confirm( PREVENT_NAVIGATION_MESSAGE ) ) return

    setCurrentItem( item )
    setNew( item.id === 0 )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleValidation = values => {
    let errors = {}

    if ( !NOT_EMPTY_REGEX.test( values.name ) ) errors.name = 'Invalid name'
    if ( !NOT_EMPTY_REGEX.test( values.sku ) ) errors.sku = 'Invalid SKU'
    if ( !FLOAT_REGEX.test( values.width ) ) errors.width = 'Invalid number'
    if ( !FLOAT_REGEX.test( values.height ) ) errors.height = 'Invalid number'
    if ( !FLOAT_REGEX.test( values.weight ) ) errors.weight = 'Invalid number'
    if ( !FLOAT_REGEX.test( values.length ) ) errors.length = 'Invalid number'

    if ( values.slipCost && !CURRENCY_REGEX.test( values.slipCost ) ) errors.slipCost = 'Invalid price'
    if ( values.productionCost && !CURRENCY_REGEX.test( values.productionCost ) ) errors.productionCost = 'Invalid price'

    return errors
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSave = async ( payload, actions ) => {
    try {

      actions.setSubmitting( true )
      const options = { method: isNew ? 'post' : 'put', body: JSON.stringify( toSnakeCase( { product: payload } ) ) }
      const endpoint = `/projects/${ project.details.id }/products/${ isNew ? '' : payload.id }`
      const newProduct = await sFetch( endpoint, options )

      let updatedProducts = cloneDeep( project.details.products )

      if ( find( updatedProducts, { id: newProduct.id } ) ) {

        updatedProducts = updatedProducts.map( product => product.id === newProduct.id ? newProduct : product )

      } else {

        updatedProducts.push( newProduct )
      }

      setProject( { ...project, details: { ...project.details, products: updatedProducts } } )

      setCurrentItem( null )
      setHasChanges( false )
      setNew( false )

      actions.setSubmitting( false )
      UI.showSuccessDisclaimer( 'Your product was saved successfully!' )

    } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleSortEnd = async event => {
    const { oldIndex, newIndex } = event
    const { products } = project.details
    const item = products[ oldIndex ]

    setProject( { ...project, details: { ...project.details, products: arrayMove( products, oldIndex, newIndex ) } } )

    const options = { method: 'put', body: JSON.stringify( { position: ( newIndex + 1 ) } ) }
    const endpoint = `/projects/${ projectId }/products/${ item.id }/insert_at`
    sFetch( endpoint, options )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleDelete = async event => {
    event.preventDefault()

    try {

      if ( !window.confirm( 'Are you sure?' ) ) return

      const options = { method: 'delete' }
      const endpoint = `/projects/${ project.details.id }/products/${ currentItem.id }`

      await sFetch( endpoint, options )
      const updatedProducts = project.details.products.filter( item => item.id !== currentItem.id )
      setProject( { ...project, details: { ...project.details, products: updatedProducts } } )

      setCurrentItem( null )
      setHasChanges( false )

      UI.showSuccessDisclaimer( 'Your product was deleted successfully!' )

    } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }

  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleCancel = event => {
    if ( hasChanges ) if ( !window.confirm( PREVENT_NAVIGATION_MESSAGE ) ) {
      event.preventDefault()
      return false
    }

    setCurrentItem( null )
    setNew( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )
  useEffect( handleProjectLoad, [ project ] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <section className={ `pd-products-form pd-tpl-nav-content ${ className }` }>
      <Prompt when={ hasChanges } message={ location => PREVENT_NAVIGATION_MESSAGE } />
      { project.isFetched ? (
        <>

          <nav className="pd-tpl-nav-content__nav">
            <h4 className="pd-tpl-nav-content__type pd-title-4">Products</h4>

            <button
              className={ `pd-button pd-button--navy ${ isNew ? 'pd-button--active' : '' }` }
              onClick={ handleItemClick.bind( this, { id: 0 } ) }>
              Add new product
            </button>

            <SortableContainer className="pd-tpl-nav-content__list" tagName="ul" onSortEnd={ handleSortEnd } useDragHandle>
              { project.details?.products.map( ( item, index ) => {
                const isActive = item.id === ( currentItem && currentItem.id )
                return (
                  <SortableItem
                    key={ index }
                    index={ index }
                    tagName="li"
                    className={ `pd-text pd-tpl-nav-content__item ${ isActive ? 'pd-tpl-nav-content__item--active' : '' }` }
                    onClick={ handleItemClick.bind( this, item ) }>
                    <DragHandle className="pd-tpl-nav-content__sort" />
                    <span>{ item.name }</span>
                  </SortableItem>
                )
              } ) }
            </SortableContainer>
          </nav>

          { currentItem ?
            (

              <Formik
                initialValues={ {
                  id: currentItem.id || '',
                  sku: currentItem.sku || '',
                  stockLimit: currentItem.stockLimit || '',
                  stockAlertThreshold: currentItem.stockAlertThreshold || 0,
                  wave: currentItem.wave || '',
                  name: currentItem.name || '',
                  width: currentItem.width || '',
                  height: currentItem.height || '',
                  weight: currentItem.weight || '',
                  length: currentItem.length || '',
                  slipCost: currentItem.slipCost || '',
                  productionCost: currentItem.productionCost || '',
                  customsTariffCodeId: currentItem.customsTariffCodeId || ''
                } }
                enableReinitialize={ true }
                onSubmit={ handleSave }
                validate={ handleValidation }
                validateOnChange={ false }
                validateOnBlur={ false }>
                { fProps => (

                  <Form className={ `pd-tpl-nav-content__main` } onChange={ event => setHasChanges( true ) }>
                    <div className="pd-tpl-nav-content__box pd-products-form__main">
                      <span className="pd-text-sm pd-text--tip pd-tpl-nav-content__required">
                        <span className="pd-text--info">*</span> Required fields
                      </span>

                      <Field type="hidden" name="id" />
                      <Field type="hidden" name="productionCost" />

                      <div className="pd-tpl-nav-content__fields pd-products-form__fields">
                        <CustomField name="name" label="Name" className="pd-products-form__field-name" required />
                        <CustomField name="customsTariffCodeId" label="Custom tariff code" className="pd-products-form__field-tariff-code" component="select" disabled={ tariffCodes.isFetching } required>
                          <option>{ tariffCodes.isFetching ? 'Fetching codes...' : 'Please select' }</option>
                          { tariffCodes.list.map( ( item, index ) => (
                            <option key={ index } value={ item.id }>{ `${ item.description } ( ${ item.code } )` }</option>
                          ) ) }
                        </CustomField>
                        <CustomField name="stockLimit" label="Stock" className="pd-products-form__field-stock-limit" required />
                        <CustomField name="stockAlertThreshold" label="Stock alert threshold" className="pd-products-form__field-threshold" type="number" />
                        <CustomField name="sku" label="SKU" className="pd-products-form__field-sku" required />
                        <CustomField name="slipCost" label="Unit cost" className="pd-products-form__field-slip" required />
                        <CustomField name="weight" label="Weight" className="pd-products-form__field-weight" required />
                        <CustomField name="width" label="Width" className="pd-products-form__field-width" required />
                        <CustomField name="length" label="Length" className="pd-products-form__field-length" required />
                        <CustomField name="height" label="Height" className="pd-products-form__field-height" required />
                        <CustomField name="wave" label="Wave" className="pd-products-form__field-wave" required />
                      </div>

                      <nav className="pd-tpl-nav-content__actions">
                        <button className="pd-button pd-button--navy" type="submit" disabled={ tariffCodes.isFetching || fProps.isSubmitting }>{ fProps.isSubmitting ? 'Processing...' : 'Save' }</button>
                        <span className="pd-tpl-nav-content__actions-wrapper">
                          { isNew ? null : <button className="pd-button pd-button--danger" onClick={ handleDelete }>Delete</button> }
                          <button className="pd-button pd-button--glass-dark" onClick={ handleCancel }>Cancel</button>
                        </span>
                      </nav>
                    </div>
                  </Form>

                ) }
              </Formik>

            ) : (

              <div className="pd-tpl-nav-content__main">
                <InfoTip>Use the products list to select an item to edit.</InfoTip>
              </div>

            )
          }

        </>
      ) : <LoadingMessage /> }
    </section>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default ProductsForm