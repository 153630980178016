import React, { forwardRef, Component } from 'react'
import { isString } from 'lodash'

import { PREVENT_NAVIGATION_MESSAGE } from '../../config/constants'

import './lightbox.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const wrapper = WrappedComponent => {
  class Wrapper extends Component {
    render() {
      const { forwardedRef, ...rest } = this.props
      return ( <WrappedComponent ref={ forwardedRef } { ...rest } /> )
    }
  }

  return forwardRef( ( props, ref ) => <Wrapper { ...props } forwardedRef={ ref } /> )
}


// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

class Lightbox extends Component {

  state = {
    isActive: this.props.portalBag.isOpen,
    isLocked: this.props.isLocked || false,
    askBeforeClose: false
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  close() {
    const { closePortal } = this.props.portalBag
    this.setState( { askBeforeClose: false, isLocked: false, isActive: false } )
    setTimeout( () => closePortal(), 320 )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  safeClose() {
    const { isLocked, askBeforeClose } = this.state
    if ( isLocked ) return
    if ( askBeforeClose && !window.confirm( isString( askBeforeClose ) ? askBeforeClose : PREVENT_NAVIGATION_MESSAGE ) ) return

    this.close()
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  lock() {
    this.setState( { ...this.state, isLocked: true } )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  unlock() {
    this.setState( { ...this.state, isLocked: false } )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  askBeforeClose( ask = true ) {
    this.setState( { ...this.state, askBeforeClose: ask } )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  render() {
    const { isActive, isLocked } = this.state

    return (
      <div className={ `pd-lightbox ${ isActive ? 'pd-lightbox--active' : '' } ${ isLocked ? 'pd-lightbox--locked' : '' }` }>
        <span className="pd-lightbox__curtain" onClick={ this.safeClose.bind( this ) }></span>
        <div className="pd-lightbox__wrapper">
          { !isLocked && <button className="pd-lightbox__close" onClick={ this.safeClose.bind( this ) }></button> }
          { this.props.children }
        </div>
      </div>
    )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default wrapper( Lightbox )