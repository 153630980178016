import React, { useEffect } from 'react'
import { fill } from 'lodash'

import { formatNumber } from '../../config/adapters'

import { ReactComponent as IconRight } from '../../assets/icons/right.svg'
import { ReactComponent as IconLeft } from '../../assets/icons/left.svg'

import './meta-pagination.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const MetaPagination = props => {

  const { className = '', meta, alias = 'results', onChange = () => null } = props
  const { currentPage, nextPage, prevPage, totalPages, totalCount } = meta
  const perPage = Math.ceil( totalCount / totalPages )
  const startsIn = ( perPage * ( currentPage - 1 ) ) + 1
  let endsIn = ( startsIn - 1 ) + perPage
  endsIn = endsIn > totalCount ? totalCount : endsIn

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return meta && totalPages > 1 ? (

    <div className={ `pd-meta-pagination ${ className }` }>
      <p className="pd-meta-pagination__status pd-text">
        Showing { formatNumber( startsIn ) } - { formatNumber( endsIn ) } of { formatNumber( totalCount ) } { alias }
      </p>

      <nav className="pd-meta-pagination__controls">
        <button onClick={ onChange.bind( this, prevPage ) } disabled={ !prevPage }><IconLeft className="pd-icon" /></button>

        <select className="pd-select" onChange={ event => onChange( event.target.value ) } defaultValue={ currentPage }>
          { fill( Array( totalPages ) ).map( ( item, index ) => (
            <option key={ index + 1 } value={ index + 1 }>{ index + 1 }</option>
          ) ) }
        </select>

        <button onClick={ onChange.bind( this, nextPage ) } disabled={ !nextPage }><IconRight className="pd-icon" /></button>
      </nav>
    </div>

  ) : null

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default MetaPagination