import React, { useEffect, useContext, useState } from 'react'
import { Formik, Form } from 'formik'
import { useParams } from 'react-router-dom'
import { capitalize, inRange } from 'lodash'
import autosize from 'autosize'
import moment from 'moment-timezone'

import { UIContext } from '../../context/ui-state'
import { DashboardContext } from '../../context/dashboard-state'

import Env from '../../config/env'
import { formatCurrency } from '../../config/adapters'
import { NOT_EMPTY_REGEX, CURRENCY_REGEX, ACCEPT_ONLY_FLOAT_REGEX } from '../../config/validators'

import { ErrorTip, InfoTip } from '../../components/tip/tip'
import CustomField from '../../components/custom-field/custom-field'
import CheckboxField from '../../components/checkbox-field/checkbox-field'

import './refund-form.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const RefundForm = props => {

  const UI = useContext( UIContext )
  const dashboardContext = useContext( DashboardContext )
  const { refundPledge, fetchPledge } = dashboardContext

  const { transactionId, pledgeId } = useParams()
  const [ isRefunding, setRefunding ] = useState( false )
  const [ isConfirmed, setConfirmed ] = useState( false )

  const { className = '', charge } = props
  const refundableAmount = parseFloat( charge.refundableAmount )
  const isUnrefundable = charge.gateway === 'paypal' && moment().diff( moment( charge.createdAt ), 'days' ) >= parseInt( Env.paypalTransactionRefundMaxAge )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => {
    const element = document.getElementById( 'reason' )
    autosize.destroy( element )
    autosize( element )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleValidation = values => {
    const errors = {}

    if ( !NOT_EMPTY_REGEX.test( values.reason ) ) errors.reason = 'Invalid reason'
    if ( !CURRENCY_REGEX.test( values.amount ) || !inRange( values.amount, 0.01, ( refundableAmount + 0.01 ) ) ) errors.amount = 'Invalid refund amount'

    return errors
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleRefund = async payload => {
    if ( !window.confirm( `Are you sure you want to refund ${ formatCurrency( payload.amount ) }?\n\n${ isUnrefundable ? `REMEMBER: This Paypal transaction is ${ Env.paypalTransactionRefundMaxAge } days or older. If you refund it, this transaction will have to be refunded manually.\n\n` : '' }This action can't be undone.` ) ) return

    setRefunding( true )

    try {
      await refundPledge( payload )
      await fetchPledge( pledgeId )
      UI.showSuccessDisclaimer( 'The refund was processed successfully!' )

    } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }

    setRefunding( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <div className={ `pd-refund-form ${ className }` }>
      <h3 className="pd-title-3">Refund</h3>
      { refundableAmount ?
        (

          <Formik
            initialValues={ { amount: '', reason: '', waiveFee: false, id: transactionId } }
            onSubmit={ handleRefund }
            validate={ handleValidation }
            validateOnChange={ false }
            validateOnBlur={ false }>
            { fProps => (

              <Form className={ `pd-refund-form__form` } disabled={ fProps.isSubmitting || isRefunding }>
                <CustomField label="Amount to be refunded" step="0.01" name="amount" type="number" filter={ ACCEPT_ONLY_FLOAT_REGEX } placeholder="Ex: 100.00" />
                <CustomField label="Reason" name="reason" component="textarea" autoSize />
                <CheckboxField name="waiveFee">Waive Fee</CheckboxField>

                { isUnrefundable ? (

                  <ErrorTip>
                    <div className="pd-refund-form__unrefundable-disclaimer">
                      <h3 className="pd-title-4">Important notice</h3>
                      <p>This <strong>{ capitalize( charge.gateway ) }</strong> transaction is <strong>{ Env.paypalTransactionRefundMaxAge } days or older</strong>.
                        If you refund it, this transaction will have to be <strong>refunded manually</strong>.</p>

                      <label className="pd-refund-form__confirmation" htmlFor="refund-confirmation">
                        <input id="refund-confirmation" type="checkbox" onChange={ evt => setConfirmed( evt.target.checked ) } />
                        <strong>I understood and wish to procceed to refund.</strong>
                      </label>

                      <button
                        type="submit"
                        className={ `pd-button ${ isUnrefundable ? 'pd-button--danger' : 'pd-button--navy' }` }
                        disabled={ fProps.isSubmitting || isRefunding || ( !isConfirmed ) }>
                        { fProps.isSubmitting || isRefunding ? 'Processing...' : 'Refund' }
                      </button>

                    </div>
                  </ErrorTip>

                ) : (

                  <button
                    type="submit"
                    className={ `pd-button ${ isUnrefundable ? 'pd-button--danger' : 'pd-button--navy' }` }
                    disabled={ fProps.isSubmitting || isRefunding }>
                    { fProps.isSubmitting || isRefunding ? 'Processing...' : 'Refund' }
                  </button>

                ) }

                <InfoTip>
                  Processing fees will be charged if Paypal or Stripe refunds are made after 15
                  days of purchase. <strong>Kickstarter refunds are NOT processed by the system at all</strong>,
                  those must be done separately <strong>after</strong> you cancel an order on this system.
                </InfoTip>
              </Form>

            ) }
          </Formik>

        ) : (

          <InfoTip>There are no refundable amounts available for refund.</InfoTip>

        )
      }
    </div>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default RefundForm