import React, { useEffect, useContext, Fragment } from 'react'
import { useParams } from 'react-router-dom'
import { find, toPairs } from 'lodash'

import { DashboardContext } from '../../context/dashboard-state'
import { UIContext } from '../../context/ui-state'

import { getReadbleOrderState } from '../../config/adapters'

import InitialInviteChecker from '../../modules/initial-invite-checker/initial-invite-checker'
import RevenueReportGenerator from '../../modules/revenue-report-generator/revenue-report-generator'
import PledgeTotalsReportGenerator from '../../modules/pledge-totals-report-generator/pledge-totals-report-generator'

import Table from '../../components/table/table'
import Loading from '../../components/loading-message/loading-message'
import ExpansionPanel from '../../components/expansion-panel/expansion-panel'

import './dashboard-project-index.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const DashboardProjectIndex = props => {

  const { className = '' } = props
  const UI = useContext( UIContext )
  const dashboardContext = useContext( DashboardContext )
  const {
    project,
    statistics,
    fetchConsolidatedReport,
    fetchTotalConfirmedReport,
    fetchTotalOrderedReport,
    fetchTotalByHubReport,
  } = dashboardContext
  const { totalConfirmed, totalOrdered, totalByHub, total } = statistics
  const { projectId } = useParams()
  const hubs = totalByHub.isFetched ? totalByHub.data.confirmedPerHub.map( hub => hub.name ) : []

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => {
    try {

      fetchConsolidatedReport( projectId )

    } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleHubReportExpand = () => {
    if ( !totalByHub.isFetched ) {
      try {

        fetchTotalByHubReport( projectId )

      } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleConfirmedReportExpand = () => {
    if ( !totalConfirmed.isFetched ) {
      try {

        fetchTotalConfirmedReport( projectId )

      } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleOrderedReportExpand = () => {
    if ( !totalOrdered.isFetched ) {
      try {

        fetchTotalOrderedReport( projectId )

      } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  // const handleTransactionsReportExpand = () => {
  //   if ( !transactions.isFetched ) {
  //     try {

  //       fetchTransactionsReport( projectId )

  //     } catch ( exception ) { UI.showErrorDisclaimer( exception.message ) }
  //   }
  // }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <section className={ `pd-dashboard-project-index ${ className }` }>
      <InitialInviteChecker project={ project } />

      <RevenueReportGenerator projectId={ projectId } />
      <PledgeTotalsReportGenerator projectId={ projectId } />

      <ExpansionPanel className="pd-dashboard-project-index__panel" onExpand={ handleConfirmedReportExpand } title="Total per status" expanded>
        <div className="pd-dashboard-project-index__columns">
          { total.isFetched ?
            (

              <div className="pd-dashboard-project-index__module">
                <Table
                  cols={ [ { label: 'State', sortable: true }, { label: 'Total', sortable: true } ] }
                  rows={ toPairs( total.data.ordersByState ).map( item => [ getReadbleOrderState( item[ 0 ] ), item[ 1 ] ] ) }
                />
              </div>

            ) : (

              <Loading />

            )
          }
        </div>
      </ExpansionPanel>

      <ExpansionPanel className="pd-dashboard-project-index__panel" onExpand={ handleOrderedReportExpand } title="Total ordered">
        <div className="pd-dashboard-project-index__columns">
          { totalOrdered.isFetched ?
            (

              <>
                <div className="pd-dashboard-project-index__module">
                  <h4 className="pd-title-4">Per pledge</h4>
                  <Table
                    cols={ [ { label: 'Name', sortable: true }, { label: 'Total', sortable: true } ] }
                    rows={ totalOrdered.data.allPerPledge.map( item => [ item.name, item.value ] ) }
                  />
                </div>
                <div className="pd-dashboard-project-index__module">
                  <h4 className="pd-title-4">Per add-on</h4>
                  <Table
                    cols={ [ { label: 'Name', sortable: true }, { label: 'Total', sortable: true } ] }
                    rows={ totalOrdered.data.allPerAddOn.map( item => [ item.name, item.value ] ) }
                  />
                </div>
                <div className="pd-dashboard-project-index__module">
                  <h4 className="pd-title-4">Per SKU</h4>
                  <Table
                    cols={ [ { label: 'SKU', sortable: true }, { label: 'Total', sortable: true } ] }
                    rows={ totalOrdered.data.allPerSku.map( item => [
                      item.sku.split( /\n/g ).map( item => <>{ item }<br /></> ),
                      item.value
                    ] ) }
                  />
                </div>
              </>

            ) : (

              <Loading />
            )
          }
        </div>
      </ExpansionPanel>

      <ExpansionPanel className="pd-dashboard-project-index__panel" onExpand={ handleConfirmedReportExpand } title="Total confirmed">
        <div className="pd-dashboard-project-index__columns">
          { totalConfirmed.isFetched ?
            (

              <>
                <div className="pd-dashboard-project-index__module">
                  <h4 className="pd-title-4">Per pledge</h4>
                  <Table
                    cols={ [ { label: 'Name', sortable: true }, { label: 'Total', sortable: true } ] }
                    rows={ totalConfirmed.data.confirmedPerPledge.map( item => [ item.name, item.value ] ) }
                  />
                </div>
                <div className="pd-dashboard-project-index__module">
                  <h4 className="pd-title-4">Per add-on</h4>
                  <Table
                    cols={ [ { label: 'Name', sortable: true }, { label: 'Total', sortable: true } ] }
                    rows={ totalConfirmed.data.confirmedPerAddOn.map( item => [ item.name, item.value ] ) }
                  />
                </div>
                <div className="pd-dashboard-project-index__module">
                  <h4 className="pd-title-4">Per SKU</h4>
                  <Table
                    cols={ [ { label: 'SKU', sortable: true }, { label: 'Total', sortable: true } ] }
                    rows={ totalConfirmed.data.confirmedPerSku.map( item => [
                      item.sku.split( /\n/g ).map( item => <>{ item }<br /></> ),
                      item.value
                    ] ) }
                  />
                </div>
              </>

            ) : (

              <Loading />

            )
          }
        </div>
      </ExpansionPanel>

      <ExpansionPanel className="pd-dashboard-project-index__panel" onExpand={ handleHubReportExpand } title="Total confirmed by hub">
        <div className="pd-dashboard-project-index__columns">
          { totalByHub.isFetched ?

            hubs.map( ( hubName, index ) => {

              const hubPledge = find( totalByHub.data.confirmedPerHubPerPledge, { hub: hubName } )
              const hubAddOn = find( totalByHub.data.confirmedPerHubPerAddOn, { hub: hubName } )
              const hubSKU = find( totalByHub.data.confirmedPerHubPerSku, { hub: hubName } )

              return (

                <Fragment key={ index }>
                  <h3 className="pd-title-3">{ hubName }</h3>
                  <div className="pd-dashboard-project-index__module">
                    <h4 className="pd-title-4">Per pledge</h4>
                    <Table
                      cols={ [ { label: 'Name', sortable: true }, { label: 'Total', sortable: true } ] }
                      rows={ hubPledge.pledges.map( item => [ item.name, item.value ] ) }
                    />
                  </div>
                  <div className="pd-dashboard-project-index__module">
                    <h4 className="pd-title-4">Per add-on</h4>
                    <Table
                      cols={ [ { label: 'Name', sortable: true }, { label: 'Total', sortable: true } ] }
                      rows={ hubAddOn.pledges.map( item => [ item.name, item.value ] ) }
                    />
                  </div>
                  <div className="pd-dashboard-project-index__module">
                    <h4 className="pd-title-4">Per SKU</h4>
                    <Table
                      cols={ [ { label: 'Name', sortable: true }, { label: 'Total', sortable: true } ] }
                      rows={ hubSKU.pledges.map( item => [
                        item.sku.split( /\n/g ).map( item => <>{ item }<br /></> ),
                        item.value
                      ] ) }
                    />
                  </div>
                </Fragment>

              )
            } ) : (

              <Loading />

            )
          }
        </div>
      </ExpansionPanel>

      {/* <ExpansionPanel className="pd-dashboard-project-index__panel" onExpand={ handleTransactionsReportExpand } title="Transactions">
        <div className="pd-dashboard-project-index__full">
          { transactions.isFetched ?
            (

              <div className="pd-dashboard-project-index__module">
                <Table
                  cols={ [ 'Date', 'Amount', 'Gateway' ] }
                  rows={ transactions.data.map( item => [
                    moment( item.createdAt ).format( 'MMM DD, YYYY, HH:mm' ),
                    `${ formatCurrency( item.amount, defaultCurrencyLocale, Object.assign( {}, defaultCurrencyOptions, { currency: item.currency } ) ) } (${ item.currency })`,
                    `${ capitalize( item.gateway ) } ${ item.gatewayChargeId || '' }`
                  ]
                  ) }
                />
              </div>

            ) : (

              <Loading />

            )
          }
        </div>
      </ExpansionPanel> */}
    </section>

  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
}

export default DashboardProjectIndex